<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="texts">
          <div class="text">
            <div v-for="text in block.First_column">
              <div class="title">
                {{text.Title}}
              </div>

              <div class="body" v-html="markdownToHTML(text.text)">
              </div>
            </div>
          </div>

          <div class="text">
            <div v-for="text in block.Second_column">
              <div class="title">
                {{text.Title}}
              </div>

              <div class="body" v-html="markdownToHTML(text.text)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  min-height: 1200px;
  display: flex;
  align-items: stretch;

  &.white {
    background-color: #fff;
  }

  .layout {
    justify-content: center;
    align-items: stretch;
    flex: 1 1 auto;
    max-width: 1312px;

    .section {
      max-width: 1200px;
      margin: pxToEm(50);
      text-align: left;

      .texts {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .text {
          max-width: pxToEm(541);
          min-height: pxToEm(307);
          margin-right: pxToEm(50);
          margin-bottom: pxToEm(70);

          &:not(:nth-child(odd)) {
            margin-right: 0;
          }

          .title {
            font-size: pxToPercent(36);
            margin-bottom: pxToEm(20);
          }

          .body {
            font-size: pxToPercent(18);
            line-height: 2.5em;
            margin-bottom: 80px;

            .bold {
              font-weight: 700;
              margin-top: 50px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .text {
    max-width: 100% !important;
    padding: 0 pxToEm(115);
  }
}
</style>

<script>
  import {marked} from 'marked'

  export default {
    name: "DesktopTwoColumnSectionBlock",
    methods: {
      markdownToHTML: function(markdown) {
        return marked(markdown);
      }
    },
    props: {
      block: Object
    }
  };
</script>
