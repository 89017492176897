<template>
  <div>
    <div class="layout">
        <div class="top">
          <div ref="trustpilotDesk" class="trustpilot-widget"  data-locale="nl-NL" :data-style-width="width" :data-style-height="height" :data-template-id="templateId" :data-businessunit-id="businessUnitId" data-theme="light" data-stars="1,2,3,4,5" data-review-languages="nl">

            <a href="https://nl.trustpilot.com/review/leenattent.nl" target="_blank" rel="noopener">Trustpilot</a>
          </div>
          <div ref="trustpilotMob" class="trustpilot-widget d-desktop-none"  data-locale="nl-NL" :data-style-height="height" d:data-template-id="templateId" :data-businessunit-id="businessUnitId" data-style-width="100%" data-theme="light" data-stars="1,2,3,4,5" data-review-languages="nl">

            <a href="https://nl.trustpilot.com/review/leenattent.nl" target="_blank" rel="noopener">Trustpilot</a>
          </div>
        </div>
      </div>
  </div>
</template>

<style lang="less" scoped>
  .d-mobile-none{
    display:block
  }
  .d-desktop-none{
    display:none
  }
  @media only screen and (max-width:767px){
    .d-mobile-none{
      display:none;
    }
    .d-desktop-none{
      display:block;
    }
  }
</style>

<script>

export default {
  name: 'trustpilot',
  props: {
    templateId: {
      type: String,
      default: "539ad60defb9600b94d7df2c"
    },
    businessUnitId: {
      type: String,
      default: "5a0c4c430000ff0005b089a3"
    },
    height: {
      type: String,
      default: "500px"
    },
    width: {
      type: String,
      default: "100%"
    }
  },
  mounted() {
    window.Trustpilot.loadFromElement(this.$refs.trustpilotDesk, true)
    window.Trustpilot.loadFromElement(this.$refs.trustpilotMob, true)
  },
}
</script>
