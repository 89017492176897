<template>
  <div>
    <MobileBigCtaSectionBlock :block="block" v-if="isMobile"></MobileBigCtaSectionBlock>
    <DesktopBigCtaSectionBlock :block="block" v-else></DesktopBigCtaSectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>

import MobileBigCtaSectionBlock from "@/components/strapi/BigCtaSectionBlock/MobileBigCtaSectionBlock";
import DesktopBigCtaSectionBlock from "@/components/strapi/BigCtaSectionBlock/DesktopBigCtaSectionBlock";

export default {
  name: 'BigCtaSectionBlock',
  components: {
    MobileBigCtaSectionBlock,
    DesktopBigCtaSectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
