<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="title">
            <div class="txt">
              Cookieverklaring
            </div>
          </div>
  
          <div class="dash"></div>
  
          <div class="text">
            <div class="txt">
              Wij maken gebruik van cookies. In deze cookieverklaring wordt aangegeven op welke wijze cookies worden geplaatst en uitgelezen van uw computer en randapparatuur. Hierin zal ook aangegeven worden voor welke doeleinden de verzamelde informatie via cookies wordt gebruikt. We raden u aan deze cookieverklaring zorgvuldig door te nemen. Wij adviseren u bij het openen van onze website om alle cookies aan te zetten voor de optimale webervaring. U kunt uw cookie instellingen te allen tijde aanpassen. Het is echter mogelijk dat sommige delen van onze website niet correct werken als u cookies uitschakelt.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor('greyLight');

    .layout {
      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: 0 pxToEm(25);

          .title {
            .txt {
              font-size: pxToPercent(28);
              font-weight: 800;
              line-height: 1em;
            }
          }

          .dash {
            width: pxToEm(91);
            height: pxToEm(8);
            margin-top: pxToEm(10);
            background-color: getColor("primaryLight");
          }

          .text {
            margin-top: pxToEm(25);

            .txt {
              font-size: pxToPercent(16);
              line-height: 2.1em;
            }
          }
        }
      }
    }
  }
</style>

<script>
  export default {
    name: "cookiesHeader"
  };
</script>