<template>
  <div class="calc-area">
    <div id="anchor-calc-b-top" style="position:absolute; top:-300px;"></div>

    <div
      class="btn-1"
      @click="toggleForm(true)"
      style="position:absolute; left:50%; top:0; transform:translate(-50%,-50%); z-index:300;"
    >
      <btn-big class="primary" id="calculator-form-btn" :width="448" :height="106">
        <div class="content">
          <div class="txt">Bereken hoeveel rente<br>u kunt besparen</div>
          <img src="@/assets/arrow.svg" alt>
        </div>
      </btn-big>
    </div>

    <div class="calculator" v-if="formArea">
      <div class="sep-120"></div>

      <div class="calc-content">
        <div id="calc-top"></div>
        <div class="title">Hoeveel rente kan ik besparen?</div>

        <div class="sep-50"></div>

        <div class="calc-content-body">
          <div class="row-11 select-input">
            <div class="txt-label">
              <span>Hoeveel leningen heeft u lopen?</span>
            </div>
            <div class="inputs">
              <input-dropdown
                :init="$store.state._loanRequest.prevLoans.length > 0"
                :options="$store.state.loansDropdown"
                :placeholder="'0'"
                v-model="Loans"
              ></input-dropdown>
            </div>
          </div>

          <div
            class="error"
            v-show="form.state == false"
          >Er zijn enkele velden niet correct ingevuld.</div>

          <div class="loans">
            <div
              class="loan"
              v-for="(loan, index) in $store.state._loanRequest.prevLoans"
              :key="index"
              :class="{formcheck: form.state == false}"
            >
              <div class="sep-50"></div>

              <div class="row-sub-title">
                <div class="sub-title">Lening {{ index + 1 }}</div>
              </div>

              <div class="sep-50"></div>

              <div class="calc-content-body">
                <div class="row-11 num-input">
                  <div class="txt-label">
                    <span>Leenbedrag</span>
                  </div>
                  <div class="inputs">
                    <input-num v-model="loan.amount"></input-num>
                  </div>
                </div>

                <div class="row-11 num-input">
                  <div class="txt-label">
                    <span>Effectieve jaarrente</span>
                  </div>
                  <div class="inputs">
                    <input-num v-model="loan.rate"></input-num>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sep-100"></div>
    </div>

    <btn-big
      class="primary btn-bereken"
      v-if="$store.state._loanRequest.prevLoans.length > 0 && formArea"
      :width="293"
      :height="92"
      :click="toggleBlackArea"
    >
      <div class="content">
        <div class="txt">Bereken</div>
        <img src="@/assets/arrow.svg" alt>
      </div>
    </btn-big>

    <div class="sep-100" v-if="$store.state._loanRequest.prevLoans.length > 0 && formArea"></div>
    <div id="black-area-top"></div>
    <black-area v-if="blackFormArea"></black-area>

    <div
      class="white-button"
      v-if="formArea"
      @click="toggleForm(false)"
      v-anchor="'#anchor-calc-b-top'"
    >
      <div class="arrow-cont">
        <div class="arrow"></div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 3em;
@gridColGap: 1.25em;

.area {
  .btn-big {
    border-bottom-width: pxToEm(18);

    .content {
      margin-left: pxToEm(40);
      margin-right: pxToEm(40);

      .txt {
        font-size: pxToPercent(20);
        font-weight: 700;
        text-align: left;
      }

      img {
        width: pxToEm(56);
        height: pxToEm(45);
      }
    }
  }

  .calculator {
    max-width: pxToEm(920);
    margin: 0 auto;
    background-color: getColor("whiteColor");

    .calc-content {
      margin: 0 auto;
      text-align: left;

      .title {
        font-size: pxToPercent(60);
        font-weight: 300;
      }

      .error {
        color: getColor("redColor");
        font-style: italic;
      }

      .calc-content-body {
        display: grid;
        grid-row-gap: @gridRowGap;

        .input-dropdown {
          width: pxToEm(180);
        }

        .input-num {
          width: pxToEm(180);
        }

        .txt-label {
          font-size: pxToPercent(18);
          font-weight: 300;
          line-height: 2.2em;

          .txt-label-long {
            max-width: pxToEm(290);
            line-height: 1.25em;
          }
        }

        .row-11 {
          height: pxToEm(70);
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: @gridColGap;
          align-items: center;
        }
      }

      .loans {
        .row-sub-title {
          height: pxToEm(70);
          display: grid;
          align-items: center;

          .sub-title {
            font-size: pxToPercent(24);
          }
        }
      }
    }
  }

  .btn-bereken {
    margin: 0 auto;
  }

  .white-button {
    width: pxToEm(50);
    height: pxToEm(50);
    position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: getColor("whiteColor");
    box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
    z-index: 100;
    cursor: pointer;

    .arrow-cont {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .arrow {
        display: inline-block;
        position: relative;
        transform: rotate(-135deg);
        margin-top: pxToEm(5);
        padding: pxToEm(5);
        border: solid getColor("primaryLight");
        border-width: 0 pxToEm(3) pxToEm(3) 0;
      }
    }
  }
}
</style>

<script>
import blackArea from "@/components/desktop/pdp/B/black-area";
import btnBig from "@/components/common/btn-big";
import inputDropdown from "@/components/common/input-dropdown";
import inputNum from "@/components/common/input-num";
import { sumLenings } from "@/data/leningOversluitenCalc";
import { goToAnchor, checkTab } from "@/JS/helpers";

export default {
  name: "calculatorB",
  components: {
    blackArea,
    btnBig,
    inputDropdown,
    inputNum
  },
  data() {
    return {
      blackFormArea: false,
      formArea: false,
      form: {
        fields: [$store.state._loanRequest.prevLoans],
        state: undefined
      }
    };
  },
  created(){
    this.Loans = 1;
  },
  computed: {
    Loans: {
      get() {
        var loanRequest = $store.state._loanRequest;
        var res = new Number(loanRequest.prevLoans.length);
        res.ui = loanRequest.prevLoans.ui;
        return res;
      },
      set(n) {
        var loanRequest = $store.state._loanRequest;
        if (n != loanRequest.prevLoans.length) {
          if (n > 0) {
            loanRequest.prevLoans.ui.init = true;
            loanRequest.prevLoans.ui.valid = true;
          } else {
            loanRequest.prevLoans.ui.valid = false;
          }
          if (n > loanRequest.prevLoans.length) {
            for (var i = loanRequest.prevLoans.length; i < n; i++) {
              loanRequest.prevLoans.push($store.state.prevLoanTemplate());
            }
          } else loanRequest.prevLoans.splice(n);
        }
      }
    }
  },
  methods: {
    getLoans(n) {
      var vm = this;
      if (n != vm.loans.length)
        if (n > vm.loans.length) {
          for (var i = vm.loans.length; i < n; i++) {
            vm.loans.push({ name: "", amount: 0, rate: 0, ransom: null });
          }
        } else vm.loans.splice(n);
      return vm.loans;
    },
    toggleBlackArea: function() {
      this.form.state = checkTab(this.form.fields);

      if (this.form.state) {
        $store.dispatch('calculateSavings');
        this.blackFormArea = true;
        goToAnchor("#black-area-top");
      } else {
        goToAnchor("#calc-top");
      }
    },
    toggleForm: function(state) {
      this.formArea = state;

      if (state == false) {
        this.blackFormArea = state;
        $store.commit("resetForms");
        $store.state._loanRequest.loanOption = $store.state.funnelBOption;
        this.form.state = undefined;
      } else {
        goToAnchor("#black-area-top");
      }
    }
  }
};
</script>
