<template>
  <div>
    <doorlopend-header class="area"></doorlopend-header>
    <calculator class="area"></calculator>
    <doorlopend-body class="area"></doorlopend-body>
    <safe-loan-info class="area"></safe-loan-info>
    <loan-info class="area"></loan-info>
    <desk-contact class="area"></desk-contact>
  </div>
</template>


<style lang="less" scoped>
</style>
 
 <script>
import deskContact from "@/components/desktop/desk-contact.vue";
import doorlopendHeader from "@/components/desktop/doorlopend/header.vue";
import calculator from "@/components/desktop/calculators/A/calculator-form";
import doorlopendBody from "@/components/desktop/doorlopend/body.vue";
import safeLoanInfo from "@/components/desktop/safe-loan-info.vue";
import loanInfo from "@/components/desktop/loan-bottom-info.vue";
export default {
  name: "deskDoorlopend",
  components: {
    doorlopendHeader,
    deskContact,
    calculator,
    doorlopendBody,
    safeLoanInfo,
    loanInfo
  },
  created() {
    $store.commit('resetForms');
    $store.state._loanRequest.loanType = $store.state.loanType.DOORLOPEND;
  }
};
</script>