var defaults = {
  defaultFontSize: 16,
  colors: {
    primaryLight: '#FF4367',
    primaryDefault: '#C82346',
    greyLight: '#f2f2f2',
    greenDefault: '#65CE55',
    whiteColor: '#ffffff',
    redColor: '#ff0000',
    grayLtColor: '#c0c0c0',
    grayMidColor: '#5d5d5d',
    grayDkColor: '#3a3a3a',
    outlineColor: '#1a1818',
    textColor: '#3a3a3a',
    blackColor: '#000000',
    bgColor: '#ffffff',
    disabledText: '#3a3a3a',
    disabledBg: '#dddddd'
  },
  sliderCSSDesktop: {
    trackThickness: 2,
    thumbSize: 36,
    thumbBorder: 2,
    thumbShadowOffset: 3,
    thumbShadowRadius: 5
  },
  sliderCSSMobile: {
    trackThickness: 2,
    thumbSize: 30,
    thumbBorder: 2,
    thumbShadowOffset: 3,
    thumbShadowRadius: 5
  }                                    
};

module.exports = defaults;
