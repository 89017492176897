<template>
  <div>
    <div class="layout">
      <div class="pic" :style="{ 'background-image': 'url(' + bodyImg + ')' }"></div>

      <div class="section">
        <div class="container-a">
          <div class="title">
            <div class="txt">
              Wat zijn cookies?
            </div>
          </div>

          <div class="text">
            <div class="txt spaced-lines">
              Cookies zijn kleine tekstbestanden die door websites kunnen worden gebruikt om gebruikerservaringen efficiënter te maken. Deze worden opgeslagen op uw computer, smartphone, tablet en andere apparaten met internettoegang wanneer u onze website bezoekt. Een cookie heeft een levensduur. Hiermee wordt bepaald hoelang de cookie op uw computer blijft staan. Volgens de wet mogen wij cookies op uw apparaat opslaan als ze strikt noodzakelijk zijn voor het gebruik van de site. Voor alle andere soorten cookies hebben wij uw toestemming nodig.
              <br>
              Onze website maakt gebruik van verschillende soorten cookies. Sommige cookies worden geplaatst door diensten van derden die op onze pagina’s worden gebruikt.
            </div>
          </div>

          <div class="sep-50"></div>

          <div class="subtitle">
            <div class="txt">
              Waar gebruiken wij cookies voor?
            </div>
          </div>

          <div class="text">
            <div class="txt spaced-lines">
              Cookies worden gebruikt om te identificeren welke pagina’s bezocht worden. Dit wordt gedaan om gegevens te kunnen analyseren over het webverkeer op onze website. Op deze manier kunnen wij de website verbeteren en aanpassen aan de behoeftes van de gebruikers. Verzamelde gegevens worden niet gebruikt om activiteiten van individuele gebruikers te volgen. Over het algemeen helpen cookies ons om u een betere website te bieden.
            </div>

            <div class="sep-30"></div>

            <div class="txt spaced-lines">
              Wij kunnen een contract afsluiten met derden (Zoals Social media en advertentie kanalen) die cookies kunnen gebruiken op onze website en namens ons informatie kunnen verzamelen. Het is verboden voor deze derden om informatie te delen met anderen.
            </div>

            <div class="sep-30"></div>

            <div class="txt spaced-lines">
              Welke soorten cookies gebruikt onze website? Als bezoeker kunt u kiezen voor drie soorten cookies. Bij de keuze voor noodzakelijke cookies ontvangt u een minimum set van cookies, waarbij sommige functionaliteiten op de website wegvallen. Bij de keuze voor alle cookies, krijgt u de meest rijke webervaring zoals u altijd gewend was. Het is niet mogelijk om de noodzakelijke cookies uit te schakelen. De website kan niet functioneren zonder deze cookies.
            </div>
          </div>

          <div class="sep-20"></div>

          <router-link class="text" to="/berekenZelf">
            <div class="txt bold">
              Verander uw toestemming
            </div>
          </router-link>

          <div class="sep-60"></div>

          <div class="subtitle">
            <div class="txt">
              Noodzakelijke cookies
            </div>
          </div>

          <div class="table">
            <div class="text">
              <div class="txt semi-bold">
                Naam
              </div>
            </div>

            <div class="text">
              <div class="txt">
                cnAnalytics
              </div>
            </div>

            <div class="text">
              <div class="txt">
                cnMarketing
              </div>
            </div>

            <div class="gap"></div>
            
            <div class="text">
              <div class="txt semi-bold">
                Aanbieder
              </div>
            </div>

            <div class="text">
              <div class="txt">
                leenattent.nl
              </div>
            </div>

            <div class="text">
              <div class="txt">
                leenattent.nl
              </div>
            </div>

            <div class="gap"></div>

            <div class="text">
              <div class="txt semi-bold">
                Doel
              </div>
            </div>

            <div class="text">
              <div class="txt">
                Cookie voorkeuren voor analytisch
              </div>
            </div>

            <div class="text">
              <div class="txt">
                Cookie voorkeuren voor analytisch
              </div>
            </div>

            <div class="gap"></div>

            <div class="text">
              <div class="txt semi-bold">
                Vervaldatum
              </div>
            </div>

            <div class="text">
              <div class="txt">
                60 dagen
              </div>
            </div>

            <div class="text">
              <div class="txt">
                60 dagen
              </div>
            </div>

            <div class="gap"></div>

            <div class="text">
              <div class="txt semi-bold">
                Type
              </div>
            </div>

            <div class="text">
              <div class="txt">
                HTTP Cookie
              </div>
            </div>

            <div class="text">
              <div class="txt">
                HTTP Cookie
              </div>
            </div>

            <div class="gap"></div>
          </div>

          <div class="sep-50"></div>

          <div class="subtitle">
            <div class="txt">
              Analytische cookies
            </div>
          </div>

          <div class="table">
            <div class="text">
              <div class="txt semi-bold">
                Naam
              </div>
            </div>

            <div class="text">
              <div class="txt">
                _ga
              </div>
            </div>

            <div class="text">
              <div class="txt">
                _gat
              </div>
            </div>

            <div class="text">
              <div class="txt">
                _gid
              </div>
            </div>
            
            <div class="text">
              <div class="txt semi-bold">
                Aanbieder
              </div>
            </div>

            <div class="text">
              <div class="txt">
                leenattent.nl
              </div>
            </div>

            <div class="text">
              <div class="txt">
                leenattent.nl
              </div>
            </div>

            <div class="text">
              <div class="txt">
                leenattent.nl
              </div>
            </div>

            <div class="text">
              <div class="txt semi-bold">
                Doel
              </div>
            </div>

            <div class="text">
              <div class="txt">
                Registreert een uniek ID die wordt gebruikt om statistische gegevens te genereren over hoe de bezoeker de website gebruikt.
              </div>
            </div>

            <div class="text">
              <div class="txt">
                Gebruikt door Google Analytics om verzoeksnelheid te vertragen.
              </div>
            </div>

            <div class="text">
              <div class="txt">
                Registreert een uniek ID die wordt gebruikt om statistische gegevens te genereren over hoe de bezoeker de website gebruikt.
              </div>
            </div>

            <div class="text">
              <div class="txt semi-bold">
                Vervaldatum
              </div>
            </div>

            <div class="text">
              <div class="txt">
                2 jaar
              </div>
            </div>

            <div class="text">
              <div class="txt">
                Sessie
              </div>
            </div>

            <div class="text">
              <div class="txt">
                1 dag
              </div>
            </div>

            <div class="text">
              <div class="txt semi-bold">
                Type
              </div>
            </div>

            <div class="text">
              <div class="txt">
                HTTP Cookie
              </div>
            </div>

            <div class="text">
              <div class="txt">
                HTTP Cookie
              </div>
            </div>

            <div class="text">
              <div class="txt">
                HTTP Cookie
              </div>
            </div>
          </div>

          <div class="sep-50"></div>

          <div class="subtitle">
            <div class="txt">
              Marketing cookies
            </div>
          </div>

          <div class="table">
            <div class="text">
              <div class="txt semi-bold">
                Naam
              </div>
            </div>

            <div class="text">
              <div class="txt">
                bronld
              </div>
            </div>

            <div class="gap"></div>

            <div class="gap"></div>
            
            <div class="text">
              <div class="txt semi-bold">
                Aanbieder
              </div>
            </div>

            <div class="text">
              <div class="txt">
                leenattent.nl
              </div>
            </div>

            <div class="gap"></div>

            <div class="gap"></div>

            <div class="text">
              <div class="txt semi-bold">
                Doel
              </div>
            </div>

            <div class="text">
              <div class="txt">
                Houdt campagne ID bij op basis van inkomendverkeer.
              </div>
            </div>

            <div class="gap"></div>

            <div class="gap"></div>

            <div class="text">
              <div class="txt semi-bold">
                Vervaldatum
              </div>
            </div>

            <div class="text">
              <div class="txt">
                1 dag
              </div>
            </div>

            <div class="gap"></div>

            <div class="gap"></div>

            <div class="text">
              <div class="txt semi-bold">
                Type
              </div>
            </div>

            <div class="text">
              <div class="txt">
                HTTP Cookie
              </div>
            </div>

            <div class="gap"></div>

            <div class="gap"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor('greyLight');

    .layout {
      .pic {
        width: 100%;
        height: 52vw;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .section {
        margin: pxToEm(40) pxToEm(10) pxToEm(60);
        text-align: left;

        .container-a {
          padding: 0 pxToEm(25);

          .title {
            margin-bottom: pxToEm(10);

            .txt {
              font-size: pxToPercent(24);
              line-height: 1em;
              font-weight: 700;
              text-decoration: underline;
            }
          }

          .subtitle {
            margin-bottom: pxToEm(10);

            .txt {
              font-size: pxToPercent(20);
              font-weight: 700;
            }
          }

          .text {
            .txt {
              font-size: pxToPercent(16);
              line-height: 1.88em;
            }

            .spaced-lines {
              line-height: 2.1em;
            }

            .bold {
              font-weight: 700;
            }

            .semi-bold {
              font-weight: 600;
            }
          }

          a {
            color: getColor('primaryDefault');
          }

          .table {
            display: grid;
            grid-template-columns: 1fr 1.5fr 1.5fr 1.5fr;
            grid-column-gap: 0.65em;
            grid-row-gap: 1.25em;

            .txt {
              font-size: pxToPercent(9);
              line-height: 1.2em;
            }
          }
        }
      }
    }
  }
</style>

<script>
  import bodyImg from "@/assets/cookies-img1.jpg";

  export default {
    name: "mobCookiesBody",
    data() {
      return {
        bodyImg
      };
    }
  };
</script>