import Vue from 'vue'
import Vuex from 'vuex'
import { _person, _loanRequest, _prevLoan, _currentLoans, _newLoan } from './data/data'
import { maxLoanCalc } from "@/data/maxLoanCalc";
import { setCookie, getCookie, startAnalytics, stopAnalytics, startMarketing, stopMarketing } from "@/JS/cookies";
import Api from "@/Api";

Vue.use(Vuex)

window.$store = new Vuex.Store({
    state: {
        Device: {
            DESKTOP: 0,
            TABLET: 1,
            MOBILE: 2
        },
        deviceState: null,
        lastDeviceState: null,
        defaults: {},
        modal: false,
        storedData: false,
        berekenFormValid: false,
        berekenFormData: {},
        formsOfferte: [],
        funnel: {
            A: 0,
            B: 1
        },
        tabErrors: {},
        formSent: false,
        funnelOptions: [
            "Lening oversluiten (optioneel extra lenen)", // Funnel B ?
            "Verbouwing",
            "Auto",
            "Financiele ruimte",
            "Onverwachte uitgaven",
            "Boot",
            "Camper",
            "Caravan",
            "Motor",
            "Keuken",
            "Zonnepanelen",
            "Restschuld hypotheek",
            "Uitkopen ex-partner",
            "Vakantie",
            "Studie",
            "Chalet",
            "Overige, een andere leningsdoel"
        ],
        funnelBOption: 0,
        incomeSourceDropdown: [
            "Vast dienstverband",
            "Tijdelijk dienstverband",
            "Bij uitzendbureau fase A",
            "Bij uitzendbureau fase B",
            "Bij uitzendbureau fase C",
            "WAO uitkering",
            "WIA (IVA) uitkering",
            "WIA (WGA) uitkering",
            "ZZP langer dan 3 jr.",
            "ZZP korter dan 3 jr.",
            "DGA langer dan 3 jr.",
            "DGA korter dan 3 jr.",
            "Pensioen",
            "WW uitkering",
            "Bijstandsuitkering",
            "Geen dienstverband"
        ],
        livingStateDropdown: [
            "Koop",
            "Huur",
            "Inwonend"
        ],
        loanType: {
            PERSOONLIJKE: 0,
            DOORLOPEND: 1,
            ADVIES: 2
        },
        loanTypeOptions: [
            "Persoonlijke lening",
            "Doorlopend krediet",
            "Perspectieflening",
            "Weet ik nog niet. Graag advies."
        ],
        loansDropdown: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
        _person: new _person(),
        _loanRequest: new _loanRequest(),
        _prevLoan: function() {
            return new _prevLoan();
        },
        prevLoanTemplate: function(opt) {
            var o;
            if (opt == undefined)
                o = true;
            else
                o = opt;
            return new _prevLoan(o);
        },
        cookies: {
            _names: {
                analytics: "cnAnalytics",
                marketing: "cnMarketing"
            },
            analytics: null,
            marketing: null
        }
    },
    mutations: {
        changeTabErrors(state, val) {
            state.tabErrors = val;
        },
        changeDevice(state, val) {
            state.deviceState = val;
        },
        setDefaults(state, val) {
            state.defaults = val;
        },
        createPerson(state, val) {
            state._person = new _person();
        },
        createLoanRequest(state, val) {
            state._loanRequest = new _loanRequest();
        },
        createPrevLoan(state, val) {
            state._prevLoan = new _prevLoan();
        },
        resetForms(state, val) {
            state.storedData = false;
            state._person = new _person();
            state._loanRequest = new _loanRequest();
            Vue.set(state._loanRequest, 'currentLoans', new _currentLoans());
            Vue.set(state._loanRequest, 'newLoan', new _newLoan());
            state._prevLoan = new _prevLoan();
        },
        calcMaxLoanAmount(state, val) {
            state._loanRequest.requestedLoanAmount = state._loanRequest.loanAmount;
            var max = maxLoanCalc(state._person, state._loanRequest);
            // console.log("max approved: ",max);
            if (max < state._loanRequest.loanAmount.ui.minVal) max = 0
            else if (max > state._loanRequest.loanAmount.ui.maxVal) max = state._loanRequest.loanAmount.ui.maxVal

            state._loanRequest.maxLoanAmount = max;

            //   //0 - APROVED
            //   //1 - PARTIALLY REJECTED
            //   //2 - REJECTED
            if (state._loanRequest.maxLoanAmount <= 0) state._loanRequest.calcResult = 2;
            else if (state._loanRequest.maxLoanAmount < state._loanRequest.loanAmount && state._loanRequest.loanAmount > 0) state._loanRequest.calcResult = 1;
            else state._loanRequest.calcResult = 0;

            if (state._loanRequest.maxLoanAmount < state._loanRequest.loanAmount)
                state._loanRequest.approvedLoanAmount = max;
            else
                state._loanRequest.approvedLoanAmount = state._loanRequest.loanAmount;

        },
        loadCookies: function(state, initial) {
            var cookies = state.cookies;
            for (var cn in cookies._names) {
                cookies[cn] = getCookie(cookies._names[cn]);
                if (cookies[cn] != null)
                    cookies[cn] = cookies[cn] == 'true' ? true : false;
            }

            if (initial) {
                if (cookies.analytics)
                    startAnalytics();
                else
                    stopAnalytics();

                if (cookies.marketing)
                    startMarketing();
                else
                    stopMarketing();
            }
        },
        acceptCookies: function(state) {
            var cookies = state.cookies;
            var timeoutDays = 60;
            for (var cn in cookies._names) {
                setCookie(cookies._names[cn], cookies[cn], timeoutDays);
            }
            $store.commit('loadCookies', true);
        },
        savingsCalculated: function(state, loanRequest) {
          // state._loanRequest.newLoan = val;
          Vue.set(state._loanRequest, 'currentLoans', loanRequest.currentLoans)
          Vue.set(state._loanRequest, 'newLoan', loanRequest.newLoan)
          console.log('updated newLoan to', state._loanRequest.newLoan);
        }
    },
    actions: {
      calculateSavings({commit, state}) {
        let loanRequest = {...state._loanRequest};

        if (!loanRequest.currentLoans) loanRequest.currentLoans = {};
        if (!loanRequest.newLoan) loanRequest.newLoan = {};

        Api.get(`percentage-section`).then((response) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.attributes
          ) {
            const percentageSection = response.data.data.attributes;

            let total = 0;
            let averageInterest = 0;
            let effectiveAnnualInterest = 0;

            for (let i = 0; i < loanRequest.prevLoans.length; i++) {
              total += loanRequest.prevLoans[i].amount;
            }

            for (let i = 0; i < loanRequest.prevLoans.length; i++) {
              averageInterest += loanRequest.prevLoans[i].amount / total * loanRequest.prevLoans[i].rate / 100;
            }

            if (total >= 50000) {
              effectiveAnnualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_50000) : 4.50;
            } else if (total >= 25000) {
              effectiveAnnualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_25000) : 4.50;
            } else if (total >= 15000) {
              effectiveAnnualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_15000) : 4.70;
            } else if (total >= 10000) {
              effectiveAnnualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_10000) : 5.20;
            } else {
              effectiveAnnualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_0) : 6.10;
            }

            effectiveAnnualInterest /= 100;

            loanRequest.currentLoans.total = total;
            loanRequest.currentLoans.averageInterest = averageInterest * 100;
            loanRequest.currentLoans.interestYear = averageInterest * total;

            loanRequest.newLoan.total = total;
            loanRequest.newLoan.effectiveAnnualInterest = (effectiveAnnualInterest * 100).toFixed(2);
            loanRequest.newLoan.interestYear = (effectiveAnnualInterest * total).toFixed(2);
            loanRequest.newLoan.interestSaving = (loanRequest.currentLoans.interestYear - loanRequest.newLoan.interestYear).toFixed(2);
            loanRequest.loanAmount = total;

            commit('savingsCalculated', loanRequest);
          }
        })
          .catch(e => {
            console.error(e);
          }) ;
      }
    },
    getters: {
        getMaxLoanAmount: state => {
            if (state._loanRequest.maxLoanAmount > 0)
                return state._loanRequest.maxLoanAmount
            else
                return state._loanRequest.loanAmount.ui.maxVal;
        },
        newLoan: state => state._loanRequest.newLoan
    }
});
window.$store.commit('loadCookies', true);

export default window.$store;
