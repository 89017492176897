<template>
  <div
    class="input-radio"
    :class="{ disabled: ui.disabled == true ? true : false, invalid: ui.valid == false ? true : false }"
  >
    <div class="input-container">
      <label class="option">
        <input
          type="radio"
          :name="ui.name"
          :disabled="ui.disabled"
          :checked="checkOption1"
          @change="onChange(ui.optionA)"
        >
        <span class="radio-btn"></span>
        <div class="txt">
          <slot name="option1"></slot>
        </div>
      </label>

      <div class="blank"></div>

      <label class="option">
        <input
          type="radio"
          :name="ui.name"
          :disabled="ui.disabled"
          :checked="checkOption2"
          @change="onChange(ui.optionB)"
        >
        <span class="radio-btn"></span>
        <div class="txt">
          <slot name="option2"></slot>
        </div>
      </label>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: pxToEm(2);
@gridColGap: 5em;
@radio-btn-outter-radius: pxToEm(32);
@radio-btn-inner-radius: pxToEm(9);

.input-radio {
  .input-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr;

    .option {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: flex-start;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      input[type="radio"] {
        width: 0;
        height: 0;
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked ~ .radio-btn {
          background-color: getColor("whiteColor");
        }

        &:checked ~ .radio-btn::after {
          display: block;
        }
      }

      .radio-btn {
        width: @radio-btn-outter-radius;
        height: @radio-btn-outter-radius;
        display: grid;
        justify-content: center;
        align-items: center;
        background-color: getColor("whiteColor");
        border: @border-width solid getColor("grayLtColor");
        border-radius: 50%;

        &::after {
          width: @radio-btn-inner-radius;
          height: @radio-btn-inner-radius;
          display: none;
          content: "";
          background: getColor("grayDkColor");
          border-radius: 50%;
        }
      }

      .txt {
        text-align: left;
        margin-left: pxToEm(10);
      }

      &:hover {
        input ~ .radio-btn {
          background-color: getColor("primaryLight");
        }
      }
    }

    .blank {
      min-width: pxToEm(25);
    }
  }

  &.invalid .option .radio-btn {
    .formcheck & {
      border: @border-width solid getColor("redColor");
    }
  }

  &.disabled .input-container {
    .option {
      cursor: default;

      .radio-btn {
        background-color: getColor("disabledBg");
      }

      .txt {
        color: getColor("disabledText");
      }

      &:hover input ~ .radio-btn {
        background-color: getColor("disabledBg");
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    disabled: null,
    init: null,
    valid: null,
    name: {
      type: String,
      default: null
    },
    optionA: {
      type: String,
      default: null
    },
    optionB: {
      type: String,
      default: null
    },
    value: String
  },
  data: function() {
    return {
      checkOption1: "",
      checkOption2: "",
      ui: {
        disabled: false,
        init: false,
        valid: null
      }
    };
  },
  created: function() {
    Object.assignSet(this.ui, this.value != null ? this.value.ui : null, this._props);
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = true;
    }
  },
  mounted: function() {
    if (!this.ui.disabled && this.ui.init) {
      if (this.value == this.ui.optionA) this.checkOption1 = "checked";
      if (this.value == this.ui.optionB) this.checkOption2 = "checked";
    }
  },
  beforeDestroy(){
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = false;
    }
  },
  watch: {
    value: function(newVal, oldVal) {
      if (newVal != null && newVal.ui) Object.assignSet(this.ui, this.value.ui);
    }
  },
  methods: {
    pxToEm: function(val) {
      return val / $store.state.defaults.defaultFontSize + "em";
    },
    onChange: function(val) {
      this.$emit("input", val);
    }
  }
};
</script>