<template>
  <div>
    <MobilePartnersSectionBlock :block="block" v-if="isMobile"></MobilePartnersSectionBlock>
    <DesktopPartnersSectionBlock :block="block" v-else></DesktopPartnersSectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>

import MobilePartnersSectionBlock from "@/components/strapi/PartnersSection/MobilePartnersSectionBlock";
import DesktopPartnersSectionBlock from "@/components/strapi/PartnersSection/DesktopPartnersSectionBlock";
export default {
  name: 'PartnersSectionBlock',
  components: {
    DesktopPartnersSectionBlock,
    MobilePartnersSectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
