/* eslint-disable no-undef */
import axios from 'axios';

const Api = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'https://la-api.thewiseminds.com/api/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export default Api;
