<template>
  <div>
    <MobileColorHeaderBlock :block="block" v-if="isMobile"></MobileColorHeaderBlock>
    <DesktopColorHeaderBlock :block="block" v-else></DesktopColorHeaderBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>

import MobileColorHeaderBlock from "@/components/strapi/ColorHeader/MobileColorHeaderBlock";
import DesktopColorHeaderBlock from "@/components/strapi/ColorHeader/DesktopColorHeaderBlock";

export default {
  name: 'ColorHeaderBlock',
  components: {
    DesktopColorHeaderBlock,
    MobileColorHeaderBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
