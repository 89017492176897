<template>
  <div class="page">
    <vue-headful v-if="page" :title="seoTitle" :description="seoDescription" />
    <!--    <h1>{{page.attributes.Title}}</h1>-->
    <BlockManager v-if="page" :blocks="page.attributes.Content" :showUspsBlockOnMobile="showUspsBlockOnMobile" />
    <!--<GeneralNotice text="<h2>Pas op voor nepacties</h2>
<p>Via social media zijn er acties in de omloop die niet afkomstig zijn van Leenattent. Wees hierop allert en betaal nimmer vooraf kosten!</p>" />-->
<div v-if="this.notice">
      <GeneralNotice :title="this.notice.title" :description="this.notice.description"/>
    </div>
  </div>
</template>

<style lang="less" scoped>
.page {
}
</style>

<script>
import Api from "@/Api";
import BlockManager from "@/components/strapi/BlockManager";
import GeneralNotice from "@/components/common/general-notice";

export default {
  name: "strapiPage",
  components: {GeneralNotice, BlockManager },
  data() {
    return {
      page: null,
      notice: null,
      // blog: null,
    };
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        // if (this.$route.params && this.$route.params.pageSlug === "blog") {
        //   this.fetchBlogData();
        // } else {
          this.fetchPageData();
        // }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
    Api.get(`promotion`).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.attributes
      ) {
        this.notice = response.data.data.attributes;
      }
    });
  },
  computed: {
    showUspsBlockOnMobile(){
      if(this.page !== null){
        for(var i=0; i<this.page.attributes.Content.length; i++){
          if(this.page.attributes.Content[i].__component === 'page-components.center-text'){
            return !this.page.attributes.Content[i].Add_USP_Block_Inside;
          }
        }
      }
      return true;
    },
    seoTitle() {
      return this.page !== null && this.page.attributes.page_seo
        ? this.page.attributes.page_seo.seo_title
        : "Leenattent - Eerlijk lenen tegen lage rente";
    },
    seoDescription() {
      return this.page !== null && this.page.attributes.page_seo
        ? this.page.attributes.page_seo.seo_description
        : "Lenen tegen een rente die behoort tot de allerlaagste van Nederland. Leenattent: eerlijk lenen tegen lage rente, sinds 1983.";
    },
  },
  methods: {
    fetchPageData() {
      let pageSlug = this.$route.params.pageSlug
        ? this.$route.params.pageSlug
        : "/";
      Api.get(
        `pages?filters[Slug][$eq]=${pageSlug}&populate[]=Content.Paragraphs&populate[]=Content.Image&populate[]=Content.faq_group.faq_items&populate[]=Content.ReadMore&populate[]=Content.table&populate[]=Content.contacts.Image&populate[]=Content.First_column&populate[]=Content.Second_column&populate[]=Content.Content&populate[]=Content.image&populate[]=Content.Partners&populate[]=Content.Partners.Image&populate[]=page_seo&populate[]=Content.blocks.Image&populate[]=Content.Buttons&populate[]=Content.Brochures.File&populate[]=Content.Vacancies&populate[]=Content.Link&populate[]=Content.Links&populate[]=Content.Links.Image&populate[]=Content.Companies&populate[]=Content.Companies.logo`
      )
        .then((result) => {
          if (result.data.data.length > 0) {
            this.page = result.data.data[0];
          } else {
            this.$route.push({ name: "not-found" });
          }
        })
        .catch((e) => {
          this.$route.push({ name: "not-found" });
        });
    },
    // fetchBlogData() {
    //   Api.get(`blogs`)
    //     .then((result) => {
    //       if (result.data.data.length > 0) {
    //         this.blog = result.data.data[0];
    //         this.blog = { ...this.blog, pagination: result.data.meta.pagination };
    //       } else {
    //         this.$route.push({ name: "not-found" });
    //       }
    //     })
    //     .catch((e) => {
    //       this.$route.push({ name: "not-found" });
    //     });
    // },
  },
};
</script>
