<template>
  <div>
    <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
      <div class="layout">
        <div class="section">
          <div class="texts">
            <div class="txt" v-if="block.Title">
              <div class="bold">{{ block.Title }}</div>
            </div>
            <div
              class="txt"
              v-for="(paragraph, index) in block.Paragraphs"
              :key="`paragraph-${index}`"
            >
              <h2 class="bold" :class="paragraph.Alignment" v-if="paragraph.Title" >{{paragraph.Title}}</h2>

              <UspSSectionBlock v-if="block.Add_USP_Block_Inside && index === 0" />

              <div class="body" :class="paragraph.Alignment" v-html="markdownToHTML(paragraph.text)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="block.Quotation" :class="'area ' + (block.Background === 'light' ? 'white' : '')" >
      <div class="layout">
        <div class="mobile-section">
          <div class="btn-1">
            <router-link to="/offerte" class="no-underline">
              <btn-big class="primary" :width="448" :height="70">
                <div class="content">
                  <div class="txt">Offerte aanvragen</div>
                  <img src="@/assets/arrow.svg" alt />
                </div>
              </btn-big>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="image-layout" v-if="block.Add_USP_Block_Inside">
      <a href="https://www.afm.nl/" target="_blank"><img class="image" src="@/assets/afm.png"></a>
      <a href="https://www.nvf.nl/voor-consumenten/" target="_blank"><img class="image" src="@/assets/nvf.png"></a>
      <a href="https://www.kifid.nl/" target="_blank"><img class="image" src="@/assets/kifid.png"></a>
    </div>
  </div>
</template>

<style type="text/css">
  @media (max-width: 768px) {
    p a { color: #3a3a3a; }
    p a:after { content: ' >'; text-decoration: none !important; }
  }
</style>
<style lang="less" scoped>
.image-layout {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  background-color: getColor("whiteColor");
}
.area {
  background-color: getColor("greyLight");
  padding: 0 15px;
  box-sizing: border-box;
  &.white {
    background-color: #fff;
  }

  .font-weight-bold {
    font-weight: 700;
  }

  .layout {
    align-items: center;
    max-width: pxToEm(965);

    img {
      max-width: 100%;
    }

    .section {
      margin: pxToEm(50);
      margin-bottom: pxToEm(20);
      width: pxToEm(1160);
      max-width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      align-items: center;

      a.no-underline {
        text-decoration: none;
      }

      .info {
        text-align: left;
      }

      .dash {
        width: pxToEm(123);
        height: pxToEm(11);
        background-color: getColor("primaryLight");
        margin-top: pxToEm(20);
      }

      .pic {
        width: 100%;
        height: pxToEm(350);
        margin-top: pxToEm(40);
        margin-bottom: pxToEm(50);
        background-position: center;
        background-size: cover;
      }

      .gap {
        height: pxToEm(100);
      }

      .texts {
        text-align: left;
        display: flex;
        flex-direction: column;
        max-width: pxToEm(866);

        .txt {
          flex: 1 1 auto;
          max-width: pxToEm(768);

          .title {
            text-decoration: underline;
            margin-top: pxToEm(10);
            margin-bottom: 0;
            line-height: 1em;
          }

          .left{
            text-align:left;
          }
          .right{
             text-align:right;
          }
          .center{
            text-align:center;
          }

          .body {
            font-size: pxToEm(16);
            line-height: 1.6em;
            text-align: left;
            margin: 0 pxToEm(20);
          }

          .bold {
            font-size: pxToEm(24);
            font-weight: 700;
            margin: 0 0.6em;
            margin-top: pxToEm(20);
          }
        }
      }

      .info {
        margin: pxToEm(70);
        display: flex;
        flex-direction: column;
        width: pxToEm(768);
        max-width: 100%;

        .title {
          text-decoration: underline;
        }

        .paragraphs {
          margin-top: pxToEm(30);

          .paragraph {
            margin-bottom: pxToEm(60);
            max-width: pxToEm(778);

            .subtitle {
              margin-bottom: pxToEm(30);

              div {
                font-size: pxToEm(36);
                font-weight: 700;
              }
            }

            .text-cont {
              margin-bottom: pxToEm(30);

              .text {
                font-size: pxToEm(18);
                line-height: 2.1em;
              }
            }
          }
        }
      }
    }

    .mobile-section {
      margin-bottom: pxToEm(40);
      max-width: 100%;
      align-items: center;

      .btn-1 {
        max-width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 15px;
      }

      a.no-underline {
        text-decoration: none;
      }

      .btn-big {
        .content {
          margin-right: 10px;
          margin-left: 10px;
        }
        .txt {
          font-size: 125%;
          font-weight: 700;
        }
      }
    }
  }
}

</style>

<script>
import btnBig from "@/components/common/btn-big";
import UspSSectionBlock from "@/components/strapi/UspSSectionBlock";
import { marked } from "marked";

export default {
  name: "CenterTextBlockDefaultMobile",
  components: {
    btnBig,
    UspSSectionBlock
  },
  methods: {
    markdownToHTML: function (markdown) {
      return marked(markdown);
    },
  },
  props: {
    block: Object,
  },
};
</script>
