<template>
  <div class="table-wrapper">
    <p class="font-weight-bold">Rekenvoorbeeld:</p>

    <table class="table">
      <tbody>
      <tr>
        <td>Kredietsom</td>
        <td>te betalen per maand</td>
        <td>nominale maandrente</td>
        <td>jaarlijks kostenpercentage</td>
        <td>looptijd in maanden</td>
        <td>totale prijs incl. hoofdsom</td>
      </tr>
      <tr>
        <td>€5.000,00</td>
        <td>€91,32</td>
        <td>0,79%</td>
        <td>9,90%</td>
        <td>72</td>
        <td>€6,575.04</td>
      </tr>
      <tr>
        <td>€7.500,00</td>
        <td>€136,98</td>
        <td>0,79%</td>
        <td>9,90%</td>
        <td>72</td>
        <td>€9,862.56 </td>
      </tr>
      <tr>
        <td>€10.000,00</td>
        <td>€148,99</td>
        <td>0,79%</td>
        <td>9,90%</td>
        <td>96</td>
        <td>€14,303.04</td>
      </tr>
      <tr>
        <td>€15.000,00</td>
        <td>€223,48</td>
        <td>0,79%</td>
        <td>9,90%</td>
        <td>96</td>
        <td>€21,454.08</td>
      </tr>
      <tr>
        <td>€25.000,00</td>
        <td>€310,72</td>
        <td>0,713%</td>
        <td>8,90%</td>
        <td>120</td>
        <td>€37,286.40</td>
      </tr>
      <tr>
        <td>€50.000,00</td>
        <td>€621,44</td>
        <td>0,713%</td>
        <td>8,90%</td>
        <td>120</td>
        <td>€74,572.80</td>
      </tr>
      </tbody>
    </table>

    <p>Dit rekenvoorbeeld betreft een persoonlijke lening met een vaste rente. Aan dit rekenvoorbeeld kunt u
      geen rechten ontlenen.</p>
  </div>
</template>
<style lang="less" scoped>
  table {
    border-collapse: collapse;

    td {
      padding: 5px;
      border: 1px solid gray;
    }
  }

  .font-weight-bold {
    font-weight: 700;
  }
</style>
<script>

export default {
  name: "rentePercentageTabel",

  components: {
  },

  methods: {
    toggleForm(state) {
      // TODO add logic
    }
  },
};
</script>
