<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <h1 class="title">
          <span>{{block.highlight}}</span>{{block.text}}
        </h1>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  display: flex;

  &.white {
    background-color: #fff;
  }

  .layout {
    max-width: pxToEm(1162);

    .section {
      display: flex;
      flex-direction: column;

      .title {
        font-size: pxToEm(70);
        font-weight: 800;
        line-height: 1em;
        min-height: 140px;
        margin-bottom: 0;

        span {
          color: getColor("primaryLight");
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "DesktopColorHeaderBlock",
  props: {
    block: Object
  },

};
</script>
