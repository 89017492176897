import Vue from 'vue'
import Router from 'vue-router'

import strapiPage from './views/strapi-page.vue'


import deskPersoonlijke from './views/desktop/desk-persoonlijke.vue'
import mobPersoonlijke from './views/mobile/mob-persoonlijke.vue'

import deskDoorlopend from './views/desktop/desk-doorlopend.vue'
import mobDoorlopend from './views/mobile/mob-doorlopend.vue'

import deskFinantialLease from './views/desktop/desk-finantial-lease.vue'
import mobFinantialLease from './views/mobile/mob-finantial-lease.vue'

import deskBKR from './views/desktop/desk-bkr-registratie.vue'
import mobBKR from './views/mobile/mob-bkr-registratie.vue'

import deskCookies from './views/desktop/desk-cookies.vue'
import mobCookies from './views/mobile/mob-cookies.vue'
import inputTest from './components/common/input-test-page/input-test-page.vue'
import componentsTestPage from './views/testing/components.vue'

import strapiBlogPage from './views/blog-page.vue'
import strapiBlog from './components/strapi/BlogDetailSectionBlock.vue'

import informatie from './views/desktop/desk-informatie.vue'
import Api from "@/Api";

if (window.innerWidth > 1240)
  window.$store.state.lastDeviceState = window.$store.state.Device.DESKTOP
else if (window.innerWidth > 768)
  window.$store.state.lastDeviceState = window.$store.state.Device.TABLET
else
  window.$store.state.lastDeviceState = window.$store.state.Device.MOBILE

window.$store.commit('changeDevice', window.$store.state.lastDeviceState);

var mobile = (window.$store.state.lastDeviceState == window.$store.state.Device.MOBILE);
Vue.use(Router)


const router = new Router({
  mode: "history",
  routes: [
    {
      path: '/',
      component: strapiPage
    },
    {
      path: '/blog',
      name:'Blogs',
      component: strapiBlogPage
    },
    {
      path: '/blog-:blogSlug',
      name:'Blog',
      component: strapiBlog
    },
    {
      path: '/persoonlijke',
      name: 'persoonlijke',
      component: mobile ? mobPersoonlijke : deskPersoonlijke
    },
    {
      path: '/doorlopend',
      name: 'doorlopend',
      component: mobile ? mobDoorlopend : deskDoorlopend
    },
    {
      path: '/finantialLease',
      name: 'finantialLease',
      component: mobile ? mobFinantialLease : deskFinantialLease
    },
    {
      path: '/BKR-registratie',
      name: 'BKR-registratie',
      component: mobile ? mobBKR : deskBKR
    },
    {
      path: '/cookies',
      name: 'cookies',
      component: mobile ? mobCookies : deskCookies
    },
    {
      path: '/inputtest',
      name: 'inputtest',
      component: inputTest
    },
    {
      path: '/componentsTestPage',
      name: 'componentsTestPage',
      component: componentsTestPage
    },
    {
      path: '/informatie',
      name: 'informatie',
      component: informatie
    },
    //{
    //path: '/about',
    //name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    //},
    {
      path: '/:pageSlug',
      component: strapiPage
    }
  ]
});

router.beforeEach((to, from, next) => {
  Api.get('redirects?pagination[start]=0&pagination[limit]=-1')
    .then(response => {
      const redirects = response.data.data; // assuming redirects are in the response data
      const redirect = redirects.find(r => r.attributes.from === to.path);

      if (redirect) {
        next({ path: redirect.attributes.to });
      } else {
        next();
      }
    })
    .catch(error => {
      console.error('Error fetching redirects:', error);
      next();
    });
});

export default router;
