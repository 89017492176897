<template>
  <div v-if="blog">
    <div class="area">
      <div class="layout">
        <div class="section">
          <div class="title">{{ blog.attributes.Title }}</div>
          <div class="dash"></div>
          <div
            class="pic"
            v-if="
              blog.attributes.Image &&
              blog.attributes.Image.data &&
              blog.attributes.Image.data.attributes &&
              blog.attributes.Image.data.attributes.url
            "
            :style="`background-image: url(${strapiAssetUrl(
              blog.attributes.Image.data.attributes.url
            )});`"
          ></div>
          <div class="body" v-html="markdownToHTML( blog.attributes.Description.Content)"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.layout {
  width: pxToEm(965);

  .section {
    margin: pxToEm(50);
    margin-bottom: pxToEm(100);
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: pxToPercent(70);
      font-weight: 800;
      line-height: 1em;
    }

    .dash {
      width: pxToEm(123);
      height: pxToEm(11);
      background-color: getColor("primaryLight");
      margin-top: pxToEm(20);
    }

    .txt {
      margin-top: pxtoEm(50);

      .bold {
        font-size: pxToPercent(24);
        font-weight: 600;
      }

      .regular {
        font-size: pxToPercent(18);
        font-weight: 400;
        margin-top: 20px;
      }
    }

    .pic {
      width: 100%;
      height: pxToEm(350);
      margin-top: pxToEm(40);
      background-position: center;
      background-size: cover;
    }
  }
  .body {
    font-size: pxToEm(16);
    line-height: 2.1em;
    text-align: left;
  }
  .white-button {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    width: pxToEm(50);
    height: pxToEm(50);
    position: absolute;
    bottom: -25px;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    .arrow-cont {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .arrow {
        border: solid getColor("primaryLight");
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        transform: rotate(-135deg);
        position: relative;
        margin-top: 5px;
      }
    }
  }
}
</style>

<script>
import Api from "@/Api";
import { strapiAssetUrl } from "@/JS/helpers";
import { marked } from "marked";
export default {
  name: "strapiBlog",
  data() {
    return {
      blog: null,
      pagignation: null,
      url_data: null,
    };
  },
  created() {
    this.fetchBlogData();
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path);
    },
    markdownToHTML: function (markdown) {
      return marked(markdown);
    },
    fetchBlogData() {
      let data = this.$route.params.blogSlug;
      Api.get(
        `blogs?populate=Description&populate=Image&filters[Slug][$eq]=${data}`
      )
        .then(({ data }) => {
          if (data && data.data && data.data.length > 0) {
            this.blog = data.data[0];
            this.pagination = data.meta.pagination;
            console.log("this.blog--> ", this.blog);
          } else {
            this.$route.push({ name: "not-found" });
          }
        })
        .catch((e) => {
          this.$route.push({ name: "not-found" });
        });
    },
  },
  mounted() {
    this.url_data = this.$route.params.blogSlug;
  },
};
</script>
