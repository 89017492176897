var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.blog)?_c('div',[_c('div',{staticClass:"area"},[_c('div',{staticClass:"layout"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.blog.attributes.Title))]),_c('div',{staticClass:"dash"}),(
            _vm.blog.attributes.Image &&
            _vm.blog.attributes.Image.data &&
            _vm.blog.attributes.Image.data.attributes &&
            _vm.blog.attributes.Image.data.attributes.url
          )?_c('div',{staticClass:"pic",style:(("background-image: url(" + (_vm.strapiAssetUrl(
            _vm.blog.attributes.Image.data.attributes.url
          )) + ");"))}):_vm._e(),_c('div',{staticClass:"body",domProps:{"innerHTML":_vm._s(_vm.markdownToHTML( _vm.blog.attributes.Description.Content))}})])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }