<template>
  <div>
    <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
      <div class="layout">
        <div class="section">
          <div class="texts">
            <div class="txt" v-if="block.Title">
              <h2 class="title">{{ block.Title }}</h2>
            </div>
            <div
              class="txt"
              v-for="(paragraph, index) in block.Paragraphs"
              :key="`paragraph-${index}`"
            >
              <h2 class="bold" :class="paragraph.Alignment" v-if="paragraph.Title" >{{paragraph.Title}}</h2>
              <div class="body" :class="paragraph.Alignment" v-html="markdownToHTML(paragraph.text)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="block.Quotation"
      :class="'area ' + (block.Background === 'light' ? 'white' : '')"
    >
      <div class="layout">
        <div class="section">
          <div
            class="btn-1"
            style="
              position: absolute;
              left: 50%;
              top: 0;
              transform: translate(-50%, -50%);
              z-index: 300;
            "
          >
            <router-link to="/offerte" class="no-underline">
              <btn-big class="primary" :width="448" :height="70">
                <div class="content">
                  <div class="txt">Offerte aanvragen</div>
                  <img src="@/assets/arrow.svg" alt />
                </div>
              </btn-big>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  padding: 0 15px;
  box-sizing: border-box;
  &.white {
    background-color: #fff;
  }

  .font-weight-bold {
    font-weight: 700;
  }

  .layout {
    align-items: center;
    max-width: pxToEm(965);

    img {
      max-width: 100%;
    }

    .section {
      margin: pxToEm(50);
      width: pxToEm(1160);
      max-width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      align-items: center;

      a.no-underline {
        text-decoration: none;
      }

      .info {
        text-align: left;
      }

      .btn-1 {
        max-width: 100%;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 15px;
      }

      .btn-big {
        margin: 50px 0;

        .content {
          margin-right: 20px;
          margin-left: 20px;
        }
        .txt {
          font-size: 125%;
          font-weight: 700;
        }
      }



      .dash {
        width: pxToEm(123);
        height: pxToEm(11);
        background-color: getColor("primaryLight");
        margin-top: pxToEm(20);
      }

      .pic {
        width: 100%;
        height: pxToEm(350);
        margin-top: pxToEm(40);
        margin-bottom: pxToEm(50);
        background-position: center;
        background-size: cover;
      }

      .gap {
        height: pxToEm(100);
      }

      .texts {
        text-align: left;
        display: flex;
        flex-direction: column;
        max-width: pxToEm(866);

        .txt {
          flex: 1 1 auto;
          max-width: pxToEm(768);

          .title {
            text-decoration: underline;
            margin-top: pxToEm(10);
            margin-bottom: 0;
            font-size: pxToEm(60);
            line-height: 1em;
          }

          .left{
            text-align:left;
          }
          .right{
             text-align:right;
          }
          .center{
            text-align:center;
          }

          .body {
            font-size: pxToEm(16);
            line-height: 2.1em;
          }

          .bold {
            font-size: pxToEm(36);
            font-weight: 700;
            margin: pxToEm(10) 0;
            margin-top: pxToEm(20);
          }
        }
      }

      .info {
        margin: pxToEm(70);
        display: flex;
        flex-direction: column;
        width: pxToEm(768);
        max-width: 100%;

        .title {
          text-decoration: underline;
        }

        .paragraphs {
          margin-top: pxToEm(30);

          .paragraph {
            margin-bottom: pxToEm(60);
            max-width: pxToEm(778);

            .subtitle {
              margin-bottom: pxToEm(30);

              div {
                font-size: pxToEm(36);
                font-weight: 700;
              }
            }

            .text-cont {
              margin-bottom: pxToEm(30);

              .text {
                font-size: pxToEm(18);
                line-height: 2.1em;
              }
            }
          }
        }
      }
    }
  }
}

@media screen {
  @media (max-width: 767px) {
    .area {
      .layout {
        .section {
          .title {
            font-size: pxToEm(36) !important;
          }
          .texts {
            .txt {
              .bold {
                font-size: pxToEm(32);
              }
            }
          }
        }
      }
    }
    .calc-area {
      padding-top: 5em;
    }
  }
  @media (min-width: 769px) {
    .area {
      &.white {
        &.mobile {
          display: none;
        }
      }
    }
  }
  @media (max-width: 769px) {
    .area {
      &.white {
        &.desktop {
          display: none;
        }
      }
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";
import { marked } from "marked";

export default {
  name: "CenterTextBlockDefault",
  components: {
    btnBig,
  },
  methods: {
    markdownToHTML: function (markdown) {
      return marked(markdown);
    },
  },
  props: {
    block: Object,
  },
};
</script>
