<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="texts">
            <div class="txt">
              <div class="title">Financial lease</div>
  
              <div class="body">
                Stel u heeft een bedrijf en u bent bij voorbeeld toe aan een nieuwe auto. Dan kunt u ervoor
                kiezen om het benodigde bedrag vanuit uw bedrijf te financieren, maar daarmee daalt ook uw
                bedrijfsvermogen. Financial lease via Leenattent is dan vaak een betere oplossing. U bent dan
                direct zelf eigenaar de auto en deze kan ook zo op de balans. Eigenlijk de perfecte win-win
                situatie. U bent eigenaar van een nieuwe bedrijfsauto en uw bedrijfsvermogen blijft onaangetast.
              </div>
            </div>
  
            <div class="txt">
              <div class="subtitle">Goekope financial lease</div>

              <div class="body">
                Financial lease is een lening, u koopt dus op afbetaling. Voordeel is dat u vanaf de ingangsdatum
                van de overeenkomst eigenaar bent van de auto. Daardoor kunt u ook gebruik kunt maken van de
                fiscale aftrek. Bij financial lease is de auto de zekerheid voor de bank, het onderpand. In
                overleg met Leenattent lost u iedere maand een vast bedrag af tegen de laagste rente van Nederland.
                Dit bedrag kunt u verlagen door een eigen bijdrage te betalen bij de aanschaf. Dankzij financial
                lease houdt u uw bedrijfskosten overzichtelijk en houdt u uw werkkapitaal op peil om uw bedrijf te laten groeien.
              </div>
            </div>
  
            <div class="txt">
              <div class="subtitle">Kenmerken en voordelen financial lease</div>

              <div class="body">
                Natuurlijk zijn de omstandigheden voor iedereen anders, een starter heeft andere behoeften dan een
                groeiende onderneming. De adviseurs van Leenattent helpen u graag met een passende en verantwoorde
                financial lease maatoplossing. Voor het gemak hebben wij de voordelen van financial lease nog even
                voor u op een rijtje gezet.
                <br>
                <br>• U heeft alle vrijheid om te kiezen. Kleur, merk, inrichting, nieuw of gebruikt.
                <br>• De impact op uw bedrijfskapitaal is minimaal
                <br>• U bent direct eigenaar van de (bedrijfs)auto
                <br>• Auto kan op de balans, inclusief de fiscale voordelen
                <br>• Overzicht, dankzij vaste maandelijkse kosten
                <br>• Laagste rente van Nederland
                <br>• Lengte van het contract bepaalt u zelf
                <br>• Zelf verzekering en onderhoud bepalen
                <br>
                <br>Leenattent helpt u altijd met een kosteloos advies. Bel ons gerust, of chat met een adviseur. Direct een offerte aanvragen kan ook.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor("greyLight");

    .layout {
      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: pxToEm(58) pxToEm(25) 0;

          .texts {
            .txt {
              margin-bottom: pxToEm(40);

              .title {
                margin-bottom: pxToEm(10);
                font-size: pxToPercent(24);
                line-height: 1em;
                font-weight: 700;
                text-decoration: underline;
              }

              .subtitle {
                margin-bottom: pxToEm(10);
                font-size: pxToPercent(20);
                font-weight: 700;
              }

              .body {
                font-size: pxToPercent(16);
                line-height: 2.1em;
              }
            }

            & :last-child {
              margin-bottom: 0;
            }
          }

          .bottom-title {
            margin: pxToEm(15) 0;
            font-size: pxToPercent(36);
            font-weight: 300;
          }
        }
      }
    }
  }
</style>

<script>
  import incomeSource from "@/components/common/income-source";

  export default {
    name: "financialLeaseBody"
  };
</script>