<template>
  <div class="black-area">
    <div class="black-section">
      <div class="calc-content-black" v-if="interestSaving > 0">

        <div class="sep-100"></div>

        <div class="title">Huidige leningen</div>

        <div class="sep-50"></div>

        <div class="form">
          <div class="row-11 num-input">
            <div class="txt-label">
              <span>Totale leenbedrag</span>
            </div>
            <div class="inputs">
              <input-num
                class="input-num-300"
                v-model="$store.state._loanRequest.currentLoans.total"
                readonly="true"
              ></input-num>
            </div>
          </div>

          <div class="row-11 num-input">
            <div class="txt-label">
              <span>Gemiddelde rente</span>
            </div>
            <div class="inputs">
              <input-num
                class="input-num-200"
                v-model="$store.state._loanRequest.currentLoans.averageInterest"
                readonly="true"
              ></input-num>
            </div>
          </div>

          <div class="row-11 num-input">
            <div class="txt-label">
              <span>Rentekosten per jaar</span>
            </div>
            <div class="inputs">
              <input-num
                class="input-num-300"
                v-model="$store.state._loanRequest.currentLoans.interestYear"
                readonly="true"
              ></input-num>
            </div>
          </div>
        </div>

        <div class="sep-100"></div>

        <div class="title">Nieuwe lening</div>

        <div class="sep-50"></div>

        <div class="form">
          <div class="row-11 num-input">
            <div class="txt-label">
              <span>Totale leenbedrag</span>
            </div>
            <div class="inputs">
              <input-num
                class="input-num-300"
                v-model="$store.state._loanRequest.newLoan.total"
                readonly="true"
              ></input-num>
            </div>
          </div>

          <div class="row-11 num-input">
            <div class="txt-label">
              <span>Effectieve jaarrente</span>
            </div>
            <div class="inputs">
              <input-num
                class="input-num-200"
                v-model="$store.state._loanRequest.newLoan.effectiveAnnualInterest"
                readonly="true"
              ></input-num>
            </div>
          </div>

          <div class="row-11 num-input">
            <div class="txt-label">
              <span>Rentekosten per jaar</span>
            </div>
            <div class="inputs">
              <input-num
                class="input-num-300"
                v-model="$store.state._loanRequest.newLoan.interestYear"
                readonly="true"
              ></input-num>
            </div>
          </div>

          <div class="row-11 num-input">
            <div class="txt-label green">
              <span>Rentebesparing in het eerste jaar</span>
            </div>
            <div class="inputs">
              <input-num
                class="input-num-300"
                v-model="interestSaving"
                readonly="true"
              ></input-num>
            </div>
          </div>
        </div>

        <div class="txt-bottom">
          <div class="regular">De berekening laat zien hoeveel rente u <span class="text-green">per jaar</span> kunt besparen. U bent minder rente verschuldigd indien uw schuld ieder jaar lager wordt.</div>
        </div>
      </div>

      <img
        src="@/assets/arrow.svg"
        alt
        style="transform: rotate(90deg);"
        v-if="interestSaving > 0"
      >

      <div class="b-title" v-if="interestSaving > 0">
        <div class="txt">
          U bespaart
          <span class="green">{{ parseFloat(interestSaving).toLocaleString('nl-NL', { maximumFractionDigits: 2 }) | currency('€', 0) }}</span> via Leenattent.
        </div>
      </div>

      <div class="b-title" v-else>
        <div class="txt">
          <div class="gap"></div>Helaas, u kunt geen lening oversluiten via Leenattent. Neem voor meer informatie contact met ons op.
        </div>
      </div>

      <div class="container">
        <div class="white-square">
          <div class="green-label">
            <span>Advies van Leenattent</span>
          </div>
          <div class="container">
            <div class="title">
              <div class="txt">Persoonlijke lening</div>
            </div>

            <div class="bullets">
              <div class="bullet">
                  <div class="arrow"></div>
                  <span>Eenmalig geld opnemen</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vaste rente</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Boetevrij extra aflossen</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vast maandtermijn</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vaste looptijd</span>
                </div>
            </div>
          </div>

          <btn-big
            class="allGreen"
            :height="106"
            :click="() => { selectLoanType($store.state.loanType.PERSOONLIJKE); }"
            v-anchor="'#anchor-grey-container'"
          >
            <div class="content">
              <div class="txt">Bekijk maandlasten</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </div>

        <div class="white-square">
          <!--
          <div class="green-label" v-if="$store.state._loanRequest.approvedLoanAmount <= 30000">
            <span>Advies van Leenattent</span>
          </div>
          -->

          <div class="container">
            <div class="title">
              <div class="txt">Doorlopend krediet</div>
            </div>

            <div class="bullets">
              <div class="bullet">
                <div class="arrow"></div>
                <span>Geld opnemen tot een afgesproken limiet</span>
              </div>

              <div class="bullet">
                <div class="arrow"></div>
                <span>Variabele rente</span>
              </div>

              <div class="bullet">
                <div class="arrow"></div>
                <span>Boetevrij extra aflossen</span>
              </div>

              <div class="bullet">
                <div class="arrow"></div>
                <span>Vast maandtermijn</span>
              </div>

              <div class="bullet">
                <div class="arrow"></div>
                <span>Looptijd afhankelijk van de geldopnames</span>
              </div>
            </div>
          </div>

          <btn-big
            class="allGreen"
            :height="106"
            :click="() => { selectLoanType($store.state.loanType.DOORLOPEND); }"
            v-anchor="'#anchor-grey-container'"
          >
            <div class="content">
              <div class="txt">Bekijk maandlasten</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </div>
      </div>

      <btn-big
        id="submit-btn"
        class="primary"
        :width="450"
        :height="102"
        :click="() => { navTo('offerte'); }"
      >
        <div class="content">
          <img src="@/assets/money-w.svg" alt />
          <div class="txt">Offerte aanvragen</div>
          <img src="@/assets/arrow.svg" alt />
        </div>
      </btn-big>

      <div id="anchor-grey-container" class="grey-container" v-if="loanTypeModal == true">
        <img class="close" @click="loanTypeModal = false" src="@/assets/close.png" />

        <div class="small-txt">{{ getLoanTypeName }}</div>

        <div class="big-txt">Wat wordt mijn maandlast?</div>

        <loan-type-table
            :select="selectAndSubmit"
        ></loan-type-table>
      </div>

      <div class="contact-text">Meer informatie of advies?</div>

      <div class="icons">
        <div class="icon">
          <img src="@/assets/white-phone.svg">
          <div class="txt">023 539 5926</div>
        </div>

        <div class="icon">
          <img src="@/assets/white-email.svg">
          <div class="txt">
            <a href="mailto:info@leenattent.nl">info@leenattent.nl</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @gridColGap: 8em;
  @gridRowGap: 3em;

  .text-green {
    color: getColor("greenDefault");
  }
  .black-area {
    background-color: getColor('textColor');
    display: flex;
    min-height: 700px;
    align-items: center;
    margin: 0 -15px;

    .black-section {
      margin: auto;
      margin-top: 0;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      color: getColor('whiteColor');
      text-align: center;

      img {
        width: 120px;
        margin: 0 auto;
      }

      .calc-content-black {
        display: flex;
        flex-direction: column;
        align-self: center;
        text-align: left;

        .title {
          font-size: pxToPercent(24);
          font-weight: 600;
        }

        .form {
          display: grid;
          grid-row-gap: @gridRowGap;

          .input-num {
            height: pxToEm(60);
          }

          .txt-label {
              font-size: pxToPercent(18);

              &.green {
                color: getColor("greenDefault");
                font-weight: 600;
              }
          }

          .row-11 {
            height: pxToEm(70);
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: @gridColGap;
            align-items: center;

            .input-num-200 {
              width: pxToEm(200);
            }

            .input-num-300 {
              width: pxToEm(300);
            }
          }
        }

        .txt-bottom {
          margin: pxToEm(50) auto;
          text-align: center;
          max-width: pxToEm(736);

          .regular {
            font-size: pxToPercent(18);
            font-weight: 400;
          }
        }
      }

      .b-title {
        margin: pxToEm(40) auto;

        .txt {
          > .gap {
            height: pxToEm(40);
          }
          font-weight: 800;
          font-size: pxToPercent(36);

          .green {
            color: getColor("greenDefault");
          }
        }
      }

      > .container {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        max-width: 894px;
        width: 100%;
        color: getColor("textColor");
        align-self: center;

        .white-square {
          width: 417px;
          height: 443px;
          background-color: #fff;
          box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
          position: relative;

          &:first-child {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 10px;
          }

          .green-label {
            width: pxToEm(210);
            height: pxToEm(43);
            background-color: getColor("grayMidColor");
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: pxToEm(-20);
            left: 50%;
            transform: translateX(-50%);

            span {
              font-size: pxToPercent(14);
            }
          }

          > .container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: left;

            > .title {
              flex: 1 1 auto;
              margin-top: pxToEm(60);
              .txt {
                font-size: pxToPercent(24);
              }
            }

            .bullets {
              flex: 1 1 auto;
              line-height: 2.7em;
              margin-top: pxToEm(30);

              .bullet {
                .arrow {
                  border: solid #000;
                  border-width: 0 1px 1px 0;
                  display: inline-block;
                  padding: 4px;
                  transform: rotate(-45deg);
                }

                span {
                  margin-left: pxToEm(20);
                  font-size: pxToPercent(16);
                }
              }
            }
          }

          .btn-big {
            width: 100%;
            height: pxToEm(104);
            flex: 0 1 auto;
            position: absolute;
            bottom: 0;

            .content {
              margin-left: pxToEm(40);
              margin-right: pxToEm(40);

              .txt {
                font-size: pxToPercent(20);
                font-weight: 700;
              }

              img {
                width: pxToEm(48);
                height: pxToEm(38);
              }
            }
          }
        }
      }

      .btn-big {
        border-bottom-width: pxToEm(18);
        margin: pxtoEm(50) auto;
        margin-bottom: 0;

        .content {
          margin-left: pxToEm(20);
          margin-right: pxToEm(20);

          .txt {
            font-size: pxToPercent(20);
          }

          img {
            width: pxToEm(48);
            height: pxToEm(38);
          }
        }
      }

      .grey-container {
        flex-direction: column;
        min-height: 632px;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
        margin-top: pxToEm(-45);
        margin-bottom: pxToEm(50);
        display: flex;
        background-color: getColor("greyLight");
        max-width: 1156px;
        width: 100%;
        align-self: center;

        .close {
          align-self: flex-end;
          margin: pxToEm(30) pxToEm(40);
          cursor: pointer;
          width: pxToEm(24);
          height: pxToEm(14);
        }

        .small-txt {
          font-size: pxToPercent(18);
          font-weight: 700;
          color: getColor("textColor");
        }

        .big-txt {
          margin-top: pxToEm(10);
          color: getColor("textColor");
          font-size: pxToPercent(36);
          font-weight: 300;
        }

        .input-dropdown {
          align-self: center;
          width: pxToEm(235);
        }

        .table {
          margin: pxToEm(35) pxToEm(95);
        }
      }

      .contact-text {
        font-size: pxToPercent(22);
        margin: 40px 0;
      }

      .icons {
        margin: pxToEm(50) 0;
        margin-bottom: pxToEm(150);
        max-width: 680px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-self: center;

        .icon {
          display: flex;
          flex-flow: column;
          flex: 1 1 100%;
          justify-content: flex-end;

          img {
            margin-bottom: pxToEm(38);
          }

          .txt {
            font-size: pxToEm(22);
            color: getColor("greenDefault");
            font-weight: 600;

            a {
              color: getColor("greenDefault");
              cursor: pointer;
            }
          }
        }
      }
    }
  }
</style>

<script>
  import btnBig from "@/components/common/btn-big";
  import inputNum from "@/components/common/input-num";
  import loanTypeTable from "@/components/common/loan-type-table.vue";
  import store from "@/store";


  export default {
    name: "blackArea",
    components: {
      btnBig,
      inputNum,
      loanTypeTable
    },
    data() {
      return {
        loanTypeModal: false
      };
    },
    computed: {
      getLoanTypeName() {
        console.log($store.state._loanRequest);
        return $store.state._loanRequest.loanTypeTable === $store.state.loanType.PERSOONLIJKE ? "Persoonlijke lening" : "Doorlopend krediet";
      },
      newLoan () {
        console.log('getting newLoan', this.$store.getters.newLoan);
        return this.$store._loanRequest.newLoan;
      },
      interestSaving () {
        console.log('getting interestSaving', this.$store.getters.newLoan ? this.$store.getters.newLoan.interestSaving : 0);
        return store.getters.newLoan ? store.getters.newLoan.interestSaving : 0;
      }
    },
    methods: {
      navTo: function(url) {
        $store.state.storedData = true;
        this.$router.push(url);
      },
      selectLoanType(loanType) {
        this.loanTypeModal = true;
        $store.state._loanRequest.loanTypeTable = loanType;
      },
      selectAndSubmit() {
        this.$el.querySelector('#submit-btn').click();
      }
    },
    created() {
      $store.state._loanRequest.loanOption = $store.state.funnelBOption;
    }
  };
</script>
