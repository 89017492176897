<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="title">Ik heb een negatieve BKR-registratie</div>

          <div class="dash"></div>
        </div>
      </div>
  
      <div class="pic" :style="{ 'background-image': 'url(' + headerImg + ')' }"></div>

      <div class="section">
        <div class="container-a">
          <div class="text">
            <div class="txt">
              Een negatieve BKR registratie kan behoorlijk zwaar wegen in de mogelijkheden om opnieuw geld te lenen. Toch kunnen
              onze adviseurs u goed van dienst zijn wanneer u de komende tijd wat extra geld nodig heeft. Een appeltje voor de dorst
              aanvragen of een specifieke aankoop financieren? De negatieve BKR registratie kan langere tijd gevolgen hebben, wij
              gaan graag met u na wat er toch mogelijk is.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor("greyLight");

    .layout {
      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: 0 pxToEm(25);

          .title {
            font-size: pxToPercent(28);
            font-weight: 800;
            line-height: 1em;
          }

          .dash {
            width: pxToEm(91);
            height: pxToEm(8);
            margin-top: pxToEm(10);
            background-color: getColor("primaryLight");
          }

          .text {
            margin-bottom: pxToEm(50);
            
            .txt {
              font-size: pxToPercent(16);
              line-height: 2.1em;
            }
          }
        }
      }

      .pic {
        width: 100%;
        height: 66vw;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
</style>

<script>
  import headerImg from "@/assets/bkr-img.jpg";

  export default {
    name: "bkrHeader",
    data() {
      return {
        headerImg
      };
    }
  };
</script>
