
import { uiInterface, uiInterfaceDate, createProperty, validateEmail, rxValidEmailChars, validatePhone, rxLegalPhoneChars,validatePostCode, rxValidPostCodeChars } from './data.js';

var dataTest = {
    firstName: new String("John"),
    middleName: new String("M."),
    lastName: new String("Doe"),
    gender: new String("male"),
    streetName: new String("Some street name"),
    houseNumber: new String("15A"),
    affix: new String("AF"),
    postCode: new String("1200FA"),
    city: new String("Rotterdam"),
    emailAddress: new String("emailaddress@domain.nl"),
    fixedPhoneNumber: new String("+31000000000"),
    mobilePhoneNumber: new String("+31000000000"),
    comments: new String("Lorem ipsum dolor, sit amet consectetur adipisicing elit. Recusandae corporis velit dolor id quo a praesentium ipsum quas nesciunt ducimus.")
};

var data = {
    firstName: new String(),
    middleName: new String(),
    lastName: new String(),
    gender: new String(),
    streetName: new String(),
    houseNumber: new String(),
    affix: new String(),
    postCode: new String(),
    city: new String(),
    emailAddress: new String(),
    fixedPhoneNumber: new String(),
    mobilePhoneNumber: new String(),
    comments: new String()
};

function Form0() {
    return data;
}

function _form0() {
    var self = this;
    var source = Form0();
        
    createProperty(source, self, 'firstName', new uiInterface({ required: true, label: 'Voorletter', order:0 }));
    createProperty(source, self, 'middleName', new uiInterface({ required: false, label: 'Tussenvoegsel', order:1 }));
    createProperty(source, self, 'lastName', new uiInterface({ required: true, label: 'Achternaam', order:2 }));
    createProperty(source, self, 'gender', new uiInterface({ required: true, name: 'gender', optionA: 'male', optionB: 'female', label: 'Geslacht', order:3 }));
    createProperty(source, self, 'streetName', new uiInterface({ required: true, label: 'Straat', order:4 }));
    createProperty(source, self, 'houseNumber', new uiInterface({ required: true, label: 'Huisnummer', order:5 }));
    createProperty(source, self, 'affix', new uiInterface({ required: false, label: 'Toevoeging', order:6 }));
    createProperty(source, self, 'postCode', new uiInterface({ required: true, label: 'Postcode', validator: validatePostCode, rxValidChars: rxValidPostCodeChars, minLen: 6, maxLen: 7, order:7 }));
    createProperty(source, self, 'city', new uiInterface({ required: true, label: 'Plaats', order:8 }));
    createProperty(source, self, 'emailAddress', new uiInterface({ required: true, validator: validateEmail, rxValidChars: rxValidEmailChars, label: 'E-mail', order:11 }));
    createProperty(source, self, 'fixedPhoneNumber', new uiInterface({ validator: validatePhone, rxValidChars: rxLegalPhoneChars, label: 'Vaste telefoonnummer', minLen: 10, maxLen: 10, order:9 }));
    createProperty(source, self, 'mobilePhoneNumber', new uiInterface({ required: true, validator: validatePhone, rxValidChars: rxLegalPhoneChars, label: 'Mobiele telefoonnummer', minLen: 10, maxLen: 10, order:10 }));
    createProperty(source, self, 'comments', new uiInterface({ required: false, label: 'Opmerkingen', order:12}));

    return self;
}


export { _form0 };