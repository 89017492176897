<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="items-parent">
            <div class="items">
              <a :href="block.url" class="item" v-for="block in block.blocks">
                <img class="item-image" :src="strapiAssetUrl(block.Image.data.attributes.url)">
                <div class="text">
                  <div class="pink">
                    <div class="txt">
                      {{block.Label}}
                    </div>
                  </div>
                  <div class="black">
                    <div class="txt">
                      {{block.Title}}
                    </div>
                  </div>
                  <div class="small">
                    <div class="txt">
                      {{block.Text}}
                    </div>
                  </div>
                  <button class="btn-big secondary-pink">
                    <div class="f-bg"></div>
                    <div style="margin-top: -0.670625em; display: flex; flex: 1 1 100%;">
                      <div class="content">
                        <div class="txt">Lees verder</div>
                        <img
                          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='63.773' height='51.096' viewBox='0 0 63.773 51.096'%3E %3Cg transform='translate(0)'%3E %3Cg fill='none' stroke='%23fff' stroke-width='3' transform='translate(12.677 0)'%3E %3Cellipse cx='25.548' cy='25.548' stroke='none' rx='25.548' ry='25.548'/%3E %3Cellipse cx='25.548' cy='25.548' fill='none' rx='24.048' ry='24.048'/%3E %3C/g%3E %3Cpath fill='none' stroke='%23fff' stroke-width='3' d='M7232.648,1306h47.439' transform='translate(-7232.648 -1280.452)'/%3E %3Cpath fill='none' stroke='%23fff' stroke-width='3' d='M0,.176,19.721,0l-.132,19.766' transform='translate(34.564 11.603) rotate(45)'/%3E %3C/g%3E %3C/svg%3E"
                          alt=""></div>
                    </div>
                  </button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  padding-bottom: pxToEm(58);
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    overflow: hidden;

    .section {
      margin: pxToEm(25) pxToEm(10);
      text-align: left;

      .container-a {
        padding: 0px 25px;

        .items-parent {
          //width: 0;
          //margin: 0 auto;

          .items {
            //width: 634px;
            //left: 50%;
            //transform: translateX(-50%);
            //margin-top: pxToEm(25);
            display: grid;
            justify-content: center;

            .item {
              width: 100%;
              margin: pxToEm(20) pxToEm(20) 0 0;
              background-color: getColor('bgColor');
              display: flex;
              flex-direction: column;
              overflow: hidden;

              text-decoration: none;

              img.item-image {
                width: 100%;
                height: 190px;
                align-self: center;
                object-fit: fill !important;
              }

              .btn-big {
                width: 9.4375em;
                height: 4.625em;
                max-width: 100%;
                text-decoration: none;
                color: white;

                margin: pxToEm(10) auto pxToEm(20) auto;

                &.extra-margin {
                  margin-top: pxToEm(30)
                }

                img {
                  width: pxToEm(30)
                }

                .f-bg {
                  height: 0.670625em;
                }

                .content {
                  margin: 0 20px;
                }
              }

              &:last-child {
                margin: pxToEm(20) 0 0 0;
              }

              .text {
                display: flex;
                flex-direction: column;
                padding: 0 pxToEm(30);

                .pink {
                  padding: pxToEm(15) 0;
                  color: getColor("primaryLight");

                  .txt {
                    font-size: pxToPercent(12);
                    font-weight: 700;
                    text-align: center;
                  }
                }

                .black {
                  color: black;

                  .txt {
                    font-size: pxToPercent(16);
                    font-weight: 700;
                  }
                }

                .small {
                  margin: pxToEm(15) 0 pxToEm(25);
                  color: #3f3f3f;

                  .txt {
                    font-size: pxToPercent(12);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import {marked} from "marked";
import {strapiAssetUrl} from "@/JS/helpers";

export default {
  name: 'MobileBlocksSectionBlock',
  props: {
    block: Object
  },
  methods: {
    markdownToHTML: function(markdown) {
      return marked(markdown);
    },
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    }
  },
}
</script>
