<template>
  <div>
    <div class="area">
      <MobLogoSection v-if="$store.state.deviceState == $store.state.Device.MOBILE"></MobLogoSection>
      <div class="layout">
        <div class="section">
            <h1 class="title">{{block.Title}}</h1>
            <div class="dash"></div>
          <div class="pic" :style="`background-image: url(${strapiAssetUrl(block.Image.data.attributes.url)});`"></div>
        </div>
      </div>
    </div>
    <calculatorA v-if="$store.state.deviceState != $store.state.Device.MOBILE && block.Calculator && (!block.CalculatorType || block.CalculatorType === 'Max loan calculator')" class="area white"></calculatorA>
    <calculatorAMobile v-if="$store.state.deviceState == $store.state.Device.MOBILE && block.Calculator && (!block.CalculatorType || block.CalculatorType === 'Max loan calculator')" class="area white"></calculatorAMobile>
    <calculatorB v-if="$store.state.deviceState != $store.state.Device.MOBILE && block.Calculator && block.CalculatorType === 'Loan saving calculator'" class="area white"></calculatorB>
    <calculatorBMobile v-if="$store.state.deviceState == $store.state.Device.MOBILE && block.Calculator && block.CalculatorType === 'Loan saving calculator'" class="area white"></calculatorBMobile>
  </div>
</template>

<style lang="less" scoped>
.area {
    background-color: getColor("greyLight");
    padding: 0 15px;
    box-sizing: border-box;
    &.white {
      background-color: #fff;
    }

    .logo {
      // display: none;
    }

    .font-weight-bold {
      font-weight: 700;
    }

    .layout {
      align-items: center;
      max-width: pxToEm(965);

      img {
        max-width: 100%;
      }

      .section {
        margin: pxToEm(50);
        width: pxToEm(1160);
        max-width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        align-items: center;

        @media (max-width: 768px){
          text-align: left !important;
          align-items: unset;
        }

        a.no-underline {
          text-decoration: none;
        }

        .info {
          text-align: left;
        }

        .title {
          font-size: pxToEm(70);
          font-weight: 800;
          line-height: 1em;
          margin-top: 0;
          margin-bottom: 0;
        }

        .dash {
          width: pxToEm(123);
          height: pxToEm(11);
          background-color: getColor("primaryLight");
          margin-top: pxToEm(20);
        }

        .pic {
          width: 100%;
          height: pxToEm(350);
          margin-top: pxToEm(40);
          margin-bottom: pxToEm(50);
          background-position: center;
          background-size: cover;
        }

        .gap {
          height: pxToEm(100);
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
  .title {
    font-size: 175% !important;
  }
}

  @media screen {
    @media (max-width: 767px) {

      .calc-area {
        padding-top: 5em;
      }
    }
    @media (min-width: 769px){
      .area{
        &.white{
          &.mobile{
            display: none;
          }
        }
      }
    }
    @media (max-width: 769px){
      .area{
        &.white{
          &.desktop{
            display: none;
          }
        }
      }
    }
  }
</style>

<script>
import { strapiAssetUrl } from "@/JS/helpers";
import calculatorA from "@/components/desktop/calculators/A/calculator-form.vue";
import calculatorAMobile from "@/components/mobile/calculators/A/calculator-form.vue";
import calculatorB from "@/components/desktop/calculators/B/calculator-form.vue";
import calculatorBMobile from "@/components/mobile/calculators/B/calculator-form.vue";
import MobLogoSection from "../mobile/mob-logo-section.vue";

export default {
  name: 'InfoPageHeaderBlock',
  components: {
    calculatorA,
    calculatorAMobile,
    calculatorB,
    calculatorBMobile,
    MobLogoSection
},
  props: {
    block: Object
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    }
  }
}
</script>
