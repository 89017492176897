<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="title">Financial lease</div>
  
          <div class="dash"></div>
  
          <div class="txt">
            <div class="bold">Een nieuwe bedrijfsauto?</div>
            <div
              class="regular"
            >Eigenaar van een nieuwe bedrijfsauto en uw bedrijfsvermogen blijft onaangetast.</div>
          </div>
        </div>
      </div>

      <div class="pic" :style="{ 'background-image': 'url(' + headerImg + ')' }"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    padding-bottom: pxToEm(83);
    background-color: getColor("greyLight");

    .layout {
      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: 0 pxToEm(25);

          .title {
            font-size: pxToPercent(28);
            font-weight: 800;
            line-height: 1em;
          }

          .dash {
            width: pxToEm(91);
            height: pxToEm(8);
            margin-top: pxToEm(10);
            background-color: getColor("primaryLight");
          }

          .txt {
            margin-top: pxToEm(25);

            .bold {
              font-size: pxToPercent(24);
              font-weight: 600;
            }

            .regular {
              margin-top: pxToEm(20);
              font-size: pxToPercent(18);
              font-weight: 400;
            }
          }
        }
      }

      .pic {
        width: 100%;
        height: 66vw;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
</style>

<script>
  import headerImg from "@/assets/finantial-lease-header.jpg";

  export default {
    name: "financialLeaseHeader",
    data() {
      return {
        headerImg
      };
    }
  };
</script>
