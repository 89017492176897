function maxLoanCalc(person, loanRequest) {
    var values = [
        {
            basisnorm: 814,
            grondslag: 3285,
            leennorm: 1190,
            basishuur: 229,
            vasteopslag: 275
        },
        {
            basisnorm: 903,
            grondslag: 3104,
            leennorm: 1425,
            basishuur: 229,
            vasteopslag: 451
        },
        {
            //basisnorm: 1516, --> old/inccorecct value
            basisnorm: 1261,
            grondslag: 3285,
            leennorm: 1649,
            basishuur: 229,
            vasteopslag: 326
        },
        {
            //basisnorm: 1482, --> old/incorrect value
            basisnorm: 1251,
            grondslag: 3104,
            leennorm: 1739,
            basishuur: 229,
            vasteopslag: 408
        }
    ]
    //NOT MARRIED / NO KIDS: 00 - 0
    //NOT MARRIED / KIDS:    01 - 1
    //MARRIED / NO KIDS:     10 - 2
    //MARRIED / KIDS:        11 - 3
    var MARRIED = 2;
    var CHILDREN = 1;
    var monthlyExpenses = person.monthlyExpenses;
    var income = person.partnerIncome > 0 ? person.income + person.partnerIncome : person.income;
    var minMonthlyExpenses = 300;
    var minIncome = 173;
    var ans;
    var maxLoanAmount;
    var valuesIdx = ((person.married==true ? 1 : 0) * MARRIED) + ((person.children==true ? 1 : 0) * CHILDREN);

    var m, n = income; //m - based on expenses | n - based on income
    //if (person.married && income < minIncome) n = minIncome; //IMPORTANT - THIS SHOULD BE FIELD VALIDATION
    //else n = income;

    if (monthlyExpenses < minMonthlyExpenses) m = minMonthlyExpenses;
    else m = monthlyExpenses;

    if (person.livingState == 0) m = m * .85;
    else if (person.livingState == 2) m = n / 3;

    //Calculate ans
    if (n > values[valuesIdx].grondslag) ans = values[valuesIdx].leennorm;
    else if (n <= values[valuesIdx].grondslag) ans = values[valuesIdx].basisnorm + .15 * (n - values[valuesIdx].basishuur - values[valuesIdx].basisnorm)

    //Calculate max
    maxLoanAmount = (n - ans - m - values[valuesIdx].vasteopslag) * 100;

    return maxLoanAmount;

}

export { maxLoanCalc };
