<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="items">
          <a :href="block.url" class="item" v-for="block in block.blocks">
            <img class="item-image" :src="strapiAssetUrl(block.Image.data.attributes.url)">
            <div class="text">
              <div class="pink">
                <div class="txt">
                  {{block.Label}}
                </div>
              </div>
              <div class="black">
                <div class="txt">
                  {{block.Title}}
                </div>
              </div>
              <div class="light">
                <div class="txt txtl">{{block.Text}}</div>
              </div>
              <button class="btn-big secondary-pink">
                <div class="f-bg"></div>
                <div style="margin-top: -0.670625em; display: flex; flex: 1 1 100%;">
                  <div class="content">
                    <div class="txt">Lees verder</div>
                    <img
                      src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='63.773' height='51.096' viewBox='0 0 63.773 51.096'%3E %3Cg transform='translate(0)'%3E %3Cg fill='none' stroke='%23fff' stroke-width='3' transform='translate(12.677 0)'%3E %3Cellipse cx='25.548' cy='25.548' stroke='none' rx='25.548' ry='25.548'/%3E %3Cellipse cx='25.548' cy='25.548' fill='none' rx='24.048' ry='24.048'/%3E %3C/g%3E %3Cpath fill='none' stroke='%23fff' stroke-width='3' d='M7232.648,1306h47.439' transform='translate(-7232.648 -1280.452)'/%3E %3Cpath fill='none' stroke='%23fff' stroke-width='3' d='M0,.176,19.721,0l-.132,19.766' transform='translate(34.564 11.603) rotate(45)'/%3E %3C/g%3E %3C/svg%3E"
                      alt=""></div>
                </div>
              </button>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  display: flex;

  &.white {
    background-color: #fff;
  }

  .layout {
    max-width: pxToEm(1162);
    margin-bottom: 50px;

    max-width: pxToEm(1162);
    margin-bottom: 50px;

    .section {
      margin: pxToEm(50);
      display: flex;
      flex-direction: column;

      .items {
        display: flex;
        justify-content: center;

        .item {
          width: 33%;
          margin: 0 pxToEm(20) 0 0;
          padding-bottom: pxToEm(100);
          background-color: #fff;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          text-decoration: none;

          img.item-image {
            width: 140%;
            align-self: center;
          }

          .btn-big {
            width: 14.4375em;
            height: 4.625em;
            text-decoration: none;
            color: white;

            margin: pxToEm(10) auto pxToEm(20) auto;

            position: absolute;
            bottom: pxToEm(10);

            &.extra-margin {
              margin-top: pxToEm(30)
            }

            .f-bg {
              height: 0.670625em;
            }

            .content {
              margin: 0 20px;
            }
          }

          &:last-child {
            margin: 0;
          }

          .text {
            display: flex;
            flex-direction: column;
            min-height: pxToEm(225);
            padding: 0 pxToEm(30);

            .pink {
              padding: pxToEm(20) 0;

              .txt {
                color: getColor("primaryLight");
                font-size: pxToPercent(18);
                font-weight: 700;
              }
            }

            .black {
              font-size: pxToPercent(23);
              font-weight: 700;
              color: black;
            }

            .light {
              padding: pxToEm(20) 0;
              color: #3f3f3f;

              .txt {
                font-size: pxToPercent(16);
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import {marked} from "marked";
import {strapiAssetUrl} from "@/JS/helpers";

export default {
  name: "DesktopBlocksSectionBlock",
  props: {
    block: Object
  },
  methods: {
    markdownToHTML: function(markdown) {
      return marked(markdown);
    },
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    }
  },
};
</script>
