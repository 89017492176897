import { render, staticRenderFns } from "./BoxedSectionBlock.vue?vue&type=template&id=2e293a54&scoped=true&"
import script from "./BoxedSectionBlock.vue?vue&type=script&lang=js&"
export * from "./BoxedSectionBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e293a54",
  null
  
)

export default component.exports