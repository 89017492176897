<template>
  <div>
    <mob-logo class="area"></mob-logo>
    <mob-cookies-header class="area"></mob-cookies-header>
    <mob-cookies-body class="area"></mob-cookies-body>
    <mob-contact class="area mob-contact"></mob-contact>
  </div>
</template>

<style lang="less" scoped>
  .mob-contact {
    box-shadow: 0 pxToEm(-10) pxToEm(64) #e7e7e7;
  }
</style>

<script>
  import mobLogo from "@/components/mobile/mob-header-logo.vue";
  import mobCookiesHeader from "@/components/mobile/cookies/header.vue";
  import mobCookiesBody from "@/components/mobile/cookies/body.vue";
  import mobContact from "@/components/mobile/mob-contact.vue";

  export default {
    name: "mobCookies",
    components: {
      mobLogo,
      mobCookiesHeader,
      mobCookiesBody,
      mobContact
    }
  };
</script>