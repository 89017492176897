<template>
  <div class="modal-wrapper" v-if="shown">
    <div class="modal">
      <div class="close" @click="hide()">
        <svg height="20px" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="20px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"/></svg>
      </div>
      <div >
        <h2 v-html="title"></h2>
        <p v-html="description"></p>
      </div>
    </div>
  </div>
</template>

<style lang="less">
  .modal-wrapper {
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;

    .modal {
      background: white;
      width: 600px;
      max-width: 100%;
      //min-height: 300px;
      padding: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .close {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }
  }
</style>

<script>
export default {
  name: "generalNotice",
  props: {
    title: String,
    description:String
  },
  data() {
    return {
      shown: false
    };
  },
  mounted() {
    if (!sessionStorage.getItem('noticeHidden')) {
      this.shown = true;
    }
  },
  methods: {
    hide() {
      this.shown = false;
      sessionStorage.setItem('noticeHidden', "true");
    }
  }
};
</script>
