<template>
  <div>
    <div class="layout">
      <div class="pic" :style="{ 'background-image': 'url(' + headerImg + ')' }"></div>

      <div class="section">
        <div class="container-a">
          <div class="texts">
            <div class="txt">
              <div class="title">Persoonlike lening</div>

              <div class="body">
                De persoonlijke lening biedt de mogelijkheid om eenmalig geld te lenen, bijvoorbeeld voor een grote uitgave die u wilt doen te doen. Denk aan financiering van een nieuwe auto, een keuken, of een bijzondere vakantie. Allemaal wensen die u met een persoonlijke lening kunt realiseren. Onze adviseurs zetten de voor- en nadelen van dit type krediet graag eerlijk voor u op een rijtje. Wij kunnen dit natuurlijk ook voor u laten afwegen tegen ons doorlopend krediet.
              </div>
            </div>

            <div class="txt">
              <div class="subtitle">Laagste rente voor een persoonlijke lening</div>

              <div class="body">
                Leenattent zorgt voor de laagste rente van Nederland op een persoonlijke lening. U bent altijd verzekerd van het voordeligste krediet, op basis van een vaste lage rente. Dankzij die vaste rente en de vaste looptijd bij dit type krediet staat u nooit voor verrassingen. De lage rente maakt de persoonlijke lening een voordelige keuze, bijvoorbeeld als u een grote uitgave wilt doen en daarnaast toch graag wat spaargeld achter de hand wilt houden.
              </div>
            </div>

            <div class="txt">
              <div class="subtitle">Kenmerken en voordelen</div>

              <div class="body">
                Kernmerk van een persoonlijke lening is dat het een vaste rente en een vaste looptijd heeft. U betaalt dus altijd een vaste maandbedrag, over een vaste looptijd. Het maandbedrag wordt van te voren voor u berekend door ons. Zo weet u zeker of u de persoonlijke lening nu en in de toekomst kunt blijven betalen. En heeft u een keer de mogelijkheid om extra af te lossen dan kan dit bij ons boetevrij.
              </div>
            </div>

            <div class="txt">
              <div class="subtitle">Kosteloos advies voor een persoonlijke lening</div>

              <div class="body">
                De Leenattent adviseurs vinden het belangrijk om u eerlijk voor te lichten over de mogelijkheden. Wij adviseren u daarom altijd kosteloos over een persoonlijke lening. Aan de hand van een offerte heeft u de kosten eenvoudig op een rijtje. U vindt hierin terug wat een lening u kost gedurende de eerste maanden en hoe de kosten zich tijdens de looptijd ontwikkelen. Neem <span>online contact</span> op, <span>bel ons</span> of start een <span>livechat</span> met een van onze financiële specialisten als u meer wilt weten over een persoonlijke lening. Direct een vrijblijvende en kosteloze offerte aanvragen kan natuurlijk ook!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor("greyLight");

    .layout {
      .pic {
        width: 100%;
        height: 66vw;
        margin-top: pxToEm(83);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: pxToEm(15) pxToEm(25) 0;

          .texts {
            .txt {
              margin-bottom: pxToEm(40);

              .title {
                margin-bottom: pxToEm(10);
                font-size: pxToPercent(24);
                line-height: 1em;
                font-weight: 700;
                text-decoration: underline;
              }

              .subtitle {
                margin-bottom: pxToEm(10);
                font-size: pxToPercent(20);
                font-weight: 700;
              }

              .body {
                font-size: pxToPercent(16);
                line-height: 2.1em;

                span {
                  text-decoration: underline;
                }
              }
            }

            & :last-child {
              margin-bottom: 0;
            }
          }

          .bottom-title {
            margin: pxToEm(15) 0;
            font-size: pxToPercent(36);
            font-weight: 300;
          }
        }
      }
    }
  }
</style>

<script>
  import headerImg from "@/assets/persoonlijke_img4.jpg";

  export default {
    name: "persoonlijkeBody",
    data() {
      return {
        headerImg
      };
    }
  };
</script>