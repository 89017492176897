<template>
  <div class="calc-area">
    <div id="anchor-calc-a-top" style="position: absolute; top: -300px"></div>

    <div
      class="btn-1"
      @click="toggleForm(true)"
      style="
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        z-index: 300;
      "
    >
      <btn-big class="primary" id="calculator-form-btn" :width="448" :height="106">
        <div class="content">
          <div class="txt">Bereken hoeveel u kunt lenen</div>
          <img src="@/assets/arrow.svg" alt />
        </div>
      </btn-big>
    </div>

    <div class="calculator" v-if="formArea">
      <div class="sep-120"></div>

      <div class="calc-content">
        <div class="title">Hoeveel kan ik lenen?</div>
        <div id="calc-top"></div>
        <div class="sep-50"></div>

        <div class="error" v-show="form.state == false">
          Er zijn enkele velden niet correct ingevuld.
        </div>
        <div class="sep-70" v-show="form.state == false"></div>

        <div
          class="calc-content-body"
          :class="{ formcheck: form.state == false }"
        >
          <div class="grid-2x1 slider-input">
            <div class="txt-label">
              <span>Hoeveel geld wilt u lenen?</span>
            </div>
            <div class="inputs">
              <input-slider
                class="primary"
                :css="$store.state.defaults.sliderCSSDesktop"
                v-model="$store.state._loanRequest.loanAmount"
              ></input-slider>
              <input-num
                v-model="$store.state._loanRequest.loanAmount"
              ></input-num>
            </div>
          </div>

          <div class="row-11 toggle-input">
            <div class="txt-label">
              <span>Bent u getrouwd of samenwonend?</span>
            </div>
            <div class="inputs">
              <input-boolean
                v-model="$store.state._person.married"
              ></input-boolean>
            </div>
          </div>

          <div class="row-11 toggle-input">
            <div class="txt-label">
              <span>Heeft u inwonende kinderen?</span>
            </div>
            <div class="inputs">
              <input-boolean
                v-model="$store.state._person.children"
              ></input-boolean>
            </div>
          </div>

          <div class="row-11 select-input">
            <div class="txt-label">
              <span>Wat is uw woonsituatie?</span>
            </div>
            <div class="inputs">
              <input-dropdown
                :options="$store.state.livingStateDropdown"
                v-model="$store.state._person.livingState"
              ></input-dropdown>
            </div>
          </div>

          <div class="grid-2x1 slider-input">
            <div class="txt-label">
              <span>Wat zijn uw woonlasten?</span>
            </div>
            <div class="inputs">
              <input-slider
                class="primary"
                :css="$store.state.defaults.sliderCSSDesktop"
                v-model="$store.state._person.monthlyExpenses"
              ></input-slider>
              <input-num
                v-model="$store.state._person.monthlyExpenses"
              ></input-num>
            </div>
          </div>

          <div class="grid-2x1 slider-input">
            <div class="txt-label">
              <div class="txt-label-long">
                Wat is uw netto inkomen per maand?
              </div>
            </div>
            <div class="inputs">
              <input-slider
                class="primary"
                :css="$store.state.defaults.sliderCSSDesktop"
                v-model="$store.state._person.income"
              ></input-slider>
              <input-num v-model="$store.state._person.income"></input-num>
            </div>
          </div>

          <div
            class="grid-2x1 slider-input"
            v-if="$store.state._person.married == true"
          >
            <div class="txt-label">
              <div class="txt-label-long">
                Wat is het netto inkomen per maand van uw partner?
              </div>
            </div>
            <div class="inputs">
              <input-slider
                class="primary"
                :css="$store.state.defaults.sliderCSSDesktop"
                v-model="$store.state._person.partnerIncome"
              ></input-slider>
              <input-num
                v-model="$store.state._person.partnerIncome"
              ></input-num>
            </div>
          </div>
        </div>
      </div>

      <div class="sep-100"></div>
    </div>

    <btn-big
      class="primary btn-bereken"
      v-if="formArea"
      :width="293"
      :height="92"
      :click="toggleBlackArea"
    >
      <div class="content">
        <div class="txt">Bereken</div>
        <img src="@/assets/arrow.svg" alt />
      </div>
    </btn-big>

    <div class="sep-100" v-if="formArea"></div>

    <div id="black-area-top"></div>
    <black-area v-if="blackFormArea"></black-area>

    <div
      class="white-button"
      v-if="formArea"
      @click="toggleForm(false)"
      v-anchor="'#anchor-calc-a-top'"
    >
      <div class="arrow-cont">
        <div class="arrow"></div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 3em;
@gridColGap: 6em;

.area {
  &.calc-area {
    .btn-1 {
      max-width: 100%;
      box-sizing: border-box;
      padding: 0 15px;
      button {
        max-width: 100%;
      }
    }
  }
  .btn-big {
    border-bottom-width: pxToEm(18);

    .content {
      margin-left: pxToEm(40);
      margin-right: pxToEm(40);

      .txt {
        font-size: pxToPercent(20);
        font-weight: 700;
      }

      img {
        width: pxToEm(56);
        height: pxToEm(45);
      }
    }
  }

  .calculator {
    max-width: pxToEm(754);
    position: relative;
    left: pxToEm(50);
    margin: 0 auto;
    background-color: getColor("whiteColor");

    .calc-content {
      text-align: left;

      .title {
        font-size: pxToPercent(60);
        font-weight: 300;
      }

      .error {
        color: getColor("redColor");
        font-style: italic;
      }

      .calc-content-body {
        display: grid;
        grid-row-gap: @gridRowGap;

        .input-boolean {
          width: pxToEm(180);
        }

        .input-dropdown {
          width: pxToEm(280);
        }

        .input-num {
          width: pxToEm(180);
        }

        .txt-label {
          font-size: pxToPercent(18);
          font-weight: 300;
          line-height: 2.2em;

          .txt-label-long {
            max-width: pxToEm(290);
            line-height: 1.25em;
          }
        }

        .row-11 {
          height: pxToEm(70);
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: @gridColGap;
          align-items: center;
        }

        .grid-2x1 {
          height: pxToEm(115);
          display: grid;
          grid-template-rows: pxToEm(45) pxToEm(70);
          grid-column-gap: @gridColGap;
          align-items: center;
        }

        .slider-input {
          .inputs {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: @gridColGap;
            align-items: center;
          }
        }
      }
    }
  }

  .btn-bereken {
    margin: 0 auto;
  }

  .white-button {
    width: pxToEm(50);
    height: pxToEm(50);
    position: absolute;
    left: 50%;
    bottom: -25px;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: getColor("whiteColor");
    box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
    z-index: 100;
    cursor: pointer;

    .arrow-cont {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .arrow {
        display: inline-block;
        position: relative;
        transform: rotate(-135deg);
        margin-top: pxToEm(5);
        padding: pxToEm(5);
        border: solid getColor("primaryLight");
        border-width: 0 pxToEm(3) pxToEm(3) 0;
      }
    }
  }
}
@media screen {
  @media (max-width: 767px) {
    .area {
      .calculator {
        left: 0;
        width: 100%;
        .calc-content {
          text-align: center;
          .calc-content-body {
            .slider-input,
            .toggle-input,
            .select-input {
              .inputs {
                display: flex;
                justify-content: center;
              }
            }
            .row-11 {
              display: initial;
            }
          }
        }
        .input-slider {
          display: none;
        }
      }
    }
  }
}
</style>

<script>
import blackArea from "@/components/desktop/pdp/A/black-area";
import btnBig from "@/components/common/btn-big";
import inputBoolean from "@/components/common/input-boolean";
import inputDropdown from "@/components/common/input-dropdown";
import inputNum from "@/components/common/input-num";
import inputSlider from "@/components/common/input-slider";
import { maxLoanCalc } from "@/data/maxLoanCalc";
import { goToAnchor, checkTab } from "@/JS/helpers";

export default {
  name: "calculatorA",
  components: {
    blackArea,
    btnBig,
    inputBoolean,
    inputDropdown,
    inputNum,
    inputSlider,
  },
  mounted() {
    if (this.$route.query.gclid) {
      localStorage.setItem('gclid', this.$route.query.gclid);
    }
  },
  data() {
    return {
      formArea: false,
      blackFormArea: false,
      form: {
        fields: [
          $store.state._loanRequest.loanAmount,
          $store.state._person.married,
          $store.state._person.children,
          $store.state._person.livingState,
          $store.state._person.monthlyExpenses,
          $store.state._person.income,
          $store.state._person.partnerIncome,
        ],
        state: undefined,
      },
    };
  },
  watch: {
    "$store.state._person.married": function (newVal, oldVal) {
      // *** THIS NEEDS A BETTER, CLEANER CODING SOLUTION ***
      var re = /partnerIncome/;
      if ($store.state._person.married == true) {
        for (var field of this.form.fields) {
          if (field.ui.label && re.test(field.ui.label)) {
            field.ui.valid = false;
          }
        }
      }
    },
  },
  methods: {
    toggleBlackArea: function () {
      this.form.state = checkTab(this.form.fields);

      if (this.form.state == true) {
        $store.commit("calcMaxLoanAmount");
        this.blackFormArea = true;
        goToAnchor("#black-area-top");
      } else {
        goToAnchor("#calc-top", 100);
      }
    },
    toggleForm: function (state) {
      this.formArea = state;

      if (state == false) {
        this.blackFormArea = state;
        $store.commit("resetForms");
        this.form.state = undefined;
      } else {
        goToAnchor("#black-area-top");
      }
    },
  },
};
</script>
