<template>
  <div class="area">
    <h3>input boolean</h3>
    <input-boolean
      :init="$store.state._person.children.ui.init"
      v-model="$store.state._person.children"
    ></input-boolean>

    <h3>input date</h3>
    <input-date
      v-model="$store.state._person.birthDate"
    ></input-date>
    <h4>input date disabled</h4>
      <input-date
      :disabled="true"
      v-model="$store.state._person.birthDate"
    ></input-date>

    <h3>input dropdown</h3>
    <input-dropdown
      class="input-dropdown-300"
      :init="$store.state._person.livingState.ui.init"
      :options="$store.state.livingStateDropdown"
      option-id="id"
      option-label="txt"
      :placeholder="$store.state._person.livingState.ui.placeholder"
      v-model="$store.state._person.livingState"
    ></input-dropdown>

    <h3>input num</h3>
    <input-num
      v-model="$store.state._loanRequest.loanAmount"
    ></input-num>

    <h3>input percentage</h3>
    <input-num
      :unit="'%'"
      v-model="percentVal"
    ></input-num>

    <h3>input slider</h3>
    <input-slider
      class="primary"
      :init="$store.state._person.monthlyExpenses.ui.init"
      :css="$store.state.defaults.sliderCSSDesktop"
      :min-value="$store.state._person.monthlyExpenses.ui.minVal"
      :max-value="$store.state._person.monthlyExpenses.ui.maxVal"
      :step="$store.state._person.monthlyExpenses.ui.step"
      v-model="$store.state._person.monthlyExpenses"
    ></input-slider>
    <input-num
      v-model="$store.state._person.monthlyExpenses"
    ></input-num>

    <h3>input text</h3>
    <h4>input text disabled</h4>
    <input-text v-model="$store.state._person.firstName" :disabled="true"></input-text>
    <h4>input text name</h4>
    <input-text v-model="$store.state._person.firstName"></input-text>
    <h4>input text phone</h4>
    <input-text v-model="phone"></input-text>
    <h4>input text mail</h4>
    <input-text v-model="email"></input-text>

    <h3>input radio</h3>
    <div class="inputs">
      <input-radio
        :disabled="$store.state._person.gender.ui.disabled"
        :init="$store.state._person.gender.ui.init"
        :optionA="$store.state._person.gender.ui.optionA"
        :optionB="$store.state._person.gender.ui.optionB"
        v-model="$store.state._person.gender"
      >
        <span slot="option1">Man</span>
        <span slot="option2">Vrouw</span>
      </input-radio>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  text-align: left;
  .input-dropdown {
    width: pxToEm(280);
    height: pxToEm(62);
  }
  .input-num {
    width: pxToEm(180);
    height: pxToEm(62);
  }
  .input-text {
    width: pxToEm(180);
    height: pxToEm(62);
  }
  .input-date {
    width: pxToEm(300);
    height: pxToEm(62);
  }
  .input-boolean {
    width: pxToEm(180);
    height: pxToEm(62);
  }
  .input-radio {
    width: pxToEm(320);
    height: pxToEm(62);
  }
}
</style>

<script>
import inputBoolean from "@/components/common/input-boolean";
import inputDate from "@/components/common/input-date";
import inputDropdown from "@/components/common/input-dropdown";
import inputNum from "@/components/common/input-num";
import inputRadio from "@/components/common/input-radio";
import inputSlider from "@/components/common/input-slider";
import inputText from "@/components/common/input-text";

export default {
  name: "componentsTestPage",
  components: {
    inputBoolean,
    inputDate,
    inputDropdown,
    inputNum,
    inputRadio,
    inputSlider,
    inputText
  }, data: function() {
    return {
      percentVal: null,
      email: null,
      phone: null
    }
  }
};
</script>
