<template>
  <div>
    <MobileOfferSection :block="block" v-if="isMobile"></MobileOfferSection>
    <DesktopOfferSection :block="block" v-else></DesktopOfferSection>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import MobileOfferSection from "@/components/strapi/OfferSection/MobileOfferSection";
import DesktopOfferSection from "@/components/strapi/OfferSection/DesktopOfferSection";

export default {
  name: 'OfferSectionBlock',
  components: {
    MobileOfferSection,
    DesktopOfferSection
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
