import { render, staticRenderFns } from "./mob-header-logo.vue?vue&type=template&id=3d470775&scoped=true&"
var script = {}
import style0 from "./mob-header-logo.vue?vue&type=style&index=0&id=3d470775&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d470775",
  null
  
)

export default component.exports