<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="addresses">
          <div v-for="Contact in block.contacts.data" class="address">
            <div class="text">
              <img src="@/assets/la-logo.png">
              <div>
                {{Contact.attributes.Name}}
                <br>{{Contact.attributes.Title}}
                <br>{{Contact.attributes.Address}}
                <br>{{Contact.attributes.Postcode}} {{Contact.attributes.City}}
              </div>
            </div>

            <a class="button" :href="'https://maps.google.com/maps?q=' + encodeURI(Contact.attributes.Address + ' ' + Contact.attributes.Postcode + ' ' + Contact.attributes.City)">
              <span>Open maps</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="63.773"
                height="51.096"
                viewBox="0 0 63.773 51.096"
              >
                <g transform="translate(0)">
                  <g fill="none" stroke="#FF4367" stroke-width="3" transform="translate(12.677 0)">
                    <ellipse cx="25.548" cy="25.548" stroke="none" rx="25.548" ry="25.548"></ellipse>
                    <ellipse cx="25.548" cy="25.548" fill="none" rx="24.048" ry="24.048"></ellipse>
                  </g>
                  <path
                    fill="none"
                    stroke="#FF4367"
                    stroke-width="3"
                    d="M7232.648,1306h47.439"
                    transform="translate(-7232.648 -1280.452)"
                  ></path>
                  <path
                    fill="none"
                    stroke="#FF4367"
                    stroke-width="3"
                    d="M0,.176,19.721,0l-.132,19.766"
                    transform="translate(34.564 11.603) rotate(45)"
                  ></path>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  height: pxToEm(600);

  &.white {
    background-color: #fff;
  }

  .layout {
    .section {
      margin: pxToEm(25);

      .addresses {
        display: flex;
        flex-direction: column;
        align-items: center;

        .address {
          background-color: getColor('bgColor');
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          width: pxToEm(252);
          height: pxToEm(205);
          padding: pxToEm(25);
          padding-top: pxToEm(35);
          display: flex;
          flex-direction: column;
          text-align: left;
          border-bottom: 10px solid getColor("primaryLight");
          margin-bottom: pxToEm(25);

          &:last-child {
            margin-bottom: 0;
          }

          .text {
            display: flex;
            align-items: flex-start;
            margin-top: pxToEm(20);

            & > div {
              font-size: pxToPercent(20);
            }

            img {
              width: pxToEm(49);
              height: pxToEm(49);
              margin-right: pxToEm(20);
            }
          }

          .button {
            width: pxToEm(170);
            padding: pxToEm(20);
            margin-left: pxToEm(42);
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            font-size: pxToPercent(16);
            color: getColor("primaryLight");
            text-decoration:none;

            svg {
              width: pxToEm(36);
              height: pxToEm(28);
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import {strapiAssetUrl} from "@/JS/helpers";

export default {
  name: 'DesktopContactUsSectionBlock',
  props: {
    block: Object
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    }
  },
}
</script>
