<template>
  <div class="area" v-if="Items">
    <div class="layout">
      <div class="section">
        <div class="usps">
          <div class="usp-1">
            <img src="@/assets/usp0.svg" />
            <div class="usp-desc txt">
              {{ Items.First_title }}
            </div>
          </div>
          <div class="usp-2">
            <div class="txt pink">
              <span class="vanaf">Vanaf </span> {{ Items.Percentage.toLocaleString("nl-NL") }}%
            </div>
            <img src="@/assets/usp1.svg" />
            <div class="usp-desc txt pink">
              {{ Items.Second_title }}
            </div>
          </div>
          <div class="usp-3">
            <img src="@/assets/usp2.svg" />
            <div class="usp-desc txt">
              {{ Items.Third_Title }}
            </div>
          </div>
        </div>
        <div class="usps below">
          <div class="usp-1"></div>
          <div class="usp-2">
            <div class="red">
              De Europese Standaardinformatie inzake Consumentenkrediet is bij ons verkrijgbaar.
            </div>
            <div class="red"> Informeer hiernaar!</div>
          </div>
          <div class="usp-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.vanaf {
  font-size: 0.75em;
  color: #C82346;
  font-weight: 600;
}
.area {
  background-color: getColor("greyLight");
  .layout {
    .mt-2 {
      margin-top: 20px;
    }
    .red {
      color: #C82346;
      font-size: 0.875em;
    }
    max-width: 1920px;

    .section {
      display: flex;
      flex-direction: column;

      .container {
        display: flex;

        .pic {
          width: 50%;
          height: pxToEm(736);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
        .title-container {
          position: relative;
          width: 50%;
          max-width: pxToEm(580);

          .title {
            position: absolute;
            width: pxToEm(570);
            height: pxToEm(400);
            background-color: white;
            left: pxToEm(-83);
            top: pxToEm(100);

            > div:nth-child(1) {
              text-align: left;
              font-weight: 700;
              font-size: pxToPercent(50);
              line-height: 55/50em;
              margin: 1.4em;
            }
          }
        }
        .btn-big {
          position: absolute;
          right: 0;
          top: pxToEm(465);
          .content {
            margin-left: pxToEm(40);
            margin-right: pxToEm(40);
            .txt {
              font-size: pxToPercent(28);
            }
            img {
              width: pxToEm(63);
              height: pxToEm(50);
            }
          }
        }
      }

      .usps {
        display: flex;
        flex-wrap: wrap;
        width: 70%;
        justify-content: space-between;
        margin-top: pxToEm(100);
        align-self: center;
        align-items: center;

        &.below {
          margin-top: pxToEm(15);
          margin-bottom: pxToEm(100);
        }

        .txt {
          width: 100%;
          text-align: center;
          &.pink {
            font-weight: 700;
            font-size: pxToEm(38);
            margin-bottom: pxToEm(4);
            color: getColor("primaryDefault");
          }
        }
        img {
          width: fit-content;
          margin-bottom: pxToEm(38);
        }

        .usp-desc {
          font-size: pxToEm(24);
          &.pink {
            width: fit-content;
            padding: 0px 10%;
            font-size: pxToEm(24);
            font-weight: 400;
          }
        }

        .usp-1 {
          width: 25%;
        }
        .usp-2 {
          width: 40%;
          img {
            max-width: 320px;
            width: 80%;
          }
          margin-bottom: pxToEm(4);
        }
        .usp-3 {
          width: 25%;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .area {
    background-color: getColor("greyLight");
    padding: 0 !important;

    .layout {
      .section {
        max-width: 768px;
        margin: pxToEm(20) pxToEm(0);

        .usps {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          justify-content: space-between;
          margin-top: pxToEm(15);
          align-self: center;
          align-items: center;

          &.below {
            margin-bottom: pxToEm(10);
            margin-bottom: pxToEm(15);

            font-size: pxToEm(12);
          }

          .txt {
            width: 100%;
            text-align: center;
            &.pink {
              font-weight: 700;
              font-size: pxToEm(28);
              margin-bottom: pxToEm(4);
              color: getColor("primaryDefault");
            }
          }
          img {
            width: pxToEm(70);
            margin-bottom: pxToEm(20);
          }

          .usp-desc {
            font-size: pxToEm(12);
            &.pink {
              width: fit-content;
              font-size: pxToEm(12);
              font-weight: 700;
            }
          }

          .usp-1 {
            width: 26%;
            text-align: center;
          }
          .usp-2 {
            width: 48%;
            text-align: center;
            img {
              max-width: 320px;
              width: pxToEm(110);
            }
          }
          .usp-3 {
            width: 26%;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>

<script>
import Api from "@/Api";

export default {
  name: "UspSSectionBlock",
  async created() {
    Api.get(`percentage-section`).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.attributes
      ) {
        this.Items = response.data.data.attributes;
      }
    });
  },
  data() {
    return {
      Items: {},
    };
  },
  props: {
    block: Object,
  },
};
</script>
