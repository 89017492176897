<template>
  <div>
    <LoanSavingCalculator v-if="block.show_calculator" class="area white"></LoanSavingCalculator>
  </div>
</template>

<style lang="less" scoped>
.area {
    background-color: getColor("greyLight");
    padding: 0 15px;
    box-sizing: border-box;
    &.white {
      background-color: #fff;
    }

    .logo {
      // display: none;
    }

    .font-weight-bold {
      font-weight: 700;
    }

    .layout {
      align-items: center;
      max-width: pxToEm(965);

      img {
        max-width: 100%;
      }

      .section {
        margin: pxToEm(50);
        width: pxToEm(1160);
        max-width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        align-items: center;

        @media (max-width: 768px){
          text-align: left !important;
          align-items: unset;
        }

        a.no-underline {
          text-decoration: none;
        }

        .info {
          text-align: left;
        }

        .title {
          font-size: pxToEm(70);
          font-weight: 800;
          line-height: 1em;
          margin-top: 0;
          margin-bottom: 0;
        }

        .dash {
          width: pxToEm(123);
          height: pxToEm(11);
          background-color: getColor("primaryLight");
          margin-top: pxToEm(20);
        }

        .pic {
          width: 100%;
          height: pxToEm(350);
          margin-top: pxToEm(40);
          margin-bottom: pxToEm(50);
          background-position: center;
          background-size: cover;
        }

        .gap {
          height: pxToEm(100);
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
  .title {
    font-size: 175% !important;
  }
}

  @media screen {
    @media (max-width: 767px) {

      .calc-area {
        padding-top: 5em;
      }
    }
    @media (min-width: 769px){
      .area{
        &.white{
          &.mobile{
            display: none;
          }
        }
      }
    }
    @media (max-width: 769px){
      .area{
        &.white{
          &.desktop{
            display: none;
          }
        }
      }
    }
  }
</style>

<script>
import { strapiAssetUrl } from "@/JS/helpers";
import LoanSavingCalculator from "@/components/desktop/calculators/B/calculator-form.vue";

export default {
  name: 'LoanSavingCalculatorBlock',
  components: {
    LoanSavingCalculator,
},
  props: {
    block: Object
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    }
  }
}
</script>
