<template>
  <div class="area">
    <div class="layout">
      <div class="section">
        <div id="anchor-calc-a-top" style="position:absolute; top:-70px;"></div>
        
        <div class="btn-1-container" @click="toggleForm(true)" v-anchor="'#anchor-calc-a-top'">
          <btn-big class="primary" :height="86">
            <div class="content">
              <div class="txt">Bereken hoeveel<br>u kunt lenen</div>
              <img src="@/assets/arrow.svg" alt>
            </div>
          </btn-big>
        </div>

        <div class="container-b">
          <div class="form" v-if="formArea">
            <div class="sep-80"></div>
            
            <div class="title">
              <div class="txt">
                Hoeveel kan ik lenen?
              </div>
            </div>

            <div id="anchor-calc-a-error"></div>
    
            <div class="sep-50"></div>
            
            <div class="error" v-if="form.state == false">Er zijn enkele velden niet correct ingevuld.</div>
    
            <div class="form-body" :class="{formcheck: form.state == false}">
              <div class="col-111 slider-input">
                <div class="txt-label">
                  <span>Hoeveel geld wilt u lenen?</span>
                </div>
                <!-- <input-slider
                  class="primary"
                  :css="$store.state.defaults.sliderCSSMobile"
                  v-model="$store.state._loanRequest.loanAmount"
                ></input-slider> -->
                <input-num v-model="$store.state._loanRequest.loanAmount"></input-num>
              </div>
    
              <div class="col-11 toggle-input">
                <div class="txt-label">
                  <span>Bent u getrouwd of samenwonend?</span>
                </div>
                <input-boolean v-model="$store.state._person.married"></input-boolean>
              </div>
    
              <div class="col-11 toggle-input">
                <div class="txt-label">
                  <span>Heeft u inwonende kinderen?</span>
                </div>
                <input-boolean v-model="$store.state._person.children"></input-boolean>
              </div>
    
              <div class="col-11 select-input">
                <div class="txt-label">
                  <span>Wat is uw woonsituatie?</span>
                </div>
                <input-dropdown
                  :options="$store.state.livingStateDropdown"
                  v-model="$store.state._person.livingState"
                ></input-dropdown>
              </div>
    
              <div class="col-111 slider-input">
                <div class="txt-label">
                  <span>Wat zijn uw woonlasten?</span>
                </div>
                <!-- <input-slider
                  class="primary"
                  :css="$store.state.defaults.sliderCSSMobile"
                  v-model="$store.state._person.monthlyExpenses"
                ></input-slider> -->
                <input-num v-model="$store.state._person.monthlyExpenses"></input-num>
              </div>
    
              <div class="col-111 slider-input">
                <div class="txt-label">
                  <span>Wat is uw netto inkomen per maand?</span>
                </div>
                <!-- <input-slider class="primary" :css="$store.state.defaults.sliderCSSMobile" v-model="$store.state._person.income"></input-slider> -->
                <input-num v-model="$store.state._person.income"></input-num>
              </div>

              <div class="col-111 slider-input" v-if="$store.state._person.married == true">
                <div class="txt-label">
                  <span>Wat is het netto inkomen per maand van uw partner?</span>
                </div>
                <!-- <input-slider class="primary" :css="$store.state.defaults.sliderCSSMobile" v-model="$store.state._person.partnerIncome"></input-slider> -->
                <input-num v-model="$store.state._person.partnerIncome"></input-num>
              </div>
            </div>
      
            <div class="sep-70"></div>
      
            <div class="btn-2-container" v-anchor="'#black-area-top'">
              <btn-big class="primary" :height="86" :click="toggleBlackArea">
                <div class="content">
                  <div class="txt">Bereken</div>
                  <img src="@/assets/arrow.svg" alt>
                </div>
              </btn-big>
            </div>
      
            <div class="sep-80"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="layout black-layout">
      <div class="section">
        <div id="black-area-top"></div>
        
        <black-area v-if="blackFormArea"></black-area>
      </div>
    </div>

    <div
      class="white-button"
      v-if="formArea"
      @click="toggleForm(false)"
      v-anchor="'#anchor-calc-a-top'"
    >
      <div class="arrow-cont">
        <div class="arrow"></div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @gridRowGap: 3em;
  @outerMargin: pxToEm(20);
  @minWidth: pxToEm(200);
  @maxWidthForm: pxToEm(300);
  @minWidthBtnCont: @minWidth + 2 * @outerMargin;
  @maxWidthBtnCont: @maxWidthForm + 2 * @outerMargin;

  .layout {
    &.black-layout {
      background-color: getColor('grayDkColor');
    }

    .section {
      margin: 0 @outerMargin;
      text-align: left;

      .btn-1-container {
        width: 100vw;
        min-width: minWidthBtnCont;
        max-width: @maxWidthBtnCont;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        z-index: 300;

        .btn-big {
          margin: 0 @outerMargin;
        }
      }
      
      .container-b {
        width: 100%;
        min-width: @minWidth;
        margin: 0 auto;
      }
    }

    .btn-big {
      border-bottom-width: pxToEm(7);

      .content {
        margin: 0 pxToEm(20);

        .txt {
          margin-right: pxToEm(20);
          font-size: pxToPercent(16);
          font-weight: 700;
          text-align: left;
        }

        img {
          width: pxToEm(36);
          height: pxToEm(28);
        }
      }
    }

    .form {
      min-width: @minWidth;
      max-width: @maxWidthForm;
      margin: 0 auto;

      .title {
        .txt {
          font-size: pxToPercent(40);
          font-weight: 300;
          text-align: center;
        }
      }

      .error {
        margin-bottom: pxToEm(50);
        color: getColor("redColor");
        font-style: italic;
      }

      .form-body {
        margin: 0 auto;
        display: grid;
        grid-row-gap: @gridRowGap;
        text-align: left;

        .txt-label {
          margin-bottom: pxToEm(10);
          font-size: pxToPercent(16);
          font-weight: 300;
        }

        .col-11 {
          display: grid;
          grid-template-rows: 1fr pxToEm(52);
        }

        .col-111 {
          display: grid;
          grid-template-rows: 1fr pxToEm(68) pxToEm(52);

          .input-slider {
            margin-bottom: pxToEm(16);
          }
        }
      }

      .btn-2-container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .white-button {
    width: pxToEm(50);
    height: pxToEm(50);
    position: absolute;
    left: 50%;
    bottom: pxToEm(-25);
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: getColor("whiteColor");
    box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
    z-index: 100;
    cursor: pointer;

    .arrow-cont {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .arrow {
        position: relative;
        margin-top: pxToEm(5);
        padding: pxToEm(5);
        transform: rotate(-135deg);
        display: inline-block;
        border: solid getColor("primaryLight");
        border-width: 0 pxToEm(3) pxToEm(3) 0;
      }
    }
  }
</style>

<script>
  import blackArea from "@/components/mobile/pdp/A/black-area";
  import btnBig from "@/components/common/btn-big";
  import inputBoolean from "@/components/common/input-boolean";
  import inputDropdown from "@/components/common/input-dropdown";
  import inputNum from "@/components/common/input-num";
  import inputSlider from "@/components/common/input-slider";
  import { maxLoanCalc } from "@/data/maxLoanCalc";
  import { goToAnchor, checkTab } from "@/JS/helpers";

  export default {
    name: "calculatorA",
    components: {
      blackArea,
      btnBig,
      inputBoolean,
      inputDropdown,
      inputNum,
      inputSlider
    },
    data() {
      return {
        formArea: false,
        blackFormArea: false,
        form: {
          fields: [$store.state._loanRequest.loanAmount, $store.state._person.married, $store.state._person.children, $store.state._person.livingState, $store.state._person.monthlyExpenses, $store.state._person.income, $store.state._person.partnerIncome],
          state: undefined
        }
      };
    },
    watch: {
      "$store.state._person.married": function(newVal, oldVal) {
        // *** THIS NEEDS A BETTER, CLEANER CODING SOLUTION ***
        var re = /partnerIncome/;
        if ($store.state._person.married == true) {
          for (var field of this.form.fields) {
            if (field.ui.label && re.test(field.ui.label)) {
              field.ui.valid = false;
            }
          }
        }
      }
    },
    methods: {
      toggleBlackArea: function() {
        this.form.state = checkTab(this.form.fields);

        if (this.form.state == true) {
          $store.commit("calcMaxLoanAmount");
          this.blackFormArea = true;
          setTimeout(function(){
            goToAnchor('#black-area-top');
          }, 100);
        }
        else{
          setTimeout(function(){
            goToAnchor("#anchor-calc-a-error", 100);
          }, 100);
        }
        
      },
      toggleForm: function(state) {
        this.formArea = state;

        if (state == false) {
          this.blackFormArea = state;
          $store.commit("resetForms");
          this.form.state = undefined;
        } else {
          goToAnchor("#black-area-top");
        }
      }
    }
  };
</script>
