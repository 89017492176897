<template>
  <div class="area">
    <div class="layout">
      <div class="section">
        <div id="anchor-calc-b-top" style="position:absolute; top:-70px;"></div>

        <div class="btn-1-container" @click="toggleForm(true)" v-anchor="'#anchor-calc-b-top'">
          <btn-big class="primary" :height="86">
            <div class="content">
              <div class="txt">Bereken hoeveel rente<br>u kunt besparen</div>
              <img src="@/assets/arrow.svg" alt>
            </div>
          </btn-big>
        </div>

        <div class="container-b">
          <div class="form" v-if="formArea">
            <div class="sep-80"></div>

            <div class="title">
              <div class="txt">
                Hoeveel kan ik lenen?
              </div>
            </div>

            <div class="form-body">
              <div class="col-11 select-input">
                <div class="txt-label">
                  <span>Hoeveel leningen heeft u lopen?</span>
                </div>
                <input-dropdown
                  :init="$store.state._loanRequest.prevLoans.length > 0"
                  :options="$store.state.loansDropdown"
                  :placeholder="'0'"
                  v-model="Loans"
                ></input-dropdown>
              </div>
            </div>

            <div id="anchor-calc-b-error"></div>

            <div class="sep-50"></div>

            <div
              class="error"
              v-if="form.state == false"
            >Er zijn enkele velden niet correct ingevuld.</div>

            <div class="form-body">
              <div class="loans" v-if="$store.state._loanRequest.prevLoans.length > 0">
                <div
                  class="loan"
                  v-for="(loan, index) in $store.state._loanRequest.prevLoans"
                  :key="index"
                  :class="{formcheck: form.state == false}"
                >
                  <div class="sub-title">Lening {{ index + 1 }}</div>

                  <div class="form-body">
                    <div class="col-11 num-input">
                      <div class="txt-label">
                        <span>Leenbedrag</span>
                      </div>
                      <input-num v-model="loan.amount"></input-num>
                    </div>

                    <div class="col-11 num-input">
                      <div class="txt-label">
                        <span>Effectieve jaarrente</span>
                      </div>
                      <input-num v-model="loan.rate"></input-num>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="sep-70"></div>

            <div class="btn-2-container" v-anchor="'#black-area-top'">
              <btn-big class="primary" :height="86" :click="toggleBlackArea">
                <div class="content">
                  <div class="txt">Bereken</div>
                  <img src="@/assets/arrow.svg" alt>
                </div>
              </btn-big>
            </div>

            <div class="sep-80"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="layout black-layout">
      <div class="section">
        <div id="black-area-top"></div>
        <black-area v-if="blackFormArea"></black-area>
      </div>
    </div>

    <div
      class="white-button"
      v-if="formArea"
      @click="toggleForm(false)"
      v-anchor="'#anchor-calc-b-top'"
    >
      <div class="arrow-cont">
        <div class="arrow"></div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @gridRowGap: 3em;
  @outerMargin: pxToEm(20);
  @minWidth: pxToEm(200);
  @maxWidthForm: pxToEm(300);
  @minWidthBtnCont: @minWidth + 2 * @outerMargin;
  @maxWidthBtnCont: @maxWidthForm + 2 * @outerMargin;

  .layout {
    &.black-layout {
      background-color: getColor('grayDkColor');
    }

    .section {
      margin: 0 @outerMargin;
      text-align: left;

      .btn-1-container {
        width: 100vw;
        min-width: minWidthBtnCont;
        max-width: @maxWidthBtnCont;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        z-index: 300;

        .btn-big {
          margin: 0 @outerMargin;
        }
      }

      .container-b {
        width: 100%;
        min-width: @minWidth;
        margin: 0 auto;
      }
    }

    .btn-big {
      border-bottom-width: pxToEm(7);

      .content {
        margin: 0 pxToEm(20);

        .txt {
          margin-right: pxToEm(20);
          font-size: pxToPercent(16);
          font-weight: 700;
          text-align: left;
        }

        img {
          width: pxToEm(36);
          height: pxToEm(28);
        }
      }
    }

    .form {
      min-width: @minWidth;
      max-width: @maxWidthForm;
      margin: 0 auto;

      .title {
        margin-bottom: pxToEm(50);

        .txt {
          font-size: pxToPercent(40);
          font-weight: 300;
          text-align: center;
        }
      }

      .error {
        color: getColor("redColor");
        font-style: italic;
      }

      .form-body {
        margin: 0 auto;
        display: grid;
        grid-row-gap: @gridRowGap;
        text-align: left;

        .txt-label {
          margin-bottom: pxToEm(10);
          font-size: pxToPercent(16);
          font-weight: 300;
        }

        .col-11 {
          display: grid;
          grid-template-rows: 1fr pxToEm(52);
        }
      }

      .loans {
        .sub-title {
          margin-top: pxToEm(40);
          margin-bottom: pxToEm(20);
          font-size: pxToPercent(24);
        }
      }

      .btn-2-container {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .white-button {
    width: pxToEm(50);
    height: pxToEm(50);
    position: absolute;
    left: 50%;
    bottom: pxToEm(-25);
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: getColor("whiteColor");
    box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
    z-index: 100;
    cursor: pointer;

    .arrow-cont {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .arrow {
        position: relative;
        margin-top: pxToEm(5);
        padding: pxToEm(5);
        transform: rotate(-135deg);
        display: inline-block;
        border: solid getColor("primaryLight");
        border-width: 0 pxToEm(3) pxToEm(3) 0;
      }
    }
  }
</style>

<script>
  import blackArea from "@/components/mobile/pdp/B/black-area";
  import btnBig from "@/components/common/btn-big";
  import inputDropdown from "@/components/common/input-dropdown";
  import inputNum from "@/components/common/input-num";
  import { sumLenings } from "@/data/leningOversluitenCalc";
  import { goToAnchor, checkTab } from "@/JS/helpers";

  export default {
    name: "calculatorB",
    components: {
      blackArea,
      btnBig,
      inputDropdown,
      inputNum
    },
    data() {
      return {
        blackFormArea: false,
        formArea: false,
        form: {
          fields: [$store.state._loanRequest.prevLoans],
          state: undefined
        }
      };
    },
    created() {
      this.Loans = 1;
    },
    computed: {
      Loans: {
        get() {
          var loanRequest = $store.state._loanRequest;
          var res = new Number(loanRequest.prevLoans.length);
          res.ui = loanRequest.prevLoans.ui;
          return res;
        },
        set(n) {
          var loanRequest = $store.state._loanRequest;
          if (n != loanRequest.prevLoans.length) {
            if (n > 0) {
              loanRequest.prevLoans.ui.init = true;
              loanRequest.prevLoans.ui.valid = true;
            } else {
              loanRequest.prevLoans.ui.valid = false;
            }
            if (n > loanRequest.prevLoans.length) {
              for (var i = loanRequest.prevLoans.length; i < n; i++) {
                loanRequest.prevLoans.push($store.state.prevLoanTemplate());
              }
            } else loanRequest.prevLoans.splice(n);
          }
        }
      }
    },
    methods: {
      getLoans(n) {
        var vm = this;
        if (n != vm.loans.length)
          if (n > vm.loans.length) {
            for (var i = vm.loans.length; i < n; i++) {
              vm.loans.push({ name: "", amount: 0, rate: 0, ransom: null });
            }
          } else vm.loans.splice(n);
        return vm.loans;
      },
      toggleBlackArea: function() {
        this.form.state = checkTab(this.form.fields);

        if (this.form.state) {
          $store.dispatch('calculateSavings');
          this.blackFormArea = true;
          setTimeout(function(){
            goToAnchor("#black-area-top", 100);
          }, 100);
        } else {
          setTimeout(function(){
            goToAnchor("#anchor-calc-b-error", 100);
          }, 100);
        }
      },
      toggleForm: function(state) {
        this.formArea = state;

        if (state == false) {
          this.blackFormArea = state;
          $store.commit("resetForms");
          $store.state._loanRequest.loanOption = $store.state.funnelBOption;
          this.form.state = undefined;
        } else {
          goToAnchor("#black-area-top");
        }
      }
    }
  };
</script>
