<template>
  <div>
    <MobileContactSectionBlock :block="block" v-if="isMobile"></MobileContactSectionBlock>
    <DesktopContactSectionBlock :block="block" v-if="!isMobile"></DesktopContactSectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import MobileContactSectionBlock from "@/components/strapi/Contact/MobileContactSectionBlock";
import DesktopContactSectionBlock from "@/components/strapi/Contact/DesktopContactSectionBlock";

export default {
  name: 'ContactSectionBlock',
  components: {
    MobileContactSectionBlock,
    DesktopContactSectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
