<template>
  <div
    class="table"
    :class="{ doorlopend: $store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND }"
  >
    <div class="col">
      <div class="tr"></div>
      <div class="tr gap"></div>
      <div
        class="tr"
        v-for="(p, v) in loanInfo[$store.state._loanRequest.loanTypeTable].data"
        :key="v"
      ></div>
      <div class="tr gap"></div>
    </div>

    <div
      class="col"
      v-for="(k, i) in loanInfo[$store.state._loanRequest.loanTypeTable].title"
      :key="i"
    >
      <div class="tr">{{ k }}</div>
      <div class="tr gap"></div>
      <div
        class="tr"
        v-for="(p, v) in loanInfo[$store.state._loanRequest.loanTypeTable].data"
        :key="v"
      >{{ getObjectKeyValue($store.state._loanRequest.loanTypeTable, p, i) }}</div>
      <div class="tr gap"></div>
    </div>

    <div class="col">
      <div class="tr"></div>
      <div class="tr gap"></div>
      <div
        class="tr"
        v-for="(p, v) in loanInfo[$store.state._loanRequest.loanTypeTable].data"
        :key="v"
      >
        <button
          class="btn-select"
          :class="{active: ($store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND && selectedMonths == loanInfo[$store.state._loanRequest.loanTypeTable].data[v].theoretischeLooptijd) || ($store.state._loanRequest.loanTypeTable == $store.state.loanType.PERSOONLIJKE && selectedMonths == loanInfo[$store.state._loanRequest.loanTypeTable].data[v].mogelijkheden)}"
          @click="
            selectMonths($store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND ? loanInfo[$store.state._loanRequest.loanTypeTable].data[v].theoretischeLooptijd : loanInfo[$store.state._loanRequest.loanTypeTable].data[v].mogelijkheden)
            select()"
        >
          {{
            ($store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND && selectedMonths == loanInfo[$store.state._loanRequest.loanTypeTable].data[v].theoretischeLooptijd)
            ||
            ($store.state._loanRequest.loanTypeTable == $store.state.loanType.PERSOONLIJKE && selectedMonths == loanInfo[$store.state._loanRequest.loanTypeTable].data[v].mogelijkheden)
            ? 'Geselecteerd' : 'Selecteer'
          }}
        </button>
      </div>
      <div class="tr gap"></div>
    </div>

    <div class="col">
      <div class="tr"></div>
      <div class="tr gap"></div>
      <div
        class="tr"
        v-for="(p, v) in loanInfo[$store.state._loanRequest.loanTypeTable].data"
        :key="v"
      ></div>
      <div class="tr gap"></div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.table {
  display: grid;
  grid-template-columns: pxToEm(35) 1fr 1fr 1fr 1fr 1fr 1fr pxToEm(35);
  @media only screen and (max-width: 1024px) {
    font-size: 12px;
  }

  &.doorlopend {
    grid-template-columns: pxToEm(35) 1fr 1fr 1fr 1fr 1fr 1fr 1fr pxToEm(35);

    .col {
      .tr {
        &:nth-child(6) {
          border: none;
        }
      }
    }
  }

  .col {
    .tr {
      box-sizing: border-box;
      display: grid;
      align-items: center;
      height: pxToEm(80);
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      color: #3a3a36;

      &:first-child {
        background-color: getColor("grayDkColor");
        color: #ffffff;
        font-weight: 700;
        height: pxToEm(100);
        border: none;
      }

      &:nth-child(2),
      &:last-child {
        border: none;
        height: pxToEm(25);
      }

      &:nth-child(7) {
        border: none;
      }
      .btn-select {
        justify-content: center;
        align-items: center;
        border: 0;
        border-bottom-style: solid;
        height: pxToEm(65);
        border-bottom-width: pxToEm(65*0.145);
        cursor:pointer;
        flex: 0 1 auto;
        color:white;
        font-size: 18px;
        font-weight: 700;
        border-bottom-color: getColor('primaryDefault');
        background: linear-gradient(getColor('primaryLight'), getColor('primaryLight') 50%, getColor('primaryDefault') 50%, getColor('primaryDefault'));
        background-size: 100% 200%;
        transition: background 0.15s;

        &:hover {
          background-position: 100% 100%;
        }
      }
      .btn-select.active {
        border-bottom-color: #4a983e;
        background: linear-gradient(getColor('greenDefault'), getColor('greenDefault') 50%, #4a983e 50%, #4a983e);
        background-size: 100% 200%;
        transition: background 0.15s;
        &:hover {
          background-position: 100% 100%;
        }
      }
    }

    &:first-child,
    &:last-child {
      width: pxToEm(35);
      .tr {
        border: none;
      }
    }
  }
}
</style>

<script>
import Api from "@/Api";
import { DoorlopendType, PersoonlijkeType } from "@/data/loanTypes";

export default {
  name: "loanTypeTable",
  props: {
    select: null
  },
  data() {
    return {
      loanInfo: [
        {
          title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Totaalprijs"],
          data: [new PersoonlijkeType(36), new PersoonlijkeType(60), new PersoonlijkeType(96), new PersoonlijkeType(120), new PersoonlijkeType(180)],
          months: ["36 maanden", "60 maanden", "96 maanden", "120 maanden", "180 maanden"]
        },
        {
          title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Theoretische looptijd", "Totaalprijs"],
          data: [new DoorlopendType(56), new DoorlopendType(77), new DoorlopendType(126)],
          months: ["56 maanden", "77 maanden", "126 maanden"]
        }
      ],
      selectedMonths: $store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND ? "56 maanden" : "36 maanden",
    };
  },
  created() {
    Api.get(`percentage-section`).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.attributes
      ) {
        this.percentageSection = response.data.data.attributes;

        //FIX FOR NaN VALUE WHEN COMMING FROM CALCULATOR B
        this.calcMaandlast(Number.isNaN($store.state._loanRequest.approvedLoanAmount.valueOf()) ? $store.state._loanRequest.newLoan.total : $store.state._loanRequest.approvedLoanAmount);
        //END FIX
      }
    });

    this.$root.$on('loantype_select_event', (loanType) => {
      let _selectedMonths = loanType == $store.state.loanType.DOORLOPEND ? "56 maanden" : "36 maanden";
      this.selectMonths(_selectedMonths);
      // $store.state.selectedMonths = _selectedMonths;
      // this.$root.$emit('months_select_event', loanType, _selectedMonths);
    })

    //this.calcMaandlast($store.state._loanRequest.approvedLoanAmount);

    //FIX FOR NaN VALUE WHEN COMMING FROM CALCULATOR B
    //this.calcMaandlast(Number.isNaN($store.state._loanRequest.approvedLoanAmount.valueOf()) ? $store.state._loanRequest.loanAmount : $store.state._loanRequest.approvedLoanAmount);
    //END FIX
  },
  methods: {
    calcMaandlast(val) {
      var x;
      var result;
      for (var i = 0; i < this.loanInfo[$store.state._loanRequest.loanTypeTable].data.length; i++) {
        this.loanInfo[$store.state._loanRequest.loanTypeTable].data[i].update(this.percentageSection, val);
      }
    },
    getObjectKeyValue(tableType, obj, idx) {
      const keys = {
        [$store.state.loanType.PERSOONLIJKE]: {
          0: 'mogelijkheden',
          1: 'maandlast',
          2: 'effectiveJaarrente',
          3: 'nominaleMaandrente',
          4: 'totaalprijs',
        },
        [$store.state.loanType.DOORLOPEND]: {
          0: 'mogelijkheden',
          1: 'maandlast',
          2: 'effectiveJaarrente',
          3: 'nominaleMaandrente',
          4: 'theoretischeLooptijd',
          5: 'totaalprijs',
        }
      }
      return obj[keys[tableType][idx] || 'maandlast'];
    },

    selectMonths(monthsStr){
      this.selectedMonths = monthsStr;
      $store.state.selectedMonths = monthsStr;

      $store.state._loanRequest.loanType = $store.state._loanRequest.loanTypeTable;
      $store.state._loanRequest.months = this.loanInfo[$store.state._loanRequest.loanTypeTable].months.indexOf($store.state.selectedMonths);

      this.$root.$emit('months_select_event', $store.state._loanRequest.loanTypeTable, monthsStr);
    }
  },
  watch: {
    "$store.state._loanRequest.approvedLoanAmount": function(newVal, oldVal) {
      this.calcMaandlast(newVal);
    },
    "$store.state._loanRequest.loanTypeTable": function(newVal, oldVal) {
      //this.calcMaandlast($store.state._loanRequest.approvedLoanAmount);
      //FIX FOR NaN VALUE WHEN COMMING FROM CALCULATOR B
      this.calcMaandlast(Number.isNaN($store.state._loanRequest.approvedLoanAmount.valueOf()) ? $store.state._loanRequest.newLoan.total : $store.state._loanRequest.approvedLoanAmount);
      //END FIX
    }
  }
};
</script>
