<template>
  <div>
    <MobileHomeHeaderBlock :block="block" v-if="isMobile"></MobileHomeHeaderBlock>
    <DesktopHomeHeaderBlock :block="block" v-else></DesktopHomeHeaderBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>

import MobileHomeHeaderBlock from "@/components/strapi/HomeHeaderBlock/MobileHomeHeaderBlock";
import DesktopHomeHeaderBlock from "@/components/strapi/HomeHeaderBlock/DesktopHomeHeaderBlock";

export default {
  name: 'HomeHeaderBlock',
  components: {
    MobileHomeHeaderBlock,
    DesktopHomeHeaderBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
