function setCookie(key, value, expirationDays) {
    var d = new Date();
    d.setTime(d.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
    var expires = 'expires=' + d.toGMTString();
    document.cookie = key + '=' + value + '; ' + expires + ';path=/;';
};

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
};

function startAnalytics() {
    //console.log('Start Google Analytics.');
    function __ga(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    }
    __ga(window,document,'script','dataLayer','GTM-M26CTH4');
}

function stopAnalytics() {
    //console.log('Stop Google Analytics.');
    setCookie('_ga', '', 0);
    setCookie('_gat', '', 0);
    setCookie('_gid', '', 0);
}

function startMarketing() {
    //console.log('Start Marketing.');

}

function stopMarketing() {
    //console.log('Stop Marketing.');
    //cnSetCookie('bestedingsdoel', '', 0);
    setCookie('bronId', '', 0);
    setCookie('cnDisabled', '', 0);
}

export {
    setCookie, getCookie, startAnalytics, stopAnalytics, startMarketing, stopMarketing
}