<template>
  <div class="loan-type-table">
    <div class="white-container" v-for="(d, v) in loanInfo[$store.state._loanRequest.loanTypeTable].data" :key="v">
      <div class="row" v-for="(t, i) in loanInfo[$store.state._loanRequest.loanTypeTable].title" :key="i">
        <div class="description" >
          <div class="txt">
            {{ t }}
          </div>
        </div>

        <div class="gap"></div>

        <div class="value">
          <div class="txt">
            {{ getObjectKeyValue(loanInfo[$store.state._loanRequest.loanTypeTable].data[v], i) }}
          </div>
        </div>
      </div>

      <button
        class="btn-select"
        :class="{active: ($store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND && selectedMonths == loanInfo[$store.state._loanRequest.loanTypeTable].data[v].theoretischeLooptijd) || ($store.state._loanRequest.loanTypeTable == $store.state.loanType.PERSOONLIJKE && selectedMonths == loanInfo[$store.state._loanRequest.loanTypeTable].data[v].mogelijkheden)}"
        @click="
          selectMonths($store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND ? loanInfo[$store.state._loanRequest.loanTypeTable].data[v].theoretischeLooptijd : loanInfo[$store.state._loanRequest.loanTypeTable].data[v].mogelijkheden)
          select()"
      >
        {{ ($store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND && selectedMonths == loanInfo[$store.state._loanRequest.loanTypeTable].data[v].theoretischeLooptijd) || ($store.state._loanRequest.loanTypeTable == $store.state.loanType.PERSOONLIJKE && selectedMonths == loanInfo[$store.state._loanRequest.loanTypeTable].data[v].mogelijkheden) ? 'Geselecteerd' : 'Selecteer' }}
      </button>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .white-container {
    margin-bottom: pxToEm(25);
    padding: pxToEm(25) pxToEm(20) 0;
    color: getColor('textColor');
    background-color: #ffffff;

    .row {
      height: pxToEm(70);
      display: grid;
      grid-template-columns: pxToEm(120) 0.35fr 1fr;
      align-items: center;
      border-bottom: 1px solid getColor('grayLtColor');

      .description {
        .txt {
          font-weight: 700;
        }
      }

      .value {
        .txt {
          font-weight: 600;
        }
      }

      &:first-child {
        height: auto;
        display: block;
        text-align: center;

        & .description {
          .txt {
            font-weight: 400;
          }
        }

        & .value {
          margin: pxToEm(10) 0 pxToEm(25);

          .txt {
            font-weight: 700;
            color: getColor('greenDefault');
          }
        }
      }

      &:last-child {
        border: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .btn-select {
        width: 100%;
        justify-content: center;
        align-items: center;
        border: 0;
        border-bottom-style: solid;
        height: pxToEm(65);
        border-bottom-width: pxToEm(65*0.145);
        cursor:pointer;
        flex: 0 1 auto;
        color:white;
        font-size: 18px;
        font-weight: 700;
        border-bottom-color: getColor('primaryDefault');
        background: linear-gradient(getColor('primaryLight'), getColor('primaryLight') 50%, getColor('primaryDefault') 50%, getColor('primaryDefault'));
        background-size: 100% 200%;
        transition: background 0.15s;

        &:hover {
          background-position: 100% 100%;
        }
      }
      .btn-select.active {
        border-bottom-color: #4a983e;
        background: linear-gradient(getColor('greenDefault'), getColor('greenDefault') 50%, #4a983e 50%, #4a983e);
        background-size: 100% 200%;
        transition: background 0.15s;
        &:hover {
          background-position: 100% 100%;
        }
      }
  }
</style>

<script>
  import Api from "@/Api";
  import { DoorlopendType, PersoonlijkeType } from "@/data/loanTypes";

  export default {
    name: "loanTypeTable",
    props: {
      select: null
    },
    data() {
      return {
        loanInfo: [
          {
            title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Totaalprijs"],
            // data: [new PersoonlijkeType(180), new PersoonlijkeType(120), new PersoonlijkeType(96), new PersoonlijkeType(60), new PersoonlijkeType(36)],
            data: [new PersoonlijkeType(36), new PersoonlijkeType(60), new PersoonlijkeType(96), new PersoonlijkeType(120), new PersoonlijkeType(180)],
            months: ["36 maanden", "60 maanden", "96 maanden", "120 maanden", "180 maanden"]
          },
          {
            title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Theoretische looptijd", "Totaalprijs"],
            data: [new DoorlopendType(56), new DoorlopendType(77), new DoorlopendType(126)],
            months: ["56 maanden", "77 maanden", "126 maanden"]
          }
        ],
        selectedMonths: $store.state._loanRequest.loanTypeTable == $store.state.loanType.DOORLOPEND ? "56 maanden" : "36 maanden",
      };
    },
    created() {
      Api.get(`percentage-section`).then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.attributes
        ) {
          this.percentageSection = response.data.data.attributes;

          //FIX FOR NaN VALUE WHEN COMMING FROM CALCULATOR B
          this.calcMaandlast(Number.isNaN($store.state._loanRequest.approvedLoanAmount.valueOf()) ? $store.state._loanRequest.newLoan.total : $store.state._loanRequest.approvedLoanAmount);
          //END FIX
        }
      });

      this.$root.$on('loantype_select_event_mobile', (loanType) => {
        let _selectedMonths = loanType == $store.state.loanType.DOORLOPEND ? "56 maanden" : "36 maanden";
        this.selectMonths(_selectedMonths);
        // $store.state.selectedMonths = _selectedMonths;
        // this.$root.$emit('months_select_event', loanType, _selectedMonths);
      })
    },
    methods: {
      calcMaandlast(val) {
        var result;
        for (var i = 0; i < this.loanInfo[$store.state._loanRequest.loanTypeTable].data.length; i++) {
          this.loanInfo[$store.state._loanRequest.loanTypeTable].data[i].update(this.percentageSection, val);
        }
      },
      getObjectKeyValue(obj, idx) {
        return obj[Object.keys(obj)[idx]];
      },
      selectMonths(monthsStr){
        this.selectedMonths = monthsStr;
        $store.state.selectedMonths = monthsStr;

        $store.state._loanRequest.loanType = $store.state._loanRequest.loanTypeTable;
        $store.state._loanRequest.months = this.loanInfo[$store.state._loanRequest.loanTypeTable].months.indexOf($store.state.selectedMonths);

        this.$root.$emit('months_select_event_mobile', $store.state._loanRequest.loanTypeTable, monthsStr);
      }
    },
    watch: {
      "$store.state._loanRequest.approvedLoanAmount": function(newVal, oldVal) {
        this.calcMaandlast(newVal);
      },
      "$store.state._loanRequest.loanTypeTable": function(newVal, oldVal) {
        this.calcMaandlast(Number.isNaN($store.state._loanRequest.approvedLoanAmount.valueOf()) ? $store.state._loanRequest.newLoan.total : $store.state._loanRequest.approvedLoanAmount);
      }
    },
  };
</script>
