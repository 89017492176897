<template>
<div>
   <finantial-lease-header class="area"></finantial-lease-header>
   <calculator class="area"></calculator>
   <finantial-lease-body class="area"></finantial-lease-body>
   <safe-loan-info class="area"></safe-loan-info>
   <loan-info class="area"></loan-info>
  <desk-contact class="area"></desk-contact>
</div>
</template>


<style lang="less" scoped>
</style>
 
 <script>
import deskContact from "@/components/desktop/desk-contact.vue";
import finantialLeaseHeader from "@/components/desktop/finantial-lease/header.vue";
import calculator from "@/components/desktop/calculators/A/calculator-form";
import finantialLeaseBody from "@/components/desktop/finantial-lease/body.vue";
import safeLoanInfo from "@/components/desktop/safe-loan-info.vue";
import loanInfo from "@/components/desktop/loan-bottom-info.vue";

export default {
  name: "deskFinantialLease",
  components: {
    finantialLeaseHeader,
    deskContact,
    calculator,
    finantialLeaseBody,
    safeLoanInfo,
    loanInfo
  },
  created() {
    $store.commit('resetForms');
    $store.state._loanRequest.loanType = $store.state.loanType.ADVIES;
  }
};
</script>