<template>
  <div
    class="input-date input"
    :class="{
      disabled: ui.disabled == true ? true : false,
      initialized: ui.init == true || hasFocus == true ? true : false,
      invalid: ui.valid != true ? true : false
    }"
    :value="value"
  >
    <input-num
      class="day"
      :disabled="ui.disabled"
      :init="ui.init"
      :valid="ui.valid"
      :minVal="1"
      :maxVal="31"
      :maxLen="2"
      :pad="'0'"
      :format="false"
      :step="1"
      :placeholder="dayPlaceholder"
      v-model="day"
    ></input-num>
    <span class="txt">-</span>
    <input-num
      class="month"
      :disabled="ui.disabled"
      :init="ui.init"
      :valid="ui.valid"
      :minVal="1"
      :maxVal="12"
      :maxLen="2"
      :pad="'0'"
      :format="false"
      :step="1"
      :placeholder="monthPlaceholder"
      v-model="month"
    ></input-num>
    <span class="txt">-</span>
    <input-num
      class="year"
      :disabled="ui.disabled"
      :init="ui.init"
      :valid="ui.valid"
      :minVal="1900"
      :maxLen="4"
      :format="false"
      :step="1"
      :placeholder="yearPlaceholder"
      v-model="year"
    ></input-num>
  </div>
</template>

<style lang="less" scoped>
@border-width: pxToEm(2);

.input-date {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: getColor("grayLtColor");
  -moz-appearance: none;
  -webkit-appearance: none;

  ::placeholder {
    color: getColor("grayLtColor");
    opacity: 1;
  }

  .input-num {
    height: 100%;

    &.day {
      width: 27%;
    }
    &.month {
      width: 27%;
    }
    &.year {
      width: 31.5%;
    }
  }

  &.invalid {
    .day,
    .month,
    .year {
      .formcheck & {
        border: @border-width solid getColor("redColor");
      }
    }
  }

  &.initialized {
    color: getColor("textColor");
  }

  &.disabled {
    color: getColor("disabledText");
    cursor: default;
  }
}
</style>

<script>
import inputNum from "@/components/common/input-num";

export default {
  components: {
    inputNum
  },
  props: {
    disabled: Boolean,
    init: null,
    valid: null,
    placeholder: {
      type: String,
      default: "Dag-Maand-Jaar"
    },
    value: Date
  },
  data: function() {
    return {
      hasFocus: false,
      ui: {
        disabled: null,
        init: null,
        valid: null
      },

      day: null,
      month: null,
      year: null,
      dayPlaceholder: "Day",
      monthPlaceholder: "Month",
      yearPlaceholder: "Year"
    };
  },
  created: function() {
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = true;
    }

    Object.assignSet(this.ui, this.value != null ? this.value.ui : null, this._props);
    
    if (this.placeholder != null && this.placeholder != "") {
      var phs = this.placeholder.split("-");
      if (phs.length == 3) {
        this.dayPlaceholder = phs[0];
        this.monthPlaceholder = phs[1];
        this.yearPlaceholder = phs[2];
      }
    }

    if(this.ui.init && this.value instanceof Date) {
      this.getDate();
    }
  },
  beforeDestroy(){
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = false;
    }
  },
  watch: {
    day: function(newVal, oldVal) {
      this.update();
    },
    month: function(newVal, oldVal) {
      this.update();
    },
    year: function(newVal, oldVal) {
      this.update();
    },
    value:function(newVal, oldVal) {      
      if (newVal != null && newVal)
        Object.assignSet(this.ui, this.value.ui, this._props);  
    },
  },
  methods: {
    pxToEm: function(val) {
      return val / $store.state.defaults.defaultFontSize + "em";
    },
    getDate:function(){
      this.day = this.value.getDate();
      this.month = this.value.getMonth()+1;
      this.year = this.value.getFullYear();
    },
    update: function() {
      var vm = this;
      var emitValue = NaN;
      if (vm.day == null || isNaN(vm.day)) vm.ui.valid = false;
      else if (vm.month == null || isNaN(vm.month)) vm.ui.valid = false;
      else if (vm.year == null || isNaN(vm.year)) vm.ui.valid = false;
      else {        
        var date = new Date(vm.year, vm.month-1, vm.day);
        if(date.getFullYear() == vm.year && (date.getMonth()+1)==vm.month && date.getDate()==vm.day){
          emitValue = date;
          vm.ui.valid = true;
        }
        else
          vm.ui.valid = false        
      }
      this.$emit("input", emitValue);
    }
  }
};
</script>
