<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="top">
          <div class="texts">
            <div class="txt">
              <div class="table-wrapper">
                <p class="title" v-if="block.Title">{{ block.Title }}</p>
                <p class="font-weight-bold" v-if="block.Subtitle">{{ block.Subtitle }}</p>

                <table class="table">
                  <tbody>
                  <tr>
                    <td v-for="headItem in block.table.data.attributes.data.head">{{ headItem }}</td>
                  </tr>
                  <tr v-for="bodyRow in block.table.data.attributes.data.body">
                    <td v-for="cell in bodyRow">{{ cell }}</td>
                  </tr>
                  </tbody>
                </table>

                <p>{{ block.ClosingSentence }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  padding: 0 15px;
  box-sizing: border-box;

  &.white {
    background-color: #fff;
  }

  .layout {
    align-items: center;
    max-width: pxToEm(965);

    .section {
      margin: pxToEm(50);
      width: pxToEm(1160);
      max-width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      align-items: center;

      .top {
        margin: pxToEm(70);
        margin-top: pxToEm(10);
        text-align: left;
        max-width: 100%;

        .texts {
          display: flex;
          flex-direction: column;
          max-width: pxToEm(866);

          .txt {
            flex: 1 1 auto;
            max-width: 768px;

            .title {
              text-decoration: underline;
              font-weight: 700;
              font-size: pxToEm(60);
              line-height: 1em;
              margin: pxToEm(10) 0;
            }

            table {
              border-collapse: collapse;

              @media (max-width: 620px){
                display: block;
                overflow-y: scroll;
              }

              td {
                padding: 5px;
                border: 1px solid gray;
              }
            }

            .font-weight-bold {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
</style>

<script>

export default {
  name: 'TableBlock',
  props: {
    block: Object
  }
}
</script>
