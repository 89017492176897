<template>
  <div>
    <informatie-complete></informatie-complete>
  </div>
</template>


<style lang="less" scoped>
</style>

<script>
import informatieComplete from "@/components/desktop/informatie/complete.vue";

export default {
  name: "deskInformatie",

  components: {
    informatieComplete,
  }
};
</script>
