<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="title">{{block.Title}}</div>

        <div class="subtitle" v-html="markdownToHTML(block.Subtitle)"></div>

        <div class="partners">
          <img :src="strapiAssetUrl(partner.Image.data.attributes.url)" v-for="partner in block.Partners">
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    .layout {
      width: 100%;

      .section {
        margin: pxToEm(40) pxToEm(38);

        .title {
          font-size: pxToPercent(40);
          font-weight: 300;
        }

        .subtitle {
          font-size: pxToPercent(16);
          margin: pxToEm(25) 0;
          line-height: 2.5em;
        }

        .partners {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 2em;
          align-items: center;

          img {
            width: pxToEm(90);
            margin: pxToEm(20) auto;

            &:nth-last-child(2) {
              width: pxToEm(50);
            }
          }
        }
      }
    }
  }
</style>

<script>
  import {marked} from "marked";
  import {strapiAssetUrl} from "@/JS/helpers";

  export default {
    name: "MobilePartnersSectionBlock",
    props: {
      block: Object
    },
    methods: {
      markdownToHTML: function(markdown) {
        return marked(markdown);
      },
      strapiAssetUrl: function (path) {
        return strapiAssetUrl(path)
      }
    }
  };
</script>
