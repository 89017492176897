<template>
  <div>
    <mob-logo class="area"></mob-logo>
    <bkr-header class="area"></bkr-header>
    <calculator class="area"></calculator>
    <bkr-body class="area"></bkr-body>
    <mob-contact class="area mob-contact"></mob-contact>
  </div>
</template>

<style lang="less" scoped>
  .mob-contact {
    box-shadow: 0 pxToEm(-10) pxToEm(64) #e7e7e7;
  }
</style>

 <script>
  import mobLogo from "@/components/mobile/mob-header-logo.vue";
  import bkrHeader from "@/components/mobile/bkr-registratie/header.vue";
  import calculator from "@/components/mobile/calculators/A/calculator-form.vue";
  import bkrBody from "@/components/mobile/bkr-registratie/body.vue";
  import mobContact from "@/components/mobile/mob-contact.vue";

  export default {
    name: "mobBKR",
    components: {
      mobLogo,
      bkrHeader,
      calculator,
      bkrBody,
      mobContact
    }
  };
</script>
