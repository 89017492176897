<template>
<div>
   <bkr-header class="area"></bkr-header>
   <calculator class="area"></calculator>
   <bkr-body class="area"></bkr-body>
  <desk-contact class="area"></desk-contact>
</div>
</template>


<style lang="less" scoped>
</style>
 
 <script>
import deskContact from "@/components/desktop/desk-contact.vue";
import bkrHeader from "@/components/desktop/bkr-registratie/header.vue";
import calculator from "@/components/desktop/calculators/A/calculator-form";
import bkrBody from "@/components/desktop/bkr-registratie/body.vue";
export default {
  name: "deskBKR",
  components: {
    bkrHeader,
    calculator,
    deskContact,
    bkrBody
  }
};
</script>