<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="texts">
            <div class="txt">
              <div class="title">Doorlopend krediet</div>

              <div class="body">
                Het doorlopend krediet is uitstekende manier om tijdelijk wat extra geld achter de hand te hebben? Het doorlopend krediet is een flexibele lening, waarbij u slechts rente betaalt over het bedrag dat u opneemt om uw rekeningen te kunnen voldoen. U kunt bovendien een bestaand doorlopend krediet oversluiten, of een nieuw krediet afsluiten voor kosten die eraan zitten te komen. Uiteraard tegen een rente die behoort tot de allerlaagste van Nederland. Onze adviseurs laten u graag zien wat een doorlopend krediet betekent. Daarbij kunt u de kredieten vergelijken met de andere aanbiedingen die beschikbaar zijn.
              </div>
            </div>

            <div class="txt">
              <div class="subtitle">Goedkoop doorlopend krediet</div>

              <div class="body">
                Bij Leenattent zorgen we voor een lening die behoort tot de allerlaagste van Nederland. Dit betekent dat u altijd een minimaal bedrag aan kosten betaalt voor het geleende bedrag. Binnen het doorlopend krediet betaalt u slechts voor het geld dat u daadwerkelijk gebruikt. Over de rest van het kredietbedrag dat u eventueel op kunt nemen hoeft u nog geen rente te betalen. Dit helpt u de kosten goed in de hand te houden.
              </div>
            </div>

            <div class="txt">
              <div class="subtitle">Kenmerken en voordelen</div>

              <div class="body">
                Het doorlopend krediet is een flexibele kredietvorm. U kunt geld opnemen tot een afgesproken
                maximum, bijvoorbeeld €10.000. Op momenten dat het u het beste uitkomt kunt u tussentijds het
                geleende bedrag aflossen. Gedurende de looptijd heeft u de bij Leenattent mogelijkheid om
                maximaal dat bedrag op te nemen en weer te lenen.
                <br>De flexibiliteit van een doorlopend krediet is één van de grote voordelen. U kunt geld opnemen
                wanneer u wenst. Bovendien aflossen wanneer het u uitkomt en vervolgens gewoon weer opnieuw
                opnemen. Weet u nog niet goed hoeveel extra geld u de komende tijd nodig heeft? Een doorlopend
                krediet vormt dan in veel gevallen een goede keuze. Het is uiteraard iets waar we u graag persoonlijk
                over kunnen adviseren.
              </div>
            </div>

            <div class="txt">
              <div class="subtitle">Kosteloos advies voor een doorlopend krediet</div>

              <div class="body">
                Wenst u een kosteloos advies voor een doorlopend krediet, zodat u eenvoudig kunt vergelijken?  We helpen u er graag mee op weg, zodat u kunt nagaan of u een goede keuze maakt. We adviseren u vrijblijvend
                over het doorlopend krediet, ook als u daarna geen lening afsluit bij Leenattent. Desgewenst
                vergelijken we het doorlopend krediet voor u met de persoonlijke leningen die er beschikbaar zijn. U
                krijgt op die manier een goed voorbeeld van het doorlopend krediet en of dat in uw geval een goede
                keuze vormt.
                <br>Heeft u vragen over het doorlopend krediet? Bel ons gerust of chat met een adviseur. Of vraag direct
                een offerte aan!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor("greyLight");

    .layout {
      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: pxToEm(58) pxToEm(25) 0;

          .texts {
            .txt {
              margin-bottom: pxToEm(40);

              .title {
                margin-bottom: pxToEm(10);
                font-size: pxToPercent(24);
                line-height: 1em;
                font-weight: 700;
                text-decoration: underline;
              }

              .subtitle {
                margin-bottom: pxToEm(10);
                font-size: pxToPercent(20);
                font-weight: 700;
              }

              .body {
                font-size: pxToPercent(16);
                line-height: 2.1em;
              }
            }

            & :last-child {
              margin-bottom: 0;
            }
          }

          .bottom-title {
            margin: pxToEm(15) 0;
            font-size: pxToPercent(36);
            font-weight: 300;
          }
        }
      }
    }
  }
</style>

<script>
  import incomeSource from "@/components/common/income-source";

  export default {
    name: "doorlopendBody"
  };
</script>