<template>
  <div id="app">
    <mob-base v-if="$store.state.deviceState == $store.state.Device.MOBILE"></mob-base>
    <desk-base v-else></desk-base>
  </div>
</template>

<style lang="less">
  #app {
    font-family: "Open Sans", sans-serif;
    font-size: getDefaultFontSize();
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: getColor('textColor');
    overflow-x: hidden;
  }

  .scroll-container {
    width: 100%;
    position: absolute;
    top: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    //touch-action:none;
  }

  input[type="date"] {
    font-family: "Open Sans", sans-serif;
    &::placeholder {
      color: #c0c0c0;
    }
  }

  .color-primary-default {
    color: getColor("primaryDefault");
  }

  .color-green-default {
    color: getColor("greenDefault");
  }

  .bolder {
    font-weight: 700;
  }

  .exp-item {
    &:not(.expanded) {
      .exp-item-text {
        display: none !important;
      }
    }
  }
</style>

<script>
export default {
  components: {
    deskBase: () => import(/* webpackChunkName: "deskBase" */ "./views/desk-base.vue"),
    mobBase: () => import(/* webpackChunkName: "mobBase" */ "./views/mob-base.vue")
  }
};
</script>