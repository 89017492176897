<template>
  <div>
    <MobileWorkWithUsSectionBlock :block="block" v-if="isMobile"></MobileWorkWithUsSectionBlock>
    <DesktopWorkWithUsSectionBlock :block="block" v-else></DesktopWorkWithUsSectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>

import MobileWorkWithUsSectionBlock from "@/components/strapi/WorkWithUsSection/MobileWorkWithUsSectionBlock";
import DesktopWorkWithUsSectionBlock from "@/components/strapi/WorkWithUsSection/DesktopWorkWithUsSectionBlock";

export default {
  name: 'WorkWithUsSectionBlock',
  components: {
    DesktopWorkWithUsSectionBlock,
    MobileWorkWithUsSectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
