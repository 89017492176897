<template>
  <div class="black-area">
    <div class="black-section">
      <img class="arrow-svg" src="@/assets/arrow.svg" alt />
      <div class="b-title">
        <div class="txt" v-if="$store.state._loanRequest.calcResult == 0">
          Ja, u kunt
          <span class="green">{{ $store.state._loanRequest.requestedLoanAmount | currency('€', 0) }}</span> verantwoord lenen via Leenattent.
        </div>
        <div class="txt" v-else-if="$store.state._loanRequest.calcResult == 1">
          Helaas, u kunt geen
          <span
            class="color-primary-default"
          >{{ $store.state._loanRequest.requestedLoanAmount | currency('€', 0) }}</span> verantwoord lenen via Leenattent. U kunt maximaal
          <span
            class="green"
          >{{ $store.state._loanRequest.maxLoanAmount | currency('€', 0) }}</span> lenen.
        </div>
        <div class="txt" v-else>
          Helaas, u kunt geen
          <span
            class="color-primary-default"
          >{{ $store.state._loanRequest.requestedLoanAmount | currency('€', 0) }}</span> verantwoord lenen via Leenattent.
        </div>
      </div>

      <div class="container">
        <div class="white-square">
          <div class="green-label">
            <span>Advies van Leenattent</span>
          </div>

          <div class="container">
              <div class="title">
                <div class="txt">Persoonlijke lening</div>
              </div>

              <div class="bullets">
                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Eenmalig geld opnemen</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vaste rente</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Boetevrij extra aflossen</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vast maandtermijn</span>
                </div>

                <div class="bullet">
                  <div class="arrow"></div>
                  <span>Vaste looptijd</span>
                </div>

              </div>
          </div>

          <btn-big
            class="allGreen"
            :height="106"
            :click="
                () => {
                  selectLoanType($store.state.loanType.PERSOONLIJKE);
                }
              "
              v-anchor="'#anchor-grey-container'"
          >
            <div class="content">
              <div class="txt">Bekijk maandlasten</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </div>

        <div class="white-square">
          <div class="container">
            <div class="title">
              <div class="txt">Doorlopend krediet</div>
            </div>

            <div class="bullets">
              <div class="bullet">
                <div class="arrow"></div>
                <span>Geld opnemen tot een afgesproken limiet</span>
              </div>

              <div class="bullet">
                <div class="arrow"></div>
                <span>Variabele rente</span>
              </div>

              <div class="bullet">
                <div class="arrow"></div>
                <span>Boetevrij extra aflossen</span>
              </div>

              <div class="bullet">
                <div class="arrow"></div>
                <span>Vast maandtermijn</span>
              </div>

            </div>
          </div>

          <btn-big
            class="allGreen"
            :height="106"
            :click="
                () => {
                  selectLoanType($store.state.loanType.DOORLOPEND);
                }
              "
            v-anchor="'#anchor-grey-container'"
          >
            <div class="content">
              <div class="txt">Bekijk maandlasten</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </div>
      </div>

      <btn-big
        id="submit-btn"
        class="primary"
        :width="450"
        :height="102"
        :click="
          () =>{
            navTo('offerte');
          }
        "
      >
        <div class="content">
          <img src="@/assets/money-w.svg" alt />
          <div class="txt">Offerte aanvragen</div>
          <img src="@/assets/arrow.svg" alt />
        </div>
      </btn-big>

      <div class="desktop">
        <div id="anchor-grey-container" class="grey-container" v-if="loanTypeModal == true">
          <img class="close" @click="loanTypeModal = false" src="@/assets/close.png" />

          <div class="small-txt">{{ getLoanTypeName }}</div>

          <div class="big-txt">Wat wordt mijn maandlast?</div>

          <loan-type-table 
            :select="selectAndSubmit"
          ></loan-type-table>
        </div>
      </div>
      <div class="mobile">
        <div id="anchor-grey-container" class="grey-container" v-if="loanTypeModal == true">
          <img class="close" @click="loanTypeModal = false" src="@/assets/close-white.png" />

          <div class="small-txt">{{ getLoanTypeName }}</div>

          <div class="big-txt">Wat wordt mijn maandlast?</div>

          <div class="mobile-table-content">
            <loan-type-table-mobile></loan-type-table-mobile>
          </div>
        </div>
      </div>
      <div class="contact-text">Meer informatie of advies?</div>

      <div class="icons">
        <div class="icon">
          <img src="@/assets/white-phone.svg" />
          <div class="txt">023 539 5926</div>
        </div>

        <div class="icon">
          <img src="@/assets/white-email.svg" />
          <div class="txt">
            <a href="mailto:info@leenattent.nl">info@leenattent.nl</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.input {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  input[type=""] {
    height: pxToEm(62);
    background-color: #fff;
    border: 1px solid #c0c0c0;
    text-align: left;
    color: #3a3a3a;
    -webkit-appearance: none;
    padding-left: 20px;
  }
}

.select {
  margin: pxToEm(25) 0;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  align-self: center;

  .dropdown {
    height: pxToEm(62);
    width: pxToEm(277);
    background-color: #fff;
    border: 1px solid #c0c0c0;
    text-align: left;
    color: #3a3a3a;
    -webkit-appearance: none;
    background: no-repeat right #fff;
    background-position: 95%;
    padding-left: pxToEm(20);
  }
}

.black-area {
  background-color: #3a3a3a;
  min-height: 700px;
  align-items: center;

  @media (max-width: 768px) {
    margin: 0 -15px;
  }

  .black-section {
    margin: 0 50px;
    margin-top: 0;
    color: #fff;
    text-align: center;

    .arrow-svg {
      height: pxToEm(78);
      width: pxToEm(63);
      align-self: center;
      margin-top: pxToEm(50);
      transform: rotate(90deg);
    }

    .b-title {
      margin: pxToEm(40) auto;

      .txt {
        font-weight: 800;
        font-size: pxToPercent(36);

        .green {
          color: getColor("greenDefault");
        }

        .color-primary-default {
          color: getColor("primaryDefault");
        }
      }
    }

    > .container {
      margin: 50px auto 0;
      justify-content: space-between;
      max-width: 894px;
      width: 100%;
      color: getColor("textColor");
      align-self: center;

      .white-square {
        width: 417px;
        max-width: 100%;
        height: 443px;
        background-color: #fff;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
        position: relative;
        display: inline-block;

        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }

        .green-label {
          width: pxToEm(210);
          height: pxToEm(43);
          background-color: getColor("grayMidColor");
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: pxToEm(-20);
          left: 50%;
          transform: translateX(-50%);

          span {
            font-size: pxToPercent(14);
          }
        }

        > .container {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          text-align: left;

          > .title {
            flex: 1 1 auto;
            margin-top: pxToEm(60);
            .txt {
              font-size: pxToPercent(24);
            }
          }

          .bullets {
            flex: 1 1 auto;
            line-height: 2.7em;
            margin-top: pxToEm(30);

            .bullet {
              .arrow {
                border: solid #000;
                border-width: 0 1px 1px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(-45deg);
              }

              span {
                margin-left: pxToEm(20);
                font-size: pxToPercent(16);
              }
            }
          }
        }
        .btn-big {
          width: 100%;
          height: pxToEm(104);
          flex: 0 1 auto;
          position: absolute;
          bottom: 0;

          .content {
            margin-left: pxToEm(40);
            margin-right: pxToEm(40);

            .txt {
              font-size: pxToPercent(20);
              font-weight: 700;
            }

            img {
              width: pxToEm(48);
              height: pxToEm(38);
            }
          }
        }
      }
    }

    .btn-big {
      border-bottom-width: pxToEm(18);
      margin: pxtoEm(50) auto;
      margin-bottom: 0;

      .content {
        margin-left: pxToEm(40);
        margin-right: pxToEm(40);

        .txt {
          font-size: pxToPercent(20);
        }

        img {
          width: pxToEm(48);
          height: pxToEm(38);
        }
      }
    }

    .grey-container {
      flex-direction: column;
      min-height: 632px;
      box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);
      margin-top: pxToEm(-45);
      margin-bottom: pxToEm(50);
      display: flex;
      background-color: getColor("greyLight");
      width: 100%;
      align-self: center;

      @media (max-width: 768px) {
        margin-left: pxToEm(-15);
        margin-right: pxToEm(-15);
        width: calc(100% + 30px);
        padding-top: pxToEm(45);
        padding-bottom: pxToEm(40);
        color: getColor('whiteColor');
        background-color: getColor('grayMidColor');
      }

      .mobile-table-content {
        padding: 0 pxToEm(15);
      }

      .close {
        align-self: flex-end;
        margin: pxToEm(30) pxToEm(40);
        cursor: pointer;
        width: pxToEm(24);
        height: pxToEm(14);

        @media (max-width: 768px) {
          margin: pxToEm(20) pxToEm(20);
          width: pxToEm(16);
          height: pxToEm(16);
          padding: pxToEm(20) pxToEm(25);
          align-self: flex-end;
          cursor: pointer;
        }
      }

      .small-txt {
        font-size: pxToPercent(18);
        font-weight: 700;
        color: getColor("textColor");

        @media (max-width: 768px) {
          color: getColor('whiteColor');
        }
      }

      .big-txt {
        margin-top: pxToEm(10);
        color: getColor("textColor");
        font-size: pxToPercent(36);
        font-weight: 300;

        @media (max-width: 768px) {
          margin-bottom: 20px;
          color: getColor('whiteColor');
        }
      }

      .input-dropdown {
        align-self: center;
        width: pxToEm(235);
      }

      .table {
        margin: pxToEm(35) pxToEm(95);
      }
    }

    .contact-text {
      font-size: pxToPercent(22);
      margin: 40px 0;
    }

    .icons {
      margin: pxToEm(50) auto 0;
      padding-bottom: pxToEm(150);
      max-width: 680px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-self: center;

      .icon {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        flex: 1 1 100%;

        img {
              max-width: 100%;
              width: 120px;
              margin: 0 auto 2.375em;
        }

        .txt {
          font-size: pxToEm(22);
          color: getColor("greenDefault");
          font-weight: 600;

          a {
            color: getColor("greenDefault");
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .white-square {
    margin: 0 0 20px !important;
  }
  .black-section {
    margin: 0 15px !important;
  }
  .icons {
    display: block !important;
    padding-bottom: 20px;
    margin-bottom: 0 !important;
    .icon {
      margin-bottom: 20px;
      img {
        width: 68px !important;
      }
    }
  }
}

@media (max-width: 768px){
  .desktop {
    display: none;
  }
}

@media (min-width: 769px){
  .mobile {
    display: none;
  }
}

</style>

<script>
import btnBig from "@/components/common/btn-big";
import loanTypeTable from "@/components/common/loan-type-table.vue";
import loanTypeTableMobile from "@/components/mobile/loan-type-table/loan-type-table.vue";
import inputDropdown from "@/components/common/input-dropdown";

export default {
  name: "blackArea",
  components: {
    btnBig,
    loanTypeTable,
    loanTypeTableMobile,
    inputDropdown
  },
  data() {
    return {
      loanTypeModal: false
    };
  },
  methods: {
    navTo: function(url) {
      $store.state.storedData = true;
      this.$router.push(url);
    },
    selectLoanType(loanType) {
      this.loanTypeModal = true;
      $store.state._loanRequest.loanTypeTable = loanType;
    },
    selectAndSubmit() {
      this.$el.querySelector('#submit-btn').click();
    }
  },
  computed: {
    getLoanTypeName() {
      return $store.state._loanRequest.loanTypeTable == $store.state.loanType.PERSOONLIJKE ? "Persoonlijke lening" : "Doorlopend krediet";
    }
  }
};
</script>
