<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <DesktopOfferSectionHead :class="'area ' + (block.Background === 'light' ? 'white' : '')" @selected="selectFunnel"></DesktopOfferSectionHead>
        <offerte-body :class="'area ' + (block.Background === 'light' ? 'white' : '')" v-if="$store.state.storedData && $store.state._loanRequest.selectedFunnel == $store.state.funnel.A && $store.state._loanRequest.approvedLoanAmount > 0"></offerte-body>
        <offerte-exp-items :class="'area ' + (block.Background === 'light' ? 'white' : '')" :funnel="funnel"></offerte-exp-items>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor("greyLight");

    &.white {
      background-color: #fff;
    }
  }
</style>

<script>
  import deskContact from "@/components/desktop/desk-contact";
  import offerteExpItems from "@/components/desktop/offerte/expitems";
  import offerteBody from "@/components/desktop/offerte/body";
  import DesktopOfferSectionHead from "@/components/strapi/OfferSection/DesktopOfferSectionHead";

  export default {
    name: "DesktopOfferSection",
    props: {
      block: Object
    },
    components: {
      DesktopOfferSectionHead,
      deskContact,
      offerteExpItems,
      offerteBody
    },
    data() {
      return {
        funnel: null
      };
    },
    methods: {
      selectFunnel(o) {
        this.funnel = o;
      }
    },
    created() {
      if ($store.state.storedData == false || $store.state._loanRequest.approvedLoanAmount == NaN) {
        $store.commit("resetForms");
      }
    }
  };
</script>
