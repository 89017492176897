<template>
    <div class="icons">
        <div class="icon">
            <img src="@/assets/phone.svg">
            <div class="txt"><a href="tel:023 539 5926">023 539 5926</a></div>
        </div>

        <div class="icon">
            <img src="@/assets/email.svg">
            <div class="txt"><a href="mailto:info@leenattent.nl">info@leenattent.nl</a></div>
        </div>
    </div>
</template>

<style lang="less" scoped>


.icons {
    width: 430px;
    max-width: 100%;
    margin: pxToEm(40) pxtoEm(80);
    display: flex;
    justify-content: space-between;
    align-self: center;

    .icon {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;

        img {
            max-width: 100%;
            width: 120px;
            margin: 0 auto pxToEm(38);
        }

        .txt {
            font-size: pxToEm(22);
            color: getColor("greenDefault");
            font-weight: 600;

            a {
            color: getColor("greenDefault");
            cursor: pointer;
            }
        }
    }
}
</style>

<script>
  export default {
    name: "contactIcons"
  };
</script>
