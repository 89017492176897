<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="dynamic-section">
          <div id="anchor-berekenzelf-top" style="position:absolute;top:-300px;"></div>

          <div class="section1" v-if="formCursor == null">
            <div class="content">
              <div class="title">Weten wat u maximaal kan lenen?</div>

              <div class="sep-40"></div>

              <input-dropdown
                :options="$store.state.funnelOptions"
                placeholder="Maak uw keuze"
                v-model="Funnel"
              ></input-dropdown>

              <div class="sep-80" v-if="$store.state._loanRequest.loanOption.ui.init == false"></div>
            </div>
          </div>

          <div class="section2" v-show="formCursor != null">
            <div class="numbers">
              <div
                class="number"
                v-for="(form, index) in getTabs()"
                :key="index"
                :class="{ active: index == formCursor ? true : false, inactive: index < formCursor ? true : false }"
              >
                <span>{{ index + 1 }}</span>
              </div>
            </div>

            <div class="container">
              <!--TAB 1 ////////////////////////////////////////////////-->
              <div
                class="tab tab1"
                v-if="getTabs()[formCursor] == 1"
                :class="{formcheck:tabsState.tab1.state == false}"
              >
                <div class="sep-60"></div>

                <div
                  class="error"
                  v-if="tabsState.tab1.state == false"
                >Er zijn enkele velden niet correct ingevuld.</div>

                <div class="sep-50" v-if="tabsState.tab1.state == false"></div>

                <div class="tab-body">
                  <div class="col-111 text-input">
                    <div class="txt-label">
                      <span>Hoeveel geld wilt u lenen?</span>
                    </div>
                    <input-num v-model="$store.state._loanRequest.loanAmount"></input-num>
                  </div>
                </div>

                <div class="sep-50"></div>
              </div>

              <!--TAB 2 ////////////////////////////////////////////////-->
              <div
                class="tab tab2"
                v-if="getTabs()[formCursor] == 2"
                :class="{formcheck:tabsState.tab2.state == false}"
              >
                <div class="sep-60"></div>

                <div class="tab-body">
                  <div class="col-11 select-input">
                    <div class="txt-label">
                      <span>Hoeveel leningen heeft u lopen?</span>
                    </div>
                    <input-dropdown
                      :init="$store.state._loanRequest.prevLoans.ui.init"
                      :options="$store.state.loansDropdown"
                      v-model="Loans"
                    ></input-dropdown>
                  </div>

                  <div
                    class="error"
                    v-if="tabsState.tab2.state == false"
                  >Er zijn enkele velden niet correct ingevuld.</div>
                </div>

                <div class="loans" v-if="Loans > 0">
                  <div
                    class="loan"
                    v-for="(loan, index) in $store.state._loanRequest.prevLoans"
                    :key="index"
                  >
                    <div class="sub-title">Lening {{ index + 1 }}</div>

                    <div class="tab-body">
                      <div class="col-11 text-input">
                        <div class="txt-label">
                          <span>Maatschappij</span>
                        </div>
                        <input-text v-model="loan.name"></input-text>
                      </div>

                      <div class="col-11 num-input">
                        <div class="txt-label">
                          <span>Openstaand bedrag</span>
                        </div>
                        <input-num v-model="loan.amount"></input-num>
                      </div>

                      <div class="col-11 num-input">
                        <div class="txt-label">
                          <span>Effectieve jaarrente</span>
                        </div>
                        <input-num v-model="loan.rate"></input-num>
                      </div>

                      <div class="col-11 toggle-input">
                        <div class="txt-label">
                          <span>Inlossen</span>
                        </div>
                        <input-boolean :init="loan.ransom.ui.init" v-model="loan.ransom"></input-boolean>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sep-50"></div>
              </div>

              <!--TAB 3 ////////////////////////////////////////////////-->
              <div
                class="tab tab3"
                v-if="getTabs()[formCursor] == 3"
                :class="{formcheck:tabsState.tab3.state == false}"
              >
                <div class="sep-60"></div>

                <div
                  class="error"
                  v-if="tabsState.tab3.state == false"
                >Er zijn enkele velden niet correct ingevuld.</div>

                <div class="sep-50" v-if="tabsState.tab3.state == false"></div>

                <div class="tab-body">
                  <div class="col-11 toggle-input">
                    <div class="txt-label">
                      <span>Bent u getrouwd of samenwonend?</span>
                    </div>
                    <input-boolean v-model="$store.state._person.married"></input-boolean>
                  </div>

                  <div class="col-11 select-input">
                    <div class="txt-label">Wat is uw woonsituatie?</div>
                    <input-dropdown
                      :options="$store.state.livingStateDropdown"
                      v-model="$store.state._person.livingState"
                    ></input-dropdown>
                  </div>

                  <div class="col-11 toggle-input">
                    <div class="txt-label">Heeft u inwonende kinderen?</div>
                    <input-boolean v-model="$store.state._person.children"></input-boolean>
                  </div>
                </div>

                <div class="sep-50"></div>
              </div>

              <!--TAB 4 ////////////////////////////////////////////////-->
              <div
                class="tab tab4"
                v-show="getTabs()[formCursor] == 4"
                :class="{formcheck:tabsState.tab4.state == false}"
              >
                <div class="sep-60"></div>

                <div
                  class="error"
                  v-if="tabsState.tab4.state == false"
                >Er zijn enkele velden niet correct ingevuld.</div>

                <div class="sep-50" v-if="tabsState.tab4.state == false"></div>

                <div class="tab-body">
                  <div class="col-111 text-input">
                    <div class="txt-label">
                      <span>Wat zijn uw woonlasten?</span>
                    </div>
                    <input-num v-model="$store.state._person.monthlyExpenses"></input-num>
                  </div>

                  <div class="col-111 text-input">
                    <div class="txt-label">
                      <span>Wat is uw netto inkomen per maand?</span>
                    </div>
                    <input-num v-model="$store.state._person.income"></input-num>
                  </div>

                  <div class="col-11 select-input">
                    <div class="txt-label">
                      <span>Wat is uw inkomstenbron?</span>
                    </div>
                    <div class="info-row" style="width: 100%">
                      <input-dropdown
                        :options="$store.state.incomeSourceDropdown"
                        v-model="$store.state._person.incomeSource"
                      ></input-dropdown>

                      <div class="info-container">
                        <div class="info-icon" @click="toggleModal">
                          <div class="bg">
                            <img src="@/assets/info.png">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-11 date-input">
                    <div class="txt-label">
                      <span>Datum in dienst?</span>
                    </div>
                    <input-date v-model="$store.state._person.dateService"></input-date>
                  </div>

                  <div class="col-111 text-input" v-if="$store.state._person.married == true">
                    <div class="txt-label">
                      <span>Wat is het netto inkomen per maand van uw partner?</span>
                    </div>
                    <input-num v-model="$store.state._person.partnerIncome"></input-num>
                  </div>
                </div>

                <div class="sep-50"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-section" v-if="$store.state._loanRequest.loanOption.ui.init">
          <!-- Inactive button -->
          <btn-big
            class="prev secondary-pink"
            style="left: 0;"
            v-if="formCursor != null"
            :width="140"
            :height="64"
            :click="() => nav(-1)"
            v-anchor="'#anchor-berekenzelf-top'"
          >
            <div class="content">
              <img style="transform: rotate(180deg);" src="@/assets/arrow.svg">
              <div class="txt">Vorige</div>
            </div>
          </btn-big>

          <!-- Active button -->
          <btn-big
            class="next secondary-pink"
            style="right: 0;"
            :width="140"
            :height="64"
            :click="() => nav(1)"
            v-anchor="'#anchor-berekenzelf-top'"
          >
            <div class="content">
              <div class="txt">Volgende</div>
              <img src="@/assets/arrow.svg">
            </div>
          </btn-big>
        </div>
      </div>

      <div class="info-anchor">
        <div class="modal-container" v-show="$store.state.modal">
          <modal></modal>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@gridRowGap: 2.5em;

.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    .section {
      margin: 0 pxToEm(10);

      .dynamic-section {
        position: relative;

        .section1 {
          margin: 0 pxToEm(25);
          display: flex;
          flex-direction: column;

          .content {
            margin: 0 auto;

            .title {
              margin-top: pxToEm(15);
              font-size: pxToPercent(28);
              font-weight: 300;
            }

            .input-dropdown {
              height: pxToEm(60);
            }
          }

        }

        .section2 {
          position: relative;
          margin-top: pxToEm(50);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: getColor("whiteColor");
          border: 1px solid getColor("grayLtColor");

          .numbers {
            width: 70%;
            height: pxToEm(40);
            position: absolute;
            top: pxToEm(-20);
            display: flex;
            border-radius: 3px;
            border: 1px solid getColor("grayDkColor");

            .number {
              flex: 1 1 auto;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: getColor("whiteColor");

              &.active {
                color: getColor("whiteColor");
                background-color: getColor("blackColor");
              }

              &.inactive {
                background-color: getColor("greyLight");
              }

              &:not(:last-child) {
                border-right: 1px solid getColor("grayDkColor");
              }
            }
          }

          .container {
            width: 100%;
            display: flex;
            justify-content: center;
            text-align: left;

            .txt-label {
              margin-bottom: pxToEm(10);
              font-size: pxToPercent(16);
              font-weight: 300;
            }

            .tab {
              width: 100%;
              min-width: pxToEm(200);
              max-width: pxToEm(300);
              margin: 0 pxToEm(25);

              .error {
                font-style: italic;
                color: getColor("redColor");
              }

              &.tab4 {
                .info-container {
                  .info-icon {
                    .bg {
                      width: pxToEm(40);
                      height: pxToEm(40);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      background-color: getColor("grayLtColor");
                      border-radius: 50%;
                      cursor: pointer;
                    }

                    &:hover {
                      .bg {
                        background-color: getColor("primaryLight");
                      }
                    }
                  }
                }
              }

              .tab-body {
                display: grid;
                grid-row-gap: @gridRowGap;

                .col-11 {
                  display: grid;
                  grid-template-rows: 1fr pxToEm(52);

                  .info-row {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .input-dropdown {
                      width: 100%;
                      margin-right: pxToEm(20);
                    }
                  }
                }

                .col-111 {
                  display: grid;
                  grid-template-rows: 1fr pxToEm(68) pxToEm(52);
                }
              }

              .loans {
                .sub-title {
                  margin-top: pxToEm(40);
                  margin-bottom: pxToEm(20);
                  font-size: pxToPercent(24);
                }
              }
            }
          }
        }
      }

      .btn-section {
        height: pxToEm(64);
        position: relative;
        margin: pxToEm(50) 0;

        .btn-big {
          position: absolute;
          border-bottom-width: pxToEm(7);

          .content {
            margin-left: pxToEm(15);
            margin-right: pxToEm(15);

            .txt {
              font-size: pxToPercent(14);
              font-weight: 700;
              text-align: left;
            }

            img {
              width: pxToEm(32);
              height: pxToEm(28);
            }
          }
        }
      }
    }

    .info-anchor {
      position: relative;

      .modal-container {
        width: 100%;
        position: absolute;
        top: pxToEm(-315);
        z-index: 800;
      }
    }
  }
}
</style>

<script>
import {checkTab} from "@/JS/helpers";
import btnBig from "@/components/common/btn-big";
import inputNum from "@/components/common/input-num";
import inputBoolean from "@/components/common/input-boolean";
import inputDropdown from "@/components/common/input-dropdown";
import inputText from "@/components/common/input-text";
import inputDate from "@/components/common/input-date";
import modal from "@/components/mobile/info/modal";
import {sumLenings} from "@/data/leningOversluitenCalc";

export default {
  name: 'MobileCalculateSectionBlock',
  props: {
    block: Object
  },
  components: {
    btnBig,
    inputNum,
    inputBoolean,
    inputDropdown,
    inputText,
    inputDate,
    modal
  },
  data() {
    return {
      formCursor: null,
      tabsState: {
        tab1: {
          fields: [$store.state._loanRequest.loanAmount],
          state: undefined
        },
        tab2: {
          fields: [$store.state._loanRequest.prevLoans],
          state: undefined
        },
        tab3: {
          fields: [$store.state._person.married, $store.state._person.livingState, $store.state._person.children],
          state: undefined
        },
        tab4: {
          fields: [$store.state._person.monthlyExpenses, $store.state._person.income, $store.state._person.incomeSource, $store.state._person.dateService, $store.state._person.partnerIncome],
          state: undefined
        }
      }
    };
  },
  created() {
    this.Loans = 1;
  },
  mounted() {
    this.$infoButton = this.$el.querySelector(".info-container");
    window.addEventListener("resize", this.setInfoTrianglePos);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setInfoTrianglePos);
  },
  computed: {
    Funnel: {
      get() {
        var loanRequest = $store.state._loanRequest;

        if (loanRequest.loanOption == $store.state.funnelBOption)
          loanRequest.selectedFunnel = $store.state.funnel.B;
        else
          loanRequest.selectedFunnel = $store.state.funnel.A;

        return loanRequest.loanOption;
      },
      set(val) {
        var loanRequest = $store.state._loanRequest;
        loanRequest.loanOption = val;
      }
    },
    Loans: {
      get() {
        var loanRequest = $store.state._loanRequest;
        var res = new Number(loanRequest.prevLoans.length);
        res.ui = loanRequest.prevLoans.ui;
        return res;
      },
      set(n) {
        var loanRequest = $store.state._loanRequest;
        if (n != loanRequest.prevLoans.length) {
          if (n > 0) {
            loanRequest.prevLoans.ui.init = true;
            loanRequest.prevLoans.ui.valid = true;
          } else {
            loanRequest.prevLoans.ui.valid = false;
          }
          if (n > loanRequest.prevLoans.length) {
            for (var i = loanRequest.prevLoans.length; i < n; i++) {
              loanRequest.prevLoans.push($store.state.prevLoanTemplate());
            }
          } else loanRequest.prevLoans.splice(n);
        }
      }
    }
  },
  watch: {
    "$store.state._person.married": function(newVal, oldVal) {
      // *** THIS NEEDS A BETTER, CLEANER CODING SOLUTION ***
      var re = /partnerIncome/;
      if ($store.state._person.married == true) {
        for (var field of this.tabsState.tab4.fields) {
          if (field.ui.label && re.test(field.ui.label)) {
            field.ui.valid = false;
          }
        }
      }
    }
  },
  methods: {
    pxToEm: function(val) {
      return val / $store.state.defaults.defaultFontSize;
    },
    getTabs() {
      var loanRequest = $store.state._loanRequest;

      if (loanRequest.selectedFunnel == $store.state.funnel.A) return [1, 3, 4];
      else if (loanRequest.selectedFunnel == $store.state.funnel.B) return [2, 3, 4];
    },
    nav(dir) {
      var vm = this;
      var maxTabs = vm.getTabs().length - 1;

      if (vm.formCursor == null) vm.formCursor = 0;
      else {
        var _tab = "tab" + this.getTabs()[vm.formCursor];
        var tab = this.tabsState[_tab];
        tab.state = checkTab(tab.fields);

        if (vm.formCursor == 0 && dir == -1) vm.formCursor = null;
        else if (vm.formCursor == maxTabs && dir == 1) {
          if (tab.state == true) {
            $store.state.storedData = true;
            if ($store.state._loanRequest.selectedFunnel == $store.state.funnel.B) $store.dispatch('calculateSavings');
            $store.commit("calcMaxLoanAmount");
            if ($store.state.storedData) vm.$router.push("/offerte");
          }
        } else {
          if (tab.state == false && dir == 1) return;
          vm.formCursor += dir;
        }
      }
    },
    setInfoTrianglePos() {
      var infoRect = this.$infoButton.getBoundingClientRect();
      var $infoModal = this.$el.querySelector(".modal-container");
      var $infoModalTriangle = $infoModal.querySelector(".triangle");
      $infoModalTriangle.style.left = this.pxToEm(0.5 * (infoRect.right + infoRect.left) - 10) + "em";
    },
    toggleModal(event) {
      event.stopPropagation();
      $store.state.modal = !$store.state.modal;
      this.setInfoTrianglePos();
    }
  }
}
</script>
