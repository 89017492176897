<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="title" v-if="block.Title">
            {{ block.Title }}
          </div>

          <div class="body" v-if="block.Subtitle">
            {{ block.Subtitle }}
          </div>
        </div>

        <div id="anchor-buttons"></div>

        <div v-for="(item, index) in block.Buttons" :key="index">
          <div class="sep-30"></div>

          <div class="btn-content">
            <!--- block.content -->
            <btn-big
              :class="item.url ? 'primary new-client' : 'secondary'"
              :width="260"
              :height="107"
              :click="() => handleClick(item.url)"
            >
              <div class="content">
                <img
                  :class="{ 'rotate-90': !item.url }"
                  src="@/assets/arrow.svg"
                  alt
                />
                <div class="txt">{{ item.label }}</div>
              </div>
            </btn-big>
          </div>
        </div>

        <div class="downloads" v-if="blockStatus">
          <div class="title">
            <div class="txt">Brochure downloaden</div>
          </div>

          <div class="subtitle">
            Onderstaande PDF brochures geven u inzicht in onze huidige manier
            van samenwerken.
          </div>

          <div class="brochures">
            <div
              class="brochure"
              v-for="(brochure, i) in block.Brochures"
              :key="brochure.id"
            >
              <img src="@/assets/brochure.svg" />
              <div class="txt">
                <span class="b-title">{{ brochure.Title }}</span>
                <span class="b-subtitle">{{ brochure.Description }}</span>
                <a
                  class="b-link"
                  target="_blank"
                  v-if="
                    brochure.File &&
                    brochure.File.data &&
                    brochure.File.data.attributes &&
                    brochure.File.data.attributes.url
                  "
                  :href="strapiAssetUrl(brochure.File.data.attributes.url)"
                  >PDF bestand |
                  {{
                    brochure.File.data.attributes.size
                      ? parseInt(brochure.File.data.attributes.size) + " Kb"
                      : ""
                  }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="white-button"
          @click="toggleDiv(false)"
          v-anchor="'#anchor-buttons'"
          v-if="blockStatus"
        >
          <div class="arrow-cont">
            <div class="arrow"></div>
          </div>
        </div>

        <div id="form-top"></div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 2em;

.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    .section {
      margin: pxToEm(25) pxToEm(10);
      text-align: left;

      .container-a {
        margin: pxToEm(25) pxToEm(25) pxToEm(10);

        .title {
          font-size: pxToPercent(24);
          font-weight: 700;
        }

        .body {
          margin-top: pxToEm(25);
          font-size: pxToPercent(16);
          line-height: 2.3em;
        }
      }

      .btn-content {
        margin: 0 pxToEm(25);

        a {
          text-decoration: none;
        }
        .btn-big {
          max-width: pxToEm(300);
          margin: 0 auto;
          border-bottom-width: pxToEm(7);

          .content {
            margin-left: pxToEm(20);
            margin-right: pxToEm(20);

            .txt {
              margin-right: pxToEm(20);
              font-size: pxToPercent(16);
              font-weight: 700;
              text-align: left;
            }

            img {
              width: pxToEm(36);
              height: pxToEm(28);
            }
          }
        }
      }

      .white-button {
        width: pxToEm(50);
        height: pxToEm(50);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        background-color: getColor("whiteColor");
        box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
        z-index: 100;
        cursor: pointer;

        .arrow-cont {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .arrow {
            position: relative;
            margin-top: pxToEm(5);
            padding: pxToEm(5);
            transform: rotate(-135deg);
            display: inline-block;
            border: solid getColor("primaryLight");
            border-width: 0 pxToEm(3) pxToEm(3) 0;
          }
        }
      }

      .rotate-90 {
        transform: rotate(90deg);
      }

      .downloads {
        margin: pxToEm(-43) 0 pxToEm(40);
        padding: pxToEm(25) pxToEm(25) pxToEm(50);
        text-align: center;
        background-color: getColor("bgColor");
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        .title {
          margin-top: pxToEm(35);

          .txt {
            font-size: pxToPercent(20);
            font-weight: 300;
          }
        }

        .subtitle {
          margin-top: pxToEm(25);

          .txt {
            font-size: pxToPercent(18);
          }
        }

        .brochures {
          width: 100%;
          display: flex;
          flex-direction: column;

          .brochure {
            text-align: left;
            margin-top: pxToEm(50);

            img {
              width: pxToEm(80);
              height: pxToEm(59);
            }

            .txt {
              line-height: 2.1em;

              .b-title {
                font-weight: 700;
              }

              .b-subtitle {
                font-weight: 300;
              }

              .b-link {
                text-decoration: none;
                color: getColor("primaryDefault");
                cursor: pointer;
              }
            }
          }
        }

        .white-button {
          bottom: pxToEm(160);
        }
      }

      .form {
        margin: pxToEm(-43) 0 pxToEm(25);
        padding: pxToEm(60) pxToEm(25);
        text-align: center;
        background-color: getColor("bgColor");
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        svg {
          margin-top: pxToEm(25);
          width: pxToEm(55);
        }

        .form-title {
          margin-top: pxToEm(30);

          .txt {
            font-size: pxToEm(40);
            font-weight: 300;
          }
        }

        .error {
          width: 100%;
          max-width: pxToEm(400);
          margin: 0 auto pxToEm(50);
          color: getColor("redColor");
          font-style: italic;
          text-align: left;
        }

        .content {
          width: 100%;
          max-width: pxToEm(400);
          margin: 0 auto;
          display: grid;
          grid-row-gap: @gridRowGap;
          text-align: left;

          .txt-label {
            margin-bottom: pxToEm(10);
            font-size: pxToPercent(16);
            font-weight: 300;
          }

          textarea {
            text-align: left;
            font-size: pxToPercent(16);
            background-color: getColor("whiteColor");
            border: @border-width solid getColor("grayLtColor");
            resize: none;
          }

          .col-11 {
            display: grid;
            grid-template-rows: 1fr pxToEm(52);

            &.textarea-input {
              grid-template-rows: 1fr pxToEm(210);
            }
          }
        }

        .form-btn {
          max-width: pxToEm(400);
          margin: pxToEm(50) auto 0;

          .content {
            margin: 0 pxToEm(20);
            margin-right: pxToEm(30);
            display: flex;
            flex-flow: row;

            .txt {
              margin-right: pxToEm(20);
              font-size: pxToPercent(16);
              font-weight: 700;
            }

            img {
              width: pxToEm(36);
              height: pxToEm(28);
            }
          }
        }

        .white-button {
          bottom: pxToEm(30);
        }

        .form-message {
          margin: pxToEm(50) pxToEm(25);
        }
      }
    }
  }
}
</style>

<script>
import { marked } from "marked";
import headerImg from "@/assets/about-second-header.jpg";
import inputDropdown from "@/components/common/input-dropdown";
import btnBig from "@/components/common/btn-big";
import inputRadio from "@/components/common/input-radio";
import inputText from "@/components/common/input-text";
import formMessage from "@/components/common/form-message";
import { _form0 } from "@/data/aboutData";
import { checkTab, goToAnchor } from "@/JS/helpers";
import { dataToMail, mails } from "@/JS/mail";
import { strapiAssetUrl } from "@/JS/helpers";

export default {
  name: "MobileWorkWithUsSectionBlock",
  props: {
    block: Object,
  },
  components: {
    btnBig,
    inputRadio,
    inputText,
    formMessage,
  },
  data() {
    return {
      $div1: null,
      $div2: null,
      blockStatus: false,
      form0: {
        fields: new _form0(),
        state: undefined,
      },
      formSent: false,
    };
  },
  mounted() {
    this.$div1 = document.querySelector(".form");
    this.$div2 = document.querySelector(".downloads");
  },
  methods: {
      strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    },
    toggleDiv(val) {
      this.blockStatus = val;
    },
    handleClick: function (item) {
      if (item) {
        this.$router.push(item);
      } else {
        this.toggleDiv(true);
        goToAnchor("#form-top");
      }
    },
    submitForm() {
      this.form0.state = checkTab(this.form0.fields);
      if (this.form0.state == false) {
        goToAnchor("#form-error");
      } else {
        dataToMail({
          mailTo: mails.info,
          mailSubject: "Over Leenattent",
          mailData: this.form0.fields,
        })
          .then((res) => {
            //CODE FOR MAIL SENT /////////////
            this.form0.fields = new _form0();
            this.form0.state = undefined;
            this.formSent = true;
            goToAnchor("#form-top");
            // console.log(res);
          })
          .catch((rej) => {
            //CODE FOR MAIL NOT SENT /////////////
            this.formSent = false;
            // console.log(rej);
          });
      }
    },
  },
};
</script>
