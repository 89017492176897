<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <MobileOfferSectionHead class="area" @selected="selectFunnel"></MobileOfferSectionHead>
        <offerte-body class="area" v-if="$store.state.storedData && $store.state._loanRequest.selectedFunnel == $store.state.funnel.A && $store.state._loanRequest.approvedLoanAmount > 0"></offerte-body>
        <offerte-exp-items class="area" :funnel="funnel"></offerte-exp-items>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }
}
</style>

<script>
  import MobileOfferSectionHead from "./MobileOfferSectionHead";
  import offerteBody from "@/components/mobile/offerte/body";
  import offerteExpItems from "@/components/mobile/offerte/expitems";

  export default {
    name: "MobileOfferSection",
    components: {
      MobileOfferSectionHead,
      offerteBody,
      offerteExpItems,
    },
    props: {
      block: Object
    },
    data() {
      return {
        funnel: null
      };
    },
    methods: {
      selectFunnel(o) {
        this.funnel = o;
      }
    },
    created() {
      if ($store.state.storedData == false || $store.state._loanRequest.approvedLoanAmount == NaN) {
        $store.commit("resetForms");
      }
    }
  };
</script>
