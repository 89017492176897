<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="title">Persoonlijke lening</div>
  
          <div class="dash"></div>
  
          <div class="items-parent">
            <div class="items">
              <div class="item">
                <img src="@/assets/persoonlijke_img1.jpg">
                <div class="text">
                  <div class="pink">
                    <div class="txt">
                      AUTO
                    </div>
                  </div>
                  <div class="black">
                    <div class="txt">
                      Kom je me morgen weer ophalen?
                    </div>
                  </div>
                  <div class="small">
                    <div class="txt">
                      Iederde dag plezier van een lage rente.
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="item">
                <img src="@/assets/persoonlijke_img2.jpg">
                <div class="text">
                  <div class="pink">
                    <div class="txt">
                      KEUKEN
                    </div>
                  </div>
                  <div class="black">
                    <div class="txt">
                      Het koken is bijna leuker dan het opeten…
                    </div>
                  </div>
                  <div class="small">
                    <div class="txt">
                      Fijn eerlijk geld lenen tegen een lage rente.
                    </div>
                  </div>
                </div>
              </div>
    
              <div class="item">
                <img src="@/assets/persoonlijke_img3.jpg">
                <div class="text">
                  <div class="pink">
                    <div class="txt">
                      VAKANTIE
                    </div>
                  </div>
                  <div class="black">
                    <div class="txt">
                      Zon, strand & zee. Anders nog iets?
                    </div>
                  </div>
                  <div class="small">
                    <div class="txt">
                      Samen plezier van eerlijk geld lenen.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    padding-bottom: pxToEm(58);
    background-color: getColor("greyLight");

    .layout {
      overflow: hidden;

      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: 0 pxToEm(25);

          .title {
            font-size: pxToPercent(28);
            font-weight: 800;
            line-height: 1em;

            span {
              color: getColor("primaryLight");
            }
          }

          .dash {
            width: pxToEm(91);
            height: pxToEm(8);
            margin-top: pxToEm(10);
            background-color: getColor("primaryLight");
          }

          .items-parent {
            width: 0;
            margin: 0 auto;

            .items {
              width: 634px;
              left: 50%;
              transform: translateX(-50%);
              margin-top: pxToEm(25);
              display: flex;
              justify-content: center;

              .item {
                width: 33%;
                margin: 0 pxToEm(20) 0 0;
                background-color: getColor('bgColor');
                display: flex;
                flex-direction: column;
                overflow: hidden;

                img {
                  width: 140%;
                  height: 190px;
                  align-self: center;
                }

                &:last-child {
                  margin: 0;
                }

                .text {
                  display: flex;
                  flex-direction: column;
                  padding: 0 pxToEm(30);

                  .pink {
                    padding: pxToEm(15) 0;
                    color: getColor("primaryLight");

                    .txt {
                      font-size: pxToPercent(12);
                      font-weight: 700;
                      text-align: center;
                    }
                  }

                  .black {
                    .txt {
                      font-size: pxToPercent(16);
                      font-weight: 700;
                    }
                  }

                  .small {
                    margin: pxToEm(15) 0 pxToEm(25);

                    .txt {
                      font-size: pxToPercent(12);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>

<script>
  export default {
    name: "persoonlijkeHeader"
  };
</script>
