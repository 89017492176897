<template>
  <div>
    <MobileVacancySectionBlock :block="block" v-if="isMobile"></MobileVacancySectionBlock>
    <DesktopVacancySectionBlock :block="block" v-else></DesktopVacancySectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>

import MobileVacancySectionBlock from "@/components/strapi/VacancySection/MobileVacancySectionBlock";
import DesktopVacancySectionBlock from "@/components/strapi/VacancySection/DesktopVacancySectionBlock";

export default {
  name: 'VacancySectionBlock',
  components: {
    DesktopVacancySectionBlock,
    MobileVacancySectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
