<template>
  <div class="gallery">
    <!-- <div class="slides-container"> -->
      <slot></slot>
    <!-- </div> -->
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
export default {
  props: {
    viewSlides: {
      type: Number,
      default: 3
    },
    classes: Object
  },
  data: function () {
    return {
      numSlides: Number,
      totalSlides: Number,
      slides: [],
      slideContainer: null
    };
  },
  mounted: function () {
    this.slidesHTML = this.$el.querySelectorAll(".slide");
    if (this.slidesHTML.length) {
      this.initSlides();
      this.assignClasses();
      this.slideContainer = this.$el.querySelector(".slide-container");
      if (this.slideContainer != null && this.slideContainer != undefined) {
        var vm = this;
        var setHeight = vm.setHeight = function () {
          vm.setContainerHeight(vm.slideContainer);
        };
        this.setContainerHeight(this.slideContainer);
        window.addEventListener("resize", setHeight);
      }
    } else {
      alert('[gallery.vue] No slides found...');
      console.log('[gallery.vue] No slides found...')
    }
  },
  beforeDestroy: function() {
    var vm = this;
    window.removeEventListener("resize", vm.setHeight);
  },
  methods: {
    pxToEm: function(val) {
      return val / $store.state.defaults.defaultFontSize + "em";
    },
    setContainerHeight: function (elem) {
      if (elem != null && elem != undefined) {
        var heights = [];
        var minHeight = 200;
        for (var i = 0; i < this.numSlides; i++) {
          heights.push(this.slides[i].html.offsetHeight);
        }
        var max = heights.reduce(function (a, b) {
          return Math.max(a, b);
        });
        max = Math.max(max, minHeight);
        elem.style.height = this.pxToEm(max);
      }
    },
    initSlides: function () {
      // Obter HTML descritivo dos slides, e sua quantidade:
      // this.slideContainer = this.$el.querySelectorAll(".slide-container");  // a fazer: expôr classe do container dos slides...
      // console.log('this.slideContainer:', this.slideContainer);

      this.numSlides = this.slidesHTML.length;
      // console.log('this.numSlides:', this.numSlides);

      // 'loops' e 'totalSlides':
      var loops = Math.ceil((this.viewSlides + 2) / this.numSlides);
      this.totalSlides = loops * this.numSlides;
      // console.log('loops:', loops);
      // console.log('this.totalSlides:', this.totalSlides);

      for (var i = 0; i < loops; i++) {
        var repeat = i * this.numSlides;
        // this.slideElem = document.createElement('div');
        // console.log('this.slideElem:', this.slideElem);
        // this.slideContainer.appendChild(this.slideElem);
        for (var j = 0; j < this.numSlides; j++) {
          var idx = j + repeat;
          // console.log('this.slidesHTML[j].innerHTML:', this.slidesHTML[j].innerHTML);
          this.slides[idx] = { index: idx, html: this.slidesHTML[j] };
        }
      }
      // console.log('this.slides:', this.slides);
    },
    assignClasses: function (val) {
      for (var i = 0; i < this.totalSlides; i++) {
        if (this.slides[i].index < this.viewSlides) {
          this.slides[i].html.classList.add(this.classes.slide + this.slides[i].index);
        }
        else if (this.slides[i].index === this.totalSlides - 1) {
          this.slides[i].html.classList.add(this.classes.exitLeft);
          if (val === 1)
            this.slides[i].html.classList.add(this.classes.immediate);
        }
        else {
          this.slides[i].html.classList.add(this.classes.exitRight);
          if (val === -1)
            this.slides[i].html.classList.add(this.classes.immediate);
        }
      }
    },
    clearClasses: function () { // dá para mapear, sem utilizar o ciclo 'for' principal?
      for (var i = 0; i < this.totalSlides; i++) {
        this.slides[i].html.classList.remove(this.classes.exitLeft, this.classes.exitRight, this.classes.immediate);
        for (var j = 0; j < this.viewSlides; j++)
          this.slides[i].html.classList.remove(this.classes.slide + j);
      }
    },
    cursor: function (val) {
      for (var i = 0; i < this.totalSlides; i++) {
        this.slides[i].index += val;
        if (this.slides[i].index > this.totalSlides - 1)
          this.slides[i].index = 0;
        else if (this.slides[i].index < 0)
          this.slides[i].index = this.totalSlides - 1;
      }
    },
    navigate: function (dir) {
      if (this.slidesHTML.length) {
        this.cursor(dir);
        this.clearClasses();
        this.assignClasses(dir);
      }
    }
  }
};
</script>