<template>
  <div>
    <MobileBoxedSectionBlock :block="block" v-if="isMobile"></MobileBoxedSectionBlock>
    <DesktopBoxedSectionBlock :block="block" v-else></DesktopBoxedSectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import MobileBoxedSectionBlock from "@/components/strapi/BoxedSection/MobileBoxedSectionBlock";
import DesktopBoxedSectionBlock from "@/components/strapi/BoxedSection/DesktopBoxedSectionBlock";

export default {
  name: 'TwoColumnSectionBlock',
  components: {
    DesktopBoxedSectionBlock,
    MobileBoxedSectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
