<template>
  <div>
    <MobileLargeImageHeader :block="block" v-if="isMobile"></MobileLargeImageHeader>
    <DesktopLargeImageHeader :block="block" v-if="!isMobile"></DesktopLargeImageHeader>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  padding: 0 15px;
  box-sizing: border-box;

  &.white {
    background-color: #fff;
  }

  .layout {
    align-items: center;
    max-width: pxToEm(965);

    .section {
      margin: pxToEm(50);
      width: pxToEm(1160);
      max-width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      align-items: center;

      .top {
        margin: pxToEm(70);
        margin-top: pxToEm(10);
        text-align: left;
        max-width: 100%;

        .texts {
          display: flex;
          flex-direction: column;
          max-width: pxToEm(866);

          .txt {
            flex: 1 1 auto;
            max-width: 768px;

            .title {
              text-decoration: underline;
              font-weight: 700;
              font-size: pxToEm(60);
              line-height: 1em;
              margin: pxToEm(10) 0;
            }

            table {
              border-collapse: collapse;

              td {
                padding: 5px;
                border: 1px solid gray;
              }
            }

            .font-weight-bold {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import MobileLargeImageHeader from "@/components/strapi/LargeImageHeader/MobileLargeImageHeader";
import DesktopLargeImageHeader from "@/components/strapi/LargeImageHeader/DesktopLargeImageHeader";

export default {
  name: 'LargeImageHeaderBlock',
  components: {
    MobileLargeImageHeader,
    DesktopLargeImageHeader
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  },
  props: {
    block: Object
  }
}
</script>
