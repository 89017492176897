<template>
  <div>
    <MobileComparisionBlock :block="block" v-if="isMobile"></MobileComparisionBlock>
    <DesktopComparisionBlock :block="block" v-else></DesktopComparisionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>

import MobileComparisionBlock from "@/components/strapi/ComparisionBlock/MobileComparisionBlock";
import DesktopComparisionBlock from "@/components/strapi/ComparisionBlock/DesktopComparisionBlock";

export default {
  name: 'ColorHeaderBlock',
  components: {
    MobileComparisionBlock,
    DesktopComparisionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
