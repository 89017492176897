<template>
  <div>
    <cookies-header class="area"></cookies-header>
    <cookies-body class="area"></cookies-body>
    <desk-contact class="area"></desk-contact>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
  import cookiesHeader from "@/components/desktop/cookies/header.vue";
  import cookiesBody from "@/components/desktop/cookies/body.vue";
  import deskContact from "@/components/desktop/desk-contact.vue";

  export default {
    name: "deskCookies",
    components: {
      cookiesHeader,
      cookiesBody,
      deskContact
    }
  };
</script>