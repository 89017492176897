<template>
    <div class="grey-section">
        <img
            class="logo"
            src="@/assets/logo.png"
            style="align-self: left"
          />
    </div>
</template>


<style lang="less" scoped>
.grey-section{
    background-color: #f2f2f2;
    text-align: left;
    z-index: 1;
    padding: 0 10px;
}

.logo{
    height: 3.125em;
    margin-bottom: -4px;
}
</style>

<script>
    export default {
        name: "mobLogoSection"
    };
</script>