<template>
  <div class="area" v-if="block">
    <div class="layout">
      <div class="section">
        <div class="container">
          <div
            class="pic"
            v-if="block.Image && block.Image.data && block.Image.data.attributes && block.Image.data.attributes.url"
            :style="{ 'background-image': 'url(' + strapiAssetUrl(block.Image.data.attributes.url) + ')' }"
          ></div>
          <div class="title-container">
            <div class="title">
              <div class="txt">
                {{block.Title}}
              </div>
            </div>
            <router-link :to="block.Link.url">
              <btn-big class="primary" :width="474" :height="123">
                <div class="content">
                  <div class="txt">{{block.Link.label}}</div>
                  <img src="@/assets/arrow.svg" alt />
                </div>
              </btn-big>
            </router-link>
          </div>
        </div>

        
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  .layout {
    max-width: 1920px;

    .section {
      display: flex;
      flex-direction: column;

      .container {
        display: flex;

        .pic {
          width: 50%;
          height: pxToEm(736);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
        .title-container {
          position: relative;
          width: 50%;
          max-width: pxToEm(580);

          .title {
            position: absolute;
            width: pxToEm(570);
            height: pxToEm(400);
            background-color: white;
            left: pxToEm(-83);
            top: pxToEm(100);

            > div:nth-child(1) {
              text-align: left;
              font-weight: 700;
              font-size: pxToPercent(50);
              line-height: 55/50em;
              margin: 1.4em;
            }
          }
        }
        .btn-big {
          position: absolute;
          right: 0;
          top: pxToEm(465);
          .content {
            margin-left: pxToEm(40);
            margin-right: pxToEm(40);
            .txt {
              font-size: pxToPercent(28);
            }
            img {
              width: pxToEm(63);
              height: pxToEm(50);
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";
import secondHeaderImg from "@/assets/homepage-second-header.jpg";
import { strapiAssetUrl } from "@/JS/helpers";

export default {
  name: "deskSecondHeader",
  props: {
    block: Object,
  },
  components: {
    btnBig,
  },
  data() {
    return {
      secondHeaderImg,
    };
  },
  methods:{
     strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    },
  }
};
</script>
