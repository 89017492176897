<template>
  <div class="modal">
    <div class="box">
      <div class="sep-40"></div>

      <div class="title-container">
        <div class="title">Wat zegt uw inkomstenbron over uw lening?</div>
      </div>

      <div class="sep-40"></div>
      
      <income-source class="area"></income-source>

      <div class="sep-50"></div>

      <div class="close-container" @click="close">
        <img src="@/assets/close.svg">
      </div>
    </div>

    <div class="triangle"></div>
  </div>
</template>

<style lang="less" scoped>
  .modal {
    text-align: left;

    .box {
      position: relative;
      padding: 0 pxToEm(12);
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: getColor("greyLight");
      border: 1px solid getColor("grayLtColor");
      box-sizing: border-box;

      .title-container {
        text-align: center;
        margin: 0 pxToEm(23);

        .title {
          font-size: pxToPercent(28);
          font-weight: 300;
        }
      }

      .close-container {
        width: pxToEm(18);
        height: pxToEm(18);
        position: absolute;
        right: 0;
        padding: pxToEm(20);
        cursor: pointer;
        transition: all 100ms ease-in;
      }

      .expandable-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .exp-item {
          width: 100%;
          height: pxToEm(95);
          background-color: getColor("whiteColor");
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

          &.expanded {
            height: auto;

            .exp-item-title .right .arrow {
              transform: rotate(-135deg);
            }
          }

          a {
            text-decoration: none;
          }

          .exp-item-title {
            padding: 0 pxToEm(23);
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            .left {
              height: 100%;
              flex: 2 1 auto;
              display: flex;
              align-items: center;
              font-size: pxToPercent(18);
              font-weight: 600;
            }

            .right {
              width: pxToEm(50);
              height: pxToEm(100);
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .arrow {
                position: relative;
                border: solid black;
                border-width: 0 pxToEm(2) pxToEm(2) 0;
                padding: pxToEm(5);
                transform: rotate(45deg);
              }
            }
          }

          .exp-item-text {
            padding: 0 pxToEm(23);
            font-size: pxToPercent(16);
            line-height: 2.2em;
            text-align: justify;

            .btn-container {
              width: pxToEm(160);

              .btn-big {
                .content {
                  margin: 0 pxToEm(15);

                  .txt {
                    font-size: pxToPercent(16);
                    font-weight: 700;
                    text-align: left;
                  }

                  img {
                    width: pxToEm(36);
                    height: pxToEm(28);
                  }
                }
              }
            }

            .exp-item-subtitle {
              font-weight: 700;
              text-align: left;
            }
          }
        }
      }
    }

    .triangle {
      width: pxToEm(20);
      height: pxToEm(20);
      position: absolute;
      top: pxToEm(-10);
      right: pxToEm(45);
      transform: rotate(45deg);
      background-color: getColor("greyLight");
      border-top: 1px solid getColor("grayLtColor");
      border-left: 1px solid getColor("grayLtColor");
    }
  }
</style>

<script>
  import incomeSource from "@/components/common/income-source";

  export default {
    components: {
      incomeSource
    },
    methods: {
      close: function () {
        event.stopPropagation();
        $store.state.modal = false;
      }
    }
  };
</script>