<template>
  <div>
    <MobileTwoColumnSectionBlock :block="block" v-if="isMobile"></MobileTwoColumnSectionBlock>
    <DesktopTwoColumnSectionBlock :block="block" v-else></DesktopTwoColumnSectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import MobileTwoColumnSectionBlock from "@/components/strapi/TwoColumnSection/MobileTwoColumnSectionBlock";
import DesktopTwoColumnSectionBlock from "@/components/strapi/TwoColumnSection/DesktopTwoColumnSectionBlock";

export default {
  name: 'TwoColumnSectionBlock',
  components: {
    MobileTwoColumnSectionBlock,
    DesktopTwoColumnSectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
