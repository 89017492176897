<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div
        class="pic"
        :style="{ 'background-image': 'url(' + headerImg + ')' }"
      ></div>
      <div class="section">
        <div id="anchor-white-container"></div>

        <div class="white-container">
          <div class="container">
            <div class="big-title">
              <div class="txt">{{ block.Title }}</div>
            </div>

            <div class="subtitle">
              <div class="txt">{{ block.Subtitle }}</div>
            </div>

            <input-dropdown
              v-if="block.Vacancies.data"
              :options="setOptions(block.Vacancies.data)"
              :placeholder="setOptions(block.Vacancies.data)[0]"
              v-model="dropdownIndex"
            ></input-dropdown>
            <!-- block.contents -->
            <div
              v-for="(data, index) in block.Vacancies.data"
              :key="`data-${index}`"
              class="white-container-txt"
              v-if="block.Vacancies.data.length > 0 && dropdownIndex == index"
            >
              <div class="wc-title" v-if="data.attributes.Title">
                <div class="txt">{{ data.attributes.Title }}</div>
              </div>
              <div class="text" v-html="markdownToHTML(data.attributes.Description)"></div>
            </div>
          </div>

          <div
            class="white-button"
            v-show="dropdownIndex == 0"
            @click="collapseForm"
            v-anchor="'#anchor-white-container'"
          >
            <div class="arrow-cont">
              <div class="arrow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 2em;
@gridColGap: 1.25em;
@gridColHalfGap: @gridColGap * 0.5;

.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .text {
    font-size: pxToEm(18);
    line-height: 2.1em;
  }

  .layout {
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .pic {
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: -200px;
      height: 805px;
      top: 0;
      width: 100%;
      position: absolute;
    }

    .section {
      width: 100%;
      max-width: 1200px;
      margin: 0 pxToEm(50);
      margin-top: 30%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .white-container {
        background-color: #fff;
        min-height: 25em;
        width: 71.25em;
        -webkit-box-shadow: 0 0 13px rgb(0 0 0 / 30%);
        box-shadow: 0 0 13px rgb(0 0 0 / 30%);
        border: 1px solid silver;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;

        .container {
          display: flex;
          flex-direction: column;
          margin: pxToEm(90);
          align-items: center;

          & > div {
            flex: 1 1 auto;
          }

          .big-title {
            margin-bottom: pxToEm(50);

            .txt {
              font-size: pxToPercent(60);
              line-height: 1.1em;
            }
          }

          .subtitle {
            margin-bottom: pxToEm(50);

            .txt {
              font-size: pxToPercent(24);
            }
          }

          .input-dropdown {
            width: pxToEm(572);
            height: pxToEm(70);
          }

          .white-container-txt {
            width: 100%;
            text-align: left;
            line-height: 2.3em;
            margin-top: pxToEm(50);

            .wc-title {
              margin: pxToEm(20) 0;

              .txt {
                font-size: pxToEm(24);
                font-weight: 700;
              }
            }
          }
        }

        .white-button {
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
          width: pxToEm(50);
          height: pxToEm(50);
          position: absolute;
          bottom: -25px;
          z-index: 100;
          left: 50%;
          transform: translateX(-50%);
          cursor: pointer;

          .arrow-cont {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .arrow {
              border: solid getColor("primaryLight");
              border-width: 0 3px 3px 0;
              display: inline-block;
              padding: 5px;
              transform: rotate(-135deg);
              position: relative;
              margin-top: 5px;
            }
          }
        }
      }

      .title {
        margin-top: pxToEm(90);
        margin-bottom: pxToEm(10);

        .txt {
          font-size: pxToPercent(36);
          font-weight: 700;
        }
      }

      .body {
        max-width: pxToEm(700);

        .txt {
          font-size: pxToPercent(18);
          line-height: 2.3em;
        }
      }

      .btn-content {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: pxToEm(50) 0 pxToEm(100) 0;
        width: pxToEm(800);

        a {
          text-decoration: none;
        }
      }
    }
  }
}
</style>

<script>
import { marked } from "marked";
import headerImg from "@/assets/about-second-header.jpg";
import inputDropdown from "@/components/common/input-dropdown";

export default {
  name: "DesktopVacancySectionBlock",
  props: {
    block: Object,
  },
  methods: {
    markdownToHTML: function (markdown) {
      return marked(markdown);
    },
    collapseForm() {
      this.dropdownIndex = null;
    },
    setOptions(options){
      return options.map(({attributes}) => attributes.Title)
    }

  },
  components: {
    inputDropdown,
  },
  data() {
    return {
      headerImg,
      dropdownIndex: null
    };
  },
};
</script>
