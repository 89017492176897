<template>
  <div>

    <div class="layout">
      <div class="section">
        <div class="container">

          <div class="expandable-items" v-if="$store.state.formSent == false">
            <!--ITEM 1 ////////////////////////////////////////// -->
            <div id="form-error-tab1"></div>
            <div
              class="exp-item item1"
              :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[0].expand : false }"
              v-registerForm="true"
            >
              <div :class="{ formcheck: tabs.tab1.state == false }">
                <div class="exp-item-title-container" :class="{ valid: tabs.tab1.state == true }">
                  <div class="left">
                    <span>1. Uw gewenste lening</span>
                  </div>

                  <div class="right">
                    <div class="arrow"></div>
                  </div>
                </div>

                <div class="exp-item-text">
                  <div
                    class="error"
                    v-show="tabs.tab1.state == false"
                    style="white-space: pre-line"
                  >{{ $store.state.tabErrors.tab1 }}</div>

                  <div class="sep-40" v-show="tabs.tab1.state == false"></div>

                  <div class="sep-30"></div>

                  <div class="exp-item-body">
                    <div class="row-1-15-a select-input">
                      <div class="txt-label">
                        <span>Waarvoor wilt u geld lenen?</span>
                      </div>
                      <div class="inputs">
                        <input-dropdown
                          style="z-index: 230;"
                          :options="$store.state.funnelOptions"
                          v-model="Funnel"
                        ></input-dropdown>
                      </div>
                    </div>

                    <div
                      class="row-1-15-a text-input"
                    >
                      <div class="txt-label">
                        <span>Hoeveel geld wilt u lenen?</span>
                      </div>
                      <div class="inputs">
                        <input-num v-model="loanRequest.approvedLoanAmount"></input-num>
                      </div>
                    </div>

                    <div class="row-1-15-a select-input">
                      <div class="txt-label">
                        <span>
                          In welk kredietvorm wilt u
                          <br />de lening afsluiten?
                        </span>
                      </div>
                      <div class="inputs">
                        <input-dropdown
                          style="z-index: 220;"
                          :options="$store.state.loanTypeOptions"
                          v-model="$store.state._loanRequest.loanTypeTable"
                          placeholder="Maak uw keuze"
                        ></input-dropdown>
                      </div>
                    </div>

                    <div v-if="showSelectedMonths" class="row-1-15-a select-input">
                      <div class="txt-label">
                        <span>
                          Doorlooptijd in maanden
                        </span>
                      </div>
                      <div class="inputs">
                        <input-dropdown
                          style="z-index: 210;"
                          :options="loanInfo[$store.state._loanRequest.loanTypeTable].months"
                          v-model="loanRequest.months"
                        ></input-dropdown>
                      </div>
                    </div>
                  </div>

                  <div class="sep-60"></div>

                  <btn-big
                    class="next secondary-pink"
                    :width="240"
                    :height="80"
                    v-show="$store.state.formsOfferte.length > 0 ? !$store.state.formsOfferte[getTabs == 5 ? 1 : 2].expand : false"
                  >
                    <div class="content">
                      <div class="txt">Volgende</div>
                      <img src="@/assets/arrow.svg">
                    </div>
                  </btn-big>

                  <div class="sep-60"></div>
                </div>
              </div>
            </div>

            <div class="sep-30"></div>

            <!--ITEM 2 ////////////////////////////////////////// -->
            <div id="form-error-tab2"></div>
            <div
              class="exp-item item2"
              :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[1].expand : false }"
              v-show="loanRequest.selectedFunnel == $store.state.funnel.B"
              v-registerForm="tabs.tab2.state == false || ($store.state.storedData == true && tabs.tab2.state == undefined)"
            >
              <div :class="{ formcheck: tabs.tab2.state == false }">
                <div
                  class="exp-item-title-container"
                  :class="{ valid: tabs.tab2.state == true }"
                >
                  <div class="left">
                    <span>{{ getTabs - 3 }}. Huidige leningen</span>
                  </div>

                  <div class="right">
                    <div class="arrow"></div>
                  </div>
                </div>
                <div class="exp-item-text">
                  <div
                    class="error"
                    v-show="tabs.tab2.state == false"
                    style="white-space: pre-line"
                  >{{ $store.state.tabErrors.tab2 }}</div>

                  <div class="sep-40" v-show="tabs.tab2.state == false"></div>

                  <div class="sep-30"></div>

                  <div class="exp-item-body">
                    <div class="row-1-15-a select-input">
                      <div class="txt-label">
                        <span>Hoeveel leningen heeft u lopen?</span>
                      </div>
                      <div class="inputs">
                        <input-dropdown
                          class="input-dropdown-180"
                          style="z-index: 210;"
                          :init="true"
                          :options="$store.state.loansDropdown"
                          v-model="Loans"
                        ></input-dropdown>
                      </div>
                    </div>
                  </div>

                  <div class="loans" v-if="Loans > 0">
                    <div class="loan" v-for="(loan, index) in loanRequest.prevLoans" :key="index">
                      <div class="sep-50"></div>

                      <div class="row-sub-title">
                        <div class="sub-title">Lening {{ index + 1 }}</div>
                      </div>

                      <div class="sep-50"></div>

                      <div class="exp-item-body">
                        <div class="row-1-15-a text-input">
                          <div class="txt-label">
                            <span>Maatschappij</span>
                          </div>
                          <div class="inputs">
                            <input-text :init="loan.name.ui.init" v-model="loan.name"></input-text>
                          </div>
                        </div>

                        <div class="row-1-15-a num-input">
                          <div class="txt-label">
                            <span>Openstaand bedrag</span>
                          </div>
                          <div class="inputs">
                            <input-num :blur="calculateSaving(loan.amount, loan.rate, loan.name, index)" v-model="loan.amount"></input-num>
                          </div>
                        </div>

                        <div class="row-1-15-a num-input">
                          <div class="txt-label">
                            <span>Effectieve jaarrente</span>
                          </div>
                          <div class="inputs">
                            <input-num :blur="calculateSaving(loan.amount, loan.rate, loan.name, index)" v-model="loan.rate"></input-num>
                          </div>
                        </div>

                        <div class="row-1-15-a toggle-input">
                          <div class="txt-label">
                            <span>Inlossen</span>
                          </div>
                          <div class="inputs">
                            <input-boolean :init="loan.ransom.ui.init" v-model="loan.ransom"></input-boolean>
                          </div>
                        </div>
                        <p v-if="saveAmount[index]">{{ saveAmount[index] }}</p>
                      </div>

                      <div class="sep-50"></div>
                    </div>
                  </div>

                  <div class="sep-60"></div>

                  <btn-big
                    class="next secondary-pink"
                    :width="240"
                    :height="80"
                    v-show="$store.state.formsOfferte.length > 0 ? !$store.state.formsOfferte[2].expand : false"
                  >
                    <div class="content">
                      <div class="txt">Volgende</div>
                      <img src="@/assets/arrow.svg">
                    </div>
                  </btn-big>

                  <div class="sep-60"></div>
                </div>
              </div>
            </div>

            <div class="sep-30" v-if="loanRequest.selectedFunnel == $store.state.funnel.B"></div>

            <!--ITEM 3 ////////////////////////////////////////// -->
            <div id="form-error-tab3"></div>
            <div
              class="exp-item item3"
              :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[2].expand : false }"
              v-registerForm="tabs.tab3.state == false || ($store.state.storedData == true && tabs.tab3.state == undefined)"
            >
              <div :class="{ formcheck: tabs.tab3.state == false }">
                <div
                  class="exp-item-title-container"
                  :class="{ valid: tabs.tab3.state == true }"
                >
                  <div class="left">
                    <span>{{ getTabs - 2 }}. Uw situatie</span>
                  </div>

                  <div class="right">
                    <div class="arrow"></div>
                  </div>
                </div>
                <div class="exp-item-text">
                  <div
                    class="error"
                    v-show="tabs.tab3.state == false"
                    style="white-space: pre-line"
                  >{{ $store.state.tabErrors.tab3 }}</div>

                  <div class="sep-40" v-show="tabs.tab3.state == false"></div>

                  <div class="sep-30"></div>

                  <div class="exp-item-body">
                    <div class="row-11-a toggle-input">
                      <div class="txt-label">
                        <span>Bent u getrouwd of samenwonend?</span>
                      </div>
                      <div class="inputs">
                        <input-boolean v-model="person.married"></input-boolean>
                      </div>
                    </div>

                    <div class="row-11-a select-input">
                      <div class="txt-label">Wat is uw woonsituatie?</div>
                      <div class="inputs">
                        <input-dropdown
                          class="input-dropdown-300"
                          :options="$store.state.livingStateDropdown"
                          v-model="person.livingState"
                        ></input-dropdown>
                      </div>
                    </div>

                    <div class="row-11-a toggle-input">
                      <div class="txt-label">Heeft u inwonende kinderen?</div>
                      <div class="inputs">
                        <input-boolean v-model="person.children"></input-boolean>
                      </div>
                    </div>

                    <div class="row-11-a text-input">
                      <div class="txt-label">
                        <span>Wat zijn uw woonlasten?</span>
                      </div>
                      <div class="inputs">
                        <input-num :min=1 v-model="person.monthlyExpenses"></input-num>
                      </div>
                    </div>
                    <div class="row-11-a text-input">
                      <div class="txt-label">
                        <span>Wat is uw netto inkomen per maand?</span>
                      </div>
                      <div class="inputs">
                        <input-num :min=1 v-model="person.income"></input-num>
                      </div>
                    </div>
                    <div class="row-1-15-a select-input">
                      <div class="txt-label">
                        <span>Wat is uw inkomstenbron?</span>
                      </div>
                      <div class="inputs align-right">
                        <input-dropdown
                          class="input-dropdown-300"
                          :options="$store.state.incomeSourceDropdown"
                          v-model="person.incomeSource"
                        ></input-dropdown>
                      </div>
                      <div class="info-container">
                        <div class="info-icon" @click="toggleModal">
                          <div class="bg">
                            <img src="@/assets/info.png" />
                          </div>
                        </div>

                        <div class="modal-container" v-show="$store.state.modal">
                          <modal></modal>
                        </div>
                      </div>
                    </div>

                    <div class="row-1-15-a date-input">
                      <div class="txt-label">
                        <span>Datum in dienst?</span>
                      </div>
                      <div class="inputs align-right">
                        <input-date v-model="person.dateService"></input-date>
                      </div>
                    </div>
                  </div>

                  <div class="sep-60"></div>

                  <btn-big
                    class="next secondary-pink"
                    :width="240"
                    :height="80"
                    v-show="$store.state.formsOfferte.length > 0 ? !$store.state.formsOfferte[3].expand : false"
                  >
                    <div class="content">
                      <div class="txt">Volgende</div>
                      <img src="@/assets/arrow.svg">
                    </div>
                  </btn-big>

                  <div class="sep-60"></div>
                </div>
              </div>
            </div>

            <div class="sep-30"></div>

            <!--ITEM 4 ////////////////////////////////////////// -->
            <div id="form-error-tab4"></div>
            <div
              class="exp-item item4"
              :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[3].expand : false }"
              v-registerForm="tabs.tab4.state == false || ($store.state.storedData == true && tabs.tab4.state == undefined)"
            >
              <div :class="{ formcheck: tabs.tab4.state == false }">
                <div
                  class="exp-item-title-container"
                  :class="{ valid: tabs.tab4.state == true }"
                >
                  <div class="left">
                    <span>{{ getTabs - 1 }}. Uw persoonlijke gegevens</span>
                  </div>
                  <div class="right">
                    <div class="arrow"></div>
                  </div>
                </div>

                <div class="exp-item-text">
                  <div
                    class="error"
                    v-show="tabs.tab4.state == false"
                    style="white-space: pre-line"
                  >{{ $store.state.tabErrors.tab4 }}</div>

                  <div class="sep-40" v-show="tabs.tab4.state == false"></div>

                  <div class="sep-30"></div>

                  <div class="exp-item-body">
                    <div class="row-112-b text-input">
                      <div class="col">
                        <div class="txt-label">Voorletter(s)</div>
                        <div class="inputs">
                          <input-text v-model="person.firstName"></input-text>
                        </div>
                      </div>
                      <div class="col">
                        <div class="txt-label">Tussenvoegsel</div>
                        <div class="inputs">
                          <input-text v-model="person.middleName"></input-text>
                        </div>
                      </div>
                      <div class="col">
                        <div class="txt-label">Achternaam</div>
                        <div class="inputs">
                          <input-text v-model="person.lastName"></input-text>
                        </div>
                      </div>
                    </div>

                    <div class="row-fw-b radio-input">
                      <div class="col">
                        <div class="txt-label">Geslacht</div>
                        <div class="inputs">
                          <input-radio v-model="person.gender">
                            <span slot="option1">Man</span>
                            <span slot="option2">Vrouw</span>
                          </input-radio>
                        </div>
                      </div>
                    </div>

                    <div class="row-fw-b date-input">
                      <div class="col">
                        <div class="txt-label">Geboortedatum</div>
                        <div class="inputs">
                          <input-date v-model="person.birthDate"></input-date>
                        </div>
                      </div>
                    </div>

                    <div class="row-211-b text-input">
                      <div class="col">
                        <div class="txt-label">Straat</div>
                        <div class="inputs">
                          <input-text v-model="person.streetName"></input-text>
                        </div>
                      </div>
                      <div class="col">
                        <div class="txt-label">Huisnummer</div>
                        <div class="inputs">
                          <input-text v-model="person.houseNumber"></input-text>
                        </div>
                      </div>
                      <div class="col">
                        <div class="txt-label">Toevoeging</div>
                        <div class="inputs">
                          <input-text v-model="person.affix"></input-text>
                        </div>
                      </div>
                    </div>

                    <div class="row-13-b text-input">
                      <div class="col">
                        <div class="txt-label">Postcode</div>
                        <div class="inputs">
                          <input-text v-model="person.postCode"></input-text>
                        </div>
                      </div>
                      <div class="col">
                        <div class="txt-label">Plaats</div>
                        <div class="inputs">
                          <input-text v-model="person.city"></input-text>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="exp-item-text" v-if="person.married == true">
                  <div class="sep-40"></div>

                  <div class="exp-item-subtitle">Gegevens van uw partner</div>

                  <div class="sep-30"></div>

                  <div class="exp-item-body">
                    <div class="row-112-b text-input">
                      <div class="col">
                        <div class="txt-label">Voorletter(s)</div>
                        <div class="inputs">
                          <input-text v-model="person.partnerFirstName"></input-text>
                        </div>
                      </div>
                      <div class="col">
                        <div class="txt-label">Tussenvoegsel</div>
                        <div class="inputs">
                          <input-text v-model="person.partnerMiddleName"></input-text>
                        </div>
                      </div>
                      <div class="col">
                        <div class="txt-label">Achternaam</div>
                        <div class="inputs">
                          <input-text v-model="person.partnerLastName"></input-text>
                        </div>
                      </div>
                    </div>

                    <div class="row-fw-b radio-input">
                      <div class="col">
                        <div class="txt-label">Geslacht</div>
                        <div class="inputs">
                          <input-radio v-model="person.partnerGender">
                            <span slot="option1">Man</span>
                            <span slot="option2">Vrouw</span>
                          </input-radio>
                        </div>
                      </div>
                    </div>

                    <div class="row-fw-b date-input">
                      <div class="col">
                        <div class="txt-label">Geboortedatum</div>
                        <div class="inputs">
                          <input-date v-model="person.partnerBirthDate"></input-date>
                        </div>
                      </div>
                    </div>

                    <div class="row-11-a text-input">
                      <div class="txt-label">
                        <span>Wat is het netto inkomen per maand van uw partner?</span>
                      </div>
                      <div class="inputs">

                        <input-num v-model="person.partnerIncome"></input-num>
                      </div>
                    </div>

                    <div class="row-1-15-a select-input">
                      <div class="txt-label">
                        <span>Wat is uw inkomstenbron?</span>
                      </div>
                      <div class="inputs align-right">
                        <input-dropdown
                          class="input-dropdown-300"
                          :options="$store.state.incomeSourceDropdown"
                          v-model="person.partnerIncomeSource"
                        ></input-dropdown>
                      </div>
                      <div class="info-container">
                        <div class="info-icon" @click="toggleModal">
                          <div class="bg">
                            <img src="@/assets/info.png" />
                          </div>
                        </div>

                        <div class="modal-container" v-show="$store.state.modal">
                          <modal></modal>
                        </div>
                      </div>
                    </div>

                    <div class="row-1-15-a date-input">
                      <div class="txt-label">
                        <span>Datum in dienst?</span>
                      </div>
                      <div class="inputs align-right">
                        <input-date v-model="person.partnerDateService"></input-date>
                      </div>
                    </div>


                  </div>
                </div>

                <div class="exp-item-text">
                  <div class="sep-60"></div>

                  <btn-big
                    class="next secondary-pink"
                    :width="240"
                    :height="80"
                    v-show="$store.state.formsOfferte.length > 0 ? !$store.state.formsOfferte[4].expand : false"
                  >
                    <div class="content">
                      <div class="txt">Volgende</div>
                      <img src="@/assets/arrow.svg">
                    </div>
                  </btn-big>

                  <div class="sep-60"></div>
                </div>
              </div>
            </div>

            <div class="sep-30"></div>

            <!--ITEM 5 ////////////////////////////////////////// -->
            <div id="form-error-tab5"></div>
            <div
              class="exp-item item5"
              :class="{ expanded: $store.state.formsOfferte.length > 0 ? $store.state.formsOfferte[4].expand : false }"
              v-registerForm="tabs.tab5.state == false || ($store.state.storedData == true && tabs.tab5.state == undefined)"
            >
              <div :class="{ formcheck: tabs.tab5.state == false }">
                <div
                  class="exp-item-title-container"
                  :class="{ valid: tabs.tab5.state == true }"
                >
                  <div class="left">
                    <span>{{ getTabs }}. Uw contactgegevens</span>
                  </div>

                  <div class="right">
                    <div class="arrow"></div>
                  </div>
                </div>

                <div class="exp-item-text">
                  <div
                    class="error"
                    v-show="tabs.tab5.state == false"
                    style="white-space: pre-line"
                  >{{ $store.state.tabErrors.tab5 }}</div>

                  <div class="sep-40" v-show="tabs.tab5.state == false"></div>

                  <div class="sep-30"></div>

                  <div class="exp-item-body">
                    <div class="row-11-b">
                      <div class="col">
                        <div class="txt-label">E-mail</div>
                        <div class="inputs">
                          <input-text v-model="person.emailAddress"></input-text>
                        </div>
                      </div>
                      <div class="col"></div>
                    </div>

                    <div class="row-11-b">
                      <div class="col">
                        <div class="txt-label">Vaste telefoonnummer (Optioneel)</div>
                        <div class="inputs">
                          <input-text v-model="person.fixedPhoneNumber"></input-text>
                        </div>
                      </div>
                      <div class="col">
                        <div class="optioneel">
                          <!--<span>Optioneel</span>-->
                        </div>
                      </div>
                    </div>

                    <div class="row-11-b">
                      <div class="col">
                        <div class="txt-label">Mobiele telefoonnummer</div>
                        <div class="inputs">
                          <input-text v-model="person.mobilePhoneNumber"></input-text>
                        </div>
                      </div>
                      <div class="col"></div>
                    </div>

                    <div class="row-fw-b textarea-input">
                      <div class="col">
                        <div class="txt-label">Opmerkingen (Optioneel)</div>
                        <div class="inputs">
                          <textarea v-model="person.comments"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="sep-90"></div>
                </div>
              </div>
            </div>

            <div class="sep-50"></div>

            <btn-big class="primary" :width="393" :height="92" :click="submitForm">
              <div class="content">
                <div class="txt">Formulier verzenden</div>
                <img src="@/assets/arrow.svg" alt />
              </div>
            </btn-big>


            <div class="agreement">Door dit formulier te verzenden gaat u akkoord met het privacyreglement van Leenattent.</div>
            <div class="sep-30"></div>
          </div>

          <form-message v-else-if="$store.state.formSent == true" :option="'OFFERTE'"></form-message>

          <div class="sep-90"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 2em;
@gridColGap: 1.25em;
@gridColHalfGap: @gridColGap * 0.5;

.area {
  background-color: getColor("greyLight");

  .layout {
    max-width: 1200px;

    .section {
      .input-boolean {
        width: pxToEm(180);
      }

      .input-date {
        width: pxToEm(300);
      }

      .input-dropdown-180 {
        width: pxToEm(180);
      }

      .input-dropdown-300 {
        width: pxToEm(300);
      }

      .input-radio {
        width: pxToEm(320);
      }

      .input-text {
        height: pxToEm(60);
      }

      .optioneel {
        margin-top: pxToEm(35);
      }

      textarea {
        width: 100%;
        height: pxToEm(210);
        text-align: left;
        font-size: pxToPercent(18);
        background-color: getColor("whiteColor");
        border: @border-width solid getColor("grayLtColor");
        resize: none;
      }

      .txt-label {
        font-size: pxToPercent(18);
        font-weight: 300;
        line-height: 2.2em;
      }

      .title {
        font-size: pxToPercent(36);
        font-weight: 700;
        max-width: 600px;
        align-self: center;
        margin-bottom: pxToEm(15);
      }

      .subtitle {
        font-size: pxToPercent(24);
        font-weight: 300;
        letter-spacing: 0.05em;
        margin-bottom: pxToEm(35);
      }

      .container {
        max-width: pxToEm(992);
        position: relative;
        margin: 0 auto;

        .expandable-items {
          min-width: pxToEm(800);
          text-align: left;

          .exp-item {
            height: pxToEm(100);
            background-color: getColor("whiteColor");
            box-shadow: pxToEm(1) pxToEm(1) pxToEm(10) rgba(0, 0, 0, 0.2);

            &.expanded {
              height: auto;

              .exp-item-title-container {
                .right {
                  .arrow {
                    transform: rotate(-135deg);
                  }
                }
              }
            }

            .exp-item-subtitle {
              font-size: pxToPercent(24);
            }

            .exp-item-title-container {
              height: pxToEm(100);
              padding: 0 pxToEm(50);
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;

              &.valid {
                color: getColor("greenDefault");
              }

              .left {
                height: 100%;
                flex: 2 1 auto;
                display: flex;
                align-items: center;
                font-size: pxToPercent(24);
              }

              .right {
                width: pxToEm(100);
                height: pxToEm(100);
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .arrow {
                  position: relative;
                  border: solid black;
                  border-width: 0 pxToEm(2) pxToEm(2) 0;
                  padding: 5px;
                  transform: rotate(45deg);
                }
              }
            }

            .error {
              color: getColor("redColor");
              font-style: italic;
            }

            .exp-item-text {
              padding: 0 pxToEm(100);

              .exp-item-body {
                display: grid;
                grid-row-gap: @gridRowGap;

                .row-11-a {
                  height: pxToEm(70);
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-column-gap: @gridColGap;
                  align-items: center;
                }

                .row-1-15-a {
                  height: pxToEm(70);
                  display: grid;
                  grid-template-columns: 1fr 1.5fr;
                  grid-column-gap: @gridColGap;
                  align-items: center;

                  .align-right {
                    justify-self: end;
                  }

                  .info-container {
                    position: absolute;
                    right: pxToEm(45);
                    margin-top: pxToEm(14);

                    .info-icon {
                      .bg {
                        width: pxToEm(40);
                        height: pxToEm(40);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: getColor("grayLtColor");
                        border-radius: 50%;
                        cursor: pointer;
                      }

                      &:hover {
                        .bg {
                          background-color: getColor("primaryLight");
                        }
                      }
                    }
                  }
                }

                .row-11-b {
                  height: pxToEm(100);
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  align-items: center;

                  .col {
                    &:first-child {
                      margin-right: @gridColHalfGap;
                    }

                    &:last-child {
                      margin-left: @gridColHalfGap;
                      margin-right: 0;
                    }
                  }

                  .input-text-300 {
                    width: pxToEm(296);
                  }
                }

                .row-13-b {
                  height: pxToEm(100);
                  display: grid;
                  grid-template-columns: 1fr 3fr;
                  align-items: center;

                  .col {
                    margin-right: @gridColGap;

                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }

                .row-112-b {
                  height: pxToEm(100);
                  display: grid;
                  grid-template-columns: 1fr 1fr 2fr;
                  align-items: flex-start;

                  .col {
                    &:nth-child(1) {
                      margin-right: @gridColGap;
                    }

                    &:nth-child(2) {
                      margin-right: @gridColHalfGap;
                    }

                    &:nth-last-child(1) {
                      margin-left: @gridColHalfGap;
                      margin-right: 0;
                    }
                  }
                }

                .row-211-b {
                  height: pxToEm(100);
                  display: grid;
                  grid-template-columns: 2fr 1fr 1fr;

                  .col {
                    &:nth-child(1) {
                      margin-right: @gridColHalfGap;
                    }

                    &:nth-last-child(2) {
                      margin-left: @gridColHalfGap;
                      margin-right: @gridColHalfGap;
                    }

                    &:nth-last-child(1) {
                      margin-left: @gridColHalfGap;
                      margin-right: 0;
                    }
                  }
                }

                .row-fw-b {
                  height: pxToEm(100);

                  &.radio-input {
                    height: pxToEm(80);
                  }

                  &.textarea-input {
                    height: pxToEm(280);
                  }
                }

                .radio-input {
                  position: relative;
                }

                .slider-input {
                  .inputs {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: @gridColGap;
                    align-items: center;
                  }
                }
              }

              .loans {
                .input-num {
                  width: pxToEm(180);
                }

                .row-sub-title {
                  height: pxToEm(70);
                  display: grid;
                  align-items: center;

                  .sub-title {
                    font-size: pxToPercent(24);
                  }
                }
              }
            }
          }

          .agreement {
            width: pxToEm(393);
            margin-left: auto;
            line-height: 2.1em;
          }

          .btn-big {
            margin-left: auto;

            .content {
              margin-left: pxToEm(30);
              margin-right: pxToEm(30);

              .txt {
                font-size: pxToPercent(20);
                font-weight: 700;
              }

              img {
                width: pxToEm(48);
                height: pxToEm(38);
              }
            }
          }
        }
      }

      .modal-container {
        position: absolute;
        z-index: 800;
      }
    }
  }
}
</style>

<script>
import inputSlider from "@/components/common/input-slider";
import inputDate from "@/components/common/input-date";
import inputNum from "@/components/common/input-num";
import inputBoolean from "@/components/common/input-boolean";
import btnBig from "@/components/common/btn-big";
import inputDropdown from "@/components/common/input-dropdown";
import inputText from "@/components/common/input-text";
import inputRadio from "@/components/common/input-radio";
import modal from "@/components/desktop/info/modal";
import formMessage from "@/components/common/form-message";
import { _person, _loanRequest, _prevLoan } from "@/data/data";
import { goToAnchor, checkTab } from "@/JS/helpers";
import { collectValidateSend, XMLtoMail, mails } from "@/JS/mail";
import Api from "@/Api";
import { DoorlopendType, PersoonlijkeType } from "@/data/loanTypes";
import {mapState} from "vuex";

export default {
  name: "offerteExpItems",
  components: {
    inputSlider,
    inputNum,
    inputBoolean,
    btnBig,
    inputDropdown,
    inputText,
    inputDate,
    inputRadio,
    modal,
    formMessage
  },
  data() {
    var data = {
      person: new _person(),
      loanRequest: new _loanRequest(),
      prevLoan: new _prevLoan(),
      tabs: {},
      loanInfo: [
        {
          title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Totaalprijs"],
          data: [new PersoonlijkeType(36), new PersoonlijkeType(60), new PersoonlijkeType(96), new PersoonlijkeType(120), new PersoonlijkeType(180)],
          months: ["36 maanden", "60 maanden", "96 maanden", "120 maanden", "180 maanden"]
        },
        {
          title: ["Mogelijkheden", "Maandlast", "Effectieve jaarrente", "Nominale maandrente", "Theoretische looptijd", "Totaalprijs"],
          data: [new DoorlopendType(56), new DoorlopendType(77), new DoorlopendType(126)],
          months: ["56 maanden", "77 maanden", "126 maanden"]
        }
      ],
      showSelectedMonths: window.document.querySelectorAll('.area .layout .black-area .black-section').length > 0
    };
    return data;
  },
  mounted() {
    this.showSelectedMonths = window.document.querySelectorAll('.area .layout .black-area .black-section').length > 0;
  },
  created() {
    Api.get(`percentage-section`).then((response) => {
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.attributes
      ) {
        this.percentageSection = response.data.data.attributes;
      }
    });
    this.saveAmount = [];

    if ($store.state.storedData === true) {
      Object.assign(this.loanRequest, $store.state._loanRequest);
      Object.assign(this.prevLoan, $store.state._prevLoan);
      if ($store.state._loanRequest.loanOption != $store.state.funnelBOption)
        Object.assign(this.person, $store.state._person);
    }

    this.tabs = {
      tab1: {
        label: "tab1",
        origin: this.loanRequest,
        fields: ["loanOption", "approvedLoanAmount", "loanType", "months"],
        id: ".exp-item.item1",
        state: undefined
      },
      tab2: {
        label: "tab2",
        origin: this.loanRequest,
        fields: ["prevLoans"],
        id: ".exp-item.item2",
        state: undefined
      },
      tab3: {
        label: "tab3",
        origin: this.person,
        fields: [
          "married",
          "livingState",
          "children",
          "monthlyExpenses",
          "income",
          "incomeSource",
          "dateService"
        ],
        id: ".exp-item.item3",
        state: undefined
      },
      tab4: {
        label: "tab4",
        origin: this.person,
        fields: [
          "firstName",
          "middleName",
          "lastName",
          "gender",
          "birthDate",
          "streetName",
          "houseNumber",
          "affix",
          "postCode",
          "city",
          "partnerFirstName",
          "partnerMiddleName",
          "partnerLastName",
          "partnerGender",
          "partnerBirthDate",
          "partnerIncome",
          "partnerIncomeSource",
          "partnerDateService"
        ],
        id: ".exp-item.item4",
        state: undefined
      },
      tab5: {
        label: "tab5",
        origin: this.person,
        fields: [
          "emailAddress",
          "fixedPhoneNumber",
          "mobilePhoneNumber",
          "comments"
        ],
        id: ".exp-item.item5",
        state: undefined
      }
    };

    this.$root.$on('months_select_event', (loanType, monthsStr) => {
      const monthIndex = this.loanInfo[$store.state._loanRequest.loanTypeTable].months.indexOf(monthsStr);
      this.loanRequest.loanType = $store.state._loanRequest.loanTypeTable;
      this.loanRequest.months = monthIndex;
    })

    this.loanRequest.loanType = $store.state._loanRequest.loanTypeTable;
    this.loanRequest.months = this.loanInfo[$store.state._loanRequest.loanTypeTable].months.indexOf($store.state.selectedMonths);
  },
  beforeDestroy() {
    $store.state.formSent = false;
    // $store.changeFormSent(false);

    $store.state.formsOfferte = [];
  },
  computed: {
    Funnel: {
      get() {
        var loanRequest = this.loanRequest;
        if (loanRequest.loanOption == $store.state.funnelBOption)
          loanRequest.selectedFunnel = $store.state.funnel.B;
        else
          loanRequest.selectedFunnel = $store.state.funnel.A;

        return loanRequest.loanOption;
      },
      set(val) {
        // alert(val)
        var loanRequest = this.loanRequest;
        loanRequest.loanOption = val;
      }
    },
    Loans: {
      get() {
        var loanRequest = this.loanRequest;
        var res = new Number(loanRequest.prevLoans.length);
        res.ui = loanRequest.prevLoans.ui;
        return res;
      },
      set(n) {
        var loanRequest = this.loanRequest;
        if (n != loanRequest.prevLoans.length) {
          if (n > 0) {
            loanRequest.prevLoans.ui.init = true;
            loanRequest.prevLoans.ui.valid = true;
          } else {
            loanRequest.prevLoans.ui.valid = false;
          }
          if (n > loanRequest.prevLoans.length) {
            for (var i = loanRequest.prevLoans.length; i < n; i++) {
              loanRequest.prevLoans.push(
                $store.state.prevLoanTemplate(false)
              );
            }
          } else loanRequest.prevLoans.splice(n);
        }
      }
    },
    LoanType: {
      get() {
        var loanRequest = this.loanRequest;
        return loanRequest.loanType;
      },
      set(val){
        // alert(val);
        var loanRequest = this.loanRequest;
        this.loanRequest.loanType = $store.state._loanRequest.loanTypeTable;
      }
    },
    SelectedMonths: {
      get() {
        var loanRequest = this.loanRequest;
        return loanRequest.months;
      },
      set(val){
        // alert(val);
        var loanRequest = this.loanRequest;
        this.loanRequest.months = this.loanInfo[$store.state._loanRequest.loanTypeTable].months.indexOf($store.state.selectedMonths);
      }
    },
    getTabs() {
      if (this.loanRequest.selectedFunnel == $store.state.funnel.B)
        return 5;
      else return 4;
    }
  },
  watch: {
    "person.married": function(newVal, oldVal) {
      // *** THIS NEEDS A BETTER, CLEANER CODING SOLUTION ***
      if (this.person.married == true) {
        var tab4 = this.tabs.tab4;
        var re = /partner/;
        for (var field of tab4.fields) {
          if (re.test(field) == true && (field != "partnerMiddleName")) {
            tab4.origin[field].ui.valid = false;
          }
          if (re.test(field) == true && field == "partnerIncome") {
            if (tab4.origin[field] == 0) {
              tab4.origin[field].ui.init = false;
            } else if (tab4.origin[field] > 0) {
              tab4.origin[field].ui.init = true;
              tab4.origin[field].ui.valid = true;
            }
          }
        }
      }
    }
  },
  methods: {
    submitForm() {
      this.loanRequest.loanType = $store.state._loanRequest.loanTypeTable;
      collectValidateSend($store, this.$route, this.tabs, this.loanRequest.prevLoans, checkTab, this.loanRequest, this.showSelectedMonths);
    },
    toggleModal(event) {
      event.stopPropagation();
      $store.state.modal = !$store.state.modal;
    },
    calculateSaving(amount, rate, name, index){
      let totalSaving = 0;
      this.saveAmount[index] = "";
      name = name.toString();
      if(!name || name === ""){
        name = "de eerdere aanbieder";
      }

      if(this.percentageSection){
        if(amount >= 50000 && (rate > this.percentageSection.Personal_loan_percentage_50000)){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_50000)/100) * amount;
        } else if(amount >= 25000 && (rate > this.percentageSection.Personal_loan_percentage_25000)){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_25000)/100) * amount;
        } else if(amount >= 15000 && (rate > this.percentageSection.Personal_loan_percentage_15000)){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_15000)/100) * amount;
        } else if(amount >= 10000 && (rate > this.percentageSection.Personal_loan_percentage_10000)){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_10000)/100) * amount;
        } else if(rate > this.percentageSection.Personal_loan_percentage_0){
          totalSaving = ((rate - this.percentageSection.Personal_loan_percentage_0)/100) * amount;
        }
      }

      if(totalSaving > 0){
        this.saveAmount[index] = "U bespaart € " + totalSaving.toLocaleString('nl-NL', { maximumFractionDigits: 2 }) + " als u overstapt naar Leenattent van " + name;
      }
    }
  },
  // mounted: function () {
  //   this.$root.$on('months_select_event', (loanType, monthsStr) => {
  //     this.loanRequest.loanType = loanType; // $store.state._loanRequest.loanTypeTable;
  //     this.loanRequest.months = this.loanInfo[loanType].months.indexOf(monthsStr) // this.loanInfo[$store.state._loanRequest.loanTypeTable].months.indexOf($store.state.selectedMonths);
  //   })
  // }
};
</script>
