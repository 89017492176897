<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="texts">
            <div class="txt">
              <div class="title">Herstelcode biedt mogelijkheden</div>

              <div class="body">
                Een achterstand op lopende leningen dient uiteraard eerst ingelopen te worden, voordat het op basis
                van een negatieve BKR registratie opnieuw mogelijk is om geld te lenen. Dit gebeurt om uzelf te
                beschermen tegen een te hoog oplopende maandtermijn. Zodra u de huidige lening heeft ingelopen is
                het van belang om een herstelcode (H) aan te laten brengen door de instantie die om de negatieve
                BKR registratie heeft gevraagd. Zodra die herstelcode er is kunnen we de volgende stap voorwaarts zetten.
              </div>
            </div>

            <div class="txt">
              <div class="subtitle">Lage rente voor lening na negatieve bkr registratie</div>

              <div class="body">
                Zelf bij een (oude) negatieve BKR registratie is het mogelijk om tegen een lage rente relatief
                voordelig geld te lenen. Om financiële problemen nu en in de toekomst te voorkomen, kijken we
                goed naar uw huidige en toekomstige situatie. Dat biedt de geldverstrekker meer zekerheid en
                voorkomt dat u opnieuw te maken zal krijgen met een negatieve BKR registratie. Onze adviseurs
                hebben een ruime ervaring met dergelijke gevallen. Daardoor kunnen wij er goed mee helpen. Ons
                advies is volledig kosteloos en de offerte is vrijblijvend, zodat u nergens direct aan vast
                zit. Neem contact met ons op voor meer informatie. Dat kan door online een aanvraag te doen,
                een livechat te starten of telefonisch contact op te nemen. Onze adviseurs staan voor u klaar.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor("greyLight");

    .layout {
      .section {
        margin: pxToEm(25) pxToEm(10);
        text-align: left;

        .container-a {
          padding: pxToEm(80) pxToEm(25) 0;

          .texts {
            .txt {
              margin-bottom: pxToEm(40);

              .title {
                margin-bottom: pxToEm(10);
                font-size: pxToPercent(24);
                line-height: 1em;
                font-weight: 700;
                text-decoration: underline;
              }

              .subtitle {
                margin-bottom: pxToEm(10);
                font-size: pxToPercent(20);
                font-weight: 700;
              }

              .body {
                font-size: pxToPercent(16);
                line-height: 2.1em;
              }
            }

            & :last-child {
              margin-bottom: pxToEm(25);
            }
          }
        }
      }
    }
  }
</style>

<script>
  export default {
    name: "bkrBody"
  };
</script>
