<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div>
      <div class="layout">
        <div class="section">
          <div id="anchor-buttons"></div>
          <div class="title">
            <div class="txt" v-if="block.Title">{{ block.Title }}</div>
          </div>

          <div class="body">
            <div class="txt" v-if="block.Subtitle">{{ block.Subtitle }}</div>
          </div>

          <div id="form-top"></div>

          <div class="btn-content">
            <!--- block.content -->
            <div v-for="(item, index) in block.Buttons" :key="index">
              <btn-big
                :class="item.url ? 'primary new-client' : 'secondary'"
                :width="260"
                :height="107"
                :click="() => handleClick(item.url)"
              >
                <div class="content">
                  <img
                    :class="{ 'rotate-90': !item.url }"
                    src="@/assets/arrow.svg"
                    alt
                  />
                  <div class="txt">{{ item.label }}</div>
                </div>
              </btn-big>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="downloads" v-if="blockStatus">
      <div class="title">
        <div class="txt">Brochure downloaden</div>
      </div>

      <div class="subtitle">
        Onderstaande PDF brochures geven u inzicht in onze huidige manier van
        samenwerken.
      </div>

      <div class="brochures">
        <div
          class="brochure"
          v-for="(brochure, i) in block.Brochures"
          :key="brochure.id"
        >
          <img src="@/assets/brochure.svg" />
          <div class="txt">
            <span class="b-title">{{ brochure.Title }}</span>
            <span class="b-subtitle">{{ brochure.Description }}</span>
            <a
              class="b-link"
              target="_blank"
              v-if="
                brochure.File &&
                brochure.File.data &&
                brochure.File.data.attributes &&
                brochure.File.data.attributes.url
              "
              :href="strapiAssetUrl(brochure.File.data.attributes.url)"
            >
              PDF bestand |
              {{
                brochure.File.data.attributes.size
                  ? parseInt(brochure.File.data.attributes.size) + " Kb"
                  : ""
              }}
            </a>
          </div>
        </div>
      </div>

      <div
        class="white-button"
        @click="toggleDiv(false)"
        v-anchor="'#anchor-buttons'"
      >
        <div class="arrow-cont">
          <div class="arrow"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: 2px;
@gridRowGap: 2em;
@gridColGap: 1.25em;
@gridColHalfGap: @gridColGap * 0.5;

.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .section {
      width: 100%;
      max-width: 1200px;
      margin: 0 pxToEm(50);
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-top: pxToEm(90);
        margin-bottom: pxToEm(10);

        .txt {
          font-size: pxToPercent(36);
          font-weight: 700;
        }
      }

      .body {
        max-width: pxToEm(700);

        .txt {
          font-size: pxToPercent(18);
          line-height: 2.3em;
        }
      }

      .btn-content {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        padding: pxToEm(50) 0 pxToEm(100) 0;
        width: pxToEm(800);

        a {
          text-decoration: none;
        }
        .btn-big {
          border-bottom-width: pxToEm(18);
          width: pxToEm(400);
          height: pxToEm(104);
          text-align: left;
          &.new-client {
            margin: 0 auto;
          }
          .content {
            margin-left: pxToEm(30);
            margin-right: pxToEm(30);
            .rotate-90 {
              transform: rotate(90deg);
            }
            .txt {
              font-size: pxToPercent(20);
              font-weight: 700;
            }

            img {
              width: pxToEm(48);
              height: pxToEm(38);
              margin-right: pxToEm(15);
            }
          }
        }
      }
    }
  }

  .downloads {
    background-color: #fff;
    margin-top: pxToEm(-150);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: pxToEm(100);

    .title {
      margin-top: pxToEm(120);

      .txt {
        font-size: pxToPercent(60);
        font-weight: 300;
      }
    }

    .subtitle {
      margin-top: pxToEm(25);

      .txt {
        font-size: pxToPercent(18);
      }
    }

    .brochures {
      max-width: 1000px;
      width: 100%;
      margin-top: pxToEm(70);
      flex-wrap: wrap;
      display: flex;

      .brochure {
        flex: 1 1 auto;
        display: flex;
        text-align: left;
        margin-top: pxToEm(40);
        width: 50%;

        img {
          width: pxToEm(179);
          height: pxToEm(132);
        }

        .txt {
          line-height: 2.1em;
          margin-left: pxToEm(20);
          display: flex;
          flex-direction: column;
          max-width: 250px;
          justify-content: space-between;

          .b-title {
            font-weight: 700;
          }

          .b-subtitle {
            font-weight: 300;
          }

          .b-link {
            text-decoration: none;
            color: getColor("primaryDefault");
            cursor: pointer;
          }
        }
      }
    }

    .white-button {
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      width: pxToEm(50);
      height: pxToEm(50);
      position: absolute;
      bottom: -125px;
      z-index: 100;
      align-self: center;
      cursor: pointer;

      .arrow-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .arrow {
          border: solid getColor("primaryLight");
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 5px;
          transform: rotate(-135deg);
          position: relative;
          margin-top: 5px;
        }
      }
    }
  }

  .form {
    background-color: getColor("whiteColor");
    margin-top: pxToEm(-150);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: pxToEm(150) 0 pxToEm(100);

    .error {
      color: getColor("redColor");
      font-style: italic;
    }

    .form-title {
      margin-top: pxToEm(60);

      .txt {
        font-size: pxToEm(60);
        font-weight: 300;
      }
    }

    .content {
      max-width: 766px;
      width: 100%;
      display: grid;
      grid-row-gap: @gridRowGap;
      text-align: left;

      .input-radio {
        width: pxToEm(320);
      }

      .input-text {
        height: pxToEm(60);
      }

      textarea {
        width: 100%;
        height: pxToEm(210);
        text-align: left;
        font-size: pxToPercent(18);
        background-color: getColor("whiteColor");
        border: @border-width solid getColor("grayLtColor");
        resize: none;
      }

      .txt-label {
        font-size: pxToPercent(18);
        font-weight: 300;
        line-height: 2.2em;
      }

      .row-11-b {
        height: pxToEm(100);
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        .col {
          &:first-child {
            margin-right: @gridColHalfGap;
          }

          &:last-child {
            margin-left: @gridColHalfGap;
            margin-right: 0;
          }
        }

        .input-text-300 {
          width: pxToEm(296);
        }
      }

      .row-13-b {
        height: pxToEm(100);
        display: grid;
        grid-template-columns: 1fr 3fr;
        align-items: center;

        .col {
          margin-right: @gridColGap;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .row-112-b {
        height: pxToEm(100);
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        align-items: flex-start;

        .col {
          &:nth-child(1) {
            margin-right: @gridColGap;
          }

          &:nth-child(2) {
            margin-right: @gridColHalfGap;
          }

          &:nth-last-child(1) {
            margin-left: @gridColHalfGap;
            margin-right: 0;
          }
        }
      }

      .row-211-b {
        height: pxToEm(100);
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;

        .col {
          &:nth-child(1) {
            margin-right: @gridColHalfGap;
          }

          &:nth-last-child(2) {
            margin-left: @gridColHalfGap;
            margin-right: @gridColHalfGap;
          }

          &:nth-last-child(1) {
            margin-left: @gridColHalfGap;
            margin-right: 0;
          }
        }
      }

      .row-fw-b {
        height: pxToEm(100);

        &.radio-input {
          height: pxToEm(80);
        }

        &.textarea-input {
          height: pxToEm(280);
        }
      }
    }

    .form-btn {
      margin: 0 auto;
      margin-top: pxToEm(70);

      .content {
        margin-left: pxToEm(30);
        margin-right: pxToEm(30);
        display: flex;
        flex-flow: row;

        .txt {
          font-size: pxToPercent(20);
          font-weight: 700;
        }

        img {
          width: pxToEm(48);
          height: pxToEm(38);
        }
      }
    }

    .form-message {
      margin: pxToEm(50) 0 pxToEm(70);
    }

    .white-button {
      width: pxToEm(50);
      height: pxToEm(50);
      position: absolute;
      left: 50%;
      bottom: pxToEm(-25);
      transform: translateX(-50%);
      align-self: center;
      border-radius: 50%;
      background-color: getColor("whiteColor");
      box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
      z-index: 100;
      cursor: pointer;

      .arrow-cont {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .arrow {
          display: inline-block;
          position: relative;
          transform: rotate(-135deg);
          margin-top: pxToEm(5);
          padding: pxToEm(5);
          border: solid getColor("primaryLight");
          border-width: 0 pxToEm(3) pxToEm(3) 0;
        }
      }
    }
  }
}
</style>

<script>
import { marked } from "marked";
import headerImg from "@/assets/about-second-header.jpg";
import inputDropdown from "@/components/common/input-dropdown";
import btnBig from "@/components/common/btn-big";
import inputRadio from "@/components/common/input-radio";
import inputText from "@/components/common/input-text";
import formMessage from "@/components/common/form-message";
import { _form0 } from "@/data/aboutData";
import { checkTab, goToAnchor } from "@/JS/helpers";
import { dataToMail, mails } from "@/JS/mail";
import { strapiAssetUrl } from "@/JS/helpers";

export default {
  name: "DesktopWorkWithUsSectionBlock",
  props: {
    block: Object,
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path);
    },
    markdownToHTML: function (markdown) {
      return marked(markdown);
    },
    handleClick: function (item, index) {
      if (item) {
        this.$router.push(item);
      } else {
        this.toggleDiv(true);
        goToAnchor("#form-top");
      }
    },
    collapseForm() {
      this.dropdown1value = null;
    },
    toggleDiv(val) {
      this.blockStatus = val;
    },
    collapseForm() {
      this.dropdown1value = null;
    },
    submitForm() {
      this.form0.state = checkTab(this.form0.fields);
      if (this.form0.state == false) {
        goToAnchor("#form-error");
      } else {
        dataToMail({
          mailTo: mails.info,
          mailSubject: "About Leenattent",
          mailData: this.form0.fields,
        })
          .then((res) => {
            //CODE FOR MAIL SENT /////////////
            this.form0.fields = new _form0();
            this.form0.state = undefined;
            this.formSent = true;
            goToAnchor("#form-top");
            // console.log(res);
          })
          .catch((rej) => {
            //CODE FOR MAIL NOT SENT /////////////
            this.formSent = false;
            // console.log(rej);
          });
      }
    },
  },
  components: {
    btnBig,
    inputDropdown,
    inputRadio,
    inputText,
    formMessage,
  },
  data() {
    return {
      blockStatus: false,
      form0: {
        fields: new _form0(),
        state: undefined,
      },
      formSent: false,
    };
  },
};
</script>
