<template>
  <div
    class="input-text input"
    :class="{
      disabled: ui.disabled == true ? true : false,
      initialized: ui.init == true || hasFocus == true ? true : false,
      invalid: ui.valid != true ? true : false
    }"
  >
    <input
      class="input-txt"
      type="text"
      ref="input"
      :disabled="ui.disabled"
      :placeholder="ui.placeholder"
      :value="value"
      :minlength="ui.minLen"
      :maxlength="ui.maxLen"
      @focus="onFocus"
      @keypress="onKeyPress($event)"
      @blur="onBlur"
    >
  </div>
</template>

<style lang="less" scoped>
@border-width: pxToEm(2);

.input-text {
  display: flex;
  overflow: hidden;
  border: @border-width solid getColor("grayLtColor");
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: #fff;

  .input-txt {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0 1.25em;
    color: getColor("grayLtColor");
    border: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: getColor("grayLtColor");
    }
  }

  &.invalid {
    .formcheck &{
      border: @border-width solid getColor("redColor");
    }
  }

  &.initialized .input-txt {
    color: getColor("textColor");
  }

  &.disabled .input-txt {
    color: getColor("disabledText");
    background-color: getColor("disabledBg");
    cursor: default;
  }
}
</style>

<script>
export default {
  props: {
    disabled: Boolean,
    init: null,
    valid: null,
    rxInvalidChars: null,
    rxValidChars: null,
    minLen: {
      type: Number,
      default: null
    },
    maxLen: {
      type: Number,
      default: null
    },
    placeholder: String,
    validator: null,
    value: String
  },
  data: function() {
    return {
      ui: {
        disabled: false,
        init: false,
        valid: null
      },
      hasFocus: false
    };
  },
  created: function () {    
    Object.assignSet(this.ui, this.value != null ? this.value.ui : null, this._props);
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = true;
    }
  },
  beforeDestroy(){
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = false;
    }
  },
  watch:{
    value:function(newVal, oldVal){
      if (newVal != null && newVal)
        Object.assignSet(this.ui, this.value.ui);  
    }
  },
  methods: {
    pxToEm: function(val) {
      return val / $store.state.defaults.defaultFontSize + "em";
    },
    isEmpty: function() {
      this.ui.empty = this.value.length > 0 ? false : true;      
    },
    onFocus: function() {
      this.hasFocus = true;
    },
    onKeyPress: function(event) {
      var key = event.key;
      if (event.code == "Enter") {
        this.$refs.input.blur();
        event.preventDefault();
      }
      else if(this.ui.rxInvalidChars != null && this.ui.rxInvalidChars.test(key)) {
        event.preventDefault();
      }
      else if(this.ui.rxValidChars != null && !this.ui.rxValidChars.test(key)) {
        event.preventDefault();
      }
    },
    onBlur: function() {
      this.hasFocus = false;
      this.ui.init = false;
      this.ui.valid = false;
      var emitValue = this.$refs.input.value.trim();
      if (emitValue !== "") {
        this.ui.init = true;
        this.ui.valid = true;
        if(this.value == null || this.value != null && this.value.ui == null) {
          
          if(this.ui.validator)
            this.ui.valid = this.ui.validator(emitValue);
        }
      }      
      this.$emit("input", emitValue);
    }
  }
};
</script>