<template>
  <div
    class="input-slider"
    :class="{ disabled: ui.disabled == true ? true : false, initialized: ui.init == true ? true : false }"
    @touchstart.prevent.stop="interactionStart"
  >
    <div class="input-container" @mousedown.prevent.stop="interactionStart" @touchmove="moveThumb">
      <div
        class="track"
        :style="{
          height: pxToEm(ThumbDiameter())
        }"
      >
        <div
          class="inner-track"
          :style="{
            height: pxToEm(ThumbDiameter()),
            left: pxToEm(ThumbRadius()),
            right: pxToEm(ThumbRadius())
          }"
        >
          <div
            class="line line-bg"
            :style="{
              height: pxToEm(css.trackThickness),
            }"
          ></div>
          <div
            class="line line-fg"
            :style="{
              width: GetThumbPosX(value),
              height: pxToEm(css.trackThickness)
            }"
          ></div>
          <div
            class="thumb"
            :style="{
              width: pxToEm(ThumbDiameter()),
              height: pxToEm(ThumbDiameter()),
              left: GetThumbPosX(value),
              'margin-left': pxToEm(- ThumbRadius()),
              'border-width': pxToEm(css.thumbBorder),
              'box-shadow': '0 ' + pxToEm(ShadowOffset()) + ' ' + pxToEm(ShadowRadius()) + ' rgba(0, 0, 0, 0.2)'
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @border-width: pxToEm(2);

  .input-slider {
    display: flex;
    align-items: center;
    touch-action: none; // Edge prevent default page drag.
    -moz-appearance: none;
    -webkit-appearance: none;

    .input-container {
      width: 100%;
      position: relative;
      cursor: pointer;

      div {
        pointer-events: none;
      }

      .track {
        position: relative;
        display: flex;
        align-items: center;

        .inner-track {
          position: absolute;
          display: flex;
          align-items: center;

          .line {
            position: absolute;
          }

          .thumb {
            position: absolute;
            border-style: solid;
            border-radius: 50%;
          }
        }
      }
    }

    &.disabled .input-container {
      cursor: default;
    }

    &.primary {
      .line-bg {
        background-color: #d3d3d3;
        width: 100%;
      }

      .line-fg {
        background-color: getColor("grayLtColor");
      }

      .thumb {
        border-color: getColor("whiteColor");
        background-color: getColor("grayLtColor");
      }

      &.initialized {
        .line-fg {
          background-color: getColor("primaryLight");
        }

        .thumb {
          background-color: getColor("primaryLight");
        }
      }

      &.disabled {
        .line {
          background-color: getColor("grayLtColor");
        }

        .thumb {
          border-color: getColor("grayLtColor");
          background-color: getColor("disabledBg");
        }
      }
    }
  }
</style>

<script>
  export default {
    props: {
      disabled: { type: Boolean, default: null },
      init: { type: Boolean, default: null },
      valid: { type: Boolean, default: null },
      css: {},
      minVal: {
        type: Number,
        default: null
      },
      maxVal: {
        type: Number,
        default: null
      },
      step: {
        type: Number,
        default: 1
      },
      eIn: null,
      eOut: null,
      value: Number
    },
    data: function() {
      return {
        ui: {
          disabled: false,
          init: false,
          valid: null
        }
      };
    },
    created() {    
      if(this.value != null){
        if(this.value.ui)
          this.value.ui.visible = true;
      }
      Object.assignSet(this.ui, this.value != null ? this.value.ui : null, this._props);
    },
    mounted: function() {
      this.innerTrack = this.$el.querySelector(".inner-track");
      window.addEventListener("mousemove", this.moveThumb);
      window.addEventListener("mouseup", this.interactionStop);
      window.addEventListener("touchend", this.interactionStop);
      window.addEventListener("touchcancel", this.interactionStop);
    },
    beforeDestroy: function() {
      window.removeEventListener("mousemove", this.moveThumb);
      window.removeEventListener("mouseup", this.interactionStop);
      window.removeEventListener("touchend", this.interactionStop);
      window.removeEventListener("touchcancel", this.interactionStop);
      if(this.value != null){
        if(this.value.ui)
          this.value.ui.visible = false;
      }
    },
    watch: {
      value: function(newVal, oldVal) {
        if (newVal != null && newVal) Object.assignSet(this.ui, this.value.ui, this._props);
      }
    },
    computed: {
      GetThumbPosX: function() {
        return val => {
          var nval = (val - this.ui.minVal) / (this.ui.maxVal - this.ui.minVal);
          if (this.ui.eOut) nval = this.ui.eOut(nval);
          if (nval < 0) nval = 0;
          if (nval > 1) nval = 1;
          return nval * 100 + "%";
        };
      },
      ThumbDiameter: function() {
        return () => {
          return this.css.thumbSize + this.css.thumbBorder;
        };
      },
      ThumbRadius: function() {
        return () => {
          return 0.5 * this.ThumbDiameter();
        };
      },
      ShadowOffset: function() {
        return () => {
          return this.ui.disabled ? 0 : this.css.thumbShadowOffset;
        };
      },
      ShadowRadius: function() {
        return () => {
          return this.ui.disabled ? 0 : this.css.thumbShadowRadius;
        };
      }
    },
    methods: {
      pxToEm: function(val) {
        return val / $store.state.defaults.defaultFontSize + "em";
      },
      interactionStart: function(event) {
        if (!this.ui.disabled) {
          this.onInteraction = true;
          this.moveThumb(event);
        }
      },
      interactionStop: function(event) {
        if (!this.ui.disabled) {
          event.stopPropagation();
          this.onInteraction = false;
        }
      },
      stepRound: function(val, step) {
        return Number((Math.round(val / step) * step).toFixed(2));
      },
      moveThumb: function(event) {
        if (!this.onInteraction) return;
        var ibcr = this.innerTrack.getBoundingClientRect();
        var clientX = event.touches ? event.touches[0].clientX : event.clientX;
        var posx = clientX - ibcr.left;
        var nval = posx / ibcr.width;
        if (this.ui.eIn) nval = this.ui.eIn(nval);
        if (nval < 0) nval = 0;
        if (nval > 1) nval = 1;
        var value = this.ui.minVal + nval * (this.ui.maxVal - this.ui.minVal);
        value = this.ui.step ? this.stepRound(value, this.ui.step) : value;
        this.$emit("input", value);
        return value;
      }
    }
  };
</script>