<template>
  <div>
    <MobileCalculateSectionBlock :block="block" v-if="isMobile"></MobileCalculateSectionBlock>
    <DesktopCalculateLoadSectionBlock :block="block" v-if="!isMobile"></DesktopCalculateLoadSectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import MobileCalculateSectionBlock from "@/components/strapi/CalculateLoanSection/MobileCalculateLoanSectionBlock";
import DesktopCalculateLoadSectionBlock
  from "@/components/strapi/CalculateLoanSection/DesktopCalculateLoanSectionBlock";

export default {
  name: 'CalculateMaxLoanSectionBlock',
  components: {
    DesktopCalculateLoadSectionBlock,
    MobileCalculateSectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
