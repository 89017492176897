<template>
  <div
    class="input-boolean"
    :class="{ disabled: ui.disabled == true ? true : false, initialized: ui.init == true ? true : false, invalid: ui.valid == false ? true : false }"
  >
    <div
      :class="{ active: ui.init && (value == true ? true : false) }"
      @click="setState(true)"
    >
      <div class="txt">{{ texttrue }}</div>
    </div>
    <div
      :class="{ active: ui.init && (value == true ? false : true) }"
      @click="setState(false)"
    >
      <div class="txt">{{ textfalse }}</div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@border-width: pxToEm(2);

.input-boolean {
  display: flex;
  color: getColor("grayLtColor");
  background-color: getColor("whiteColor");
  border: @border-width solid getColor("grayLtColor");
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;

  > div {
    width: 50%;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      color: getColor("whiteColor");
      background-color: getColor("primaryLight");
    }

    &:first-child {
      border-right: @border-width solid getColor("grayLtColor");
    }
  }

  &.invalid {
    .formcheck & {
      border: @border-width solid getColor("redColor");
    }
  }

  &.initialized {
    color: getColor("textColor");
  }

  &.disabled {
    cursor: default;

    > div {
      color: getColor("disabledText");
      background-color: getColor("disabledBg");
    }
  }
}
</style>

<script>
export default {
  props: {
    disabled: null,
    init: null,
    valid: null,
    texttrue: {
      type: String,
      default: "Ja"
    },
    textfalse: {
      type: String,
      default: "Nee"
    },
    value: {
      type: Boolean,
      default: null
    }
  },
  data: function() {
    return {
      ui: {
        disabled: false,
        init: false,
        valid: null
      }
    };
  },
  created: function () {
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = true;
    }
    Object.assignSet(this.ui, this.value != null ? this.value.ui : null, this._props);
  },
  beforeDestroy(){
    if(this.value != null){
      if(this.value.ui)
        this.value.ui.visible = false;
    }
  },
  watch: {
    value: function (newVal, oldVal) {
       if (newVal != null && newVal.ui)
        Object.assignSet(this.ui, this.value.ui);
    }
  },
  methods: {
    pxToEm: function(val) {
      return val / $store.state.defaults.defaultFontSize + "em";
    },
    setState: function(bool) {
      this.$emit("input", bool);
    }
  }
};
</script>
