<template>
  <div class="black-area">
    <div class="black-layout">
      <div class="black-section">
        <div class="container-forms" v-if="$store.state._loanRequest.newLoan.interestSaving > 0">
           <div class="sep-60"></div>

          <div class="title">
            <div class="txt">
              Huidige leningen
            </div>
          </div>

          <div class="form">
            <div class="col num-input">
              <div class="txt-label">
                <span>Totale leenbedrag</span>
              </div>
              <div class="inputs">
                <input-num
                  v-model="$store.state._loanRequest.currentLoans.total"
                  readonly="true"
                ></input-num>
              </div>
            </div>

            <div class="col num-input">
              <div class="txt-label">
                <span>Gemiddelde rente</span>
              </div>
              <div class="inputs">
                <input-num
                  v-model="$store.state._loanRequest.currentLoans.averageInterest"
                  readonly="true"
                ></input-num>
              </div>
            </div>

            <div class="col num-input">
              <div class="txt-label">
                <span>Rentekosten per jaar</span>
              </div>
              <div class="inputs">
                <input-num
                  v-model="$store.state._loanRequest.currentLoans.interestYear"
                  readonly="true"
                ></input-num>
              </div>
            </div>
          </div>

          <div class="sep-100"></div>

          <div class="title">
            <div class="txt">
              Nieuwe lening
            </div>
          </div>

          <div class="form">
            <div class="col num-input">
              <div class="txt-label">
                <span>Totale leenbedrag</span>
              </div>
              <div class="inputs">
                <input-num
                  v-model="$store.state._loanRequest.newLoan.total"
                  readonly="true"
                ></input-num>
              </div>
            </div>

            <div class="col num-input">
              <div class="txt-label">
                <span>Effectieve jaarrente</span>
              </div>
              <div class="inputs">
                <input-num
                  v-model="$store.state._loanRequest.newLoan.effectiveAnnualInterest"
                  readonly="true"
                ></input-num>
              </div>
            </div>

            <div class="col num-input">
              <div class="txt-label">
                <span>Rentekosten per jaar</span>
              </div>
              <div class="inputs">
                <input-num
                  v-model="$store.state._loanRequest.newLoan.interestYear"
                  readonly="true"
                ></input-num>
              </div>
            </div>

            <div class="col num-input">
              <div class="txt-label green">
                <span>Rentebesparing in het eerste jaar</span>
              </div>
              <div class="inputs">
                <input-num
                  v-model="$store.state._loanRequest.newLoan.interestSaving"
                  readonly="true"
                ></input-num>
              </div>
            </div>
          </div>
        </div>

        <div class="txt-bottom">
          <div class="regular">De berekening laat zien hoeveel rente u <span class="text-green">per jaar</span> kunt besparen. U bent minder rente verschuldigd indien uw schuld ieder jaar lager wordt.</div>
        </div>

        <img
          class="arrow-svg"
          src="@/assets/arrow.svg"
          alt
          v-if="$store.state._loanRequest.newLoan.interestSaving > 0"
        />

        <div class="b-title" v-if="$store.state._loanRequest.newLoan.interestSaving > 0">
          <div class="txt">
            U bespaart
            <span class="green">{{ parseFloat(totalSaving).toLocaleString('nl-NL', { maximumFractionDigits: 2 }) | currency('€', 0) }}</span> via Leenattent.
          </div>
        </div>

        <div class="b-title" v-else>
          <div class="txt">
            Helaas, u kunt geen lening oversluiten via Leenattent. Neem voor meer informatie contact met ons op.
          </div>
        </div>

        <div class="container-b">
          <div id="anchor-white-squares"></div>

          <div class="sep-30"></div>

          <div class="white-square">
            <div class="green-label">
              <span>Advies van Leenattent</span>
            </div>

            <div class="title">
              <div class="txt green">optie 1</div>
              <div class="txt">Persoonlijke lening</div>
            </div>

            <div class="bullets">
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Eenmalig geld lenen</div>
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Vaste rente</div>
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Boetevrij extra aflossen</div>
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Vaste maandtermijn</div>
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Vaste looptijd</div>
            </div>

            <btn-big class="allGreen" :height="80" :click="() => selectLoanType($store.state.loanType.PERSOONLIJKE)" v-anchor="'#anchor-grey-container'">
              <div class="content">
                <div class="txt">Bekijk maandlasten</div>
                <img src="@/assets/arrow.svg" alt>
              </div>
            </btn-big>

            <btn-big
              class="primary"
              :height="86"
              :click="() => { navTo('offerte'); }"
            >
              <div class="content">
                <img src="@/assets/money-w.svg" alt />
                <div class="txt">Offerte aanvragen</div>
                <img src="@/assets/arrow.svg" alt />
              </div>
            </btn-big>
          </div>

          <div class="sep-50"></div>

          <div class="white-square">
            <div class="green-label grey-bg">
              <span>Ook mogelijk</span>
            </div>
            <div class="title">
              <div class="txt green">optie 2</div>
              <div class="txt">Doorlopend krediet</div>
            </div>

            <div class="bullets">
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Geld opnemen tot een afgesproken limiet</div>
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Variabele rente</div>
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Boetevrij extra aflossen</div>
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Vast maandtermijn</div>
              <img src="@/assets/greenCheck.svg" alt="x" width="15" class="checkmark" />
              <!-- <div class="arrow"></div> -->
              <div class="txt">Looptijd afhankelijk van de geldopnames</div>
            </div>

            <btn-big class="allGreen" :height="80" :click="() => selectLoanType($store.state.loanType.DOORLOPEND)" v-anchor="'#anchor-grey-container'">
              <div class="content">
                <div class="txt">Bekijk maandlasten</div>
                <img src="@/assets/arrow.svg" alt>
              </div>
            </btn-big>

            <btn-big
              class="primary"
              :height="86"
              :click="() => { navTo('offerte'); }"
            >
              <div class="content">
                <img src="@/assets/money-w.svg" alt />
                <div class="txt">Offerte aanvragen</div>
                <img src="@/assets/arrow.svg" alt />
              </div>
            </btn-big>
          </div>

          <div class="sep-30"></div>

          <div id="anchor-grey-container"></div>

          <div class="sep-30"></div>

          <!-- <btn-big
            class="primary"
            :height="86"
            :click="() => { navTo('offerte'); }"
          >
            <div class="content">
              <img src="@/assets/money-w.svg" alt />
              <div class="txt">Offerte aanvragen</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big> -->
        </div>
      </div>

      <div class="grey-container" v-if="loanTypeModal == true">
        <img class="close" @click="loanTypeModal = false" v-anchor="'#anchor-white-squares'" src="@/assets/close-white.png" />

        <div class="contents">
          <input-dropdown
            v-if="loanTypeDropdown"
            :options="['Doorlopend krediet', 'Persoonlijke lening']"
            :init="true"
            v-model="$store.state._loanRequest.loanType"
          >
          </input-dropdown>

          <div v-else class="small-txt">{{ getLoanTypeName }}</div>

          <div class="big-txt">
            <div class="txt">Wat wordt mijn maandlast?</div>
          </div>

          <loan-type-table></loan-type-table>

          <btn-big
            class="primary"
            :height="86"
            :click="() => { navTo('offerte'); }"
          >
            <div class="content">
              <img src="@/assets/money-w.svg" alt />
              <div class="txt">Offerte aanvragen</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </div>
      </div>

      <div class="black-section">
        <div class="cnt-container">
          <div class="contact-text">Meer informatie of advies?</div>

          <div class="icons">
            <img src="@/assets/white-phone.svg">
            <div class="txt">
              <a href="tel:023 539 5926">023 539 5926</a>
            </div>

            <img src="@/assets/white-email.svg">
            <div class="txt">
              <a href="mailto:info@leenattent.nl">info@leenattent.nl</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @gridRowGap: 2.5em;
  .text-green {
    color: getColor("greenDefault");
  }
  .black-area {
    .black-layout {
      color: getColor('whiteColor');
      background-color: getColor('grayDkColor');

      .black-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .container-forms {
          width: 100%;
          min-width: pxToEm(200);
          max-width: pxToEm(300);
          margin: 0 auto;
          text-align: left;

          .title {
            margin-bottom: pxToEm(50);

            .txt {
              font-size: pxToPercent(24);
              font-weight: 600;
            }
          }

          .form {
            display: grid;
            grid-row-gap: @gridRowGap;

            .txt-label {
                font-size: pxToPercent(18);

                &.green {
                  color: getColor("greenDefault");
                  font-weight: 600;
                }
            }

            .col {
              height: pxToEm(86);
              display: grid;
              grid-template-rows: 1fr 1fr;
            }
          }
        }

        .txt-bottom {
          margin: pxToEm(50) pxToEm(40) 0;
          text-align: center;

          .regular {
            font-size: pxToPercent(18);
            font-weight: 400;
          }
        }

        .arrow-svg {
          width: pxToEm(50);
          height: pxToEm(50);
          margin-top: pxToEm(50);
          transform: rotate(90deg);
          align-self: center;
        }

        .b-title {
          margin-top: pxToEm(40);

          .txt {
            font-size: pxToPercent(28);
            font-weight: 800;

            .green {
              color: getColor("greenDefault");
            }
          }
        }

        .container-b {
          width: 100%;
          min-width: pxToEm(260);
          max-width: pxToEm(500);
          margin: 0 auto;
          margin-top: pxToEm(20);

          .white-square {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: left;
            color: getColor("textColor");
            background-color: getColor('bgColor');
            box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);

            .green-label {
              width: pxToEm(210);
              height: pxToEm(43);
              position: absolute;
              margin-top: pxToEm(-20);
              color: getColor('whiteColor');
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: getColor("greenDefault");

              span {
                font-size: pxToPercent(14);
              }
            }

            .grey-bg {
              background-color: getColor("grayMidColor");
            }

            .title {
              margin: pxToEm(40) pxToEm(20) 0;

              .txt {
                font-size: pxToPercent(22);
                font-weight: 400;
              }
              .green {
                color: getColor("greenDefault");
                font-size: pxToPercent(14);
                font-weight: 700;
                text-align: center;
              }
            }

            .bullets {
              margin: pxToEm(20) pxToEm(20);
              display: grid;
              grid-template-columns: pxToEm(30) 1fr;
              grid-row-gap: 1.5em;
              align-self: flex-start;

              .arrow {
                width: pxToEm(5);
                height: pxToEm(5);
                margin-left: pxToEm(4);
                margin-top: pxToEm(8);
                transform: rotate(-45deg);
                border: solid getColor('blackColor');
                border-width: 0 pxToEm(1) pxToEm(1) 0;
              }

              .txt {
                font-size: pxToPercent(16);
              }

            }

            .btn-big {
              width: 100%;
              box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.2);

              .content {
                margin-left: pxToEm(20);
                margin-right: pxToEm(20);

                .txt {
                  margin-right: pxToEm(20);
                  font-size: pxToPercent(16);
                  font-weight: 700;
                  text-align: left;
                }

                img {
                  width: pxToEm(36);
                  height: pxToEm(28);
                }
              }
            }
          }

          .btn-container {
            position: relative;
            z-index: 300;

            &.shadow {
              box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
            }
          }
        }

        .btn-big {
          margin: 0 0 pxToEm(10) 0;
          align-self: flex-start;
          border-bottom-width: pxToEm(7);

          .content {
            margin: 0 pxToEm(20);

            .txt {
              margin-right: pxToEm(20);
              font-size: pxToPercent(16);
              font-weight: 700;
              text-align: left;
            }

            img {
              width: pxToEm(36);
              height: pxToEm(28);
            }
          }
        }

        .cnt-container {
          margin-top: pxToEm(60);
          margin-bottom: pxToEm(100);
          display: flex;
          flex-direction: column;
          align-items: center;

          .contact-text {
            font-size: pxToPercent(20);
          }

          .icons {
            margin-top: pxToEm(40);
            display: grid;
            grid-template-columns: pxToEm(36) 1fr;
            grid-row-gap: 2.5em;
            grid-column-gap: pxToEm(30);

            img {
              width: pxToEm(36);
              height: pxToEm(36);
            }

            .txt {
              font-size: pxToEm(18);
              font-weight: 600;
              align-self: center;
              text-align: left;
              color: getColor("greenDefault");

              a {
                color: getColor("greenDefault");
                cursor: pointer;
              }
            }
          }
        }
      }

      .grey-container {
        margin: pxToEm(-43) pxToEm(-20) 0;
        padding-top: pxToEm(60);
        padding-bottom: pxToEm(50);
        display: flex;
        flex-direction: column;
        color: getColor('whiteColor');
        background-color: getColor('grayMidColor');

        .close {
          width: pxToEm(16);
          height: pxToEm(16);
          padding: pxToEm(20) pxToEm(25);
          align-self: flex-end;
          cursor: pointer;
        }

        .contents {
          margin: 0 pxToEm(15);

          .input-dropdown {
            margin-top: pxToEm(20);
          }

          .small-txt {
            font-size: pxToPercent(18);
            font-weight: 700;
            text-align: center;
          }

          .big-txt {
            margin-top: pxToEm(20);
            margin-bottom: pxToEm(30);

            .txt {
              font-size: pxToPercent(36);
              text-align: center;
              font-weight: 300;
            }
          }

          .btn-big {
            margin: pxToEm(20) auto 0 auto;
            border-bottom-width: pxToEm(7);

            .content {
              margin: 0 pxToEm(20);

              .txt {
                margin-right: pxToEm(20);
                font-size: pxToPercent(16);
                font-weight: 700;
                text-align: left;
              }

              img {
                width: pxToEm(36);
                height: pxToEm(28);
              }
            }
          }
        }
      }
    }
  }
</style>

<script>
  import btnBig from "@/components/common/btn-big";
  import inputDropdown from "@/components/common/input-dropdown";
  import inputNum from "@/components/common/input-num";
  import loanTypeTable from "@/components/mobile/loan-type-table/loan-type-table.vue";

  export default {
    name: "blackArea",
    components: {
      btnBig,
      inputDropdown,
      inputNum,
      loanTypeTable
    },
    data() {
      return {
        loanTypeDropdown: false,
        loanTypeModal: false
      };
    },
    computed: {
      getLoanTypeName() {
        return $store.state._loanRequest.loanTypeTable == $store.state.loanType.PERSOONLIJKE ? "Persoonlijke lening" : "Doorlopend krediet";
      },
      totalSaving: function() {
        return $store.state._loanRequest.newLoan.interestSaving;
      }
    },
    methods: {
      navTo: function(url) {
        $store.state.storedData = true;
        this.$router.push(url);
      },
      selectLoanType(loanType) {
        this.loanTypeModal = true;
        $store.state._loanRequest.loanTypeTable = loanType;
      }
    },
    created() {
      $store.state._loanRequest.loanOption = $store.state.funnelBOption;
    }
  };
</script>
