<template>
  <div class="container">
    <div v-if="blogs">
      <div class="main">
        <h1>Blogs</h1>
        <div class="cards">
          <div class="cards_item" v-for="(blog, index) in blogs">
            <div class="card" @click="redirect(blog.attributes.Slug)">
              <div class="card_image">
                <img
                  v-if="
                    blog.attributes.Image &&
                    blog.attributes.Image.data &&
                    blog.attributes.Image.data.attributes &&
                    blog.attributes.Image.data.attributes.url
                  "
                  :src="
                    strapiAssetUrl(blog.attributes.Image.data.attributes.url)
                  "
                />
              </div>
              <div class="card_content">
                <h2 class="card_title">{{ blog.attributes.Title }}</h2>
                <p class="card_text">
                  {{ unmarkText(blog.attributes.Description.Content) }}
                </p>
                <div class="readMore">
                  <div class="blogDate">
                    {{ formatDate(blog.attributes.Date) }}
                  </div>
                  <div class="readMoreText">Lees meer</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="pagination">
        <paginate
          :pageCount="pagination.pageCount ? pagination.pageCount : 1"
          :clickHandler="handlePagination"
          :margin-pages="1"
          :prevText="'<b> < </b>'"
          :nextText="'<b> > </b>'"
          :page-class="'page-item'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}

::v-deep .disabled {
  color: grey;
}

::v-deep li {
  margin: 0px 5px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid grey;
}

::v-deep .active {
  border: 1px solid #c82346;
  color: #c82346;
}
.main {
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 24px;
  text-align: center;
}

img {
  height: 250px;
  max-width: 100%;
  object-fit: contain;
  vertical-align: middle;
}

.readMore {
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.readMoreText {
  cursor: pointer;
}

.btn {
  color: #ffffff;
  padding: 0.8rem;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 400;
  display: block;
  width: 100%;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.12);
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cards_item {
  cursor: pointer;
  display: flex;
  padding: 1rem;
}

@media (min-width: 40rem) {
  .cards_item {
    width: 50%;
  }
}

@media (min-width: 56rem) {
  .cards_item {
    width: 33.3333%;
  }
}

.card {
  width: 100%;
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.card_content {
  padding: 1rem;
  background: #c82346;
}

.card_title {
  color: #ffffff;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card_text {
  color: #ffffff;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.5rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.made_by {
  font-weight: 400;
  font-size: 13px;
  margin-top: 35px;
  text-align: center;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.blogsList {
  display: flex;
  justify-content: center;
}
</style>

<script>
import Vue from "vue";
import { strapiAssetUrl } from "@/JS/helpers";
import Api from "@/Api";
import Paginate from "vuejs-paginate";
import markdownToTxt from "markdown-to-txt";

Vue.component("paginate", Paginate);
export default {
  name: "blogPage",
  data() {
    return {
      blogs: null,
      pagignation: null,
      index: 1,
    };
  },
  created() {
    this.fetchBlogData();
  },
  methods: {
    unmarkText(text) {
      return markdownToTxt(text);
    },
    formatDate(dates) {
      let date = new Date(dates);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      day = day < 10 ? "0" +day : day;
      month = month < 10 ? "0" + month : month;
      return (
        day
         +
        "-" + month
         +
        "-" +
        date.getFullYear()
      );
    },
    redirect(path) {
      console.log("Path ", path);
      if (path) {
        this.$router.push("/blog-" + path);
      }
    },
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path);
    },
    fetchBlogData: function () {
      Api.get(
        `blogs?populate=Image&populate=Description&pagination[page]=${this.index}&pagination[pageSize]=9`
      )
        .then((result) => {
          if (result.data.data.length > 0) {
            this.blogs = result.data.data;
            this.pagination = result.data.meta.pagination;
          } else {
            this.blogs = [];
          }
        })
        .catch((e) => {
          console.log("error--> ", e);
        });
    },
    handlePagination: function (page) {
      if (this.pagination.pageCount >= page) {
        this.index = page;
        this.fetchBlogData();
      }
    },
    nextPage() {
      if (
        this.pagignation &&
        this.pagignation.pageCount &&
        this.pagignation.pageCount >= this.index
      )
        this.index = fetchBlog;
    },
  },
};
</script>
