<template>
  <div class="area" v-if="block">
    <div
      style="
        width: 100%;
        height: 45px;
        background-color: white;
        position: absolute;
      "
    ></div>
    <div class="layout">
      <div
        class="pic"
        v-if="
          block.Image &&
          block.Image.data &&
          block.Image.data.attributes &&
          block.Image.data.attributes.url
        "
        :style="{
          'background-image':
            'url(' + strapiAssetUrl(block.Image.data.attributes.url) + ')',
        }"
      ></div>
      <div
        class="btn-header"
        v-if="contactOff == false"
        @mouseenter="contactActive = true"
        @mouseleave="contactActive = false"
      >
        <btn-big class="secondary" :width="225" :height="100">
          <div class="content">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40.75"
              height="40.66"
              viewBox="0 0 67.885 67.743"
            >
              <g transform="translate(1.531 1.71)">
                <g transform="translate(0 0)">
                  <path
                    d="M1030.355,40.822c-1.453-2.321-1.19-2.971-1.19-2.971l4.333-6.292a3.924,3.924,0,0,0-.385-5.12l-8.98-8.923a3.673,3.673,0,0,0-4.838-.3l-3.9,3.022a4.4,4.4,0,0,0-.893.907c-7.7,10.742,3.1,27.657,16.414,40.9,12.95,12.877,30.479,24.348,41.061,16.147a4.417,4.417,0,0,0,.792-.812l3.049-3.98a3.673,3.673,0,0,0-.327-4.837l-8.979-8.921a3.925,3.925,0,0,0-5.122-.357l-6.266,4.369s-2.507.909-14.677-11.192a94.127,94.127,0,0,1-8.681-9.607c-.593-.787-1.054-1.459-1.41-2.029"
                    transform="translate(-1011.931 -16.45)"
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    stroke-miterlimit="10"
                    stroke-width="4"
                  ></path>
                </g>
                <path
                  d="M1058.763,44.5a17.814,17.814,0,0,0-17.586-17.8"
                  transform="translate(-1006.51 -14.55)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="4"
                ></path>
                <path
                  d="M1069.013,46.106a28.071,28.071,0,0,0-27.836-28.054"
                  transform="translate(-1006.51 -16.153)"
                  fill="none"
                  stroke="#fff"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="4"
                ></path>
                <path
                  d="M1066.206,33.886c-3.5-8.106-14.437-15.381-25.029-15.834"
                  transform="translate(-1006.51 -16.153)"
                  fill="none"
                  stroke="#f"
                  stroke-linecap="square"
                  stroke-miterlimit="10"
                  stroke-width="4"
                ></path>
              </g>
            </svg>
            <div class="txt">Advies nodig?</div>
          </div>
        </btn-big>
      </div>
      <div
        class="btn-container"
        :class="{ formcheck: contactForm.state == false }"
        v-show="contactOff == false && (contactActive || formSent)"
        @mouseenter="contactActive = true"
        @mouseleave="contactActive = false"
       >
        <img class="telephone" src="@/assets/telephone.svg" />

        <div style="height: 22px"></div>

        <div v-show="formSent == false">
          <div class="text-cont">Wij geven u graag advies</div>
          <span>Neem telefonisch contact met mij op</span>
          <div style="height: 18px"></div>
          <input-text
            v-model="contactForm.fields.name"
            :placeholder="'Uw naam'"
          ></input-text>
          <div class="sep-12"></div>
          <input-text
            v-model="contactForm.fields.phoneNumber"
            :placeholder="'Uw telefoonnummer'"
          ></input-text>
          <div class="sep-12"></div>
          <input-text
            v-model="contactForm.fields.emailAddress"
            :placeholder="'Uw e-mailadres'"
          ></input-text>
          <div class="sep-12"></div>
          <btn-big class="allGreen" :height="84" :click="submitForm">
            <div class="content">
              <div class="txt">
                Neem contact
                <br />op met mij
              </div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </div>

        <div v-show="formSent == true">
          <div class="text-cont">Uw gegevens zijn verzonden</div>
          <span>We nemen zo snel mogelijk contact met u op.</span>
        </div>
      </div>

      <div class="section">
        <div class="container">
          <div class="only-tablet logo-wrapper">
            <img src="../../../assets/logo.png" />
          </div>
          <h1 class="first-txt">
            <span class="pink">{{ block.Title }}</span>
            {{ block.Title2 }}
          </h1>

          <div class="table">
            <div class="header">{{ block.Label }}</div>
            <div class="buttons">
              <div
                class="button"
                v-if="block.Links"
                v-for="(link, i) in block.Links"
                :key="i"
              >
                <router-link v-if="link.url" :to="link.url">
                  <div class="cont">
                    <div class="img">
                      <img
                        v-if="
                          link.Image &&
                          link.Image.data &&
                          link.Image.data.attributes &&
                          link.Image.data.attributes.url
                        "
                        :src="strapiAssetUrl(link.Image.data.attributes.url)"
                      />
                    </div>

                    <div class="text" style="margin-top: 0.5em">
                      {{ link.label }}
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor('greyLight');
    height: pxToEm(724);

    .only-tablet.logo-wrapper {
      img {
        max-width: pxToEm(400);
        margin-top: pxToEm(10);

      }

      @media (min-width: 1240px) {
        display: none;
      }
    }

    .layout {
      justify-content: center;
      align-items: center;

      .btn-header {
        right: 0;
        width: pxToEm(225);
        height: pxToEm(100);
        border-bottom-width: pxToEm(18);
        position: absolute;
        z-index: 100;

        .content {
          margin-left: pxToEm(27);
          margin-right: pxToEm(27);
          .txt {
            font-size: pxToPercent(21);
          }

          img {
            width: pxToEm(56);
            height: pxToEm(45);
          }
        }
      }

      .pic {
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        right: 0;
        left: 50%;
        margin-left: -420px;
        height: 724px;
        transform: rotateY(-180deg);
      }

      .btn-container {
        width: 226px;
        background-color: #3a3a3a;
        z-index: 100;
        position: absolute;
        right: 0;
        padding: 40px;

        .text-cont {
          color: #fff;
          font-size: pxToPercent(18);
          margin-bottom: pxToEm(10);
        }

        .input {
          height: 62px;
          font-size: pxToPercent(14);
        }

        span {
          font-size: pxToPercent(16);
          font-weight: 300;
          color: #fff;
          text-align: center;
          margin-bottom: pxToEm(10);
        }

        .btn-big {
          position: relative;
          text-align: left;

          .txt {
            font-size: pxToPercent(14);
          }

          .content {
            margin-left: pxToEm(20);
            margin-right: pxToEm(20);
          }

          img {
            width: pxToEm(40);
            height: pxToEm(32);
          }
        }
      }

      .section {
        width: 100%;
        max-width: 1300px;

        .pink {
          color: getColor('primaryLight');
          font-weight: 800;
        }

        .container {
          background-color: getColor('greyLight');
          width: pxToEm(452);
          height: pxToEm(583);

          @media (max-width: 1240px) {
            height: pxToEm(630);
          }

          padding: 0 pxToEm(50) 0 pxToEm(120);

          .first-txt {
            color: #3a3a3a;
            text-align: left;
            padding-top: pxToEm(15);
            font-size: pxToEm(46);
            line-height: 1.1em;
            font-weight: 800;
            margin-top: 0;
            margin-bottom: 0;

            &.first-txt {
              @media (max-width: 1240px) {
                padding-top: pxToEm(5);
              }
            }
          }

          .table {
            padding-top: pxToEm(20);
            width: pxToEm(436);
            height: pxToEm(264);

            .header {
              background-color: #3a3a3a;
              color: #fff;
              font-size: pxToEm(14);
              height: pxToEm(32);
              padding: pxToEm(12) 0 0 0;
            }

            .buttons {
              background-color: #fff;
              width: pxToEm(436);
              height: pxToEm(220);

              .button {
                border: 1px solid #3a3a3a;
                float: left;
                box-sizing: border-box;
                width: pxToEm(218);
                height: pxtoEm(110);
                transition: all ease-in-out 250ms;

                a {
                  color: #3a3a3a;
                  text-decoration: none;
                }

                &:nth-child(1),
                &:nth-child(2) {
                  border-bottom: none;
                }

                &:nth-child(2),
                &:nth-child(4) {
                  border-left: none;
                }

                &:hover {
                  border-bottom: pxToEm(6) solid getColor('primaryLight');
                  box-shadow: 0px 9px 29px rgba(0, 0, 0, 0.2);
                  cursor: pointer;
                  transition: all ease-in-out 250ms;
                }

                .cont {
                  display: flex;
                  height: 100%;
                  width: pxToEm(176);
                  height: pxToEm(57);
                  margin: pxToEm(26) pxToEm(20);

                  .img {
                    flex: 0 1 auto;
                    min-width: pxToEm(50);
                    margin: 0 pxToEm(15) 0 0;

                    img {
                      margin: pxToEm(12) 0;
                    }
                  }

                  .text {
                    font-size: pxToEm(14);
                    flex: 1 1 auto;
                    text-align: left;
                    max-width: pxToEm(132);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>

<style lang="less">
.btn-big {
  .content {
    .txt {
      font-size: pxToPercent(28);
    }
  }
}
</style>

<script>
import headerImg from "@/assets/homepage-header.jpg";
import btnBig from "@/components/common/btn-big";
import inputText from "@/components/common/input-text";
import { _smallForm } from "@/data/contactData.js";
import { goToAnchor, checkTab } from "@/JS/helpers";
import { dataToMail, mails } from "@/JS/mail";
import { strapiAssetUrl } from "@/JS/helpers";

export default {
  name: "deskHeader",
  props: {
    block: Object,
  },
  components: {
    btnBig,
    inputText,
  },
  data() {
    return {
      headerImg,
      contactOff: false,
      contactActive: false,
      formSent: false,
      contactForm: { fields: new _smallForm(), state: undefined },
    };
  },
  methods: {
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path);
    },
    submitForm() {
      this.contactForm.state = checkTab(this.contactForm.fields);
      if (this.contactForm.state == false) {
        // goToAnchor("#form-error");
      } else {
        dataToMail({
          mailTo: mails.info,
          mailSubject: "Contact - Advies nodig?",
          mailData: this.contactForm.fields,
        })
          .then((res) => {
            //CODE FOR MAIL SENT /////////////
            // this.contactForm.fields = new _form0();
            // this.contactForm.state = undefined;
            this.formSent = true;
            //goToAnchor("#form-top");
            // console.log(res);
          })
          .catch((rej) => {
            //CODE FOR MAIL NOT SENT /////////////
            this.formSent = false;
            // console.log(rej);
          });

        window.setTimeout(function () {
          this.contactOff = true;
        }, 2000);
      }
    },
  },
};
</script>
