<template>
  <div class="persoonlijke">
    <mob-logo class="area"></mob-logo>
    <persoonlijke-header class="area"></persoonlijke-header>
    <calculator class="area"></calculator>
    <persoonlijke-body class="area"></persoonlijke-body>
    <safe-loan-info class="area"></safe-loan-info>
    <loan-info class="area"></loan-info>
    <mob-contact class="area mob-contact"></mob-contact>
  </div>
</template>

<style lang="less" scoped>
  .mob-contact {
    box-shadow: 0 pxToEm(-10) pxToEm(64) #e7e7e7;
  }
</style>

<script>
  import mobLogo from "@/components/mobile/mob-header-logo.vue";
  import persoonlijkeHeader from "@/components/mobile/persoonlijke/header.vue";
  import calculator from "@/components/mobile/calculators/A/calculator-form.vue";
  import persoonlijkeBody from "@/components/mobile/persoonlijke/body.vue";
  import mobContact from "@/components/mobile/mob-contact.vue";
  import safeLoanInfo from "@/components/mobile/mob-safe-loan-info.vue";
  import loanInfo from "@/components/mobile/mob-loan-bottom-info.vue";

  export default {
    name:'mobPersoonlijke',
    components: {
      mobLogo,
      persoonlijkeHeader,
      calculator,
      persoonlijkeBody,
      mobContact,
      safeLoanInfo,
      loanInfo
    },
    created() {
      $store.commit("resetForms");
      $store.state._loanRequest.loanType = $store.state.loanType.PERSOONLIJKE;
    }
  }
</script>