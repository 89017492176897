<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="title">Ik heb een negatieve BKR-registratie</div>
        <div class="pink"></div>

        <div class="text">
          Een negatieve BKR registratie kan behoorlijk zwaar wegen in de mogelijkheden om opnieuw geld te lenen. Toch kunnen
          onze adviseurs u goed van dienst zijn wanneer u de komende tijd wat extra geld nodig heeft. Een appeltje voor de dorst
          aanvragen of een specifieke aankoop financieren? De negatieve BKR registratie kan langere tijd gevolgen hebben, wij
          gaan graag met u na wat er toch mogelijk is.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  .layout {
    max-width: 1200px;

    .section {
      margin: 50px;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: pxToPercent(70);
        margin: 20px 130px 0px 130px;
        font-weight: 800;
      }

      .pink {
        width: 123px;
        height: 11px;
        background-color: getColor("primaryLight");
      }

      .text {
        font-size: pxToPercent(18);
        line-height: 2.3em;
        margin: 50px 50px 20px 50px;
      }

      .btn-big {
        width: pxToEm(530);
        height: pxToEm(105);
        margin: pxToEm(55) pxToEm(200);
        margin-bottom: 0;

        .content {
          margin-left: pxToEm(40);
          margin-right: pxToEm(40);

          .txt {
            font-size: pxToPercent(21);
            font-weight: 700;
          }

          img {
            width: pxToEm(56);
            height: pxToEm(45);
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.btn-big {
  .content {
    .txt {
      font-size: pxToPercent(28);
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";

export default {
  name: "bkrHeader",
  components: {
    btnBig
  }
};
</script>
