<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="top" :style="{ 'background-image': 'url(' + headerImg + ')' }"></div>

        <div class="middle">
          <div class="texts">
            <div class="txt">
              <div class="title">Persoonlijke lening</div>

              <div class="body">
                De persoonlijke lening biedt de mogelijkheid om eenmalig geld te lenen, bijvoorbeeld voor een grote uitgave die u wilt doen te doen. Denk aan financiering van een nieuwe auto, een keuken, of een bijzondere vakantie. Allemaal wensen die u met een persoonlijke lening kunt realiseren. Onze adviseurs zetten de voor- en nadelen van dit type krediet graag eerlijk voor u op een rijtje. Wij kunnen dit natuurlijk ook voor u laten afwegen tegen ons doorlopend krediet.
              </div>
            </div>

            <div class="txt">
              <div class="bold">Laagste rente voor een persoonlijke lening</div>
              <div class="body">
                Leenattent zorgt voor de laagste rente van Nederland op een persoonlijke lening. U bent altijd verzekerd van het voordeligste krediet, op basis van een vaste lage rente. Dankzij die vaste rente en de vaste looptijd bij dit type krediet staat u nooit voor verrassingen. De lage rente maakt de persoonlijke lening een voordelige keuze, bijvoorbeeld als u een grote uitgave wilt doen en daarnaast toch graag wat spaargeld achter de hand wilt houden.
              </div>
            </div>

            <div class="txt">
              <div class="bold">Kenmerken en voordelen</div>
              <div class="body">
                Kernmerk van een persoonlijke lening is dat het een vaste rente en een vaste looptijd heeft. U betaalt dus altijd een vaste maandbedrag, over een vaste looptijd. Het maandbedrag wordt van te voren voor u berekend door ons. Zo weet u zeker of u de persoonlijke lening nu en in de toekomst kunt blijven betalen. En heeft u een keer de mogelijkheid om extra af te lossen dan kan dit bij ons boetevrij.
              </div>
            </div>

            <div class="txt">
              <div class="bold">Kosteloos advies voor een persoonlijke lening</div>
              <div class="body">
                De Leenattent adviseurs vinden het belangrijk om u eerlijk voor te lichten over de mogelijkheden. Wij adviseren u daarom altijd kosteloos over een persoonlijke lening. Aan de hand van een offerte heeft u de kosten eenvoudig op een rijtje. U vindt hierin terug wat een lening u kost gedurende de eerste maanden en hoe de kosten zich tijdens de looptijd ontwikkelen. Neem <span>online contact</span> op, <span>bel ons</span> of start een <span>livechat</span> met een van onze financiële specialisten als u meer wilt weten over een persoonlijke lening. Direct een vrijblijvende en kosteloze offerte aanvragen kan natuurlijk ook!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  .layout {
    align-items: center;

    .section {
      margin: 0 pxToEm(50);
      margin-top: pxtoEm(50);
      max-width: pxToEm(1160);
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      .top {
        width: 100%;
        height: pxToEm(460);
        margin-top: pxToEm(40);
        background-position: center;
        background-size: cover;
      }

      .middle {
        min-height: pxToEm(802);
        margin: pxToEm(70);
        margin-top: pxToEm(10);
        text-align: left;

        .texts {
          display: flex;
          flex-direction: column;
          min-height: pxToEm(802);
          max-width: pxToEm(866);

          .txt {
            flex: 1 1 auto;
            max-width: 768px;

            .title {
              text-decoration: underline;
              font-weight: 700;
              font-size: pxToEm(60);
              line-height: 1em;
              margin: pxToEm(10) 0;
            }

            .body {
              font-size: pxToEm(16);
              line-height: 2.1em;

              span {
                text-decoration: underline;
              }
            }

            &:first-child {
              min-height: pxToEm(300);
            }

            .bold {
              font-size: pxToEm(36);
              font-weight: 700;
              margin: pxToEm(10) 0;
              margin-top: pxToEm(20);
            }

            &.pink {
              color: getColor("primaryLight");
              font-size: pxToEm(24);
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";
import headerImg from "@/assets/persoonlijke_img4.jpg";
import loanBottomInfo from "@/components/desktop/loan-bottom-info"

export default {
  name: "persoonlijkeBody",
  components: {
    btnBig,
    loanBottomInfo
  },
  data() {
    return {
      headerImg
    };
  }
};
</script>