var DoorlopendType = function (months) {
  var self = this;

  Object.defineProperty(self, 'months', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: months
  });

  Object.defineProperty(self, 'monthlyFee', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  Object.defineProperty(self, 'anualInterest', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  Object.defineProperty(self, 'monthlyInterest', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  self.mogelijkheden = "0% van het limiet";
  self.maandlast = "€0.00";
  self.effectiveJaarrente = "0%";
  self.nominaleMaandrente = "0%";
  self.theoretischeLooptijd = " maanden";
  self.totaalprijs = "€0.00";

  return self;
};

DoorlopendType.prototype.update = function (percentageSection, amount) {
  if (amount >= 50000) {
    this.anualInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_50000) : 4.50;
    if(percentageSection && percentageSection.Revolving_credit_percentage_50000_monthly){
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_50000_monthly) : 0.368;
    } else {
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_50000)/12 : 0.368;
    }
  } else if (amount >= 25000) {
    this.anualInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_25000) : 4.50;
    if(percentageSection && percentageSection.Revolving_credit_percentage_25000_monthly){
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_25000_monthly): 0.368;
    } else {
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_25000)/12 : 0.368;
    }
  } else if (amount >= 15000) {
    this.anualInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_15000) : 4.70;
    if(percentageSection && percentageSection.Revolving_credit_percentage_15000_monthly){
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_15000_monthly) : 0.383;
    } else {
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_15000)/12 : 0.383;
    }
  } else {
    this.anualInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_0) : 6.10;
    if(percentageSection && percentageSection.Revolving_credit_percentage_0_monthly){
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_0_monthly): 0.495;
    } else {
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Revolving_credit_percentage_0)/12: 0.495;
    }
  }

  this.theoretischeLooptijd = this.months + " maanden";

  this.monthlyInterest /= 100.0;
  this.anualInterest /= 100.0;

  this.rate = this.monthlyInterest / (1 - Math.pow((1 + this.monthlyInterest), -this.months));
  this.monthlyFee = this.rate * amount;
  this.mogelijkheden = (this.rate * 100).toFixed(3) + "% van het limiet";


  this.maandlast = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(this.monthlyFee);
  this.effectiveJaarrente = (this.anualInterest * 100).toFixed(3) + '%';
  this.nominaleMaandrente = (this.monthlyInterest * 100).toFixed(3) + '%';
  this.totaalprijs = (this.monthlyFee * this.months).toFixed(2);
  this.totaalprijs = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(this.totaalprijs);
}


var PersoonlijkeType = function (months) {
  var self = this;

  Object.defineProperty(self, 'months', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: months
  });

  Object.defineProperty(self, 'monthlyFee', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  Object.defineProperty(self, 'anualInterest', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  Object.defineProperty(self, 'monthlyInterest', {
    enumerable: false,
    configurable: false,
    writable: true,
    value: 0
  });

  self.mogelijkheden = this.months + " maanden";
  self.maandlast = "€0.00";
  self.effectiveJaarrente = "0%";
  self.nominaleMaandrente = "0%";
  self.totaalprijs = "€0.00";

  return this;
}

PersoonlijkeType.prototype.update = function (percentageSection, amount) {
  var self = this;

  if (amount >= 50000) {
    this.anualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_50000) / 100 : 0.034;
    if(percentageSection && percentageSection.Personal_loan_percentage_50000_monthly){
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_50000_monthly) / 100 : 0.00279;
    } else {
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_50000) / 100 / 12 : 0.00279;
    }
  } else if (amount >= 25000) {
    this.anualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_25000) / 100 : 0.036;
    if(percentageSection && percentageSection.Personal_loan_percentage_25000_monthly){
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_25000_monthly) / 100 : 0.00295;
    } else {
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_25000) / 100 / 12 : 0.00295;
    }
  } else if (amount >= 15000) {
    this.anualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_15000) / 100 : 0.0410;
    if(percentageSection && percentageSection.Personal_loan_percentage_15000_monthly){
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_15000_monthly) / 100 : 0.00335;
    } else {
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_15000) / 100 / 12 : 0.00335;
    }
  } else if (amount >= 10000) {
    this.anualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_10000) / 100 : 0.0580;
    if(percentageSection && percentageSection.Personal_loan_percentage_10000_monthly){
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_10000_monthly) / 100 : 0.00471;
    } else {
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_10000) / 100 / 12 : 0.00471;
    }
  } else {
    this.anualInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_0) / 100 : 0.064;
    if(percentageSection && percentageSection.Personal_loan_percentage_0_monthly){
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_0_monthly) / 100 : 0.00518;
    } else {
      this.monthlyInterest = percentageSection ? parseFloat(percentageSection.Personal_loan_percentage_0) / 100 / 12 : 0.00518;
    }
  }

  // //R / (1 - ((1 + R) ^ -mogelijkheden)) * X
  // // self.monthlyFee = self.monthlyInterest / (1 - Math.pow((1 + self.monthlyInterest), -self.months)) * amount;

  // self.monthlyFee = (amount / self.months) + (self.monthlyInterest * 100 * amount);
  // let totaalprijs = (amount * Math.pow((1 + self.monthlyInterest), self.months)).toFixed(2);
  // self.monthlyFee = totaalprijs / self.months;
  // // self.maandlast = "€" + self.monthlyFee.toFixed(2);
  // self.maandlast = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(self.monthlyFee);
  // self.effectiveJaarrente = (self.anualInterest * 100).toFixed(2) + '%';
  // self.nominaleMaandrente = (self.monthlyInterest * 100).toFixed(2) + '%';
  // // self.totaalprijs = "€" + (self.monthlyFee * self.months).toFixed(2);
  // // self.totaalprijs = "€" + totaalprijs;
  // self.totaalprijs = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(totaalprijs);


  // // // totalPrice = totalLoan * (1 + (monthlyPercentage/ 100))^(nrMonths)
  // // // monthlyPrice = totalPrice / nrMonths

  self.monthlyFee = (self.monthlyInterest / (1 - Math.pow((1 + self.monthlyInterest), -self.months))) * amount;
  self.maandlast = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(self.monthlyFee);
  self.effectiveJaarrente = (self.anualInterest * 100).toFixed(3) + '%';
  self.nominaleMaandrente = (self.monthlyInterest * 100).toFixed(3) + '%';
  self.totaalprijs = (self.monthlyFee * self.months).toFixed(2);
  self.totaalprijs = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(self.totaalprijs);
}

export {DoorlopendType, PersoonlijkeType}
