<template>
  <div class="area" v-if="block">
    <div class="layout">
      <div class="section">
        <h3>{{ block.Title }}</h3>
        <div class="bar"></div>
        <div class="desc">
          <p class="txt"  v-html="markdownToHTML(block.Description)">
          </p>
        </div>
        <table>
          <thead>
            <th rowspan="6"></th>
            <th></th>
            <th align="center"  v-for="(item,i) in block.Companies">
              <tr>
                <img v-if="item.logo.data.attributes.url" :src="strapiAssetUrl(item.logo.data.attributes.url)" />
              </tr>
              <tr :class="i==0 ? 'pink' : ''">
                {{item.Name}}
              </tr>
            </th>
            <th rowspan="6"></th>
          </thead>
          <tbody>
            <tr>
              <td colspan="7"></td>
            </tr>
            <tr v-for="(item,i) in setOptions(block.Companies)">
              <td  class="margin"></td>
              <th :class="{'text-bold': item.length==i }">{{item[0]}}</th>
              <td class="pink">{{item[1]}}</td>
              <td :class="{'green': item.length==i }">{{item[2]}}</td>
              <td :class="{'green': item.length==i }">{{item[3]}}</td>
              <td :class="{'green': item.length==i }">{{item[4]}}</td>
              <td :class="{'green': item.length==i }" >{{item[5]}}</td>
            </tr>
              <td colspan="7"></td>
            </tr>
          </tbody>
        </table>

        <div class="note"  v-html="markdownToHTML(block.BottomText)">
        </div>

        <router-link to="/offerte">
          <btn-big class="primary" :width="400" :height="89">
            <div class="content">
              <div class="txt">Offerte aanvragen</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  &:before {
    content: "\A";
    position: absolute;
    background: #fff;
    bottom: 0;
    left: 0;
    height: pxToEm(509);
    width: 100%;
  }

  .layout {
    max-width: 964px;
    .section {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: pxToEm(100);
      //padding-bottom: pxToEm(100);

      .text-bold {
        font-weight: 700;
      }

      .pink {
        color: getColor("primaryDefault");
      }

      .green {
        color: getColor("greenDefault");
      }

      h3 {
        font-size: pxToEm(24);
        color: getColor("primaryDefault");
        margin: 0;
        margin-bottom: 0.8em;
      }
      .bar {
        width: pxToEm(104);
        height: pxToEm(6);
        background-color: getColor("primaryDefault");
      }
      .desc {
        margin-left: 4em;
        margin-right: 4em;
        max-width: pxToEm(750);
        p {
          font-size: pxToEm(40);
          line-height: 60/40em;
          font-weight: 300;
          span {
            //Properties to make the underline look like the designs
            //Only works for solid background colors
            border-bottom: 1px solid #3a3a3a;
            line-height: 0.92em;
            display: inline-block;
            text-shadow: 2px 2px getColor("greyLight"),
              2px -2px getColor("greyLight"), -2px 2px getColor("greyLight"),
              -2px -2px getColor("greyLight");
          }
        }
      }

      table {
        border-collapse: collapse;
        border: 1px solid #c1c1c1;
        width: 100%;
        margin-top: pxToEm(55);

        thead {
          th {
            height: pxToEm(150);
            background-color: getColor("greyLight");
            border-bottom: 1px solid #c1c1c1;

            img {
              margin: 0 pxToEm(4);
            }
          }
        }
        tbody {
          font-weight: 600;
          background-color: white;

          th,
          td {
            font-weight: 600;
            border-bottom: 1px solid #c1c1c1;
            padding-top: pxToEm(28);
            padding-bottom: pxToEm(28);
          }
          .total {
            th,
            td {
              border: none;
            }
          }

          th {
            text-align: left;
          }

          tr:nth-child(1),
          tr:last-child {
            td,
            th {
              border-bottom: none;
              padding: pxToEm(14) 0;
            }
          }
          .margin {
            width: pxToEm(50);
            border: none;
            background-color: white;
          }
        }
      }

      .note {
        margin-top: 20px;
        //width: pxToEm(540);
        width: 100%;
        line-height: 2.1em;
      }

      .btn-big {
        margin-top: pxToEm(55);

        .content {
          margin-left: pxToEm(40);
          margin-right: pxToEm(40);
          .txt {
            font-size: pxToPercent(22);
            font-weight: 700;
          }
          img {
            width: pxToEm(50);
            height: pxToEm(40);
          }
        }
      }

      a {
        text-decoration: none;
      }
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";
import { strapiAssetUrl } from "@/JS/helpers";
import {marked} from 'marked'
export default {
  name: "DesktopComparisionBlock",
  props: {
    block: Object,
  },
  components: {
    btnBig,
  },
   methods: {
     setOptions:function(data){
       let Tabledata=[];
       let allData=data.map((values)=> values.data);
       let TableKeys=data.map((values)=> Object.keys(values.data));
        let TableData=TableKeys[0].map((values,i)=>{
          let tempdata=[values];
            allData.map((options,j)=>{
              tempdata.push(options[values])
            })
            Tabledata.push(tempdata);
        })
        return Tabledata;
     },
      strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    },
    markdownToHTML: function(markdown) {
      return marked(markdown);
    }
  },
};
</script>
