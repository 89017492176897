<template>
  <div class="contact-form">
    <div class="layout">
      <div class="section">
        <div id="contact-form-top"></div>

        <div class="container-a" v-if="formSent == false">
          <div class="title">
            <div class="txt">Neem telefonisch contact met mij op</div>
          </div>

          <div class="form" :class="{ formcheck: contactForm.state == false }">
            <div class="error" v-if="contactForm.state == false">Er zijn enkele velden niet correct ingevuld.</div>
          
            <div class="col-11">
              <div class="txt-label">Uw naam</div>
              <input-text v-model="contactForm.fields.name"></input-text>
            </div>
  
            <div class="col-11">
              <div class="txt-label">Uw telefoonnummer</div>
              <input-text v-model="contactForm.fields.phoneNumber"></input-text>
            </div>
  
            <div class="col-11">
              <div class="txt-label">Uw e-mailadres</div>
              <input-text v-model="contactForm.fields.emailAddress"></input-text>
            </div>
          </div>

          <div class="sep-60"></div>
  
          <btn-big class="allGreen" :width="234" :height="74" :click="submitForm">
            <div class="content">
              <div class="txt">Gegevens verzenden</div>
              <img src="@/assets/arrow.svg" alt>
            </div>
          </btn-big>
        </div>

        <form-message v-else-if="formSent == true" :option="'FOOTER'"></form-message>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  @gridRowGap: 2em;

  .contact-form {
    margin-top: pxToEm(-37);
    background-color: getColor('greyLight');

    .layout {
      .section {
        margin: pxToEm(25) pxToEm(20) pxToEm(10);
        text-align: left;

        #contact-form-top {
          position: absolute;
          top: pxToEm(-90);
        }

        .container-a {
          padding: pxToEm(70) 0;

          .title {
            .txt {
              font-size: pxToPercent(25);
              font-weight: 300;
              text-align: center;
            }
          }

          .error {
            color: getColor("redColor");
            font-style: italic;
          }

          .form {
            min-width: pxToEm(234);
            max-width: pxToEm(400);
            margin: pxToEm(30) auto 0;
            display: grid;
            grid-row-gap: @gridRowGap;

            .txt-label {
              font-size: pxToPercent(16);
              font-weight: 300;
              line-height: 2.2em;
            }

            .col-11 {
              height: pxToEm(86);
              display: grid;
              grid-template-rows: 1fr 1fr;
            }
          }

          .btn-big {
            margin: 0 auto;
            cursor: pointer;

            .content {
              margin-left: pxToEm(20);
              margin-right: pxToEm(20);

              .txt {
                margin-right: pxToEm(20);
                font-size: pxToPercent(16);
                font-weight: 700;
                text-align: left;
              }

              img {
                width: pxToEm(36);
                height: pxToEm(28);
              }
            }
          }

        }
        
        .form-message {
          margin: pxToEm(100) pxToEm(25);
        }
      }
    }
  }
</style>

<script>
  import btnBig from "@/components/common/btn-big";
  import inputText from "@/components/common/input-text";
  import formMessage from "@/components/common/form-message";
  import { _smallForm } from "@/data/contactData.js";
  import { goToAnchor, checkTab } from "@/JS/helpers";
  import { dataToMail, mails } from "@/JS/mail";

  export default {
    name: "mobContactForm",
    components: {
      btnBig,
      inputText,
      formMessage
    },
    data() {
      return {
        contactForm: {
          fields: new _smallForm(),
          state: undefined
        },
        formSent: false
      };
    },
    methods: {
      submitForm() {
        this.contactForm.state = checkTab(this.contactForm.fields);
        if (this.contactForm.state == false) {
          // goToAnchor("#form-error");
        }
        else {
          dataToMail({
            mailTo: mails.info,
            mailSubject: "Contact - Telefonisch Contact",
            mailData: this.contactForm.fields
          })
            .then(res => {
              //CODE FOR MAIL SENT /////////////
              // this.contactForm.fields = new _smallForm();
              // this.contactForm.state = undefined;
              this.formSent = true;
              goToAnchor("#contact-form-top");
              // console.log(res);
            })
            .catch(rej => {
              //CODE FOR MAIL NOT SENT /////////////
              this.formSent = false;
              // console.log(rej);
            });
        }
      }
    }
  };
</script>
