<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="pic" :style="{ 'background-image': 'url(' + headerImg + ')' }"></div>

        <div class="title">Herstelcode biedt mogelijkheden</div>

        <div class="text">
          Een achterstand op lopende leningen dient uiteraard eerst ingelopen te worden, voordat het op basis
          van een negatieve BKR registratie opnieuw mogelijk is om geld te lenen. Dit gebeurt om uzelf te
          beschermen tegen een te hoog oplopende maandtermijn. Zodra u de huidige lening heeft ingelopen is
          het van belang om een herstelcode (H) aan te laten brengen door de instantie die om de negatieve
          BKR registratie heeft gevraagd. Zodra die herstelcode er is kunnen we de volgende stap voorwaarts zetten.
        </div>

        <div class="subtitle">Lage rente voor lening na negatieve bkr registratie</div>

        <div class="text">
          Zelf bij een (oude) negatieve BKR registratie is het mogelijk om tegen een lage rente relatief
          voordelig geld te lenen. Om financiële problemen nu en in de toekomst te voorkomen, kijken we
          goed naar uw huidige en toekomstige situatie. Dat biedt de geldverstrekker meer zekerheid en
          voorkomt dat u opnieuw te maken zal krijgen met een negatieve BKR registratie. Onze adviseurs
          hebben een ruime ervaring met dergelijke gevallen. Daardoor kunnen wij er goed mee helpen. Ons
          advies is volledig kosteloos en de offerte is vrijblijvend, zodat u nergens direct aan vast
          zit. Neem contact met ons op voor meer informatie. Dat kan door online een aanvraag te doen,
          een livechat te starten of telefonisch contact op te nemen. Onze adviseurs staan voor u klaar.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  .layout {
    max-width: 964px;

    .section {
      margin: pxToEm(50);
      margin-bottom: pxToEm(150);
      display: flex;
      flex-direction: column;
      text-align: left;

      .pic {
        width: 100%;
        height: pxToEm(510);
        margin-top: pxToEm(40);
        background-position: top;
        background-size: cover;
      }

      .title {
        font-size: pxToPercent(50);
        font-weight: 700;
        text-decoration: underline;
        margin: 50px 0;
        margin-bottom:0;
      }

      .subtitle {
        margin-top: pxToEm(25);
        font-size: pxToPercent(36);
        font-weight: 700;
      }

      .text {
        margin-top: pxToEm(30);
        font-size: pxToPercent(18);
        line-height: 2.3em;
      }
    }
  }
}
</style>

<script>
import headerImg from "@/assets/bkr-img.jpg";

export default {
  name: "bkrBody",
  data() {
    return {
      headerImg
    };
  }
};
</script>
