<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="info">
            <div id="anchor-info-top" style="position:absolute; top:0px;"></div>

            <div class="title">KAN IK MIJN LENING VERZEKEREN?</div>

            <div class="paragraphs">
                <div class="paragraph">
                    <div class="subtitle">
                        <div>Goedkoopste lening</div>
                    </div>
                    <div class="text">
                        <div>
                            Een lening verzekeren of benieuwd naar de voordelen daarvan? We gaan er liever niet
                            vanuit, maar er kan ons allemaal onverwacht iets overkomen. In dat geval kan een
                            leningverzekering voorkomen dat er ongewenste financiële gevolgen ontstaan voor
                            anderen of uzelf. Door de lening te verzekeren kunt u voorkomen dat u het maandtermijn
                            van de lening niet meer kunt betalen of kunt u voorkomen dat nabestaanden daarmee te
                            maken krijgen. Een lening verzekeren hoeft bovendien niet duur te zijn, zodat het
                            verstandig is om u daar goed over te laten adviseren.
                        </div>
                    </div>

                    <div @click="toggleInfo(true)" v-if="!infoArea">
                        <btn-big class="secondary-pink" :width="231" :height="74">
                            <div class="content">
                                <div class="txt">Lees meer</div>
                                <img src="@/assets/arrow.svg" alt />
                            </div>
                        </btn-big>
                    </div>
                </div>

                <div class="expanded" v-if="infoArea">
                    <div class="paragraph">
                        <div class="subtitle">
                            <div>Kredieten verzekeren</div>
                        </div>
                        <div class="text">
                            <div>
                                We kunnen kredieten verzekeren en er daarmee voor zorgen dat u zich geen zorgen hoeft
                                te maken over de betaalbaarheid daarvan. Kunt u op basis van uw huidige situatie een
                                krediet dragen? Dankzij het verzekeren van leningen kunt u daar ook in de toekomst voor
                                blijven zorgen. We voorkomen met de leningverzekering dat een krediet ineens
                                onbetaalbaar wordt op het moment dat u arbeidsongeschikt of onvrijwillig werkloos wordt
                                of komt te overlijden. Dat geeft een belangrijke mate van rust. We adviseren u graag over
                                de mogelijkheden om een lening te verzekeren.
                            </div>
                        </div>
                    </div>

                    <div class="paragraph">
                        <div class="subtitle">
                            <div>Arbeidsongeschiktheid, overlijden<br>en werkloosheid</div>
                        </div>
                        <div class="text">
                            <div>
                                Het verzekeren van een lening beschermt u tegen de gevolgen van een aantal ingrijpende
                                gebeurtenissen. Raakt u arbeidsongeschikt? Het risico bestaat dat een aandoening of
                                ongeval ertoe leidt dat u niet meer kunt werken. De inkomsten dalen, waardoor het wellicht
                                lastig wordt om de maandtermijnen op te blijven brengen. Door de lening hier tegen te<
                                verzekeren hoeft u zich daar financieel in ieder geval geen zorgen over te maken.
                                <br><br>
                                Daarnaast kunt u een lening verzekeren tegen onvrijwillige werkloosheid en overlijden. In
                                de beide gevallen loopt u financieel geen risico met het krediet. De leningverzekering
                                neemt de maandtermijnen van u over op het moment dat u die door
                                arbeidsongeschiktheid, werkloosheid of overlijden niet meer kunt betalen.
                            </div>
                        </div>
                    </div>

                    <div class="paragraph">
                        <div class="subtitle">
                            <div>Advies over leningverzekering</div>
                        </div>
                        <div class="text">
                            <div>
                                Het hoeft niet duur te zijn om een lening te verzekeren, we kunnen u daar op een
                                voordelige wijze mee van dienst zijn. We adviseren u graag over de mogelijkheden en
                                kunnen u uiteraard een vrijblijvende offerte toesturen. Aan de hand daarvan kunt u nagaan
                                wat het kost om een verzekering voor uw lening af te sluiten en daarmee belangrijke
                                financiële risico’s af te dekken. Neem contact op met onze adviseurs voor meer informatie.
                                We zijn zowel telefonisch als via de livechat beschikbaar.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
        class="white-button"
        v-if="infoArea"
        @click="toggleInfo(false)"
        v-anchor="'#anchor-info-top'"
        >
        <div class="arrow-cont">
            <div class="arrow"></div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("whiteColor");

  .layout {

    .section {
        margin: pxToEm(25) pxToEm(30);
        text-align: left;

        &.expanded {
            height: auto;
        }

        .btn-big {
            margin: 50px 0;

            .content {
                margin: 0 20px;

                .txt {
                    font-size: pxToPercent(18);
                    font-weight: 800;
                }

                img {
                    width: pxToEm(36);
                    height: pxToEm(29);
                }
            }
        }

        .info {
            text-align: left;

            .title {
                font-size: pxToEm(30);
                font-weight: 700;
                text-decoration: underline;
            }

            .paragraphs {
                margin-top: pxToEm(30);

                .paragraph {
                    margin-bottom: pxToEm(40);

                    .subtitle {
                        margin-bottom: pxToEm(30);

                        div {
                            font-size: pxToEm(25);
                            font-weight: 700;
                        }
                    }

                    .text {
                        margin-bottom: pxToEm(30);

                        div {
                            font-size: pxToEm(16);
                            line-height: 2.1em;
                        }
                    }
                }
            }
        }

        .white-button {
            width: pxToEm(40);
            height: pxToEm(40);
            position: absolute;
            left: 50%;
            bottom: -45px;
            transform: translateX(-50%);
            border-radius: 50%;
            background-color: getColor("whiteColor");
            box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
            z-index: 100;
            cursor: pointer;

            .arrow-cont {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .arrow {
                    display: inline-block;
                    position: relative;
                    transform: rotate(-135deg);
                    margin-top: pxToEm(3.5);
                    padding: pxToEm(3.5);
                    border: solid getColor("primaryLight");
                    border-width: 0 pxToEm(2) pxToEm(2) 0;
                }
            }
        }
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";

export default {
    name: "mobSafeLoanInfo",
    components: {
        btnBig
    },
    data(){
        return {
            infoArea: false
        }
    },
    methods: {
        toggleInfo: function(state) {
            this.infoArea = state;
        }
    }
};
</script>