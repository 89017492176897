<template>
  <div class="test-page">
    <div class="blank"></div>
    <div class="components">

      <!-- <gallery ref="gal" 
        :view-slides="3"
        :classes="{
          slide: 'view-slide-',
          exitLeft: 'exit-left',
          exitRight: 'exit-right',
          immediate: 'immediate'
        }"
      >
        <div class="gal-title-container">
          <div class="gal-title">
            Três Tristes Testemunhos
          </div>
        </div>

        <div class="slide-container">
          <div class="slide">
            <div class="slide-title-container">
              <div class="slide-title">
                Slide 1: Olá
              </div>
            </div>
            <div class="slide-txt-container">
              <div class="slide-txt">
                “Esta agência de crédito é espectacular. Eu uma vez liguei para lá, e foram muito simpáticos comigo. Foi realmente espectacular. Quase tão espectacular, como daquela vez... ...ahhhhh, foi tão espectacular!...”
              </div>
            </div>
            <div class="slide-name-container">
              <div class="slide-name">
                Joanuel Manuão
              </div>
            </div>
          </div>
  
          <div class="slide">
            <div class="slide-title-container">
              <div class="slide-title">
                Slide 2: 5*
              </div>
            </div>
            <div class="slide-txt-container">
              <div class="slide-txt">
                “Uma experiência maravilhosa, com uma energia incrível o trabalho da Sophia e tão especial quanto ela. A consulta é calma e positiva mesmo quando nos sentimos perdidos e sem rumo. A Sophia aconselha o melhor caminho a seguir tem uma intuição fora do comum com uma voz tranquila que inspira confiança em toda a consulta. Sai mais leve com outras certezas e quando me sinto só e com duvidas, podemos sempre ouvir a gravação da consulta e da-nos mais confiança para continuar.”
              </div>
            </div>
            <div class="slide-name-container">
              <div class="slide-name">
                Ana Afonso
              </div>
            </div>
          </div>
  
          <div class="slide">
            <div class="slide-title-container">
              <div class="slide-title">
                Slide 3: Novo Rumo
              </div>
            </div>
            <div class="slide-txt-container">
              <div class="slide-txt">
                “Sou a Sandra. Desde cedo me atraiu o facto de poder fazer a diferença onde quer que esteja. Há algum tempo, decidi dar um rumo diferente à minha vida. Fiz votos de castidade, obediência e pobreza e a minha vida mudou. Sou consagrada secular. Trabalho numa empresa e vivo com a minha família. É neste mundo igual ao vosso que procuro ser um sinal de Deus. Vivo assim e é assim que sou feliz.”
              </div>
            </div>
            <div class="slide-name-container">
              <div class="slide-name">
                Sandra Casta
              </div>
            </div>
          </div>
  
          <div class="slide">
            <div class="slide-title-container">
              <div class="slide-title">
                Slide 4: Espetadora
              </div>
            </div>
            <div class="slide-txt-container">
              <div class="slide-txt">
                “Estava eu e o meu marido a namorar, logo pela manhã, quando sinto uma presença no quarto. Era a minha filha de 10 anos. “O que se passa aqui?”, perguntou ela com ar zangado, encostada à cama, com os braços cruzados e a bater com o pé no chão. Fiquei muito encavacada e a única coisa que me lembrei de dizer foi que estava a brincar com o pai às lutas. Nem sei há quanto tempo ela ali estava, nem o que tinha visto. Lá arranjei uma desculpa esfarrapada para ela sair, mas ficou desconfiada o resto do dia.”
              </div>
            </div>
            <div class="slide-name-container">
              <div class="slide-name">
                Luísa Oliveira
              </div>
            </div>
          </div>
  
          <div class="slide">
            <div class="slide-title-container">
              <div class="slide-title">
                Slide 5: Quadrados Vermelhos
              </div>
            </div>
            <div class="slide-txt-container">
              <div class="slide-txt">
                “Quando aconteceu, eu não estava à espera. Conheci-o numa festa, nas férias. Era bonito e senti uma grande atracção por ele. Parecia ser boa pessoa, mas, naquela mesma noite, o cordeiro despiu a pele e o lobo apareceu. [...] Ando a ser acompanhada há meses. Ainda tenho pesadelos, não suporto ver camisas aos quadrados vermelhos (como a que ele tinha, naquela noite).”
              </div>
            </div>
            <div class="slide-name-container">
              <div class="slide-name">
                Susana N., 19 anos
              </div>
            </div>
          </div>
  
          <div class="slide">
            <div class="slide-title-container">
              <div class="slide-title">
                Slide 6: Força Infinita
              </div>
            </div>
            <div class="slide-txt-container">
              <div class="slide-txt">
                “Fiz o Workshop da Força Infinita e superou as minhas expectativas. Durante o workshop entendi e senti como o meu cérebro estava condicionado por crenças negativas, cheio de receios e tenso no dia-a-dia e como o podia mudar e à minha energia. Durante os exercícios a minha energia cresceu tanto, especialmente ao sentir-me ligado à Força Infinita. Foi maravilhoso. Recuperei também a autoconfiança e o bem-estar. O grupo e o ambiente também era muito acolhedor.”
              </div>
            </div>
            <div class="slide-name-container">
              <div class="slide-name">
                Tozé Jacinto, Lisboa
              </div>
            </div>
          </div>
        </div>

        <div class="nav-container">
          <div class="nav-button nav-left" @click="galNavLeft">LEFT</div>
          <div class="nav-button nav-right" @click="galNavRight">RIGHT</div>
        </div>
      </gallery> -->

      <!-- <div class="blank"></div> -->

      <input-boolean
        v-model="$store.state._person.married"
      ></input-boolean>

      <div class="blank"></div>

      <input-date
        :disabled="$store.state._person.birthDate.ui.disabled"
        :init="$store.state._person.birthDate.ui.init"
        :valid="$store.state._person.birthDate.ui.valid"
        :placeholder="$store.state._person.birthDate.ui.placeholder"
        v-model="$store.state._person.birthDate"
      ></input-date>

      <div class="blank"></div>

      <input-dropdown
        :disabled="$store.state._person.livingState.ui.disabled"
        :init="$store.state._person.livingState.ui.init"
        :valid="$store.state._person.livingState.ui.valid"
        :options="$store.state.livingStateDropdown"
        :option-id="'id'"
        :option-label="'txt'"
        :placeholder="$store.state._person.livingState.ui.placeholder"
        v-model="$store.state._person.livingState"
      ></input-dropdown>

      <div class="blank"></div>

      <input-radio
        :disabled="$store.state._person.gender.ui.disabled"
        :init="$store.state._person.gender.ui.init"
        :valid="$store.state._person.gender.ui.valid"
        :optionA="'male'"
        :optionB="'female'"
        v-model="$store.state._person.gender"
      >
        <span slot="option1">Man</span>
        <span slot="option2">Vrouw</span>
      </input-radio>

      <div class="blank"></div>

      <div class="row">
        <input-slider
          class="primary"
          :disabled="$store.state._loanRequest.loanAmount.ui.disabled"
          :init="$store.state._loanRequest.loanAmount.ui.init"
          :css="$store.state.defaults.sliderCSSDesktop"
          :min-value="$store.state._loanRequest.loanAmount.ui.minVal"
          :max-value="$store.state._loanRequest.loanAmount.ui.maxVal"
          :step="$store.state._loanRequest.loanAmount.ui.step"
          v-model="$store.state._loanRequest.loanAmount"
        ></input-slider>
        <input-num
          :disabled="$store.state._loanRequest.loanAmount.ui.disabled"
          :init="$store.state._loanRequest.loanAmount.ui.init"
          :valid="$store.state._loanRequest.loanAmount.ui.valid"
          :min-len="$store.state._loanRequest.loanAmount.ui.minLen" :max-len="$store.state._loanRequest.loanAmount.ui.maxLen"
          :min-value="$store.state._loanRequest.loanAmount.ui.minVal"
          :max-value="$store.state._loanRequest.loanAmount.ui.maxVal"
          :step="$store.state._loanRequest.loanAmount.ui.step"
          :unit="$store.state._loanRequest.loanAmount.ui.unit"
          :placeholder="$store.state._loanRequest.loanAmount.ui.placeholder"
          v-model="$store.state._loanRequest.loanAmount"
        ></input-num>
      </div>

      <div class="blank"></div>

      <input-text
        :disabled="$store.state._person.firstName.ui.disabled"
        :init="$store.state._person.firstName.ui.init"
        :valid="$store.state._person.firstName.ui.valid"
        :placeholder="'Required field'"
        v-model="$store.state._person.firstName"
      ></input-text>

      <div class="blank"></div>

      <btn-big
        class="next secondary-pink"
        :width="500"
        :height="92"
      >
        <div class="content">
          <div class="txt">Volgende</div>
          <img src="@/assets/arrow.svg">
        </div>
      </btn-big>

    </div>
    <div class="blank"></div>
  </div>
</template>

<style lang="less" scoped>
  @textColor: #3a3a3a;
  @bgColor: #ddd;
  @whiteColor: #fff;
  @shadowColor: rgba(0, 0, 0, .2);
  @trans-dur: 500ms;
  @trans-ease: ease-in-out;

  .test-page {
    background-color: @bgColor;
  }

  .blank {
    height: pxToEm(50);
    background-color: @bgColor;
  }

  .components {
    margin-left: 100px;
    // font-family: 'Franklin Gothic Medium';
    background-color: @bgColor;
  }

  // Gallery
  .gallery {
    position: relative;
    width: 1000px;
    height: 700px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #eee;
    border: 1px solid #b6b6b6;
    overflow: hidden;
    
    .gal-title-container {
      margin: 30px;

      .gal-title {
        font-size: pxToEm(30);
      }
    }

    .slide-container {
      overflow: hidden;

      .slide {
        position: absolute;
        width: pxToEm(350);
        height: pxToEm(490);
        margin: 30px;
        background-color: @whiteColor;
        box-shadow: 0 0 15px @shadowColor;

        .slide-title-container {
          margin: 20px;

          .slide-title {
            font-size: pxToEm(24);
          }
        }

        .slide-txt-container {
          margin: 30px 30px 20px;

          .slide-txt {
            text-align: justify;
            color: textColor;
          }
        }

        .slide-name-container {
          margin: 10px 50px;

          .slide-name {
            text-align: right;
            font-weight: 700;
          }
        }
      }
    }

    .nav-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top:50%;

      .nav-button {
        position: absolute;
        width: 60px;
        transform: translateY(-50%);
        margin: 20px;
        padding: 10px;
        color: #3a3a3a;
        background-color: #eee;
        opacity: .9;
        border: 1px solid #b6b6b6;

        &:hover {
          color: #fff;
          background-color: #444;
          border-color: #222;
          cursor: pointer;
        }
      }

      .nav-left {
        left: 0;
      }

      .nav-right {
        right: 0;
      }
    }
    
    // Slide positioning:
    .exit-left {
      transition: all @trans-dur @trans-ease;
      transform: translateX(-115%);
      opacity: 0;
    }

    .view-slide-0 {
      transition: all @trans-dur @trans-ease;
      transform: translateX(0);
    }

    .view-slide-1 {
      transition: all @trans-dur @trans-ease;
      transform: translateX(115%);
    }

    .view-slide-2 {
      transition: all @trans-dur @trans-ease;
      transform: translateX(230%);
    }

    .exit-right {
      transition: all @trans-dur @trans-ease;
      transform: translateX(345%);
      opacity: 0;
    }

    .immediate {
      transition-duration: 0;
    }
  }

  .input-boolean {
    width: pxToEm(200);
  }

  .input-date {
    width: pxToEm(300);
  }

  .input-dropdown {
    width: pxToEm(500);
  }

  .input-radio {
    width: pxToEm(350);
  }

  .row {
    width: pxToEm(500);
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .input-num {
      width: pxToEm(180);
    }
  }

  .input-text {
    width: pxToEm(500);
  }
</style>

<script>
  import gallery from "@/components/common/gallery.vue";
  import inputBoolean from "@/components/common/input-boolean";
  import inputDate from "@/components/common/input-date";
  import inputDropdown from "@/components/common/input-dropdown";
  import inputNum from "@/components/common/input-num";
  import inputRadio from "@/components/common/input-radio";
  import inputSlider from "@/components/common/input-slider";
  import inputText from "@/components/common/input-text";
  import btnBig from "@/components/common/btn-big";

  export default {
    components: {
      gallery,
      inputBoolean,
      inputDate,
      inputDropdown,
      inputNum,
      inputRadio,
      inputSlider,
      inputText,
      btnBig
    },
    data: function () {
      return {
        textValue: 'Some text',

        // Gallery parameters
        // gal
      }
    },
    mounted: function () {
      // console.clear();
    },
    methods: {
      // galNavLeft() {
      //   this.$refs.gal.navigate(1);
      // },
      // galNavRight() {
      //   this.$refs.gal.navigate(-1);
      // },
    }
  };
</script>