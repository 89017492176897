<template>
  <div>
    <MobileSignupSectionBlock :block="block" v-if="isMobile"></MobileSignupSectionBlock>
    <DesktopSignupSectionBlock :block="block" v-else></DesktopSignupSectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>

import MobileSignupSectionBlock from "@/components/strapi/SignupSection/MobileSignupSectionBlock";
import DesktopSignupSectionBlock from "@/components/strapi/SignupSection/DesktopSignupSectionBlock";

export default {
  name: 'SignupSectionBlock',
  components: {
    DesktopSignupSectionBlock,
    MobileSignupSectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
