<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <h1 class="title" v-if="block.Title">{{block.Title}}</h1>
        <div class="pink"></div>

        <div class="dropdown-container">
          <div class="introtitle" v-if="block.Introtitle">{{block.Introtitle}}</div>

          <div class="container">

            <div id="form-top-custom"></div>

            <div
              class="txt"
              v-if="block.Subtitle"
            >{{block.Subtitle}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .area {
    background-color: getColor("greyLight");

    &.white {
      background-color: #fff;
    }

    .layout {
      .section {
        margin: pxToEm(50);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .input {
          height: pxToEm(62);
        }

        .title {
          font-size: pxToPercent(70);
          font-weight: 800;
          margin-top: 0;
          margin-bottom: 0;
        }

        .pink {
          width: 123px;
          height: 11px;
          background-color: getColor("primaryLight");
        }

        .dropdown-container {
          display: flex;
          flex-direction: column;
          max-width: 964px;
          margin-top: pxToEm(50);
          width: 100%;

          .introtitle {
            font-size: 150%;
            font-weight: 700;
          }

          .container {
            padding: pxToEm(20) pxToEm(90) pxToEm(20);

            .txt {
              font-size: pxToPercent(20);
              line-height: 2;
            }

            .title {
              font-size: 225%;
              font-weight: 300;
              margin-bottom: pxToEm(30);
            }

            .select {
              position: absolute;
              width: pxToEm(500);
              transform: translate(-50%);
              left: 50%;
              z-index: 200;
            }
          }
        }
      }
    }
  }
</style>

<script>
  import {strapiAssetUrl} from "@/JS/helpers";
  import {marked} from 'marked'

  export default {
    name: "DesktopSimpleHeader",
    methods: {
      markdownToHTML: function(markdown) {
        return marked(markdown);
      },
      strapiAssetUrl: function (path) {
        return strapiAssetUrl(path)
      }
    },
    props: {
      block: Object
    }
  };
</script>
