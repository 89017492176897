<template>
  <div>
    <MobileContactUsSectionBlock :block="block" v-if="isMobile"></MobileContactUsSectionBlock>
    <DesktopContactUsSectionBlock :block="block" v-if="!isMobile"></DesktopContactUsSectionBlock>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import MobileContactUsSectionBlock from "@/components/strapi/ContactUs/MobileContactUsSectionBlock";
import DesktopContactUsSectionBlock from "@/components/strapi/ContactUs/DesktopContactUsSectionBlock";

export default {
  name: 'ContactUsSectionBlock',
  components: {
    MobileContactUsSectionBlock,
    DesktopContactUsSectionBlock
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
