<template>
  <div v-if="isMobile">
    <CenterTextBlockDefaultMobile v-if=" ! block.ReadMore || block.ReadMore.length == 0 " :block="block" />
    <CenterTextBlockReadMoreMobile v-if="block.ReadMore && block.ReadMore.length > 0" :block="block" />
  </div>
  <div v-else-if="!isMobile">
    <CenterTextBlockDefault v-if=" ! block.ReadMore || block.ReadMore.length == 0 " :block="block" />
    <CenterTextBlockReadMore v-if="block.ReadMore && block.ReadMore.length > 0" :block="block" />
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import CenterTextBlockReadMore from './CenterText/CenterTextBlockReadMore'
import CenterTextBlockDefault from './CenterText/CenterTextBlockDefault'
import CenterTextBlockReadMoreMobile from './CenterText/CenterTextBlockReadMoreMobile'
import CenterTextBlockDefaultMobile from './CenterText/CenterTextBlockDefaultMobile'

export default {
  name: 'CenterTextBlock',
  components: {
    CenterTextBlockReadMore,
    CenterTextBlockDefault,
    CenterTextBlockReadMoreMobile,
    CenterTextBlockDefaultMobile
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
