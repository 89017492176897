<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="pic" :style="{ 'background-image': 'url(' + headerImg + ')' }"></div>

      <div id="anchor-container"></div>

      <div class="section">
        <div class="container">
          <div class="title">
           {{ block.Title }}
          </div>

          <div class="subtitle">{{ block.Subtitle }}</div>

           <input-dropdown
              v-if="block.Vacancies.data"
              :options="setOptions(block.Vacancies.data)"
              :placeholder="setOptions(block.Vacancies.data)[0]"
              v-model="dropdownIndex"
            ></input-dropdown>
            <!-- block.contents -->
            <div
              v-for="(data, index) in block.Vacancies.data"
              :key="`data-${index}`"
              class="white-container-txt"
              v-if="block.Vacancies.data.length > 0 && dropdownIndex == index"
            >
            
                <div class="cont-title"  v-if="data.attributes.Title">
                  <div class="txt">{{ data.attributes.Title }}</div>
                </div>
                <div class="cont-body" v-html="markdownToHTML(data.attributes.Description)"></div>
              </div>
            
          </div>

          <div
            class="white-button"
            v-if="dropdownIndex == 0"
            @click="collapseForm"
            v-anchor="'#anchor-container'"
          >
            <div class="arrow-cont">
              <div class="arrow"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>

.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {

    .pic {
      width: 100%;
      height: 52vw;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .section {
      margin: pxToEm(25) pxToEm(10);

      .container {
        margin: pxToEm(15) pxToEm(25) pxToEm(25);

        .title {
          margin-bottom: pxToEm(10);
          font-size: pxToPercent(36);
          font-weight: 300;
          line-height: 1.1em;
        }

        .subtitle {
          margin-bottom: pxToEm(20);
          font-size: pxToPercent(16);
          font-weight: 300;
        }

        .cont-txt {
          text-align: left;
          line-height: 2.1em;

          .cont-title {
            margin-top: pxToEm(40);
            margin-bottom: pxToEm(20);

            .txt {
              font-size: pxToEm(20);
              font-weight: 700;
            }
          }

          .cont-body {
            font-size: pxToEm(16);

            .bullets {
              margin: pxToEm(20) 0;
              list-style-type: none;

              > li:before {
                content: "-";
                position: absolute;
                margin-left: -1em;
              }
            }

            .email {
              color: getColor("primaryLight");
              text-decoration: underline;
            }

            .number {
              font-weight: 600;
            }
          }
        }

        .white-button {
          width: pxToEm(50);
          height: pxToEm(50);
          position: absolute;
          left: 50%;
          bottom: pxToEm(-50);
          transform: translateX(-50%);
          border-radius: 50%;
          background-color: getColor("whiteColor");
          box-shadow: 0 0 pxToEm(5) rgba(0, 0, 0, 0.2);
          z-index: 100;
          cursor: pointer;

          .arrow-cont {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .arrow {
              position: relative;
              margin-top: pxToEm(5);
              padding: pxToEm(5);
              transform: rotate(-135deg);
              display: inline-block;
              border: solid getColor("primaryLight");
              border-width: 0 pxToEm(3) pxToEm(3) 0;
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import {marked} from "marked";
import headerImg from "@/assets/about-second-header.jpg";
import inputDropdown from "@/components/common/input-dropdown";

export default {
  name: 'MobileVacancySectionBlock',
  props: {
    block: Object
  },
  components: {
    inputDropdown
  },
  data() {
    return {
      headerImg,
      dropdownIndex: null
    };
  },
  mount(){
    console.log("this.block ",this.block)
  },
  methods: {
    markdownToHTML: function (markdown) {
      return marked(markdown);
    },
    collapseForm() {
      this.dropdownIndex = null;
    },
     setOptions(options){
      return options.map(({attributes}) => attributes.Title)
    }
  },
}
</script>
