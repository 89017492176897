<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="info texts">
          <div id="anchor-info-top" style="position:absolute; top:0px;"></div>

          <div class="title">KAN IK MIJN LENING<br>VERZEKEREN?</div>

          <div class="paragraphs">
            <div class="paragraph">
              <div class="text-cont">
                <div class="text">
                  Een lening verzekeren of benieuwd naar de voordelen daarvan? We gaan er liever niet
                  vanuit, maar er kan ons allemaal onverwacht iets overkomen. In dat geval kan een
                  leningverzekering voorkomen dat er ongewenste financiële gevolgen ontstaan voor
                  anderen of uzelf. Door de lening te verzekeren kunt u voorkomen dat u het maandtermijn
                  van de lening niet meer kunt betalen of kunt u voorkomen dat nabestaanden daarmee te
                  maken krijgen. Een lening verzekeren hoeft bovendien niet duur te zijn, zodat het
                  verstandig is om u daar goed over te laten adviseren.
                </div>
              </div>

              <div @click="toggleInfo(true)" v-if="!infoArea">
                <btn-big class="secondary-pink" :width="231" :height="74">
                  <div class="content">
                    <div class="txt">Lees meer</div>
                    <img src="@/assets/arrow.svg" alt/>
                  </div>
                </btn-big>
              </div>
            </div>

            <div class="expanded" v-if="infoArea">
              <div class="paragraph">
                <div class="subtitle">
                  <div>Kredieten verzekeren</div>
                </div>
                <div class="text-cont">
                  <div class="text">
                    We kunnen kredieten verzekeren en er daarmee voor zorgen dat u zich geen zorgen hoeft
                    te maken over de betaalbaarheid daarvan. Kunt u op basis van uw huidige situatie een
                    krediet dragen? Dankzij het verzekeren van leningen kunt u daar ook in de toekomst voor
                    blijven zorgen. We voorkomen met de leningverzekering dat een krediet ineens
                    onbetaalbaar wordt op het moment dat u arbeidsongeschikt of onvrijwillig werkloos wordt
                    of komt te overlijden. Dat geeft een belangrijke mate van rust. We adviseren u graag over
                    de mogelijkheden om een lening te verzekeren.
                  </div>
                </div>
              </div>

              <div class="paragraph">
                <div class="subtitle">
                  <div>Arbeidsongeschiktheid, overlijden<br>en werkloosheid</div>
                </div>
                <div class="text-cont">
                  <div class="text">
                    Het verzekeren van een lening beschermt u tegen de gevolgen van een aantal ingrijpende
                    gebeurtenissen. Raakt u arbeidsongeschikt? Het risico bestaat dat een aandoening of
                    ongeval ertoe leidt dat u niet meer kunt werken. De inkomsten dalen, waardoor het wellicht
                    lastig wordt om de maandtermijnen op te blijven brengen. Door de lening hier tegen te
                    verzekeren hoeft u zich daar financieel in ieder geval geen zorgen over te maken.
                    <br><br>
                    Daarnaast kunt u een lening verzekeren tegen onvrijwillige werkloosheid en overlijden. In
                    de beide gevallen loopt u financieel geen risico met het krediet. De leningverzekering
                    neemt de maandtermijnen van u over op het moment dat u die door
                    arbeidsongeschiktheid, werkloosheid of overlijden niet meer kunt betalen.
                  </div>
                </div>
              </div>

              <div class="paragraph">
                <div class="subtitle">
                  <div>Advies over leningverzekering</div>
                </div>
                <div class="text-cont">
                  <div class="text">
                    Het hoeft niet duur te zijn om een lening te verzekeren, we kunnen u daar op een
                    voordelige wijze mee van dienst zijn. We adviseren u graag over de mogelijkheden en
                    kunnen u uiteraard een vrijblijvende offerte toesturen. Aan de hand daarvan kunt u nagaan
                    wat het kost om een verzekering voor uw lening af te sluiten en daarmee belangrijke
                    financiële risico’s af te dekken. Neem contact op met onze adviseurs voor meer informatie.
                    We zijn zowel telefonisch als via de livechat beschikbaar.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="white-button"
          v-if="infoArea"
          @click="toggleInfo(false)"
          v-anchor="'#anchor-info-top'"
        >
          <div class="arrow-cont">
            <div class="arrow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.area {
  background-color: getColor("whiteColor");

  .layout {
    align-items: center;

    .section {
      margin: 0 pxToEm(50);
      margin-top: pxtoEm(50);
      max-width: pxToEm(1160);
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      width:100%;
      @media (max-width:999px) {
        margin: 0;
      }

      &.expanded {
        height: auto;
      }

      .info {
        text-align: left;
        margin: pxToEm(70);

        @media (max-width:999px) {
          margin: pxToEm(70) 0;
        }

        margin-top: 0;

        .title {
          font-size: pxToEm(60);
          font-weight: 700;
          text-decoration: underline;

          @media (max-width:999px) {
            font-size: pxToEm(36);
            //padding: 0 pxToEm(15);
            padding: 0;
          }
        }

        .btn-big {
          margin: 50px 0;

          .content {
            margin: 0 20px;

            @media (max-width:999px) {
              margin: 0 0;
            }

            .txt {
              font-size: pxToPercent(18);
              font-weight: 800;
            }

            img {
              width: pxToEm(36);
              height: pxToEm(29);
            }
          }
        }

        .paragraphs {
          margin-top: pxToEm(30);

          .paragraph {
            margin-bottom: pxToEm(60);
            padding: 0;

            @media (min-width: 1000px) {
              padding: 0;
              width: 778px;
            }

            .subtitle {
              margin-bottom: pxToEm(30);

              div {
                font-size: pxToEm(36);
                font-weight: 700;
              }
            }

            .text-cont {
              margin-bottom: pxToEm(30);

              .text {
                font-size: pxToEm(18);
                line-height: 2.1em;
              }
            }
          }
        }
      }
    }
  }
}
@media(max-width:450px){
     .area .layout .section .info .paragraphs .paragraph .subtitle {
              div {
                font-size: pxToEm(30);
              }
            }
}
</style>
<script>
import btnBig from "@/components/common/btn-big";

export default {
  name: "safeLoanInfo",
  components: {
    btnBig
  },
  data() {
    return {
      infoArea: false
    }
  },
  methods: {
    toggleInfo: function (state) {
      this.infoArea = state;
    }
  }
};
</script>
