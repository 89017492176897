<template>
  <div>
    <div class="area">
        <div class="layout">
            <div class="section">
                <div class="title">Informatie</div>
                <div class="dash"></div>
                <div class="pic" style="background-image: url('img/doorlopend-header.be727f68.jpg');"></div>
            </div>
        </div>
    </div>

    <calculator class="area white"></calculator>

    <div class="area">
      <div class="layout">
        <div class="section">
          <div class="gap"></div>
          <div class="texts">
              <div class="txt">
                  <div class="title">Informatie over leningen</div>
                  <div class="body">
                    <p>Hieronder heb je een overzicht van alle leningsvormen en leendoelen.</p>
                    <div class="links">
                      <div class="link-part">
                        <div>
                          <a href="/ik-ben-zelfstandige">Ik ben zelfstandige</a>
                        </div>
                        <div>
                          <a href="/doorlopend-krediet">Doorlopend krediet</a>
                        </div>
                        <div>
                          <a href="/financial-lease">Financial lease</a>
                        </div>
                        <div>
                          <a href="/ik-ben-gepensioneerd">Ik ben gepensioneerd</a>
                        </div>
                        <div>
                          <a href="/ik-ben-in-loondienst">Ik ben in loondienst</a>
                        </div>
                        <div>
                          <a href="/ik-heb-een-eigen-huis">Ik heb een eigen huis</a>
                        </div>
                        <div>
                          <a href="/ik-ben-bij-het-bkr-geregistreerd">Ik heb een negatieve BKR-registratie</a>
                        </div>
                        <div>
                          <a href="/ik-heb-een-tijdelijk-dienstverband">Ik heb een tijdelijk dienstverband</a>
                        </div>
                        <div>
                          <a href="/ik-werk-via-een-uitzendbureau">Ik werk via een uitzendbureau</a>
                        </div>
                        <div>
                          <a href="/krediet-voor-zelfstandigen">Krediet voor zelfstandigen</a>
                        </div>
                        <div>
                          <a href="/lening-oversluiten">Lening oversluiten</a>
                        </div>
                        <div>
                          <a href="/lening-voor-een-auto">Lening voor een auto</a>
                        </div>
                        <div>
                          <a href="/lening-voor-een-boot">Lening voor een boot</a>
                        </div>
                        <div>
                          <a href="/lening-voor-een-camper">Lening voor een camper</a>
                        </div>
                        <div>
                          <a href="/lening-voor-een-caravan">Lening voor een caravan</a>
                        </div>
                      </div>
                      <div class="link-part">
                        <div>
                          <a href="/lening-voor-een-keuken">Lening voor een keuken</a>
                        </div>
                        <div>
                          <a href="/lening-voor-een-motor">Lening voor een motor</a>
                        </div>
                        <div>
                          <a href="/lening-voor-een-elektrische-fiets">Lening voor een elektrische fiets</a>
                        </div>
                        <div>
                          <a href="/lening-voor-een-studie">Lening voor een studie</a>
                        </div>
                        <div>
                          <a href="/lening-voor-een-verbouwing">Lening voor een verbouwing</a>
                        </div>
                        <div>
                          <a href="/lening-voor-financiele-ruimte">Lening voor financiële ruimte</a>
                        </div>
                        <div>
                          <a href="/lening-voor-onverwachte-uitgaven">Lening voor onverwachte uitgaven</a>
                        </div>
                        <div>
                          <a href="/lening-voor-restschuld-hypotheek">Lening voor restschuld hypotheek</a>
                        </div>
                        <div>
                          <a href="/lening-voor-uitkoop-ex-partner">Lening voor uitkoop ex partner</a>
                        </div>
                        <div>
                          <a href="/lening-voor-vakantie">Lening voor vakantie</a>
                        </div>
                        <div>
                          <a href="/lening-voor-zonnepanelen">Lening voor zonnepanelen</a>
                        </div>
                        <div>
                          <a href="/persoonlijke-lening">Persoonlijke lening</a>
                        </div>
                        <div>
                          <a href="/perspectieflening">Perspectieflening</a>
                        </div>
                        <div>
                          <a href="/seniorenlening">Seniorenlening</a>
                        </div>
                        <div>
                          <a href="/tweede-hypotheek">Tweede hypotheek</a>
                        </div>
                        <div>
                          <a href="/woz-krediet">Woz krediet</a>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <desk-contact class="area"></desk-contact>

  </div>
</template>

<style lang="less" scoped>
  @import '../../../less/pages.less';

  .links {
    display: flex;

    .link-part {
      width: 50%;
    }
  }
</style>

<script>
import btnBig from "@/components/common/btn-big";
import calculator from "@/components/desktop/calculators/A/calculator-form.vue";
import trustpilot from "@/components/common/trustpilot";
import headerImg from "@/assets/doorlopend-header.jpg";
import LoanInfo from "@/components/desktop/loan-bottom-info";
import DeskContact from "@/components/desktop/desk-contact";
import RentePercentageTabel from "@/components/common/rente-percentage-table";

export default {
  name: "informatieComplete",

  components: {
    DeskContact,
    LoanInfo,
    headerImg,
    calculator,
    btnBig,
    trustpilot,
    RentePercentageTabel
  },

  data() {
    return {
      headerImg
    };
  },

  methods: {
    pxToEm(val) {
      return val / $store.state.defaults.defaultFontSize + "em";
    },
    toggleForm(state) {
      // TODO add logic
    }
  },
};
</script>
