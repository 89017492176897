<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="title">Persoonlijke lening</div>

        <div class="dash"></div>

        <div class="items">
          <div class="item">
            <img src="@/assets/persoonlijke_img1.jpg">
            <div class="text">
              <div class="pink">
                <div class="txt">AUTO</div>
              </div>
              <div class="black">Kom je me morgen weer ophalen?</div>
              <div class="light">
                <div class="txt">Iederde dag plezier van een lage rente.</div>
              </div>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/persoonlijke_img2.jpg">
            <div class="text">
              <div class="pink">
                <div class="txt">KEUKEN</div>
              </div>
              <div class="black">Het koken is bijna leuker dan het opeten…</div>
              <div class="light">
                <div class="txt">Fijn eerlijk geld lenen tegen een lage rente.</div>
              </div>
            </div>
          </div>

          <div class="item">
            <img src="@/assets/persoonlijke_img3.jpg">
            <div class="text">
              <div class="pink">
                <div class="txt">VAKANTIE</div>
              </div>
              <div class="black">Zon, strand & zee. Anders nog iets?</div>
              <div class="light">
                <div class="txt">Samen plezier van eerlijk geld lenen.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  min-height: pxToEm(885);
  background-color: getColor("greyLight");
  display: flex;

  .layout {
    max-width: pxToEm(1162);
    margin-bottom: 50px;

    .section {
      margin: pxToEm(50);
      display: flex;
      flex-direction: column;

      .title {
        font-size: pxToEm(70);
        font-weight: 800;
        line-height: 1em;
        min-height: 90px;

        span {
          color: getColor("primaryLight");
        }
      }

      .dash {
        width: pxToEm(123);
        height: pxToEm(11);
        background-color: getColor("primaryLight");
        margin-top: pxToEm(20);
        align-self: center;
      }

      .items {
        display: flex;
        justify-content: center;
        margin-top: pxToEm(60);

        .item {
          width: 33%;
          margin: 0 pxToEm(20) 0 0;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          overflow: hidden;

          img {
            width: 140%;
            align-self: center;
          }

          &:last-child {
            margin: 0;
          }

          .text {
            display: flex;
            flex-direction: column;
            min-height: pxToEm(225);
            padding: 0 pxToEm(30);

            .pink {
              padding: pxToEm(20) 0;

              .txt {
                color: getColor("primaryLight");
                font-size: pxToPercent(18);
                font-weight: 700;
              }
            }

            .black {
              font-size: pxToPercent(23);
              font-weight: 700;
            }

            .light {
              padding: pxToEm(20) 0;

              .txt {
                font-size: pxToPercent(16);
              }
            }
          }
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "persoonlijkeHeader"
};
</script>
