<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <MobLogoSection v-if="$store.state.deviceState == $store.state.Device.MOBILE"></MobLogoSection>
    <div class="layout">
      <div class="section">
        <div class="container-a">
          <div class="title">
            <span>{{block.highlight}}</span>{{block.text}}
          </div>
          <div class="dash"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    overflow: hidden;

    .section {
      margin: pxToEm(25) pxToEm(10);
      text-align: left;

      .container-a {
        padding: 0 pxToEm(25);

        .title {
          font-size: pxToPercent(28);
          font-weight: 800;
          line-height: 1em;

          span {
            color: getColor("primaryLight");
          }
        }

        .dash {
          margin-top: pxToEm(10);
          width: pxToEm(91);
          height: pxToEm(8);
          background-color: getColor("primaryLight");
        }
      }
    }
  }
}
</style>

<script>
import MobLogoSection from '../../mobile/mob-logo-section.vue';

export default {
    name: "MobileColorHeaderBlock",
    props: {
        block: Object
    },
    components: { MobLogoSection }
}
</script>
