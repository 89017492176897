<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="title">Financial lease</div>

        <div class="dash"></div>

        <div class="txt">
          <div class="bold">Een nieuwe bedrijfsauto?</div>
          <div
            class="regular"
          >Eigenaar van een nieuwe bedrijfsauto en uw bedrijfsvermogen blijft onaangetast.</div>
        </div>

        <div class="pic" :style="{ 'background-image': 'url(' + headerImg + ')' }"></div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.area {
  min-height: pxToEm(670);
  background-color: getColor("greyLight");
  display: flex;

  .layout {
    width: pxToEm(965);

    .section {
      margin: pxToEm(50);
      margin-bottom: pxToEm(100);
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: pxToPercent(70);
        font-weight: 800;
        line-height: 1em;
      }

      .dash {
        width: pxToEm(123);
        height: pxToEm(11);
        background-color: getColor("primaryLight");
        margin-top: pxToEm(20);
      }

      .txt {
        margin-top: pxtoEm(50);

        .bold {
          font-size: pxToPercent(24);
          font-weight: 600;
        }

        .regular {
          font-size: pxToPercent(18);
          font-weight: 400;
          margin-top: 20px;
        }
      }

      .pic {
        width: 100%;
        height: pxToEm(350);
        margin-top: pxToEm(40);
        background-position: top;
        background-size: cover;
      }
    }
  }
}
</style>
<script>
import headerImg from "@/assets/finantial-lease-header.jpg";

export default {
  name: "finantialLeaseHeader",
  data() {
    return {
      headerImg
    };
  }
};
</script>
