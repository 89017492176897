import { render, staticRenderFns } from "./DesktopHomeHeaderBlock.vue?vue&type=template&id=4f066dff&scoped=true&"
import script from "./DesktopHomeHeaderBlock.vue?vue&type=script&lang=js&"
export * from "./DesktopHomeHeaderBlock.vue?vue&type=script&lang=js&"
import style0 from "./DesktopHomeHeaderBlock.vue?vue&type=style&index=0&id=4f066dff&lang=less&scoped=true&"
import style1 from "./DesktopHomeHeaderBlock.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f066dff",
  null
  
)

export default component.exports