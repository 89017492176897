<template>
  <div>
    <div class="layout">
      <div class="section">
        <div id="anchor-top-contact"></div>

        <div class="title">
          <div class="txt">Contact opnemen</div>
        </div>

        <div class="contact txt">
          Wij zijn bereikbaar van maandag t/m vrijdag tussen
          <span>09:00 uur en 17:00 uur.</span>
          <br>Vul uw contactgegevens in en wij nemen zo spoedig mogelijk contact met u op.
        </div>

        <div id="contact-form-top"></div>

        <btn-big class="secondary" :width="448" :height="106" :click="() => toggleContact(1)" v-anchor="'#contact-form-top'">
          <div class="content">
            <div class="txt">Neem contact op met mij</div>
            <img src="@/assets/arrow.svg" alt>
          </div>
        </btn-big>
      </div>
    </div>

    <contact-form v-if="contactActive==1"></contact-form>

    <div class="white-button" v-show="contactActive" v-anchor="'#anchor-top'" @click="toggleContact(0)">
      <div class="arrow-cont">
        <div class="arrow"></div>
      </div>
    </div>

    <contact-icons></contact-icons>


  </div>
</template>

<style lang="less" scoped>
  .area {
    min-height: pxToEm(500); //828
    display: flex;
    flex-direction: column;

    .layout {
      width: 100%;
      max-width: 964px;

      .section {
        padding-top: pxToEm(100);
        padding-bottom: pxToEm(100);

        a {
          text-decoration: none;
        }

        .title {
          .txt {
            font-size: pxToPercent(80);
            line-height: 1em;
            color: #3a3a3a;
            font-weight: 300;
          }
        }

        .contact {
          margin: pxToEm(40) pxToEm(100);
          line-height: 2.1em;
          .txt {
            font-size: pxToPercent(20);
            color: #3a3a3a;
          }

          span {
            text-decoration: underline;
          }
        }

        #contact-form-top {
          position: relative;
          top: pxToEm(-20);
        }

        .btn-big {
          width: pxToEm(400);
          height: pxToEm(104);
          margin: pxToEm(55) auto;
          cursor: pointer;

          .content {
            margin-left: pxToEm(40);
            margin-right: pxToEm(40);
            .txt {
              font-size: pxToPercent(21);
              font-weight: 700;
            }
            img {
              width: pxToEm(56);
              height: pxToEm(45);
            }
          }
        }
      }
    }

    .white-button {
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      width: pxToEm(50);
      height: pxToEm(50);
      position: absolute;
      bottom: 245px;
      z-index: 100;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;

      .arrow-cont {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        .arrow {
          border: solid getColor("greenDefault");
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 5px;
          transform: rotate(-135deg);
          position: relative;
          margin-top: 5px;
        }
      }
    }
  }
</style>
<style lang="less">
  .footer {
    &.active {
      box-shadow: 0px 50px 95px rgba(0, 0, 0, 0.5);
    }
  }
  .btn-big {
    .content {
      .txt {
        font-size: pxToPercent(28);
      }
    }
  }
</style>

<script>
  import btnBig from "@/components/common/btn-big";
  import contactIcons from "@/components/common/contact-icons.vue";
  import contactForm from "@/components/desktop/desk-contact-form";
  import { goToAnchor, checkTab } from "@/JS/helpers";

  export default {
    name: "deskContact",
    components: {
      btnBig,
      contactForm,
      contactIcons
    },
    data() {
      return {
        contactActive: 0
      };
    },
    methods: {
      toggleContact(val) {
        this.contactActive = val;
      }
    }
  };
</script>
