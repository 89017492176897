import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vueHeadful from 'vue-headful';

Vue.config.productionTip = false

import defaults from './defaults.js';
import { goToAnchor, assignSet } from "@/JS/helpers";

assignSet();

Vue.filter('currency', function (value, unit, decimals) {
  if (!value) return '';
  value = (unit ? unit + " " : "") + value.toLocaleString('nl-NL', { maximumFractionDigits: decimals });
  return value;
});

Vue.component('vue-headful', vueHeadful);

new Vue({
  router,
  store,
  created() {
    var checkDevice = () => {
      var device;
      if (window.innerWidth > 1240)
        device = $store.state.Device.DESKTOP
      else if (window.innerWidth > 768)
        device = $store.state.Device.TABLET
      else
        device = $store.state.Device.MOBILE

      if (device != $store.state.lastDeviceState) {
        $store.state.lastDeviceState = device;
        $store.commit('changeDevice', device);
        window.location.reload();
      }
      this.$router.beforeEach((to, from, next) => {
        window.document.querySelector('.scroll-container').scrollTop = 0;
        next();
      });
    }

    window.addEventListener("resize", checkDevice);
    checkDevice();
    $store.commit('setDefaults', defaults);
  },
  render: h => h(App)
}).$mount('#app')


Vue.directive('expand', {
  inserted: function (el, binding, vnode) {
    var val = binding.value;
    var target = el.closest(".exp-item");

    function toggle() {
      if (val == true) {
        target.classList.add("expanded");
      } else {
        target.classList.remove("expanded")
      }
    }

    toggle();

    el.addEventListener("click", () => {
      if (val == undefined) val = false;
      val = !val;
      toggle();
    });

    vnode.context.$on('expand', function ($el, state) {
      if (target == $el) {
        val = state;
        toggle();
      }
    });
  }
});

Vue.directive('anchor', {
  inserted: function (el, binding, vnode) {
    var anchorID = binding.value;
    el.addEventListener('click', () => goToAnchor(anchorID));
  }
});

Vue.directive('registerForm', {
  inserted: function (el, binding, vnode) {
    function match (obj, key, el) {
      var res;
      for (var i = 0; i < obj.length; ++i) {
        if (obj[i][key] == el) {
          res = i;
          break;
        }
      }
      return res;
    }

    var $title = el.querySelector('.exp-item-title-container');
    var $next = el.querySelector('.next');

    var onclickTitle = function () {
      var cursor = match($store.state.formsOfferte, 'title', $title);
      $store.state.formsOfferte[cursor].expand = !$store.state.formsOfferte[cursor].expand;
    }

    var onclickNext = function () {
      var cursor = match($store.state.formsOfferte, 'next', $next);
      var funnel = vnode.context.loanRequest.selectedFunnel;
      var gap = funnel == 0 ? 1 : 0;
      switch (cursor) {
        case 0:
          $store.state.formsOfferte[cursor + gap + 1].expand = true;
          break;
        default:
          $store.state.formsOfferte[cursor + 1].expand = true;
      }
    }

    $store.state.formsOfferte.push({
      $el: el,
      expand: binding.value,
      title: $title,
      next: $next,
      nextHandler: onclickNext
    });

    if ($title != undefined) {
      $title.addEventListener('click', onclickTitle);
    }

    if ($next != undefined) {
      $next.addEventListener('click', onclickNext);
    }
  }
  // unbind: function (el, binding, vnode) {
  //   if ($title != undefined) {
  //     $title.addEventListener('click', );
  //   }

  //   if ($next != undefined) {
  //     $next.addEventListener('click', );
  //   }
  // }
});
