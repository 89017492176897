<template>
  <div>
    <MobileSimpleHeader :block="block" v-if="isMobile"></MobileSimpleHeader>
    <DesktopSimpleHeader :block="block" v-else></DesktopSimpleHeader>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import MobileSimpleHeader from "@/components/strapi/SimpleHeader/MobileSimpleHeader";
import DesktopSimpleHeader from "@/components/strapi/SimpleHeader/DesktopSimpleHeader";

export default {
  name: 'SimpleHeaderBlock',
  components: {
    MobileSimpleHeader,
    DesktopSimpleHeader
  },
  props: {
    block: Object
  },
  computed: {
    isMobile: function ({$store}) {
      return $store.state.lastDeviceState == window.$store.state.Device.MOBILE;
    }
  }
}
</script>
