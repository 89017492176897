<template>
  <div>
    <div class="layout">
      <div class="section">
        <div class="title">Doorlopend krediet</div>

        <div class="dash"></div>

        <div class="txt">
          <div class="bold">Tijdelijk wat extra geld achter de hand houden?</div>
          <div class="regular">Het doorlopend krediet is daarvoor uitstekend geschikt.</div>
        </div>

        <div class="pic" :style="{ 'background-image': 'url(' + headerImg + ')' }"></div>
      </div>
      

     
    </div>
  </div>
</template>
<style lang="less" scoped>
.area {
  min-height: pxToEm(670);
  background-color: getColor("greyLight");
  display: flex;

  .layout {
    width: pxToEm(965);

    .section {
      margin: pxToEm(50);
      margin-bottom: pxToEm(100);
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: pxToPercent(70);
        font-weight: 800;
        line-height: 1em;
      }

      .dash {
        width: pxToEm(123);
        height: pxToEm(11);
        background-color: getColor("primaryLight");
        margin-top: pxToEm(20);
      }

      .txt {
        margin-top: pxtoEm(50);
        
        .bold {
          font-size: pxToPercent(24);
          font-weight: 600;
        }

        .regular {
          font-size: pxToPercent(18);
          font-weight: 400;
          margin-top: 20px;
        }
      }

      .pic {
        width: 100%;
        height: pxToEm(350);
        margin-top: pxToEm(40);
        background-position: center;
        background-size: cover;
      }
    }


    .white-button {
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        width: pxToEm(50);
        height: pxToEm(50);
        position: absolute;
        bottom: -25px;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
        cursor: pointer;

        .arrow-cont {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          .arrow {
            border: solid getColor("primaryLight");
            border-width: 0 3px 3px 0;
            display: inline-block;
            padding: 5px;
            transform: rotate(-135deg);
            position: relative;
            margin-top: 5px;
          }
        }
      }
  }
}
</style>
<style lang="less">
.btn-big {
  .content {
    .txt {
      font-size: pxToPercent(24);
    }
  }
}
</style>
<script>
import headerImg from "@/assets/doorlopend-header.jpg";
import btnBig from "@/components/common/btn-big";

export default {
  name: "doorlopendHeader",
  components: {
    btnBig
  },
  data() {
    return {
      headerImg,
      calcActive: false
    };
  },
  methods:{
    toggleCalc(state){
      this.calcActive = state;
    }
  }
};
</script>
