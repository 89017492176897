<template>
  <div class="income-source">
    <div class="expandable-items">
      <div class="exp-item">
        <div class="exp-item-title-container">
          <div class="exp-item-title" @click="toggleBox">
            <div class="left">Ik heb een negatieve BKR-registratie</div>
  
            <div class="right">
              <div class="arrow"></div>
            </div>
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-subtitle">Heeft u een negatieve BKR-registratie?</div>

          <div class="exp-item-body">
            Een negatieve BKR registratie kan voor langere tijd gevolgen
            hebben. Toch kunnen onze adviseurs u goed van dienst zijn wanneer
            u de komende tijd wat extra geld nodig heeft. Leenattent gaat
            graag met u na wat er wél mogelijk is.
          </div>

          <div class="btn-container" @click="close">
            <router-link to="/BKR-registratie">
              <btn-big class="secondary-pink" :width="230" :height="74">
                <div class="content">
                  <div class="txt">Lees meer</div>
                  <img src="@/assets/arrow.svg" alt>
                </div>
              </btn-big>
            </router-link>
          </div>
        </div>
      </div>

      <div class="exp-item">
        <div class="exp-item-title-container">
          <div class="exp-item-title" @click="toggleBox">
            <div class="left">Ik ben zelfstandige</div>
  
            <div class="right">
              <div class="arrow"></div>
            </div>
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-body">
            Geld lenen als zelfstandige? Het is al na 2 jaar als ondernemer mogelijk om geld te
            lenen door middel van een doorlopend krediet of een persoonlijke lening. U kunt
            zakelijk geld investeren in nieuwe inventaris, een nieuwe machine of wellicht andere
            bedrijfsonderdelen. Er privé voor zorgen dat u een nette auto kunt rijden, eens
            lekker op vakantie of de keuken verbouwen! U kunt geld lenen als zelfstandige en
            het geld vervolgens zakelijk of privé besteden aan een doel naar keuze.
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-body">
            U kunt op verschillende manieren geld lenen als zelfstandige. Heeft u bijvoorbeeld
            een eenmanszaak, een vof of een BV? Het is in alle drie de gevallen goed mogelijk
            om een krediet voor zelfstandigen af te sluiten. Dankzij de diverse vormen kunt u
            tijdelijk zorgen voor wat meer geld achter de hand of zorgen we er met een eenmalige
            lening voor dat u een grote uitgave kunt doen. Uiteraard zorgen we voor een zo laag
            mogelijke rente, zodat u als zelfstandige op een voordelige wijze geld kunt lenen.
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-body">
            Neem contact met ons op voor meer informatie. Dat kan door online een aanvraag te
            doen, een livechat te starten of telefonisch contact op te nemen. Onze adviseurs
            staan voor u klaar.
          </div>
        </div>
      </div>

      <div class="exp-item">
        <div class="exp-item-title-container">
          <div class="exp-item-title" @click="toggleBox">
            <div class="left">Ik heb een tijdelijk dienstverband</div>
  
            <div class="right">
              <div class="arrow"></div>
            </div>
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-body">
            Een tijdelijk dienstverband en geld lenen om de kinderen te laten studeren, even
            lekker op vakantie te kunnen of de nieuwe keuken te financieren? Van een nieuwe
            auto tot aan nieuwe apparatuur of gewoon een appeltje voor de dorst: er is gelukkig
            van alles mogelijk. Het is bij een tijdelijk dienstverband geen probleem om geld te lenen.
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-body">
            Bij een tijdelijk dienstverband slagen onze adviseurs erin voor een lage rente te
            zorgen, zonder dat daar een intentieverklaring voor nodig is. Een persoonlijke
            lening of een doorlopend krediet werkt wat dat betreft net wat anders dan de
            hypotheek. Dat heeft te maken met verschillende factoren. Het gaat om een kleiner
            kredietbedrag en de looptijd zal doorgaans (een stuk) korter zijn. Dit betekent
            dat de geldverstrekker beduidend minder risico loopt. Het is het inkomen uit uw
            tijdelijk dienstverband dat vooral van belang is, dit bepaalt in grote mate of
            u het krediet kunt krijgen.
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-body">
            Neem contact met ons op voor meer informatie. Dat kan door online een aanvraag te
            doen, een livechat te starten of telefonisch contact op te nemen. Onze adviseurs
            staan voor u klaar.
          </div>
        </div>
      </div>

      <div class="exp-item">
        <div class="exp-item-title-container">
          <div class="exp-item-title" @click="toggleBox">
            <div class="left">Ik ben uitzendkracht</div>
  
            <div class="right">
              <div class="arrow"></div>
            </div>
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-body">
            U werkt via een uitzendbureau en wilt geld lenen voor een mooie nieuwe auto, een
            ontspannende vakantie of wellicht gewoon een weekendje weg. Welke doelstelling u
            ook heeft, zelfs wanneer u bij een werkgever werkt via een uitzendbureau kunt u
            relatief eenvoudig geld lenen. Houd er rekening mee dat we u in fase B of C van
            dienst kunnen zijn, in fase A is er helaas nog onvoldoende sprake van zekerheid
            om een krediet af te sluiten.
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-body">
            Begint u met werken voor een uitzendbureau? Dan is er doorgaans sprake van fase A.
            We noemen dat ook wel fase 1 of 2. Het is dan nog niet mogelijk om geld te lenen
            op basis van deze overeenkomst, daarvoor is de onzekerheid helaas nog te groot.
            Werkt u voor een uitzendbureau en is er sprake van fase B of C? We noemen dat ook
            wel fase 3 of 4. Het is in die gevallen wel goed mogelijk om geld te lenen. Er is
            meer sprake van zekerheid, zodat onze adviseurs u kunnen adviseren over wat er
            mogelijk is.
          </div>
        </div>

        <div class="exp-item-text">
          <div class="exp-item-body">
            Neem contact met ons op voor meer informatie. Dat kan door online een aanvraag te
            doen, een livechat te starten of telefonisch contact op te nemen. Onze adviseurs
            staan voor u klaar.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
  .income-source {
    .expandable-items {
      display: flex;
      flex-direction: column;

      .exp-item {
        max-width: 768px;
        height: pxToEm(95);
        margin-bottom: pxToEm(30);
        background-color: getColor('bgColor');
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        overflow: hidden;

        .exp-item-title-container {
          cursor: pointer;

          .exp-item-title {
            height: pxToEm(95);
            margin: 0 pxToEm(20);
            display: flex;
            align-items: center;

            .left {
              height: 100%;
              flex: 2 1 auto;
              display: flex;
              align-items: center;
              font-size: pxToPercent(18);
              font-weight: 600;
            }

            .right {
              width: pxToEm(40);
              height: 100%;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .arrow {
                position: relative;
                padding: pxToEm(5);;
                transform: rotate(45deg);
                border: solid getColor('textColor');
                border-width: 0 pxToEm(2) pxToEm(2) 0;
              }
            }
          }
        }

        .exp-item-text {
          margin: pxToEm(20) pxToEm(30) pxToEm(50) pxToEm(20);

          .exp-item-subtitle {
            margin-bottom: pxToEm(10);
            font-weight: 700;
            line-height: 1.5em;
          }
          
          .exp-item-body {
            font-size: pxToPercent(16);
            line-height: 2.2em;
          }

          a {
            text-decoration: none;
          }
        }

        a {
          text-decoration: none;
        }

        .btn-container {
          width: 230px;

          .btn-big {
            margin-top: pxToEm(50);

            .content {
              margin: 0 pxToEm(20);

              .txt {
                font-size: pxToPercent(18);
                font-weight: 700;
              }

              img {
                width: pxToEm(36);
                height: pxToEm(29);
              }
            }
          }
        }

        &.expanded {
          height: auto;

          .exp-item-title .arrow {
            transform: rotate(-135deg);
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
</style>

<script>
  import btnBig from "@/components/common/btn-big";

  export default {
    name: "incomeSource",
    components: {
      btnBig
    },
    data() {
      return {
      };
    },
    methods: {
      toggleBox() {
        var target = event.target.closest(".exp-item");
        target.classList.toggle("expanded");
      },
      close: function () {
        event.stopPropagation();
        $store.state.modal = false;
      }
    }
  };
</script>