<template>
  <div class="persoonlijke">
    <persoonlijke-header class="area"></persoonlijke-header>
    <calculator class="area"></calculator>
    <persoonlijke-body class="area"></persoonlijke-body>
   <safe-loan-info class="area"></safe-loan-info>
   <loan-info class="area"></loan-info>
    <desk-contact class="area"></desk-contact>
  </div>
</template>

<style lang="less" scoped>
</style>

<script>
import deskContact from "@/components/desktop/desk-contact.vue";
import persoonlijkeHeader from "@/components/desktop/persoonlijke/header.vue";
import calculator from "@/components/desktop/calculators/A/calculator-form";
import persoonlijkeBody from "@/components/desktop/persoonlijke/body.vue";
import safeLoanInfo from "@/components/desktop/safe-loan-info.vue";
import loanInfo from "@/components/desktop/loan-bottom-info.vue";
export default {
  name: "deskPersoonlijke",
  components: {
    deskContact,
    persoonlijkeHeader,
    calculator,
    persoonlijkeBody,
    safeLoanInfo,
    loanInfo
  },
  created() {
    $store.commit("resetForms");
    $store.state._loanRequest.loanType = $store.state.loanType.PERSOONLIJKE;
  }
};
</script>