<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="title">
          {{block.Title}}
        </div>

        <div class="subtitle" v-html="markdownToHTML(block.Subtitle)"></div>

        <div class="partners">
          <div class="row" v-for="rowIdx in Math.ceil(block.Partners.length / 3)">
            <div class="cell" v-for="partner in block.Partners.slice(3 * (rowIdx - 1), 3 * rowIdx)">
              <img :src="strapiAssetUrl(partner.Image.data.attributes.url)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    max-width: 1200px;

    .section {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: center;
      margin: pxToEm(50);

      .title {
        font-size: pxToPercent(80);
      }

      .subtitle {
        font-size: pxToPercent(20);
        width: 595px;
        margin: pxToEm(50) auto;
        line-height: 2.1em;
      }

      .partners {
        max-width: pxToEm(1100);
        display: flex;
        flex-direction: column;
        margin: pxToEm(50) auto;
        width: 100%;

        .row {
          display: flex;
          height: pxToEm(190);

          .cell {
            flex: 1 1 auto;
            width: 33%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: pxToEm(180);
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import {marked} from "marked";
import {strapiAssetUrl} from "@/JS/helpers";

export default {
  name: 'DesktopPartnersSectionBlock',
  props: {
    block: Object
  },
  methods: {
    markdownToHTML: function(markdown) {
      return marked(markdown);
    },
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path)
    }
  }
}
</script>
