<template>
  <div :class="'area ' + (block.Background === 'light' ? 'white' : '')">
    <div class="layout">
      <div class="section">
        <div class="dynamic-section">
          <div id="anchor-berekenzelf-top" style="position:absolute;top:-300px;"></div>
          <!-- Section 1 -->
          <div class="section1" v-if="formCursor == null">
            <div class="title">Weten wat u maximaal kan lenen?</div>

            <div class="sep-70"></div>

            <input-dropdown
              :options="$store.state.funnelOptions"
              placeholder="Maak uw keuze"
              v-model="Funnel"
            ></input-dropdown>

            <div class="sep-70"></div>
          </div>
          <!-- Section 2 -->
          <div class="section2" v-else>
            <div class="numbers">
              <div
                class="number"
                v-for="(form, index) in getTabs()"
                :key="index"
                :class="{ active: index == formCursor ? true : false, inactive: index < formCursor ? true : false }"
              >
                <span>{{ index + 1 }}</span>
              </div>
            </div>

            <div class="container">
              <!--TAB 1 ////////////////////////////////////////////////-->
              <div
                class="tab tab1"
                v-if="getTabs()[formCursor] == 1"
                :class="{formcheck:tabsState.tab1.state == false}"
              >
                <div
                  class="error"
                  v-show="tabsState.tab1.state == false"
                >Er zijn enkele velden niet correct ingevuld.</div>
                <div class="sep-40" v-show="tabsState.tab1.state == false"></div>
                <div class="tab-body">
                  <div class="title">Hoeveel geld wilt u lenen?</div>

                  <div class="row-num-input">
                    <input-num v-model="$store.state._loanRequest.loanAmount"></input-num>
                  </div>
                </div>
              </div>

              <!--TAB 2 ////////////////////////////////////////////////-->
              <div
                class="tab tab2"
                v-if="getTabs()[formCursor] == 2"
                :class="{formcheck:tabsState.tab2.state == false}"
              >
                <div class="sep-70" v-show="tabsState.tab2.state == false"></div>
                <div
                  class="error"
                  v-show="tabsState.tab2.state == false"
                >Er zijn enkele velden niet correct ingevuld.</div>

                <div class="sep-100"></div>

                <div class="tab-body">
                  <div class="row-11 select-input">
                    <div class="txt-label">
                      <span>Hoeveel leningen heeft u lopen?</span>
                    </div>
                    <div class="inputs align-right">
                      <input-dropdown
                        class="input-dropdown-180"
                        :init="$store.state._loanRequest.prevLoans.ui.init"
                        :options="$store.state.loansDropdown"
                        v-model="Loans"
                      ></input-dropdown>
                    </div>
                  </div>
                </div>

                <div class="loans" v-if="Loans > 0">
                  <div
                    class="loan"
                    v-for="(loan, index) in $store.state._loanRequest.prevLoans"
                    :key="index"
                  >
                    <div class="sep-50"></div>

                    <div class="row-sub-title">
                      <div class="sub-title">Lening {{ index + 1 }}</div>
                    </div>

                    <div class="sep-50"></div>

                    <div class="tab-body">
                      <div class="row-11 text-input">
                        <div class="txt-label">
                          <span>Maatschappij</span>
                        </div>
                        <div class="inputs">
                          <input-text v-model="loan.name"></input-text>
                        </div>
                      </div>

                      <div class="row-11 num-input">
                        <div class="txt-label">
                          <span>Openstaand bedrag</span>
                        </div>
                        <div class="inputs">
                          <input-num v-model="loan.amount"></input-num>
                        </div>
                      </div>

                      <div class="row-11 num-input">
                        <div class="txt-label">
                          <span>Effectieve jaarrente</span>
                        </div>
                        <div class="inputs">
                          <input-num v-model="loan.rate"></input-num>
                        </div>
                      </div>

                      <div class="row-11 toggle-input">
                        <div class="txt-label">
                          <span>Inlossen</span>
                        </div>
                        <div class="inputs">
                          <input-boolean :init="loan.ransom.ui.init" v-model="loan.ransom"></input-boolean>
                        </div>
                      </div>
                    </div>

                    <div class="sep-50"></div>
                  </div>
                </div>

                <div class="sep-100"></div>
              </div>

              <!--TAB 3 ////////////////////////////////////////////////-->
              <div
                class="tab tab3"
                v-if="getTabs()[formCursor] == 3"
                :class="{formcheck:tabsState.tab3.state == false}"
              >
                <div class="sep-70" v-show="tabsState.tab3.state == false"></div>
                <div
                  class="error"
                  v-show="tabsState.tab3.state == false"
                >Er zijn enkele velden niet correct ingevuld.</div>
                <div class="sep-100"></div>

                <div class="tab-body">
                  <div class="row-11 toggle-input">
                    <div class="txt-label">
                      <span>Bent u getrouwd of samenwonend?</span>
                    </div>
                    <div class="inputs">
                      <input-boolean v-model="$store.state._person.married"></input-boolean>
                    </div>
                  </div>

                  <div class="row-11 select-input">
                    <div class="txt-label">Wat is uw woonsituatie?</div>
                    <div class="inputs">
                      <input-dropdown
                        class="input-dropdown-300"
                        :options="$store.state.livingStateDropdown"
                        v-model="$store.state._person.livingState"
                      ></input-dropdown>
                    </div>
                  </div>

                  <div class="row-11 toggle-input">
                    <div class="txt-label">Heeft u inwonende kinderen?</div>
                    <div class="inputs">
                      <input-boolean v-model="$store.state._person.children"></input-boolean>
                    </div>
                  </div>
                </div>

                <div class="sep-100"></div>
              </div>

              <!--TAB 4 ////////////////////////////////////////////////-->
              <div
                class="tab tab4"
                v-if="getTabs()[formCursor] == 4"
                :class="{formcheck:tabsState.tab4.state == false}"
              >
                <div class="sep-70" v-show="tabsState.tab4.state == false"></div>
                <div
                  class="error"
                  v-show="tabsState.tab4.state == false"
                >Er zijn enkele velden niet correct ingevuld.</div>

                <div class="sep-100"></div>

                <div class="tab-body">
                  <div class="row-1-15 text-input">
                    <div class="txt-label">
                      <span>Wat zijn uw woonlasten?</span>
                    </div>
                    <div class="inputs">
                      <input-num v-model="$store.state._person.monthlyExpenses"></input-num>
                    </div>
                  </div>

                  <div class="row-1-15 text-input">
                    <div class="txt-label">
                      <span>Wat is uw netto inkomen per maand?</span>
                    </div>
                    <div class="inputs">
                      <input-num v-model="$store.state._person.income"></input-num>
                    </div>
                  </div>

                  <div class="row-1-15 select-input">
                    <div class="txt-label">
                      <span>Wat is uw inkomstenbron?</span>
                    </div>
                    <div class="inputs align-right">
                      <input-dropdown
                        class="input-dropdown-300"
                        :options="$store.state.incomeSourceDropdown"
                        v-model="$store.state._person.incomeSource"
                      ></input-dropdown>
                    </div>
                    <div class="info-container">
                      <div class="info-icon" @click="toggleModal">
                        <div class="bg">
                          <img src="@/assets/info.png">
                        </div>
                      </div>

                      <div class="modal-container" v-show="$store.state.modal">
                        <modal></modal>
                      </div>
                    </div>
                  </div>

                  <div class="row-1-15 date-input">
                    <div class="txt-label">
                      <span>Datum in dienst?</span>
                    </div>
                    <div class="inputs align-right">
                      <input-date v-model="$store.state._person.dateService"></input-date>
                    </div>
                  </div>

                  <div class="row-1-15 text-input" v-if="$store.state._person.married == true">
                    <div class="txt-label">
                      <span>Wat is het netto inkomen per maand van uw partner?</span>
                    </div>
                    <div class="inputs">
                      <input-num v-model="$store.state._person.partnerIncome"></input-num>
                    </div>
                  </div>
                </div>

                <div class="sep-100"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="btn-section" v-if="$store.state._loanRequest.loanOption.ui.init">
          <div class="sep-50"></div>

          <!-- Inactive button -->
          <btn-big
            class="prev secondary-pink"
            style="left:0;"
            v-if="formCursor != null"
            :width="277"
            :height="92"
            :click="() => nav(-1)"
            v-anchor="'#anchor-berekenzelf-top'"
          >
            <div class="content">
              <img style="transform: rotate(180deg);" src="@/assets/arrow.svg">
              <div class="txt">Vorige</div>
            </div>
          </btn-big>

          <!-- Active button -->
          <btn-big
            class="next secondary-pink"
            style="right:0"
            :width="277"
            :height="92"
            :click="() => nav(1)"
            v-anchor="'#anchor-berekenzelf-top'"
          >
            <div class="content">
              <div class="txt">Volgende</div>
              <img src="@/assets/arrow.svg">
            </div>
          </btn-big>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@gridColGap: 2.5em;
@gridRowGap: 2em;
.area {
  background-color: getColor("greyLight");

  &.white {
    background-color: #fff;
  }

  .layout {
    max-width: 1200px;

    .section {
      .input-boolean {
        width: pxToEm(180);
      }

      .input-date {
        width: pxToEm(300);
      }

      .input-text {
        height: pxToEm(60);
      }

      .txt-label {
        font-size: pxToPercent(18);
        font-weight: 300;
        line-height: 2.2em;
      }

      .dynamic-section {
        max-width: pxToEm(964);
        position: relative;
        margin: 0 auto;

        .section1 {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;

          .title {
            font-size: pxToPercent(36);
          }

          .input-dropdown {
            width: pxToEm(604);
          }
        }

        .section2 {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: pxToEm(50);
          background-color: getColor("whiteColor");
          border: 1px solid getColor("grayLtColor");

          .numbers {
            width: 649px;
            height: 52px;
            position: absolute;
            top: -30px;
            display: flex;
            border-radius: 3px;
            border: 1px solid getColor("grayDkColor");

            .number {
              flex: 1 1 auto;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: getColor("whiteColor");

              &.active {
                color: getColor("whiteColor");
                background-color: getColor("blackColor");
              }
              &.inactive {
                background-color: getColor("greyLight");
              }
              &:not(:last-child) {
                border-right: 1px solid getColor("grayDkColor");
              }
            }
          }

          .container {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            text-align: left;

            .tab {
              width: 100%;

              .error {
                color: getColor("redColor");
                font-style: italic;
                align-self: flex-start;
              }

              &.tab1 {
                max-width: pxToEm(570);
                min-height: pxToEm(370);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .title {
                  font-size: pxToPercent(36);
                  font-weight: 300;
                  letter-spacing: 0.05em;
                  text-align: center;
                  margin-bottom: pxToEm(10);
                }
              }

              &.tab2 {
                max-width: pxToEm(610);
              }

              &.tab3 {
                max-width: pxToEm(726);
              }

              &.tab4 {
                max-width: pxToEm(766);

                .info-container {
                  position: absolute;
                  right: pxToEm(45);
                  margin-top: pxToEm(14);

                  .info-icon {
                    .bg {
                      width: pxToEm(40);
                      height: pxToEm(40);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      background-color: getColor("grayLtColor");
                      border-radius: 50%;
                      cursor: pointer;
                    }

                    &:hover {
                      .bg {
                        background-color: getColor("primaryLight");
                      }
                    }
                  }
                }
              }

              .tab-body {
                display: grid;
                grid-row-gap: @gridRowGap;

                .row-11 {
                  height: pxToEm(70);
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-column-gap: @gridColGap;
                  align-items: center;

                  .align-right {
                    justify-self: end;
                  }

                  .input-dropdown-180 {
                    width: pxToEm(180);
                  }

                  .input-dropdown-300 {
                    width: pxToEm(300);
                  }
                }

                .row-1-15 {
                  height: pxToEm(70);
                  display: grid;
                  grid-template-columns: 1fr 1.5fr;
                  grid-column-gap: @gridColGap;
                  align-items: center;

                  .align-right {
                    justify-self: end;
                  }
                }

                .input-dropdown-300 {
                  width: pxToEm(300);
                }

              }

              .loans {
                .row-sub-title {
                  height: pxToEm(70);
                  display: grid;
                  align-items: center;

                  .sub-title {
                    font-size: pxToPercent(24);
                  }
                }
              }
            }
          }
        }
      }

      .modal-container {
        position: absolute;
        z-index: 800;
      }

      .btn-section {
        max-width: pxToEm(964);
        height: pxToEm(288);
        position: relative;
        margin: 0 auto;

        .btn-big {
          width: pxToEm(277);
          height: pxToEm(92);
          position: absolute;
          border-bottom-width: pxToEm(18);

          .content {
            margin-left: pxToEm(40);
            margin-right: pxToEm(40);

            .txt {
              font-size: pxToPercent(20);
              font-weight: 700;
            }

            img {
              width: pxToEm(56);
              height: pxToEm(45);
            }
          }
        }
      }
    }
  }
}
</style>

<script>
import {checkTab, strapiAssetUrl} from "@/JS/helpers";
import {marked} from "marked";
import btnBig from "@/components/common/btn-big";
import inputNum from "@/components/common/input-num";
import inputBoolean from "@/components/common/input-boolean";
import inputDropdown from "@/components/common/input-dropdown";
import inputText from "@/components/common/input-text";
import inputDate from "@/components/common/input-date";
import modal from "@/components/desktop/info/modal";
import {sumLenings} from "@/data/leningOversluitenCalc";
export default {
  name: "DesktopCalculateLoadSectionBlock",
  props: {
    block: Object
  },
  components: {
    btnBig,
    inputNum,
    inputBoolean,
    inputDropdown,
    inputText,
    inputDate,
    modal
  },
  data() {
    return {
      formCursor: null,
      tabsState: {
        tab1: {
          fields: [$store.state._loanRequest.loanAmount],
          state: undefined
        },
        tab2: {
          fields: [$store.state._loanRequest.prevLoans],
          state: undefined
        },
        tab3: {
          fields: [$store.state._person.married, $store.state._person.livingState, $store.state._person.children],
          state: undefined
        },
        tab4: {
          fields: [$store.state._person.monthlyExpenses, $store.state._person.income, $store.state._person.incomeSource, $store.state._person.dateService, $store.state._person.partnerIncome],
          state: undefined
        }
      }
    };
  },
  created() {
    this.Loans = 1;
  },
  computed: {
    Funnel: {
      get() {
        var loanRequest = $store.state._loanRequest;

        if (loanRequest.loanOption == $store.state.funnelBOption)
          loanRequest.selectedFunnel = $store.state.funnel.B;
        else
          loanRequest.selectedFunnel = $store.state.funnel.A;

        return loanRequest.loanOption;
      },
      set(val) {
        var loanRequest = $store.state._loanRequest;
        loanRequest.loanOption = val;
      }
    },
    Loans: {
      get() {
        var loanRequest = $store.state._loanRequest;
        var res = new Number(loanRequest.prevLoans.length);
        res.ui = loanRequest.prevLoans.ui;
        return res;
      },
      set(n) {
        var loanRequest = $store.state._loanRequest;
        if (n != loanRequest.prevLoans.length) {
          if (n > 0) {
            loanRequest.prevLoans.ui.init = true;
            loanRequest.prevLoans.ui.valid = true;
          } else {
            loanRequest.prevLoans.ui.valid = false;
          }
          if (n > loanRequest.prevLoans.length) {
            for (var i = loanRequest.prevLoans.length; i < n; i++) {
              loanRequest.prevLoans.push($store.state.prevLoanTemplate());
            }
          } else loanRequest.prevLoans.splice(n);
        }
      }
    }
  },
  watch: {
    "$store.state._person.married": function(newVal, oldVal) {
      // *** THIS NEEDS A BETTER, CLEANER CODING SOLUTION ***
      var re = /partnerIncome/;
      if ($store.state._person.married == true) {
        for (var field of this.tabsState.tab4.fields) {
          if (field.ui.label && re.test(field.ui.label)) {
            field.ui.valid = false;
          }
        }
      }
    }
  },
  methods: {
    getTabs() {
      var loanRequest = $store.state._loanRequest;

      if (loanRequest.selectedFunnel == $store.state.funnel.A) return [1, 3, 4];
      else if (loanRequest.selectedFunnel == $store.state.funnel.B) return [2, 3, 4];
    },
    nav(dir) {
      var vm = this;
      var maxTabs = vm.getTabs().length - 1;

      if (vm.formCursor == null) vm.formCursor = 0;
      else {
        var _tab = "tab" + this.getTabs()[vm.formCursor];
        var tab = this.tabsState[_tab];
        tab.state = checkTab(tab.fields);

        if (vm.formCursor == 0 && dir == -1) vm.formCursor = null;
        else if (vm.formCursor == maxTabs && dir == 1) {
          if (tab.state == true) {
            $store.state.storedData = true;
            $store.state._loanRequest.loanType = undefined;
            if ($store.state._loanRequest.selectedFunnel == $store.state.funnel.B) $store.dispatch('calculateSavings');
            $store.commit("calcMaxLoanAmount");
            if ($store.state.storedData) vm.$router.push("/offerte");
          }
        } else {
          if (tab.state == false && dir == 1) return;
          vm.formCursor += dir;
        }
      }
    },
    toggleModal(event) {
      event.stopPropagation();
      $store.state.modal = !$store.state.modal;
    }
  }
};
</script>
