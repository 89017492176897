<template>
  <div class="area" v-if="block">
    <div class="layout">
      <div class="section">
        <div class="title">{{ block.Title }}</div>
        <div class="bar"></div>

        <div class="desc" v-html="markdownToHTML(block.Description)"></div>

        <div class="table">
          <div class="header">
            <div class="cell" v-for="(item, i) in block.Companies">
              <img
                width="40px"
                v-if="item.logo.data.attributes.url"
                :src="strapiAssetUrl(item.logo.data.attributes.url)"
              />
              <div :class="i == 0 ? 'pink' : 'black'">
                {{ item.Name }}
              </div>
            </div>
          </div>
          <div class="row" v-for="(item, i) in setOptions(block.Companies)">
            <div class="grey">{{ item[0] }}</div>
            <div class="values">
              <div class="cell pink">
                <span :class="item.length == i ? 'green' : ''"
                  >{{ item[1] }}
                </span>
              </div>

              <div class="cell black">
                <span :class="item.length == i ? 'green' : ''"
                  >{{ item[2] }}
                </span>
              </div>

              <div class="cell black">
                <span :class="item.length == i ? 'green' : ''"
                  >{{ item[3] }}
                </span>
              </div>

              <div class="cell black">
                <span :class="item.length == i ? 'green' : ''"
                  >{{ item[4] }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="note" v-html="markdownToHTML(block.BottomText)"></div>

        <router-link to="/offerte">
          <btn-big class="primary" :height="61" :width="233">
            <div class="content">
              <div class="txt">Offerte aanvragen</div>
              <img src="@/assets/arrow.svg" alt />
            </div>
          </btn-big>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.area {
  background-color: getColor("greyLight");
  .green {
    color: getColor("greenDefault");
    font-weight: 600;
  }
  &:before {
    content: "\A";
    position: absolute;
    background: #fff;
    bottom: 0;
    left: 0;
    height: pxToEm(509);
    width: 100%;
  }

  .layout {
    .section {
      margin: pxToEm(25) pxToEm(50);
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-size: pxToPercent(18);
        color: getColor("primaryDefault");
        font-weight: 700;
      }

      .bar {
        margin-top: pxToEm(5);
        width: pxToEm(70);
        height: pxToEm(4);
        background-color: getColor("primaryDefault");
      }

      .desc {
        margin-top: pxToEm(20);
        font-size: pxToPercent(18);
        line-height: 1.7em;

        span {
          text-decoration: underline;
        }
      }

      .table {
        height: pxToEm(611);
        width: pxToEm(302);
        border: 1px solid getColor("grayLtColor");
        margin-top: pxToEm(35);

        .header {
          background-color: getColor("greyLight");
          height: pxToEm(98);
          align-items: center;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid getColor("grayLtColor");

          .cell {
            align-items: center;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;

            .pink {
              color: getColor("primaryDefault");
              font-size: pxToPercent(11);
              font-weight: 700;
            }

            .black {
              font-size: pxToPercent(11);
              font-weight: 600;
            }

            .green {
              font-size: pxToPercent(11);
              color: getColor("greenDefault");
              font-weight: 600;
            }

            img {
              height: pxToEm(22);
            }

            .rabo-abn {
              display: flex;
              flex-direction: row;
              justify-content: center;

              img {
                &:first-child {
                  margin-right: pxToEm(5);
                }
              }
            }
          }
        }

        .row {
          padding: 5px;
          background-color: getColor("bgColor");
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid getColor("grayLtColor");
          height: pxToEm(84.5);
          justify-content: space-evenly;

          .grey {
            color: getColor("grayLtColor");
            font-size: pxToPercent(12);
            font-weight: 600;
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .values {
            display: flex;
            justify-content: space-between;
            flex: 1 1 auto;

            .cell {
              display: flex;
              flex: 1 1 auto;
              justify-content: center;

              &.pink {
                color: getColor("primaryDefault");
                font-size: pxToPercent(16);
                font-weight: 600;
              }

              &.pink.spacer {
                color: transparent;
              }

              &.black {
                font-size: pxToPercent(16);
                font-weight: 600;
              }

              &.green {
                font-size: pxToPercent(16);
                color: getColor("greenDefault");
                font-weight: 600;
              }
            }
          }
        }
      }

      .note {
        margin-top: 50px;
        line-height: 1.7em;
      }

      .btn-big {
        position: relative;
        flex: 0 1 auto;
        text-align: left;
        margin-top: pxToEm(35);

        .txt {
          font-size: pxToPercent(16);
          font-weight: 700;
          text-align: left;
        }

        .content {
          margin-left: pxToEm(17);
          margin-right: pxToEm(17);
        }

        img {
          width: pxToEm(36);
          height: pxToEm(28);
        }
      }

      a {
        text-decoration: none;
      }
    }
  }
}
</style>

<script>
import btnBig from "@/components/common/btn-big";
import { strapiAssetUrl } from "@/JS/helpers";
import { marked } from "marked";
export default {
  name: "MobileComparisionBlock",
  props: {
    block: Object,
  },
  components: {
    btnBig,
  },
  methods: {
    markdownToHTML: function (markdown) {
      return marked(markdown);
    },
    strapiAssetUrl: function (path) {
      return strapiAssetUrl(path);
    },
    setOptions: function (data) {
      let Tabledata = [];
      let allData = data.map((values) => values.data);
      let TableKeys = data.map((values) => Object.keys(values.data));
      let TableData = TableKeys[0].map((values, i) => {
        let tempdata = [values];
        allData.map((options, j) => {
          tempdata.push(options[values]);
        });
        Tabledata.push(tempdata);
      });
      return Tabledata;
    },
  },
};
</script>
